import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole()
// Vue.use(vConsole)
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import errorDialog from '@/components/errorDialog.vue' // 错误弹窗
import errorDialog2 from '@/components/errorDialog2.vue' // 错误弹窗


Vue.component(CollapseTransition.name, CollapseTransition);
Vue.component('error-dialog', errorDialog);
Vue.component('error-dialog2', errorDialog2);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import VCalendar from 'v-calendar';  
  
  
// 注册到Vue  
Vue.use(VCalendar, {  
  componentPrefix: 'vc',// 可以在这里配置一些全局选项（如果有的话）  
});  

import axios from 'axios'
Vue.prototype.$axios= axios

import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';
Vue.use(VueKatex);

import "vant/lib/index.css";
import { Popup, DatetimePicker  } from 'vant';
Vue.use(Popup);
Vue.use(DatetimePicker);

import { Overlay,Field } from 'vant';

Vue.use(Overlay);

Vue.use(Field);
import { NumberKeyboard } from 'vant';

Vue.use(NumberKeyboard);
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
  //参数配置 可不填

  preLoad: 1.3, // 预加载高度的比例
  attempt: 3 // 尝试加载次数
})


Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  i18n,
  store,
}).$mount('#app')
