module.exports = {
  language: "Slovenian",
  navigator: {
    tools: "AI Orodja",
    draw: "Nariši",
    healthy: "Zdrav",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Priporočilo", "Študij", "Življenje", "Ustvarjanje", "Delo"],
      presetGreet:
        "Živjo, pozdravljen~ Sem tvoj zdravstveni skrbnik, vedno pripravljen, da ti ponudim zanimivo in koristno zdravstveno znanje, da ti pomagam zaščititi tvoje zdravje!",
      presetQuiz: [
        "Kakšni so vzroki kronične utrujenosti?",
        "Kako lahko mladi preprečijo kronične bolezni?",
        "Kako vzdrževati zdrave prehranske navade?",
        "Kako vzdrževati dober spanec?",
      ],
      presets: "Lahko me vprašaš:",
      change: "Spremeni serijo",
      uploadImage: "Pošlji sliko",
      footer:
        "Ker je vsebina ustvarjena z modelom AI, njena autentičnost ne more biti v celoti zagotovljena.",
      remind: "Opomnik za opravila",
      limit:
        "Ta pogovor je dosegel omejitev, prosimo, začnite novo sejo AI klepeta",
      newDialog: "Začni nov pogovor",
      stopRecording: "Kliknite kjerkoli ali ko se števec konča za prepoznavanje",
      send: "Pošlji sporočilo",
      say: "Kliknite za pogovor",
      notImage: "Ta agent ne podpira pošiljanja datotek in slik",
      talking: "Trenutni pogovor poteka",
      drawViolations:
        "Spodbudna AI risba krši pravila, prosimo, vnesite skladno spodbudo.",
      exhausted:
        "Število Q&A je bilo izčrpano, prosimo, pojdite na konec in skenirajte QR kodo za nadaljevanje.",
      serverBusy: "Trenutna mrežna povezava je nestabilna, prosimo, poskusite znova",
      chatHistory: "Zgodovina klepeta",
      chatHistoryDesc: "Zapisniki Q&A se hranijo samo 3 dni",
      todoList: "Seznam opravkov",
      todoDesc: "Podrobnosti opravkov",
      todoListDesc: "Obstaja lahko največ 10 opravkov",
      confirm: "Potrdi",
      cancel: "Prekliči",
      text: "Besedilo ne more biti prazno",
      cancelRemind: "Prekliči opomnik",
      remindTime: "Čas opomnika:",
      remindTitle: "Naslov opomnika:",
      remindContent: "Vsebina opomnika:",
      edit: "Uredi",
      reachLimit: "Dodano do omejitve",
      errorChat:"Še ni zaznana nobena glas, prosimo, poskusite znova",
            noSTT:"Dovoljenje ni pridobljeno, omogočite dovoljenje, preden nadaljujete.",
      error50:"Prišlo je do neznane napake, poskusite znova"
    },
draw: {
      describe: "Opis vaše slike",
      voice: "Vnos z glasom",
      press: "Pritisnite za pogovor",
      placeholder: "Opis slike, ki jo želite ustvariti",
      style: "Slog slikanja",
      styleList: [
        "Črnilo",
        "Cyberpunk",
        "Anime",
        "Origami",
        "Pletenje",
        "Grafika",
        "Risanka",
        "LEGO",
        "Svinčnik",
        "Nariši",
      ],
      record: "Zapis risanja",
      recordDesc: "Shranjeni lahko so do 10 zapisov risanja",
      recordEmpty: "Trenutno nimate zapisov risanja",
      generate: "Ustvari sliko",
      result: "Rezultat generacije slike",
      download: "Prenesi sliko",
      regenerate: "Ponovno generiraj",
      setDial: "Nastavi kot ozadje",
      error: "Generacija slike je naletela na napako",
      errorDesc: "Nestabilna omrežna povezava, prosimo, pošljite znova.",
      confirm: "Potrdi",
      analysiserror: "Analiza je bila neuspešna",
      SetWatchErr:["Številčnica je uspešno nastavljena","Prekliči pošiljanje","Preverjanje podatkov ni uspelo","Preverjanje podatkov ni uspelo","Sinhronizacija številčnice je dosegla zgornjo mejo","Bluetooth je bil prekinjen"],
      SetWatchTips:["Nastavitev ni uspela","Nastavitev številčnice v teku","Številčnica se nastavlja, prosimo, ne zapustite trenutne strani."],
      progressText:"Sinhronizacijo"

    },
    sports: {
      analyze: "Zdravstvena analiza",
      analyzeDesc: "Analizirati osnovne podatke",
      advice: "Zdravstveni nasvet",
      adviceDesc: "Nuditi zdravstvene nasvete",
      experience: "Izkušnja",
      program: {
        plan: "Načrt hujšanja",
        startDate: "Izberite datum začetka",
        chooseDate: "Prosim, izberite 1 dan kot datum začetka",
        week: [
          {
            num: "Ned.",
            day: "Nedelja",
          },
          {
            num: "Pon.",
            day: "Ponedeljek",
          },
          {
            num: "Tor.",
            day: "Torek",
          },
          {
            num: "Sre.",
            day: "Sreda",
          },
          {
            num: "Čet.",
            day: "Četrtek",
          },
          {
            num: "Pet.",
            day: "Petek",
          },
          {
            num: "Sob.",
            day: "Sobota",
          },
        ],
arrange: "Načrtovanje",
        arrangeDesc:
          "Začnite od najbližjega datuma, ki ga izberete, skupno 7 dni treninga; specifična vsebina je kot sledi:",
        confirm: "Potrdi načrt",
        success:
          "Čestitam, uspešno ste prilagodili načrt! Začnite svoje treninge na strani 'Preverjanje vadbe'!",
        successButton: "Pojdi na preverjanje vadbe",
      },
      newmessage: {
        message1: ["Osebni center", "Zdravstveni podatki"],
        message2: ["Zdravje", "Vadba", "Prehrana"],
        message3: ["Spol", "Starost", "Višina", "Teža"],
        message4: [
          "Spanec",
          "SR",
          "Oksigen",
          "Koraki",
          "BP",
          "Krvni tlak",
          "Povzetek",
        ],
        message5: ["Poraba", "Predlog", "Današnji koraki", "Trajanje vadbe"],
        message6: [
          "Preverjanje vadbe",
          "Fitnes domača različica",
          "Fitnes Pro",
          "Načrt hujšanja",
        ],
        message7: [
          "Vadite in živite zdravo življenje",
          "Prilagodite svoj fitnes načrt",
          "Prilagodite svoj fitnes načrt",
          "Ustvarite načrt hujšanja za vas",
        ],
        message8: [
          "Kcal",
          "Koraki",
          "Minute",
          "Seti",
          "Dni",
          "Leta",
          "cm",
          "kg",
          "h", "Tedni"
        ],
        message9: [
          "Preverjanje",
          "Trening",
          "Možnosti vadbe",
          "Moje dosežke",
          "Zaporedne dni preverjanja",
          "Ni preverjeno",
        ],
        message10: [
          "Kliknite za analizo",
          "Zgodovina analiz (do 10 shranjenih)",
          "Analiziraj",
          "Trenutno nimate zgodovinskih zapisov",
          "Zaporedni dnevi preverjanja",
          "Analiza podatkov",
        ],
message11: ["Ženska", "Moški"],
        message12: ["Prekliči", "Potrdi"],
        message13: ["Leta", "Centimetri", "Kilogrami"],
        message14: [
          "Ročni vnos",
          "Pričakovani čas (Tedni)",
          "Spol",
          "Starost",
          "Višina (centimetri)",
          "Teža (kilogrami)",
          "Pričakovana teža (kilogrami)",
        ],
        message15: ["Zgodovina", "Analiza zdravstvenih podatkov", "Zdravstveni nasvet"],
        message16: [
          "Preverjanje uspešno",
          "Preverjanje",
          "Preveriti je mogoče le na isti dan",
        ],
        message17: [
          "Analizo zdravstvenih podatkov je mogoče pridobiti le enkrat na dan, lokalni podatki niso mogoči najti",
          "Zdravstveni nasvet je mogoče pridobiti le enkrat na dan, lokalni podatki niso mogoči najti",
        ],
        message18: [
          "Trenutno pod težo",
          "Vaš BMI indeks je",
          "ni priporočljivo nadaljevati z izgubo teže (Zdrav obseg 18.5<BMI<24)",
        ],
        message19: [
          "Zmerna izguba teže",
          "Priporočljivo je izgubiti največ 500 g na dan",
        ],
        message20: ["imajo načrte", "Ali pokriva obstoječe načrte"],
        message21: ["Enostavno", "Normalno", "Srednje", "Visoko"],
        message22: ["Duh:", "Nizek", "Visok"],
		message23: ["Številokrat je bilo porabljenih. Kupite naročnino za odklep večkrat"],
      },
    },
punch: {
      title: "Končano",
      title2: "Dokončaj",
      h1: "Možnosti vadbe",
      h2: "Moji dosežki",
      button: "Preverjanje",
      day: "Zaporedni dnevi preverjanja",
      dayunit: "Dni",
    },
    eat: {
      title: "Prehrana",
      DietaryIntake: "Jesti",
      kcal: "Kcal",
      Stillingestible: "Preostalo",
      overingest: "Prekomerno",
      Recommendedintake: "Predlagaj",
      Motorconsumption: "Gibanje",
      carbohydrate: "Ogljikovi hidrati",
      protein: "Beljakovine",
      fat: "Maščobe",
      gram: "Grami",
      drinkDk: "Preverjanje vode",
      drinkDkhealth: "Dnevno preverjanje za boljše zdravje",
      eatsuggest: "Predlogi glede prehrane",
      analyzefoodglx: "Analizirajte racionalnost prehrane",
      eatPlan: "Načrt prehrane",
      makeeatPlan: "Prilagodite svoj ekskluzivni načrt prehrane",
      breakfast: "Zajtrk",
      lunch: "Kosilo",
      dinner: "Večerja",
      snack: "Prigrizek",
      addFood: "Dodaj hrano",
      noAddFood: "Nobena hrana ni bila dodana",
      cancle: "Prekliči",
      confirm: "Potrdi",
      tips: "Proaktivno izpolnjevanje se šteje kot soglasje, da zberemo te informacije projekta in jih prikažemo v prehrani",
      addFooded: "Hrana dodana",
      added: "Dodano",
      numFood: "Število živil",
      record: "Zapis",
      dkTitle: "Preverjanje",
      dailygoal: "Dnevni cilj",
      drinkDw: "Skodelice/Dan",
      drinkWater: "Pijte vodo",
      dailygoalWater: "Dnevni cilj pitja vode",
      myachievement: "Moji dosežki",
      lxdkDays: "Zaporedni dnevi preverjanja",
      day: "Dni",
      analyzeHistory: "Zgodovina",
      conclusion: "Povzetek",
      eatDataAnalyze: "Analiza prehranskih podatkov",
      selectStartDate: "Izberite datum začetka",
      chooseoneDate: "Prosim, izberite 1 dan kot datum začetka",
      scheduling: "Načrtovanje",
      schedulingDesc:
        "Začnite od najbližjega datuma, ki ga izberete, skupno 7 dni; specifična vsebina je kot sledi:",
      eatDetail: "Podrobnosti o prehrani",
      analyzeFailed: "Analiza je bila neuspešna",
      noData: "brez podatkov",
    },
  },
  error: {
    error1101:"Omrežna povezava je nestabilna, trenutna časovna omejitev seje",
    error1102:"Trenutno ni omrežja, ponovno se povežite",
    error1103:"Prišlo je do neznane napake",
    error61:"Trenutna storitev ni na voljo",
    error4:"Glas še ni bil prepoznan, poskusite znova",
    error12:"Vnesite standardne besede ali stavke, da jih bo umetna inteligenca razumela in risala",
    error50:"Oprostite, na trenutnem strežniku je prišlo do napake, poskusite znova"

  },
  setting: {
    index: {
      title: "Nastavitve",
      problem: "Povratne informacije o težavah",
      order: "Zapisi naročil",
      notice: "Obvestilo",
      language: "Jezik",
      clear: "Počisti predpomnilnik",
      clearTip: "Ali ste prepričani, da želite\npočistiti predpomnilnik?",
      confirm: "Potrdi",
      cancel: "Prekliči",
    },
language: {
      title: "Jezik",
      langList: [
        "Poenostavljena kitajščina",
        "Tradicionalna kitajščina",
        "Angleščina",
        "Arabščina",
        "Poljščina",
        "Perzijščina",
        "Danščina",
        "Nemščina",
        "Ruščina",
        "Francoščina",
        "Nizozemščina",
        "Češčina",
        "Romunščina",
        "Portugalščina",
        "Japonščina",
        "Tajščina",
        "Turščina",
        "Španščina",
        "Grščina",
        "Italijanščina",
        "Vietnamščina",
        "Hindi",
      ],
    },
    order: {
      title: "Zapisi naročil",
      success: "Uspešno pridobljeno",
      unpaid: "Neplačano",
      paid: "Plačano",
      expired: "Poteklo",
      refunded: "Vrnjen",
      pTime: "Čas plačila",
      eTime: "Čas poteka",
      pMethod: "Način plačila",
      pObject: "Plačilni objekt",
      oNumber: "Številka naročila",
      pType: "Vrsta paketa",
      oTime: "Čas pridobitve",
      watch: "Ogled IMEI",
      delete: "Izbriši naročilo",
      refund: "Zahteva za vračilo",
    },
problem: {
      title: "Povratne informacije o težavah",
      feedback: "Vrsta povratnih informacij",
      please: "Prosim, izberite",
      content: "Vsebina",
      improve:
        "Prosim, zapišite svoje predloge, kot so prošnje za funkcije, pritožbe na izdelek itd. Potrudili se bomo, da izboljšamo",
      upload: "Naloži sliko",
      submit: "Pošlji",
      general: "Splošne povratne informacije",
      purchase: "Povratne informacije povezane z nakupom",
      bug: "Poročilo o napaki",
      suggestion: "Predlog",
      other: "Drugo",
    },
    notice: {
      title: "Obvestilo",
      type: "Vrsta obvestila: ",
      time: "Čas obvestila: ",
    },
    unsubscribe: {
      watch: "Ura:",
      title: "Neprekinjena naročnina",
      subTitle: "Naročeno",
      unsubscribe: "Prekliči naročnino",
      packList: [
        "Premium naročnina",
        "Osnovna naročnina",
        "Standardna naročnina",
      ],
    },
    refund: {
      title: "Zahteva za vračilo",
      number: "Številka naročila",
      content: "Prosim, razložite razlog za vračilo",
      packList: [
        "Pakiet za popust za nove uporabnike",
        "50-kratni paket",
        "100-kratni paket",
        "300-kratni paket",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Pridobite napredni priročnik",
      watchList: [
        "Vklopite uro, poiščite aplikacijo WearAI in kliknite, da jo odprete.",
        "Kliknite na gumb za napredne ugodnosti na domači strani.",
        "Vzemite svoj mobilni telefon in skenirajte QR kodo na uri, da vstopite na stran ugodnosti.",
      ],
    },
  },
}