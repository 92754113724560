import { getInfo } from "@/api/chat";

export default {
    // 获取次数
    setTotalNumber({ commit }) {
        getInfo().then(res => {
            // console.log("action: " + res);
            if(res.data!=null) {
                if(res.data.total_number >= 0) {
                    commit("SET_TOTAL_NUMBER", res.data.total_number);
                } else {
                    commit("SET_TOTAL_NUMBER", 0);
                }
            }
        }).catch( err => {
            console.log(err);
        }) 
    }
}