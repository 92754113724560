module.exports = {
  language: "Svenska",
  navigator: {
    tools: "AI-verktyg",
    draw: "Rita",
    healthy: "Hälsosam",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Rekommendera", "Studera", "Liv", "Skapelse", "Arbete"],
      presetGreet:
        "Hej, hej~ Jag är din hälsovakt, alltid redo att tillhandahålla intressant och användbar hälsokunskap för att hjälpa dig att skydda din hälsa!",
      presetQuiz: [
        "Vad är orsakerna till kronisk trötthet?",
        "Hur kan unga människor förebygga kroniska sjukdomar?",
        "Hur upprätthåller man hälsosamma matvanor?",
        "Hur upprätthåller man god sömn?",
      ],
      presets: "Du kan fråga mig:",
      change: "Ändra batch",
      uploadImage: "Skicka bild",
      footer:
        "Eftersom innehållet genereras av en AI-modell kan dess äkthet inte fullt ut garanteras.",
      remind: "Todo-påminnelse",
      limit:
        "Denna konversation har nått gränsen, vänligen starta en ny AI-chatt-session",
      newDialog: "Starta en ny konversation",
      stopRecording: "Klicka var som helst eller när nedräkningen slutar för att känna igen",
      send: "Skicka meddelande",
      say: "Klicka för att prata",
      notImage: "Denna agent stödjer inte att skicka filer och bilder",
      talking: "Nuvarande konversation pågår",
      drawViolations:
        "AI-teckningsprompten bryter mot reglerna, vänligen ange en kompatibel prompt.",
      exhausted:
        "Antalet Q&A har uttömts, var god gå till slutet av övervakningen och skanna QR-koden för att fortsätta.",
      serverBusy: "Den aktuella nätverkslänken är instabil, vänligen försök igen",
      chatHistory: "Chatt-historik",
      chatHistoryDesc: "Q&A-poster sparas i endast 3 dagar",
      todoList: "Todo-lista",
      todoDesc: "Todo-detaljer",
      todoListDesc: "Maximalt 10 todo kan existera",
      confirm: "Bekräfta",
      cancel: "Avbryt",
      text: "Text kan inte vara tom",
      cancelRemind: "Avbryt påminnelse",
      remindTime: "Påminnelse tid:",
      remindTitle: "Påminnelse titel:",
      remindContent: "Påminnelse innehåll:",
      edit: "Redigera",
      reachLimit: "Lagt till upp till gränsen",
      errorChat:"Ingen röst upptäcktes än, var god försök igen",
            noSTT:"Tillstånd ej erhållits, aktivera behörighet innan du fortsätter.",
      error50:"Ett okänt fel uppstod, försök igen"
    },
draw: {
      describe: "Beskriv din bild",
      voice: "Röstinmatning",
      press: "Tryck för att prata",
      placeholder: "Beskriv bilden du vill generera",
      style: "Målningstil",
      styleList: [
        "Bläckstil",
        "Cyberpunk",
        "Anime",
        "Origami",
        "Stickning",
        "Grafik",
        "Tecknad",
        "LEGO",
        "Blyertspenna",
        "Rita",
      ],
      record: "Ritningspost",
      recordDesc: "Upp till 10 ritningsposter kan sparas",
      recordEmpty: "Du har för närvarande inga ritningsposter",
      generate: "Generera bild",
      result: "Resultat av bildgenerering",
      download: "Ladda ner bild",
      regenerate: "Regenerera",
      setDial: "Ställ in som urtavla",
      error: "Bildgenerering misslyckades",
      errorDesc: "Instabil nätverksanslutning, vänligen skicka in igen.",
      confirm: "Bekräfta",
      analysiserror: "Analys misslyckades",
      SetWatchErr:["Ringen har ställts in","Ringen har ställts in","Dataverifiering misslyckades","Urtavlans datafil är för stor","Synkronisering av urtavlan har nått den övre gränsen","Bluetooth har kopplats bort"],
      SetWatchTips:["Konfigurationen misslyckades","Uppringningsinställning pågår","Urtavlan håller på att ställas in, lämna inte den aktuella sidan."],
      progressText:"Synkronisering"

    },
    sports: {
      analyze: "Hälsanalys",
      analyzeDesc: "Analysera grundläggande data",
      advice: "Hälsoråd",
      adviceDesc: "Ge hälsoråd",
      experience: "Erfarenhet",
      program: {
        plan: "Viktnedgångsplan",
        startDate: "Välj startdatum",
        chooseDate: "Vänligen välj 1 dag som startdatum",
        week: [
          {
            num: "Sön.",
            day: "Söndag",
          },
          {
            num: "Mån.",
            day: "Måndag",
          },
          {
            num: "Tis.",
            day: "Tisdag",
          },
          {
            num: "Ons.",
            day: "Onsdag",
          },
          {
            num: "Tor.",
            day: "Torsdag",
          },
          {
            num: "Fre.",
            day: "Fredag",
          },
          {
            num: "Lör.",
            day: "Lördag",
          },
        ],
arrange: "Planläggning",
        arrangeDesc:
          "Börja från det närmaste datumet du väljer, totalt 7 träningsdagar; det specifika innehållet är som följer:",
        confirm: "Bekräfta arrangemang",
        success:
          "Grattis, du har framgångsrikt anpassat planen! Börja din träning på 'Träningsinskrivning'-sidan!",
        successButton: "Gå till Träningsinskrivning",
      },
      newmessage: {
        message1: ["Personligt centrum", "Hälsodata"],
        message2: ["Hälsa", "Träning", "Kost"],
        message3: ["Kön", "Ålder", "Höjd", "Vikt"],
        message4: [
          "Sömn",
          "HR",
          "Syre",
          "Steg",
          "BP",
          "Tryck",
          "Sammanfattning",
        ],
        message5: ["Konsumera", "Förslag", "Dagens steg", "Träningsduration"],
        message6: [
          "Träningsinskrivning",
          "Fitness Hemversion",
          "Fitness Pro",
          "Viktnedgångsplan",
        ],
        message7: [
          "Träna och lev ett hälsosamt liv",
          "Anpassa din träningsplan",
          "Anpassa din träningsplan",
          "Skapa en viktnedgångsplan för dig",
        ],
        message8: [
          "Kcal",
          "Steg",
          "Minuter",
          "Set",
          "Dagar",
          "År",
          "cm",
          "kg",
          "h", "Veckor"
        ],
        message9: [
          "Checka in",
          "Träning",
          "Träningsalternativ",
          "Mina prestationer",
          "Konsekutiva incheckningsdagar",
          "Ej incheckad",
        ],
        message10: [
          "Klicka för att analysera",
          "Analyshistorik (upp till 10 sparade)",
          "Analysera",
          "Du har för närvarande inga historiska poster",
          "Konsekutiva incheckningsdagar",
          "Dataanalys",
        ],
message11: ["Kvinna", "Man"],
        message12: ["Avbryt", "Bekräfta"],
        message13: ["År", "Centimeter", "Kilogram"],
        message14: [
          "Manuell inmatning",
          "Förväntad tid (Veckor)",
          "Kön",
          "Ålder",
          "Höjd (Centimeter)",
          "Vikt (Kilogram)",
          "Förväntad vikt (Kilogram)",
        ],
        message15: ["Historia", "Hälsodataanalys", "Hälsoråd"],
        message16: [
          "Incheckning lyckad",
          "Checkar in",
          "Kan bara checka in samma dag",
        ],
        message17: [
          "Hälsodataanalys kan endast erhållas en gång om dagen, och lokala data kan inte hittas",
          "Hälsoråd kan endast erhållas en gång om dagen, och lokala data kan inte hittas",
        ],
        message18: [
          "Under normalvikt",
          "Ditt BMI-index är",
          "det är inte rekommenderat att fortsätta gå ner i vikt (Hälsosamt intervall 18.5<BMI<24)",
        ],
        message19: [
          "Måttlig viktnedgång",
          "Det rekommenderas att gå ner högst 500g per dag",
        ],
        message20: ["har planer", "Täckes det av befintliga planer"],
        message21: ["Lätt", "Normal", "Medium", "Hög"],
        message22: ["Andan:", "Låg", "Hög"],
		message23: ["Antalet gånger har använts upp. Köp en prenumeration för att låsa upp fler gånger"],
      },
    },
    punch: {
      title: "Klar",
      title2: "Avsluta",
      h1: "Träningsalternativ",
      h2: "Mina prestationer",
      button: "Checka in",
      day: "Konsekutiva incheckningsdagar",
      dayunit: "Dagar",
    },
eat: {
      title: "Kost",
      DietaryIntake: "Äta",
      kcal: "Kcal",
      Stillingestible: "Återstående",
      overingest: "Över",
      Recommendedintake: "Förslag",
      Motorconsumption: "Motion",
      carbohydrate: "Kolhydrater",
      protein: "Protein",
      fat: "Fett",
      gram: "Gram",
      drinkDk: "Vatten Check-in",
      drinkDkhealth: "Daglig check-in för en hälsosammare kropp",
      eatsuggest: "Kostråd",
      analyzefoodglx: "Analysera rimligheten av kosten",
      eatPlan: "Kostplan",
      makeeatPlan: "Anpassa din exklusiva kostplan",
      breakfast: "Frukost",
      lunch: "Lunch",
      dinner: "Middag",
      snack: "Mellanmål",
      addFood: "Lägg till mat",
      noAddFood: "Ingen mat tillagd",
      cancle: "Avbryt",
      confirm: "Bekräfta",
      tips: "Proaktiv ifyllnad anses som samtycke för oss att samla in denna projektinformation och visa den i kosten",
      addFooded: "Mat tillagd",
      added: "Tillsatt",
      numFood: "Matvaror",
      record: "Post",
      dkTitle: "Check-in",
      dailygoal: "Dagligt mål",
      drinkDw: "Muggar/ dag",
      drinkWater: "Drick vatten",
      dailygoalWater: "Dagligt dricksvattenmål",
      myachievement: "Mina prestationer",
      lxdkDays: "Konsekutiva stämpeldagar",
      day: "Dagar",
      analyzeHistory: "Historia",
      conclusion: "Sammanfattning",
      eatDataAnalyze: "Kostdataanalys",
      selectStartDate: "Välj startdatum",
      chooseoneDate: "Vänligen välj 1 dag som startdatum",
      scheduling: "Planläggning",
      schedulingDesc:
        "Börja från det närmaste datumet du väljer, totalt 7 dagar; det specifika innehållet är som följer:",
      eatDetail: "Kostdetaljer",
      analyzeFailed: "Analys misslyckades",
      noData: "inga data",
    },
  },
  error: {
    error1101:"Nätverksanslutningen är instabil, den aktuella sessionens timeout",
    error1102:"Det finns inget nätverk för närvarande, vänligen anslut igen",
    error1103:"Ett okänt fel uppstod",
    error61:"Den nuvarande tjänsten är inte tillgänglig",
    error4:"Rösten har inte identifierats ännu, försök igen",
    error12:"Ange standardord eller meningar för att AI ska förstå och rita",
    error50:"Tyvärr, det finns ett fel på den aktuella servern, försök igen"

  },
  setting: {
    index: {
      title: "Inställningar",
      problem: "Problemåterkoppling",
      order: "Orderhistorik",
      notice: "Notifikation",
      language: "Språk",
      clear: "Rensa cache",
      clearTip: "Är du säker på att du vill\nrensa cachen?",
      confirm: "Bekräfta",
      cancel: "Avbryt",
    },
    language: {
      title: "Språk",
      langList: [
        "Förenklad kinesiska",
        "Traditionell kinesiska",
        "Engelska",
        "Arabiska",
        "Polska",
        "Persiska",
        "Danska",
        "Tyska",
        "Ryska",
        "Franska",
        "Holländska",
        "Tjeckiska",
        "Rumänska",
        "Portugisiska",
        "Japanska",
        "Thailändska",
        "Turkiska",
        "Spanska",
        "Grekiska",
        "Italienska",
        "Vietnamesiska",
        "Hindi",
      ],
    },
order: {
      title: "Orderhistorik",
      success: "Framgångsrikt hämtad",
      unpaid: "Obetald",
      paid: "Betald",
      expired: "Utlöpad",
      refunded: "Återbetalad",
      pTime: "Betalningstid",
      eTime: "Utgångstid",
      pMethod: "Betalningsmetod",
      pObject: "Betalningsobjekt",
      oNumber: "Ordernummer",
      pType: "Pakettyp",
      oTime: "Hämtningstid",
      watch: "Visa IMEI",
      delete: "Radera order",
      refund: "Begär återbetalning",
    },
    problem: {
      title: "Problemåterkoppling",
      feedback: "Återkopplingstyp",
      please: "Vänligen välj",
      content: "Innehåll",
      improve:
        "Vänligen skriv ner dina förslag, såsom funktionsförfrågningar, produktklagomål, etc. Vi kommer att sträva efter att förbättra",
      upload: "Ladda upp bild",
      submit: "Skicka in",
      general: "Allmän återkoppling",
      purchase: "Köprelaterat",
      bug: "Bugg rapport",
      suggestion: "Förslag",
      other: "Annan",
    },
    notice: {
      title: "Notifikation",
      type: "Notifikationstyp: ",
      time: "Notifikationstid: ",
    },
    unsubscribe: {
      watch: "Klocka:",
      title: "Fortsatt prenumeration",
      subTitle: "Prenumererad",
      unsubscribe: "Avprenumerera",
      packList: [
        "Premiumprenumeration",
        "Grundprenumeration",
        "Standardprenumeration",
      ],
    },
refund: {
      title: "Begär återbetalning",
      number: "Ordernummer",
      content: "Vänligen förklara anledningen till återbetalningen",
      packList: [
        "Ny användar rabattpaket",
        "50 gånger paket",
        "100 gånger paket",
        "300 gånger paket",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Få avancerad handledning",
      watchList: [
        "Slå på klockan, hitta WearAI-appen och klicka för att öppna.",
        "Klicka på knappen för avancerade fördelar på startsidan.",
        "Ta fram din mobiltelefon och skanna QR-koden på klockan för att gå till fördelssidan.",
      ],
    },
  },
}