import Vue from 'vue';
import I18n from 'vue-i18n';
Vue.use(I18n);

export default new I18n({
    locale: "en-US",
    fallbackLocale: "en-US",
    messages: {
        "zh-CN": require("./lang/zh-CN"),
        "zh-HK": require("./lang/zh-HK"),
        "en-US": require("./lang/en-US"),
        "ar-AE": require("./lang/ar-AE"),
        "pl-PL": require("./lang/pl-PL"),
        "fa-IR": require("./lang/fa"),
        "da-DK": require("./lang/da-DK"),
        "de-DE": require("./lang/de-AT"),
        "ru-RU": require("./lang/ru-RU"),
        "fr-FR": require("./lang/fr-FR"),
        "nl-NL": require("./lang/nl-NL"),
        "cs-CZ": require("./lang/cs-CZ"),
        "ro-RO": require("./lang/ro-RO"),
        "pt-BR": require("./lang/pt-BR"),
        "ja-JP": require("./lang/ja-JP"),
        "th-TH": require("./lang/th-TH"),
        "tr-TR": require("./lang/tr-TR"),
        "ca-ES": require("./lang/ca-ES"),
        "el-GR": require("./lang/el-GR"),
        "it-IT": require("./lang/it-IT"),
        "vi-VN": require("./lang/vi"),
        "hi-IN": require("./lang/hi-IN"),

        "bg":require("./lang/bg"),
        "bn":require("./lang/bn"),
        "fil":require("./lang/fil"),
        "hu":require("./lang/hu"),
        "in":require("./lang/in"),
        "iw":require("./lang/iw"),
        "ko":require("./lang/ko"),
        "ms":require("./lang/ms"),
        "Punjabi-Shahmukhi":require("./lang/pa"),
        "sk":require("./lang/sk"),
        "uk":require("./lang/uk"),
        "ur":require("./lang/ur"),
		
		"da":require("./lang/da"),
		"fi":require("./lang/fi"),
		"ga":require("./lang/ga"),
		"hr":require("./lang/hr"),
		"km":require("./lang/km"),
		"mn":require("./lang/mn"),
		"no":require("./lang/no"),
		"sl":require("./lang/sl"),
		"sv":require("./lang/sv"),
    }
})