module.exports = {
	language: "Nederlands",
	navigator: {
		tools: "AI Tools",
		draw: "Tekenen",
		healthy: "Gezond",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Aanbevelen", "Studie", "Leven", "Schepping", "Werk"],
			presetGreet: "Hey, hallo~ Ik ben je gezondheidsbeheerder, altijd klaar om interessante en nuttige gezondheidskennis te bieden om je te helpen je gezondheid te beschermen!",
			presetQuiz: ["Wat zijn de oorzaken van chronische vermoeidheid?", "Hoe kunnen jongeren chronische ziekten voorkomen?", "Hoe houd je gezonde eetgewoonten aan?", "Hoe behoud je een goede nachtrust?"],
			presets: "Je kunt mij vragen:",
			change: "Wijzig batch",
			uploadImage: "Stuur afbeelding",
			footer: "Aangezien de inhoud wordt gegenereerd door een AI-model, kan de authenticiteit ervan niet volledig worden gegarandeerd.",
			remind: "Todo herinnering",
			limit: "Dit gesprek heeft het limiet bereikt, start een nieuwe AI-chat sessie",
			newDialog: "Begin een nieuw gesprek",
			stopRecording: "Klik ergens of wanneer de aftelling eindigt om te herkennen",
			send: "Stuur bericht",
			say: "Klik om te praten",
			notImage: "Deze agent ondersteunt het verzenden van bestanden en afbeeldingen niet",
			talking: "Huidig gesprek aan de gang",
			drawViolations: "AI tekenopdracht schendt regels, voer een conforme opdracht in.",
			exhausted: "Het aantal Q&A's is uitgeput, ga naar het kijkeinde en scan de QR-code om door te gaan.",
			serverBusy: "Het huidige netwerk is instabiel, probeer het opnieuw",
			chatHistory: "Gesprek geschiedenis",
			chatHistoryDesc: "Q&A-registraties worden slechts 3 dagen bewaard",
			todoList: "Te doen lijst",
			todoDesc: "Todo details",
			todoListDesc: "Er kunnen maximaal 10 todo's zijn",
			confirm: "Bevestigen",
			cancel: "Annuleren",
			text: "Tekst mag niet leeg zijn",
			cancelRemind: "Herinnering annuleren",
			remindTime: "Herinneringstijd:",
			remindTitle: "Herinneringstitel:",
			remindContent: "Herinneringsinhoud:",
			edit: "Bewerk",
			reachLimit: "Toegevoegd tot het limiet",
			errorChat:"Stem nog niet gedetecteerd. Probeer het opnieuw",
			      noSTT:"Toestemming niet verkregen. Schakel toestemming in voordat u doorgaat.",
      error50:"Er is een onbekende fout opgetreden. Probeer het opnieuw"
		},
draw: {
			describe: "Beschrijf je afbeelding",
			voice: "Stem invoer",
			press: "Druk om te praten",
			placeholder: "Beschrijf de afbeelding die je wilt genereren",
			style: "Schilderstijl",
			styleList: ["Inkt stijl", "Cyberpunk", "Anime", "Origami", "Breien", "Grafieken", "Cartoon","LEGO","Potlood","Tekenen"],
			record: "Teken record",
			recordDesc: "Houd 10 fotogeschiedenis bij",
			recordEmpty: "Je hebt momenteel geen tekenrecords",
			generate: "Genereer afbeelding",
			result: "Resultate ",
			download: "Aflaai",
			regenerate: "Weer",
			setDial: "Instellen als wijzerplaat",
			error: "Afbeelding genereren mislukt",
			errorDesc: "Onstabiele netwerkverbinding, dien opnieuw in.",
			confirm: "Bevestigen",
			analysiserror: "Analyse mislukt",
      SetWatchErr:["Kiezen is succesvol ingesteld","Annuleer het verzenden","Gegevensverificatie mislukt","Het gegevensbestand van de wijzerplaat is te groot","De synchronisatie van de wijzerplaat heeft de bovengrens bereikt","Bluetooth is ontkoppeld"],
      SetWatchTips:["Installatie mislukt","Instelling van de wijzerplaat wordt uitgevoerd","De wijzerplaat wordt ingesteld. Verlaat de huidige pagina niet."],
      progressText:"In synchronisatie"

		},
		sports: {
			analyze: "Gezondheidsanalyse",
			analyzeDesc: "Analyseer basisgegevens",
			advice: "Gezondheidsadvies",
			adviceDesc: "Geef gezondheidsadvies",
			experience: "Ervaring",
			program: {
				plan: "Gewichtsverlies Plan",
				startDate: "Kies startdatum",
				chooseDate: "Selecteer een dag als startdatum",
				week: [{
					num: "Zo.",
					day: "Zondag"
				}, {
					num: "Ma.",
					day: "Maandag"
				}, {
					num: "Di.",
					day: "Dinsdag"
				}, {
					num: "Wo.",
					day: "Woensdag"
				}, {
					num: "Do.",
					day: "Donderdag"
				}, {
					num: "Vr.",
					day: "Vrijdag"
				}, {
					num: "Za.",
					day: "Zaterdag"
				}, ],
				arrange: "Plan Arrangement",
				arrangeDesc: "Begin vanaf de dichtstbijzijnde door jouw gekozen datum, in totaal 7 trainingsdagen; de specifieke inhoud is als volgt:",
				confirm: "Bevestig Arrangement",
				success: "Gefeliciteerd, je hebt het plan succesvol aangepast! Begin je training op de 'Oefening Check-in' pagina!",
				successButton: "Ga naar Oefening Check-in",
			},
newmessage: {
				message1: ['Persoonlijke Centrum', 'Gezondheidsgegevens'],
				message2: ['Gezondheid', 'Sport', 'Dieet'],
				message3: ['Geslacht', 'Leeftijd', 'Lengte', 'Gewicht'],
				message4: ['Slaap', 'HR', 'Zuurstof', 'Stappen', 'BP', 'Druk', 'Samenvatting'],
				message5: ['Verbruik', 'Voorstellen', 'Vandaag Stappen',
					'Oefenduur'],
				message6: ['Oefening Inchecken', 'Fitness Thuis Editie',
					'Fitness Pro', 'Gewichtsverlies Plan'
				],
				message7: ['Oefen en leef een gezond leven',
					'Pas je fitnessplan aan', 'Pas je fitnessplan aan',
					'Maak een gewichtsverliesplan voor jou'
				],
				message8: ['Kcal', 'Weke', 'Minuten', 'Sets', 'Dagen', 'Jaren', 'cm', 'kg','u'],
				message9: ['Inchecken', 'Training', 'Oefening Opties', 'Mijn Prestaties',
					'Continue check-ins', 'Niet Ingecheckt'
				],
				message10: ['Klik om te Analyseren', 'Analyse Geschiedenis (tot 10 opgeslagen)', 'Analyseer',
					'Je hebt momenteel geen historische records', 'Continue check-ins', 'Data Analyse'
				],
				message11: ['Vrouw', 'Man'],
				message12: ['Annuleren', 'Bevestigen'],
				message13: ['Jaren', 'Centimeters', 'Kilogram'],
				message14: ['Handmatige Invoer', 'Verwagte tyd (Weke) ', 'Geslacht', 'Leeftijd', 'Lengte (Centimeters)',
					'Gewicht (Kilogram)', 'Verwacht Gewicht (Kilogram)'
				],
				message15: ['Geschiedenis', 'Gezondheidsgegevens Analyse', 'Gezondheidsadvies'],
				message16: ['Inchecken Succesvol', 'Inchecken', 'Kan alleen op dezelfde dag inchecken'],
				message17: ['Gezondheidsgegevens analyses kunnen slechts één keer per dag worden verkregen, en lokale gegevens kunnen niet worden gevonden', 'Gezondheidsadvies kan slechts één keer per dag worden verkregen, en lokale gegevens kunnen niet worden gevonden'],
				message18:['Momenteel ondergewicht','Je BMI-index is','het is niet raadzaam door te gaan met afvallen (Gezond bereik 18.5<BMI<24)'],
				message19:['Matig gewichtsverlies','Het wordt aanbevolen om niet meer dan 500g per dag af te vallen'],
				message20:['heb plannen','Dekt het bestaande plannen?'],
				message21:['Makkelijk','Normaal','Medium','Hoog'],
				message22:['Spirit:','Laag','Hoog'],
				message23:['The number of times has been used up. Purchase a subscription to unlock more times '],
			},
		},
		punch: {
			title: "Gedaan",
			title2: "Afwerken",
			h1: "Oefening Opties",
			h2: "Mijn Prestaties",
			button: "Inchecken",
			day: "Continue check-ins",
			dayunit: "Dagen",
		},
eat: {
			title: "Dieet",
			DietaryIntake: "Eten",
			kcal: "Kcal",
			Stillingestible: "Restant",
			overingest: "Voorbij",
			Recommendedintake: "Voorstellen",
			Motorconsumption: "Beweging",
			carbohydrate: "Koolhydraten",
			protein: "Eiwit",
			fat: "Vet",
			gram: "g",
			drinkDk: "Water Inchecken",
			drinkDkhealth: "Elke dag drinkwater is gezonder",
			eatsuggest: "Dieet Suggesties",
			analyzefoodglx: "Analyseer de rationaliteit van het dieet",
			eatPlan: "Dieet Plan",
			makeeatPlan: "Pas je exclusieve dieetplan aan",
			breakfast: "Ontbijt",
			lunch: "Lunch",
			dinner: "Diner",
			snack: "Snack",
			addFood: "Voeg Voedsel Toe",
			noAddFood: "Geen voedsel toegevoegd",
			cancle: "Annuleren",
			confirm: "Bevestigen",
			tips: "Proactief invullen wordt beschouwd als toestemming voor ons om deze projectinformatie te verzamelen en weer te geven in het dieet",
			addFooded: "Voedsel toegevoegd",
			added: "Toegevoegd",
			numFood: "Items van voedsel",
			record: "Record",
			dkTitle: "Inchecken",
			dailygoal: "Dagdoel",
			drinkDw: "Koppen/Per Dag",
			drinkWater: "Drink Water",
			dailygoalWater: "Dagelijks drinkwater doel",
			myachievement: "Mijn Prestaties",
			lxdkDays: "Continue check-ins",
			day: "Dagen",
			analyzeHistory: "Geschiedenis",
			conclusion: "Samenvatting",
			eatDataAnalyze: "Dieet Data Analyse",
			selectStartDate: "Kies Startdatum",
			chooseoneDate: "Selecteer een dag als startdatum",
			scheduling: "Plan Arrangement",
			schedulingDesc: "Begin vanaf de dichtstbijzijnde door jouw gekozen datum, in totaal 7 dagen; de specifieke inhoud is als volgt:",
			eatDetail:"Dieet Details",
			analyzeFailed:"Analyse mislukt",
			noData:"geen gegevens"


		}
	},
  error: {
    error1101:"De netwerkverbinding is onstabiel, de huidige time -out van de sessie",
    error1102:"Er is momenteel geen netwerk. Maak opnieuw verbinding",
    error1103:"Er is een onbekende fout opgetreden",
    error61:"De huidige dienst is niet beschikbaar",
    error4:"Stem nog niet gedetecteerd. Probeer het opnieuw",
    error12:"Voer standaardwoorden of -zinnen in zodat AI deze kan begrijpen en tekenen",
		error50:"Sorry, de huidige server is verkeerd, probeer het opnieuw"
  },
	setting: {
		index: {
			title: "Instellingen",
			problem: "Probleem Feedback",
			order: "Bestelrecords",
			notice: "Melding",
			language: "Taal",
			clear: "Cache Wissen",
			clearTip: "Weet je zeker dat je\nde cache wilt wissen?",
			confirm: "Bevestigen",
			cancel: "Annuleren",
		},
		language: {
			title: "Taal",
			langList: ["Vereenvoudigd Chinees", "Traditioneel Chinees", "Engels", "Arabisch", "Bulgaars", "Bengaals", "Tsjechisch", "Duits", "Grieks", "Spaans", "Perzisch", "Filipijns", "Frans", "Hindi", "Hongaars", "Indonesisch", "Italiaans", "Hebreeuws", "Japans", "Koreaans", "Maleis", "Nederlands", "Punjabi", "Pools", "Portugees", "Roemeens", "Russisch", "Slowaaks", "Thai", "Turks", "Oekraïens", "Urdu", "Vietnamees"],
		},
		order: {
			title: "Bestelrecords",
			success: "Succesvol Opgehaald",
			unpaid: "Onbetaald",
			paid: "Betaald",
			expired: "Verlopen",
			refunded: "Terugbetaald",
			pTime: "Betalingstijd",
			eTime: "Verlooptijd",
			pMethod: "Betaalmethode",
			pObject: "Betalingsobject",
			oNumber: "Ordernummer",
			pType: "Pakket Type",
			oTime: "Ophaaltijd",
			watch: "Bekijk IMEI",
			delete: "Bestelling Verwijderen",
			refund: "Terugbetaling Aanvragen",
		},
problem: {
			title: "Probleem Feedback",
			feedback: "Feedback Type",
			please: "Selecteer alstublieft",
			content: "Inhoud",
			improve: "Schrijf je suggesties op, zoals functieverzoeken, productklachten, enz. We zullen ons best doen om te verbeteren",
			upload: "Upload Afbeelding",
			submit: "Indienen",
			general: "Algemene Feedback",
			purchase: "Aankoop Gerelateerd",
			bug: "Foutmelding",
			suggestion: "Suggestie",
			other: "Andere",
		},
		notice: {
			title: "Melding",
			type: "Meldingstype: ",
			time: "Meldingstijd: "
		},
		unsubscribe: {
			watch: "Horloge:",
			title: "Doorlopende Abonnement",
			subTitle: "Geabonneerd",
			unsubscribe: "Afmelden",
			packList: ["Premium Abonnement", "Basis Abonnement", "Standaard Abonnement"],
		},
		refund: {
			title: "Terugbetaling Aanvragen",
			number: "Ordernummer",
			content: "Leg alstublieft de reden voor de terugbetaling uit",
			packList: ["Nieuwe Gebruiker Kortingspakket", "50 Keer Pakket", "100 Keer Pakket",
				"300 Keer Pakket"
			],
		}
	},
	tutorial: {
		index: {
			title: "Krijg Geavanceerde Tutorial",
			watchList: ["Zet het horloge aan, zoek de WearAI-toepassing en klik om te openen.",
				"Klik op de geavanceerde voordelen knop op de homepage.",
				"Haal je mobiele telefoon tevoorschijn en scan de QR-code op het horloge om de voordelenpagina te openen."
			]
		}
	},
}