module.exports = {
	language: "اردو",
	navigator: {
		tools: "اے آئی ٹولز",
		draw: "ڈرا",
		healthy: "ڈیٹا",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["تجویز", "مطالعہ", "زندگی", "تخلیق", "کام"],
			presetGreet: "ارے، ہیلو~ میں آپ کا صحت کا منتظم ہوں، ہمیشہ دلچسپ اور مفید صحت کے علم فراہم کرنے کے لیے تیار ہوں تاکہ آپ کی صحت کی حفاظت کر سکوں!",
			presetQuiz: ["دائمی تھکاوٹ کے اسباب کیا ہیں؟", "نوجوان افراد دائمی بیماریوں سے کیسے بچ سکتے ہیں؟", "صحت مند کھانے کی عادات کیسے بنائیں؟", "اچھی نیند کیسے حاصل کریں؟"],
			presets: "آپ مجھ سے پوچھ سکتے ہیں:",
			change: "بیچ تبدیل کریں",
			uploadImage: "تصویر بھیجیں",
			footer: "چونکہ مواد ایک AI ماڈل کے ذریعہ بنایا گیا ہے، اس کی حقیقت کی مکمل تصدیق نہیں کی جا سکتی۔",
			remind: "یاد دہانی",
			limit: "اس بات چیت کی حد پوری ہو چکی ہے، براہ کرم ایک نئی AI بات چیت شروع کریں۔",
			newDialog: "ایک نئی بات چیت شروع کریں",
			stopRecording: "کہیں بھی کلک کریں یا کاؤنٹ ڈاؤن کے ختم ہونے پر شناخت کریں",
			send: "پیغام بھیجیں",
			say: "بات کرنے کے لیے کلک کریں",
			notImage: "یہ ایجنٹ فائلز اور تصاویر بھیجنے کی حمایت نہیں کرتا",
			talking: "فی الحال بات چیت جاری ہے",
			drawViolations: "AI ڈرائینگ کی درخواست قواعد کی خلاف ورزی کرتی ہے، براہ کرم ایک مطابقت پذیر درخواست درج کریں۔",
			exhausted: "سوالات اور جوابات کی تعداد ختم ہو چکی ہے، براہ کرم گھڑی کے آخر پر جائیں اور QR کوڈ اسکین کریں جاری رکھنے کے لیے۔",
			serverBusy: "موجودہ نیٹ ورک لنک غیر مستحکم ہے، براہ کرم دوبارہ کوشش کریں",
			chatHistory: "بات چیت کی تاریخ",
			chatHistoryDesc: "سوالات اور جوابات کو صرف 3 دن تک محفوظ کیا جاتا ہے",
			todoList: "کاموں کی فہرست",
			todoDesc: "کام کی تفصیلات",
			todoListDesc: "زیادہ سے زیادہ 10 کام ہوسکتے ہیں",
			confirm: "تصدیق کریں",
			cancel: "منسوخ کریں",
			text: "متن خالی نہیں ہو سکتا",
			cancelRemind: "یاد دہانی منسوخ کریں",
			remindTime: "یاد دہانی کا وقت:",
			remindTitle: "یاد دہانی کا عنوان:",
			remindContent: "یاد دہانی کا مواد:",
			edit: "ترمیم کریں",
			reachLimit: "حد تک اضافہ ہو چکا ہے",
			errorChat:"آواز کا ابھی تک پتہ نہیں چلا، براہ کرم دوبارہ کوشش کریں۔",
			      noSTT:"اجازت نہیں ملی، براہ کرم جاری رکھنے سے پہلے اجازت کو فعال کریں۔",
      error50:"ایک نامعلوم خرابی پیش آگئی، براہ کرم دوبارہ کوشش کریں۔"
		},
		draw: {
			describe: "اپنی تصویر کی وضاحت کریں",
			voice: "آواز کی ان پٹ",
			press: "بات کریں کے لیے دبائیں",
			placeholder: "اس تصویر کی وضاحت کریں جو آپ بنانا چاہتے ہیں",
			style: "پینٹنگ کا طرز",
			styleList: ["سیاہی کا طرز", "سائبرپنک", "نینے", "اورگامی", "بُنائی","گرافکس", "کارٹون","لیگو" ,"پنسل", "ڈرائنگ"],
			record: "ڈراائنگ کا ریکارڈ",
			recordDesc: "صرف 10 تصاویر محفوظ کریں۔",
			recordEmpty: "ابھی تک کوئی ڈیٹا نہیں ہے۔",
			generate: "تصویر بنائیں",
			result: "نتیجہ",
			download: "ڈاؤن لوڈ",
			regenerate: "دوبارہ",
			setDial: "واچ فیس کے طور پر سیٹ کریں",
			error: "تصویر کی تخلیق ناکام ہو گئی",
			errorDesc: "غیر مستحکم نیٹ ورک کنکشن، براہ کرم دوبارہ جمع کرائیں۔",
			confirm: "تصدیق کریں",
			analysiserror: "تجزیہ ناکام ہو گیا",
      SetWatchErr:["کامیابی سے ڈائل سیٹ","بھیجنا منسوخ کریں۔","ڈیٹا کی توثیق ناکام ہو گئی۔","واچ فیس ڈیٹا فائل بہت بڑی ہے۔","واچ فیس سنکرونائزیشن اوپری حد تک پہنچ گئی ہے۔","بلوٹوتھ منقطع ہو گیا ہے۔"],
      SetWatchTips:["سیٹ اپ ناکام ہو گیا۔","ڈائل ترتیب جاری ہے۔","گھڑی کا چہرہ سیٹ کیا جا رہا ہے، براہ کرم موجودہ صفحہ سے باہر نہ نکلیں۔"],
      progressText:"Syncing"

		},
sports: {
			analyze: "صحت کا تجزیہ",
			analyzeDesc: "بنیادی ڈیٹا کا تجزیہ کریں",
			advice: "صحت کی مشورے",
			adviceDesc: "آواز مشورہ",
			experience: "تجربہ",
			program: {
				plan: "وزن کم کرنے کا منصوبہ",
				startDate: "شروع کرنے کی تاریخ کا انتخاب کریں",
				chooseDate: "براہ کرم 1 دن کو شروع کرنے کی تاریخ کے طور پر منتخب کریں",
				week: [{
					num: "اتوار",
					day: "اتوار"
				}, {
					num: "پیر",
					day: "پیر"
				}, {
					num: "منگل",
					day: "منگل"
				}, {
					num: "بدھ",
					day: "بدھ"
				}, {
					num: "جمعرات",
					day: "جمعرات"
				}, {
					num: "جمعہ",
					day: "جمعہ"
				}, {
					num: "ہفتہ",
					day: "ہفتہ"
				}, ],
				arrange: "منصوبہ ترتیب دیں",
				arrangeDesc: "آپ کے منتخب کردہ قریب ترین تاریخ سے شروع کریں، کل 7 تربیتی دن؛ مخصوص مواد درج ذیل ہے:",
				confirm: "ترتیب کی تصدیق کریں",
				success: "مبارک ہو، آپ نے کامیابی کے ساتھ منصوبہ مرتب کر لیا ہے! اپنے تربیت کا آغاز 'ورزش چیک-ان' صفحہ پر کریں!",
				successButton: "ورزش چیک-ان پر جائیں",
			},
newmessage: {
				message1: ['ذاتی مرکز', 'صحت کے ڈیٹا'],
				message2: ['صحت', 'ورزش', 'غذا'],
				message3: ['جنس', 'عمر', 'اونچائی', 'وزن'],
				message4: ['نیند', 'HR', 'آکسیجن', 'قدم', 'BP', 'دباؤ', 'خلاصہ'],
				message5: ['کھپت', 'بسم', 'آج کے قدم',
					'ورزش کا دورانیہ '
				],
				message6: ['ورزش چیک-ان', 'فٹنس ہوم ایڈیشن',
					'فٹنس پرو', 'وزن کم کرنے کا منصوبہ'
				],
				message7: ['ورزش کریں اور صحت مند زندگی بسر کریں',
					'اپنے فٹنس منصوبہ کو حسب ضرورت بنائیں', 'اپنے فٹنس منصوبہ کو حسب ضرورت بنائیں',
					'آپ کے لیے وزن کم کرنے کا منصوبہ بنائیں'
				],
				message8: ['kcal', 'قدم', 'منٹ', 'سیٹ', 'دن', 'سال', 'سینٹی میٹر', 'کلوگرام','h','ہفتہ'],
				message9: ['چیک-ان', 'تربیت', 'ورزش کے اختیارات', 'میری کامیابیاں',
					'مسلسل چیک-ان دن', 'چیک-ان نہیں کیا گیا'
				],
				message10: ['تجزیہ کرنے کے لیے کلک کریں', 'تجزیہ کی تاریخ (زیادہ سے زیادہ 10 محفوظ شدہ)', 'تجزیہ کریں',
					'ابھی تک کوئی ڈیٹا نہیں ہے۔', 'مسلسل چیک-ان دن', 'ڈیٹا تجزیہ'
				],
				message11: ['خاتون', 'مرد'],
				message12: ['منسوخ کریں', 'تصدیق کریں'],
				message13: ['سال', 'سینٹی میٹر', 'کلوگرام'],
				message14: ['دستی ان پٹ', ' متوقع وقت (ہفتوں)', 'جنس', 'عمر', 'اونچائی (سینٹی میٹر)',
					'وزن (کلوگرام)', 'متوقع وزن (کلوگرام)'
				],
				message15: ['تاریخ', 'صحت کے ڈیٹا کا تجزیہ', 'صحت کی مشورے'],
				message16: ['چیک-ان کامیاب', 'چیک-ان کرنا', 'صرف اسی دن چیک-ان کیا جا سکتا ہے'],
				message17: ['صحت کے ڈیٹا کا تجزیہ روزانہ صرف ایک بار حاصل کیا جا سکتا ہے، اور مقامی ڈیٹا نہیں مل سکتا', 'صحت مشورے روزانہ صرف ایک بار حاصل کیا جا سکتا ہے، اور مقامی ڈیٹا نہیں مل سکتا'],
				message18:['فی الحال کم وزن', 'آپ کا BMI انڈیکس ہے', 'وزن کم کرنا جاری رکھنا مناسب نہیں ہے (صحت مند حد 18.5<BMI<24)'],
				message19:['معمولی وزن کم کرنا', '500 گرام سے زائد کی سفارش نہیں کی جاتی روزانہ'],
				message20:['منصوبے ہیں', 'کیا موجودہ منصوبے کا احاطہ کرتے ہیں'],
				message21:['آسان', 'عام', 'درمیانہ', 'اونچا'],
				message22:['روح:', 'کم', 'زیادہ'],
				message23:['مزید بار غیر مقفل کرنے کے لیے سبسکرپشن خریدیں۔'],
			},
		},
		punch: {
			title: "مکمل",
			title2: "ختم",
			h1: "ورزش کے اختیارات",
			h2: "میری کامیابیاں",
			button: "چیک-ان",
			day: "مسلسل چیک-ان دن",
			dayunit: "دن",
		},
eat: {
			title: "غذا",
			DietaryIntake: "کھانا",
			kcal: "کیلوری",
			Stillingestible: "باقی",
			overingest: "زیادہ",
			Recommendedintake: "تجویز",
			Motorconsumption: "تحریک",
			carbohydrate: "کاربس",
			protein: "پروٹین",
			fat: "چربی",
			gram: "گرام",
			drinkDk: "پانی چیک-ان",
			drinkDkhealth: "صحت مند جسم کے لئے روزانہ چیک-ان",
			eatsuggest: "غذا کی تجاویز",
			analyzefoodglx: "غذا کا جائزہ لیں معقولیت",
			eatPlan: "غذا کا منصوبہ",
			makeeatPlan: "خصوصی وزن میں کمی",
			breakfast: "لنچ",
			lunch: "ڈنر",
			dinner: "رات کا کھانا",
			snack: "اسنیکس",
			addFood: "کھانا شامل کریں",
			noAddFood: "کوئی کھانا نہیں",
			cancle: "منسوخ کریں",
			confirm: "تصدیق کریں",
			tips: "رضاکارانہ طور پر بھرنا ہماری طرف سے اس پروجیکٹ کی معلومات کو جمع کرنے اور غذا میں دکھانے کے لئے رضامندی سمجھا جاتا ہے",
			addFooded: "کھانا شامل کیا گیا",
			added: "ہے",
			numFood: "کھانا اگانا",
			record: "ریکارڈ",
			dkTitle: "چیک-ان",
			dailygoal: "یومیہ مقصد",
			drinkDw: "کپ/دن",
			drinkWater: "پانی پیو",
			dailygoalWater: "روزانہ پانی پینے کا مقصد",
			myachievement: "میری کامیابیاں",
			lxdkDays: "مسلسل چیک-ان دن",
			day: "دن",
			analyzeHistory: "تاریخ",
			conclusion: "خلاصہ",
			eatDataAnalyze: "غذا کے ڈیٹا کا تجزیہ",
			selectStartDate: "شروع کرنے کی تاریخ کا انتخاب کریں",
			chooseoneDate: "براہ کرم 1 دن کو شروع کرنے کی تاریخ کے طور پر منتخب کریں",
			scheduling: "منصوبہ ترتیب دیں",
			schedulingDesc: "آپ کے منتخب کردہ قریب ترین تاریخ سے شروع کریں، کل 7 دن؛ مخصوص مواد درج ذیل ہے:",
			eatDetail: "غذا کی تفصیلات",
			analyzeFailed: "تجزیہ ناکام ہو گیا",
			noData: "کوئی ڈیٹا نہیں"
		}
	},
  error: {
    error1101:"نیٹ ورک کنکشن غیر مستحکم ہے ، موجودہ سیشن کا ٹائم آؤٹ",
    error1102:"فی الحال کوئی نیٹ ورک نہیں ہے، براہ کرم دوبارہ جڑیں۔",
    error1103:"ایک نامعلوم خرابی پیش آگئی",
    error61:"موجودہ سروس دستیاب نہیں ہے۔",
    error4:"آواز کا ابھی تک پتہ نہیں چلا، براہ کرم دوبارہ کوشش کریں۔",
    error12:"براہ کرم AI کو سمجھنے اور کھینچنے کے لیے معیاری الفاظ یا جملے درج کریں۔",
		error50:"معذرت، موجودہ سرور پر ایک خرابی ہے، براہ کرم دوبارہ کوشش کریں۔"

  },
	setting: {
		index: {
			title: "ترتیبات",
			problem: "مسئلہ فیڈبیک",
			order: "آرڈر رکارڈز",
			notice: "نوٹیفیکشن",
			language: "زبان",
			clear: "کیچ صاف کریں",
			clearTip: "کیا آپ یقیناً کیچ صاف کرنا چاہتے ہیں؟",
			confirm: "تصدیق کریں",
			cancel: "منسوخ کریں",
		},
		language: {
			title: "زبان",
			langList: ["سادہ چینی", "روایتی چینی", "انگریزی", "عربی", "بلغارین", "بنگالی", "چیک", "جرمن", "یونانی", "ہسپانوی", "فارسی", "فلپائنی", "فرانسیسی", "ہندی", "ہنگری", "انڈونیشی", "اطالوی", "عبرانی", "جاپانی", "کوریائی", "مالے", "ڈچ", "پنجابی", "پولش", "پرتگالی", "رومانی", "روسی", "سلوواک", "تھائی", "ترکی", "یوکرینی", "اردو", "ویتنامی"],
		},
		order: {
			title: "آرڈر رکارڈز",
			success: "کامیابی سے بازیافت کی گئی",
			unpaid: "بلا ادائیگی",
			paid: "ادائیگی شدہ",
			expired: "میعاد ختم ہو چکی",
			refunded: "واپس کی گئی",
			pTime: "ادائیگی کا وقت",
			eTime: "میعاد ختم ہونے کا وقت",
			pMethod: "ادائیگی کا طریقہ",
			pObject: "ادائیگی کا مقصد",
			oNumber: "آرڈر نمبر",
			pType: "پیکج کی قسم",
			oTime: "بازیافت کا وقت",
			watch: "IMEI دیکھیں",
			delete: "آرڈر حذف کریں",
			refund: "رقم کی واپسی کی درخواست کریں",
		},
problem: {
			title: "مسئلہ فیڈبیک",
			feedback: "فیڈبیک کی قسم",
			please: "براہ کرم انتخاب کریں",
			content: "مواد",
			improve: "براہ کرم اپنی تجاویز لکھیں، جیسے فیچر ریکویسٹ، پروڈکٹ شکایات وغیرہ۔ ہم بہتری کی کوشش کریں گے",
			upload: "تصویر اپلوڈ کریں",
			submit: "جمع کروائیں",
			general: "عام فیڈبیک",
			purchase: "خریداری سے متعلق",
			bug: "بگ رپورٹ",
			suggestion: "تجویز",
			other: "دیگر",
		},
		notice: {
			title: "نوٹیفیکشن",
			type: "نوٹیفیکشن کی قسم: ",
			time: "نوٹیفیکشن کا وقت: "
		},
		unsubscribe: {
			watch: "گھڑی:",
			title: "مسلسل سبسکرپشن",
			subTitle: "سبسکرائب شدہ",
			unsubscribe: "سبسکرائب ختم کریں",
			packList: ["پریمیم سبسکرپشن", "بنیادی سبسکرپشن", "معیاری سبسکرپشن"],
		},
		refund: {
			title: "رقم کی واپسی کی درخواست",
			number: "آرڈر نمبر",
			content: "براہ کرم رقم کی واپسی کی وجہ بیان کریں",
			packList: ["نیا صارف ڈسکاؤنٹ پیکیج", "50 دفعہ کا پیکیج", "100 دفعہ کا پیکیج",
				"300 دفعہ کا پیکیج"
			],
		}
	},
	tutorial: {
		index: {
			title: "ایڈوانسڈ ٹیوٹوریل حاصل کریں",
			watchList: ["گھڑی کو آن کریں، WearAI ایپلیکیشن کو تلاش کریں اور کھولیں پر کلک کریں۔",
				"ہوم پیج پر ایڈوانسڈ بینیفٹس بٹن پر کلک کریں۔",
				"اپنا موبائل فون نکالیں اور بینیفٹس صفحہ پر جانے کے لئے گھڑی پر QR کوڈ اسکین کریں۔"
			]
		}
	},
}