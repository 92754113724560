import axios from 'axios'
import store from '@/store'
import i18n from '@/i18n';  
const service = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 10000
})

service.interceptors.request.use(config => {
	// if(!navigator.onLine){
	//   // 如果网络不可用，则阻止请求
	//   return 
	// }
    if(store.getters.getDeviceId && !config.headers.Authorization) {
        config.headers['Authorization'] = store.getters.getDeviceId;
    }
    if(store.getters.getChannelId && !config.headers.Channel) {
        config.headers['Channel'] = store.getters.getChannelId;
    }
    // if(store.getters.getWatchList) {
    //     config.headers['Ident'] = store.getters.getWatchList[0].watchId;
    // }
    config.headers['device'] = 'web';
    return config;
},error => {
   
    return Promise.reject(error);
})

service.interceptors.response.use(res => {

	return res.data;
}, error => {
    return Promise.reject(error);
})


export default service;