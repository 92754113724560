module.exports = {
	language: "čeština",
	navigator: {
		tools: "Nástroje AI",
		draw: "Remíza",
		healthy: "Zdravý",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Doporučit", "Studovat", "Život", "Tvorba", "Práce"],
			presetGreet: "Ahoj, ahoj~ Jsem váš správce zdraví, vždy připraven poskytnout zajímavé a užitečné informace o zdraví, které vám pomohou chránit vaše zdraví!",
			presetQuiz: ["Jaké jsou příčiny chronické únavy?", "Jak mohou mladí lidé předcházet chronickým nemocem?", "Jak udržovat zdravé stravovací návyky?", "Jak udržovat dobrý spánek?"],
			presets: "Můžete se mě zeptat:",
			change: "Změnit várku",
			uploadImage: "Odeslat obrázek",
			footer: "Protože obsah je generován AI modelem, nelze zaručit jeho autenticitu.",
			remind: "Připomenutí úkolů",
			limit: "Tento rozhovor dosáhl limitu, zahajte prosím novou relaci AI chatu",
			newDialog: "Zahájit nový rozhovor",
			stopRecording: "Klikněte kamkoli nebo po skončení odpočítávání k rozpoznání",
			send: "Odeslat zprávu",
			say: "Klikněte pro mluvení",
			notImage: "Tento agent nepodporuje odesílání souborů a obrázků",
			talking: "Probíhá aktuální rozhovor",
			drawViolations: "Podnět k AI kresbě porušuje pravidla, prosím zadejte podnět vyhovující pravidlům.",
			exhausted: "Počet otázek a odpovědí byl vyčerpán, prosím přejděte na sledovacím konci a naskenujte QR kód pro pokračování.",
			serverBusy: "Aktuální síťové připojení je nestabilní, zkuste to prosím znovu",
			chatHistory: "Historie chatu",
			chatHistoryDesc: "Záznamy otázek a odpovědí se uchovávají pouze 3 dny",
			todoList: "Seznam úkolů",
			todoDesc: "Podrobnosti úkolů",
			todoListDesc: "Může existovat maximálně 10 úkolů",
			confirm: "Potvrdit",
			cancel: "Zrušit",
			text: "Text nemůže být prázdný",
			cancelRemind: "Zrušit připomenutí",
			remindTime: "Čas připomenutí:",
			remindTitle: "Název připomenutí:",
			remindContent: "Obsah připomenutí:",
			edit: "Upravit",
			reachLimit: "Přidáno na limit",
			errorChat:"Hlas dosud nebyl rozpoznán, zkuste to prosím znovu",
			      noSTT:"Oprávnění nebylo získáno, před pokračováním prosím povolení povolte.",
      error50:"Došlo k neznámé chybě, zkuste to prosím znovu"
		},
draw: {
			describe: "Popište svůj obrázek",
			voice: "Hlasový vstup",
			press: "Zmáčkněte pro mluvení",
			placeholder: "Popište obrázek, který chcete vygenerovat",
			style: "Styly malby",
			styleList: ["Styl inkoustu", "Kyberpunk", "Anime", "Origami", "Pletení", "Grafika", "Kreslený","LEGO","Tužka","Kreslit"],
			record: "Záznam kresby",
			recordDesc: "Lze uložit až 10 záznamů kreseb",
			recordEmpty: "Zatím žádný záznam",
			generate: "Vygenerovat obrázek",
			result: "Výsledek",
			download: "Stáhnout",
			regenerate: "Znovu",
			setDial: "Nastavit jako ciferník",
			error: "Generování obrázku selhalo",
			errorDesc: "Nestabilní síťové připojení, prosím znovu odešlete.",
			confirm: "Potvrdit",
			analysiserror: "Analýza selhala",
      SetWatchErr:["Vytáčení bylo úspěšně nastaveno","Zrušit odesílání","Ověření dat se nezdařilo","Datový soubor ciferníku je příliš velký","Synchronizace ciferníku dosáhla horního limitu","Bluetooth bylo odpojeno"],
      SetWatchTips:["Nastavení se nezdařilo","Probíhá nastavování číselníku","Probíhá nastavování ciferníku, neopouštějte prosím aktuální stránku."],
      progressText:"Synchronizace"

		},
		sports: {
			analyze: "Analýza zdraví",
			analyzeDesc: "Analyzovat základní data",
			advice: "Zdravotní rada",
			adviceDesc: "Poskytnout zdravotní rady",
			experience: "Zkušenost",
			program: {
				plan: "Plán hubnutí",
				startDate: "Vyberte datum zahájení",
				chooseDate: "Prosím vyberte 1 den jako datum zahájení",
				week: [{
					num: "Ne.",
					day: "Neděle"
				}, {
					num: "Po.",
					day: "Pondělí"
				}, {
					num: "Út.",
					day: "Úterý"
				}, {
					num: "St.",
					day: "Středa"
				}, {
					num: "Čt.",
					day: "Čtvrtek"
				}, {
					num: "Pá.",
					day: "Pátek"
				}, {
					num: "So.",
					day: "Sobota"
				}, ],
				arrange: "Plánované uspořádání",
				arrangeDesc: "Začne od nejbližšího vybraného data, celkem 7 tréninkových dní; konkrétní obsah je následující:",
				confirm: "Potvrdit uspořádání",
				success: "Gratulujeme, úspěšně jste přizpůsobili plán! Zahajte svůj trénink na stránce 'Přihlášení cvičení'!",
				successButton: "Přejít na Přihlášení cvičení",
			},
newmessage: {
				message1: ['Osobní centrum', 'Zdravotní data'],
				message2: ['Zdraví', 'Cvičení', 'Strava'],
				message3: ['Pohlaví', 'Věk', 'Výška', 'Hmotnost'],
				message4: ['Spánek', 'TF', 'Kyslík', 'Kroky', 'TK', 'Tlak', 'Souhrn'],
				message5: ['Spotřeba', 'Doporučení', 'Dnešní kroky',
					'Doba cvičení'],
				message6: ['Cvičení check-in', 'Fitness domácí verze',
					'Fitness Pro', 'Plán hubnutí'
				],
				message7: ['Cvičte a žijte zdravý život',
					'Přizpůsobte si svůj fitness plán', 'Přizpůsobte si svůj fitness plán',
					'Vytvořte si plán hubnutí'
				],
				message8: ['Kcal', 'Týden', 'Minuty', 'Sady', 'Dny', 'Roky', 'cm', 'kg', 'h','Týden'],
				message9: ['Check-in', 'Trénink', 'Možnosti cvičení', 'Moje úspěchy',
					'Průběžné odbavení', 'Nezapsáno v check-in'
				],
				message10: ['Klikněte pro analýzu', 'Historie analýzy (až 10 uloženo)', 'Analyzovat',
					'Momentálně nemáte žádné historické záznamy', 'Průběžné odbavení', 'Analýza dat'
				],
				message11: ['Žena', 'Muž'],
				message12: ['Zrušit', 'Potvrdit'],
				message13: ['Roky', 'Centimetry', 'Kilogramy'],
				message14: ['Ruční vstup', 'Předpokládaný čas (týdny)', 'Pohlaví', 'Věk', 'Výška (Centimetry)',
					'Hmotnost (Kilogramy)', 'Očekávaná hmotnost (Kilogramy)'
				],
				message15: ['Historie', 'Analýza zdravotních dat', 'Zdravotní rady'],
				message16: ['Check-in úspěšný', 'Probíhá check-in', 'Lze check-in pouze ve stejný den'],
				message17: ['Analýza zdravotních dat může být získána pouze jednou denně a místní data nelze najít', 'Zdravotní rady mohou být získány pouze jednou denně a místní data nelze najít'],
				message18:['Momentálně podváha','Váš BMI index je','není vhodné pokračovat v hubnutí (Zdravý rozsah 18.5<BMI<24)'],
				message19:['Mírné hubnutí','Doporučuje se ztrácet ne více než 500g denně'],
				message20:['mají plány','Zahrnuje to existující plány'],
				message21:['Lehké','Normální','Střední','Vysoké'],
				message22:['Duch:','Nízký','Vysoký'],
				message23:['Kolikrát bylo vyčerpáno Zakoupením předplatného odemkněte vícekrát.'],
			},
		},
		punch: {
			title: "Hotovo",
			title2: "Dokončeno",
			h1: "Možnosti cvičení",
			h2: "Moje úspěchy",
			button: "Check-in",
			day: "Průběžné odbavení",
			dayunit: "Dny",
		},
eat: {
			title: "Strava",
			DietaryIntake: "Jídlo",
			kcal: "Kcal",
			Stillingestible: "Zbývající",
			overingest: "Překročit",
			Recommendedintake: "Doporučení",
			Motorconsumption: "Pohyb",
			carbohydrate: "Sacharidy",
			protein: "Protein",
			fat: "Tuk",
			gram: "Gramy",
			drinkDk: "Kontrola pití vody",
			drinkDkhealth: "Denní kontrola pro zdravější tělo",
			eatsuggest: "Dietní doporučení",
			analyzefoodglx: "Analyzovat racionalitu stravy",
			eatPlan: "Stravovací plán",
			makeeatPlan: "Exkluzivní dietní plán",
			breakfast: "Snídaně",
			lunch: "Oběd",
			dinner: "Večeře",
			snack: "Svačina",
			addFood: "Přidat jídlo",
			noAddFood: "Žádné přidané jídlo",
			cancle: "Zrušit",
			confirm: "Potvrdit",
			tips: "Proaktivní vyplnění se považuje za souhlas s tím, že můžeme shromažďovat tyto informace a zobrazovat je ve stravě",
			addFooded: "Přidané jídlo",
			added: "Přidáno",
			numFood: "Položky jídla",
			record: "Záznam",
			dkTitle: "Check-in",
			dailygoal: "Denní cíl",
			drinkDw: "Pohárů/Den",
			drinkWater: "Pít vodu",
			dailygoalWater: "Denní cíl pití vody",
			myachievement: "Moje úspěchy",
			lxdkDays: "Průběžné odbavení",
			day: "Dny",
			analyzeHistory: "Historie",
			conclusion: "Souhrn",
			eatDataAnalyze: "Analýza dat o stravě",
			selectStartDate: "Vyberte datum zahájení",
			chooseoneDate: "Prosím vyberte 1 den jako datum zahájení",
			scheduling: "Plánování",
			schedulingDesc: "Začne od nejbližšího vybraného data, celkem 7 dnů; konkrétní obsah je následující:",
			eatDetail:"Podrobnosti o stravě",
			analyzeFailed:"Analýza selhala",
			noData:"žádná data"


		}
	},
  error: {
    error1101:"Síťové připojení je nestabilní, časový limit aktuálního relace",
    error1102:"Aktuálně není k dispozici žádná síť, znovu se prosím připojte",
    error1103:"Došlo k neznámé chybě",
    error61:"Aktuální služba je nedostupná",
    error4:"Hlas dosud nebyl rozpoznán, zkuste to prosím znovu",
    error12:"Zadejte prosím standardní slova nebo věty, aby jim umělá inteligence porozuměla a nakreslila",
		error50:"Omlouvám se, aktuální server je špatný, zkuste to prosím znovu"
  },
	setting: {
		index: {
			title: "Nastavení",
			problem: "Zpětná vazba na problém",
			order: "Záznamy objednávek",
			notice: "Oznámení",
			language: "Jazyk",
			clear: "Vymazat cache",
			clearTip: "Jste si jisti, že chcete\nvymazat cache?",
			confirm: "Potvrdit",
			cancel: "Zrušit",
		},
		language: {
			title: "Jazyk",
			langList: ["Zjednodušená čínština", "Tradiční čínština", "Angličtina", "Arabština", "Bulharština", "Bengálština", "Čeština", "Němčina", "Řečtina", "Španělština", "Perština", "Filipínština", "Francouzština", "Hindština", "Maďarština", "Indonéština", "Italština", "Hebrejština", "Japonština", "Korejština", "Malajština", "Nizozemština", "Paňdžábština", "Polština", "Portugalština", "Rumunština", "Ruština", "Slovenština", "Thajština", "Turečtina", "Ukrajinština", "Urdu", "Vietnamština"],
		},
order: {
			title: "Záznamy objednávek",
			success: "Úspěšně načteno",
			unpaid: "Nezaplacené",
			paid: "Zaplacené",
			expired: "Vypršelo",
			refunded: "Vráceno",
			pTime: "Čas platby",
			eTime: "Čas vypršení",
			pMethod: "Způsob platby",
			pObject: "Objekt platby",
			oNumber: "Číslo objednávky",
			pType: "Typ balíčku",
			oTime: "Čas načtení",
			watch: "Zobrazit IMEI",
			delete: "Smazat objednávku",
			refund: "Požádat o vrácení peněz",
		},
		problem: {
			title: "Zpětná vazba na problém",
			feedback: "Typ zpětné vazby",
			please: "Prosím vyberte",
			content: "Obsah",
			improve: "Prosím napište své návrhy, jako jsou požadavky na funkce, stížnosti na produkt atd. Budeme se snažit zlepšit",
			upload: "Nahrát obrázek",
			submit: "Odeslat",
			general: "Obecná zpětná vazba",
			purchase: "Související s nákupem",
			bug: "Nahlášení chyby",
			suggestion: "Návrh",
			other: "Ostatní",
		},
		notice: {
			title: "Oznámení",
			type: "Typ oznámení: ",
			time: "Čas oznámení: "
		},
		unsubscribe: {
			watch: "Hodinky:",
			title: "Nepřetržité předplatné",
			subTitle: "Předplaceno",
			unsubscribe: "Odhlásit odběr",
			packList: ["Premium předplatné", "Základní předplatné", "Standardní předplatné"],
		},
refund: {
			title: "Požádat o vrácení peněz",
			number: "Číslo objednávky",
			content: "Prosím vysvětlete důvod pro vrácení peněz",
			packList: ["Slevový balíček pro nové uživatele", "Balíček 50krát", "Balíček 100krát",
				"Balíček 300krát"
			],
		}
	},
	tutorial: {
		index: {
			title: "Získat pokročilý tutoriál",
			watchList: ["Zapněte hodinky, najděte aplikaci WearAI a klikněte na otevření.",
				"Klikněte na tlačítko pokročilých výhod na domovské stránce.",
				"Vytáhněte svůj mobilní telefon a naskenujte QR kód na hodinkách pro vstup na stránku výhod."
			]
		}
	},
}