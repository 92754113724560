module.exports = {
	language: "italiano",
	navigator: {
		tools: "Strumenti IA",
		draw: "Disegnare",
		healthy: "Salute",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Raccomandare", "Studio", "Vita", "Creazione", "Lavoro"],
			presetGreet: "Ciao, ciao~ Sono il tuo amministratore della salute, sempre pronto a fornire conoscenze sanitarie interessanti e utili per aiutarti a proteggere la tua salute!",
			presetQuiz: ["Quali sono le cause dell'affaticamento cronico?", "Come possono i giovani prevenire le malattie croniche?", "Come mantenere abitudini alimentari sane?", "Come mantenere un buon sonno?"],
			presets: "Puoi chiedermi:",
			change: "Cambia lotto",
			uploadImage: "Invia immagine",
			footer: "Poiché il contenuto è generato da un modello AI, la sua autenticità non può essere pienamente garantita.",
			remind: "Ricordo delle cose da fare",
			limit: "Questa conversazione ha raggiunto il limite, per favore inizia una nuova sessione di chat AI",
			newDialog: "Inizia una nuova conversazione",
			stopRecording: "Clicca ovunque o quando il conto alla rovescia termina per riconoscere",
			send: "Invia messaggio",
			say: "Clicca per parlare",
			notImage: "Questo agente non supporta l'invio di file e immagini",
			talking: "Conversazione in corso",
			drawViolations: "Il prompt del disegno AI viola le regole, per favore inserisci un prompt conforme.",
			exhausted: "Il numero di Q&A è stato esaurito, per favore vai alla fine del visualizzatore e scansiona il codice QR per continuare.",
			serverBusy: "Il collegamento di rete attuale è instabile, per favore riprova",
			chatHistory: "Cronologia chat",
			chatHistoryDesc: "I record di Q&A vengono conservati solo per 3 giorni",
			todoList: "Elenco delle cose da fare",
			todoDesc: "Dettagli delle cose da fare",
			todoListDesc: "Possono esistere al massimo 10 attività",
			confirm: "Conferma",
			cancel: "Annulla",
			text: "Il testo non può essere vuoto",
			cancelRemind: "Annulla promemoria",
			remindTime: "Orario del promemoria:",
			remindTitle: "Titolo del promemoria:",
			remindContent: "Contenuto del promemoria:",
			edit: "Modifica",
			reachLimit: "Aggiunto fino al limite",
			errorChat:"La voce non è stata ancora riconosciuta, riprova",
			      noSTT:"Autorizzazione non ottenuta, abilita l'autorizzazione prima di continuare.",
      error50:"Si è verificato un errore sconosciuto, riprova"
		},
		draw: {
			describe: "Descrivi la tua immagine",
			voice: "Input vocale",
			press: "Premi per parlare",
			placeholder: "Descrivi l'immagine che vuoi generare",
			style: "Stile di pittura",
			styleList: ["Stile inchiostro", "Cyberpunk", "Anime", "Origami", "Lavoro a maglia", "Grafica", "Cartone animato","LEGO","Matita","Disegnare"],
			record: "Record di disegno",
			recordDesc: "Possono essere salvati fino a 10 record di disegno",
			recordEmpty: "Attualmente non hai record di disegno",
			generate: "Genera immagine",
			result: "Risultato",
			download: "Scarica",
			regenerate: "Di nuovo",
			setDial: "Imposta come quadrante",
			error: "Generazione dell'immagine fallita",
			errorDesc: "Connessione di rete instabile, per favore riprova.",
			confirm: "Conferma",
			analysiserror: "Analisi fallita",
      SetWatchErr:["Quadrante impostato correttamente","Annulla l'invio","La verifica dei dati non è riuscita","Il file dei dati del quadrante è troppo grande","La sincronizzazione del quadrante dell'orologio ha raggiunto il limite massimo","Il Bluetooth è stato disconnesso"],
			SetWatchTips:["L'installazione non è riuscita","Impostazione del quadrante in corso","È in corso l'impostazione del quadrante, non uscire dalla pagina corrente."],
      progressText:"La sincronizzazione"


		},
sports: {
			analyze: "Analisi della Salute",
			analyzeDesc: "Analizzare i dati di base",
			advice: "Consigli sulla Salute",
			adviceDesc: "Fornire consigli sulla salute",
			experience: "Esperienza",
			program: {
				plan: "Piano di Perdita di Peso",
				startDate: "Scegli Data di Inizio",
				chooseDate: "Per favore seleziona 1 giorno come data di inizio",
				week: [{
					num: "Dom.",
					day: "Domenica"
				}, {
					num: "Lun.",
					day: "Lunedì"
				}, {
					num: "Mar.",
					day: "Martedì"
				}, {
					num: "Mer.",
					day: "Mercoledì"
				}, {
					num: "Gio.",
					day: "Giovedì"
				}, {
					num: "Ven.",
					day: "Venerdì"
				}, {
					num: "Sab.",
					day: "Sabato"
				}, ],
				arrange: "Organizzazione del Piano",
				arrangeDesc: "Inizia dalla data più vicina che scegli, per un totale di 7 giorni di allenamento; il contenuto specifico è il seguente:",
				confirm: "Conferma Organizzazione",
				success: "Congratulazioni, hai personalizzato con successo il piano! Inizia il tuo allenamento nella pagina 'Check-in Esercizio'!",
				successButton: "Vai a Check-in Esercizio",
			},
newmessage: {
				message1: ['Centro Personale', 'Dati della Salute'],
				message2: ['Salute', 'Esercizio', 'Dieta'],
				message3: ['Genere', 'Età', 'Altezza', 'Peso'],
				message4: ['Sonno', 'FC', 'Ossigeno', 'Passi', 'PA', 'Pressione', 'Riepilogo'],
				message5: ['Consumo', 'Consigli', 'Passi di Oggi',
					'Durata dell Esercizio '
				],
				message6: ['Check-in Esercizio', 'Fitness Edizione Casa',
					'Fitness Pro', 'Piano di Perdita di Peso'
				],
				message7: ['Esercitarsi e vivere una vita sana',
					'Personalizza il tuo piano fitness', 'Personalizza il tuo piano fitness',
					'Metodi di perdita di peso personalizzati'
				],
				message8: ['Kcal', 'Passi', 'Minuti', 'Serie', 'Giorni', 'Anni', 'cm', 'kg','h','Settimane'],
				message9: ['Check-in', 'Allenamento', 'Opzioni di Esercizio', 'I Miei Successi',
					'Giorni di Check-in Consecutivi', 'Non Check-in'
				],
				message10: ['Clicca per Analizzare', 'Storico delle Analisi (fino a 10 salvate)', 'Analizza',
					'Attualmente non hai record storici', 'Giorni di Check-in Consecutivi', 'Analisi dei Dati'
				],
				message11: ['Femmina', 'Maschio'],
				message12: ['Annulla', 'Conferma'],
				message13: ['Anni', 'Centimetri', 'Chilogrammi'],
				message14: ['Inserimento Manuale', 'Tempo previsto (Settimane) ', 'Genere', 'Età', 'Altezza (Centimetri)',
					'Peso (Chilogrammi)', 'Peso Previsto (Chilogrammi)'
				],
				message15: ['Storico', 'Analisi dei Dati della Salute', 'Consigli sulla Salute'],
				message16: ['Check-in Completato', 'Check-in in Corso', 'Puoi fare check-in solo lo stesso giorno'],
				message17: ['Lanalisi dei dati della salute può essere ottenuta solo una volta al giorno e i dati locali non possono essere trovati', 'I consigli sulla salute possono essere ottenuti solo una volta al giorno e i dati locali non possono essere trovati'],
				message18:['Attualmente sottopeso','Il tuo indice BMI è','non è consigliabile continuare a perdere peso(Intervallo sano 18.5<BMI<24)'],
				message19:['Perdita di peso moderata','Si consiglia di non perdere più di 500g al giorno'],
				message20:['avere piani','Copre i piani esistenti'],
				message21:['Facile','Normale','Medio','Alto'],
				message22:['Spirito:','Basso','Alto'],
				message23:['Il numero di volte è stato esaurito. Acquista un abbonamento per sbloccare più volte.'],
			},
		},
		punch: {
			title: "Fatto",
			title2: "Completato",
			h1: "Opzioni di Esercizio",
			h2: "I Miei Successi",
			button: "Check-in",
			day: "Giorni di Check-in Consecutivi",
			dayunit: "Giorni",
		},
eat: {
			title: "Dieta",
			DietaryIntake: "Mangiare",
			kcal: "Kcal",
			Stillingestible: "Rimanente",
			overingest: "Oltre",
			Recommendedintake: "Suggerisci",
			Motorconsumption: "Movimento",
			carbohydrate: "Carboidrati",
			protein: "Proteine",
			fat: "Grassi",
			gram: "Grammi",
			drinkDk: "Check-in Acqua",
			drinkDkhealth: "Bere acqua fa bene alla salute",
			eatsuggest: "Suggerimenti Dietetici",
			analyzefoodglx: "Analizzare la razionalità della dieta",
			eatPlan: "Piano Dietetico",
			makeeatPlan: "Programma di dieta esclusivo",
			breakfast: "Colazione",
			lunch: "Pranzo",
			dinner: "Cena",
			snack: "Spuntino",
			addFood: "Aggiungi Cibo",
			noAddFood: "Nessun cibo aggiunto",
			cancle: "Annulla",
			confirm: "Conferma",
			tips: "Compilare proattivamente è considerato come consenso per raccogliere queste informazioni di progetto e mostrarle nella dieta",
			addFooded: "Cibo aggiunto",
			added: "Aggiunto",
			numFood: "Elementi di cibo",
			record: "Registro",
			dkTitle: "Check-in",
			dailygoal: "Obiettivo Giornaliero",
			drinkDw: "Tazze/Giorno",
			drinkWater: "Bere Acqua",
			dailygoalWater: "Obiettivo giornaliero di acqua potabile",
			myachievement: "I Miei Successi",
			lxdkDays: "Giorni di Check-in Consecutivi",
			day: "Giorni",
			analyzeHistory: "Storico",
			conclusion: "Riepilogo",
			eatDataAnalyze: "Analisi dei Dati Dietetici",
			selectStartDate: "Scegli Data di Inizio",
			chooseoneDate: "Per favore seleziona 1 giorno come data di inizio",
			scheduling: "Organizzazione del Piano",
			schedulingDesc: "Inizia dalla data più vicina che scegli, per un totale di 7 giorni; il contenuto specifico è il seguente:",
			eatDetail:"Dettagli della Dieta",
			analyzeFailed:"Analisi fallita",
			noData:"nessun dato"
		}
	},
  error: {
    error1101:"La connessione di rete è instabile, il timeout della sessione corrente",
    error1102:"Al momento non è presente alcuna rete, riconnettiti",
    error1103:"Si è verificato un errore sconosciuto",
    error61:"Il servizio attuale non è disponibile",
    error4:"La voce non è stata ancora riconosciuta, riprova",
    error12:"Inserisci parole o frasi standard affinché l'IA possa comprenderle e disegnarle",
		error50:"Scusa, il server corrente è sbagliato, riprova"
  },
	setting: {
		index: {
			title: "Impostazioni",
			problem: "Feedback Problemi",
			order: "Record Ordini",
			notice: "Notifica",
			language: "Lingua",
			clear: "Cancella Cache",
			clearTip: "Sei sicuro di voler\ncancellare la cache?",
			confirm: "Conferma",
			cancel: "Annulla",
		},
		language: {
			title: "Lingua",
			langList: ["Cinese Semplificato", "Cinese Tradizionale", "Inglese", "Arabo", "Bulgaro", "Bengalese", "Ceco", "Tedesco", "Greco", "Spagnolo", "Persiano", "Filippino", "Francese", "Hindi", "Ungherese", "Indonesiano", "Italiano", "Ebraico", "Giapponese", "Coreano", "Malese", "Olandese", "Punjabi", "Polacco", "Portoghese", "Romeno", "Russo", "Slovacco", "Thai", "Turco", "Ucraino", "Urdu", "Vietnamita"],
		},
		order: {
			title: "Record Ordini",
			success: "Recuperato con Successo",
			unpaid: "Non Pagato",
			paid: "Pagato",
			expired: "Scaduto",
			refunded: "Rimborsato",
			pTime: "Tempo di Pagamento",
			eTime: "Tempo di Scadenza",
			pMethod: "Metodo di Pagamento",
			pObject: "Oggetto di Pagamento",
			oNumber: "Numero dell'Ordine",
			pType: "Tipo di Pacchetto",
			oTime: "Tempo di Recupero",
			watch: "Visualizza IMEI",
			delete: "Elimina Ordine",
			refund: "Richiedi Rimborso",
		},
		problem: {
			title: "Feedback Problemi",
			feedback: "Tipo di Feedback",
			please: "Per favore Seleziona",
			content: "Contenuto",
			improve: "Per favore scrivi i tuoi suggerimenti, come richieste di funzionalità, reclami sui prodotti, ecc. Ci impegneremo a migliorare",
			upload: "Carica Immagine",
			submit: "Invia",
			general: "Feedback Generale",
			purchase: "Acquisto Collegato",
			bug: "Segnala Bug",
			suggestion: "Suggerimento",
			other: "Altro",
		},
notice: {
			title: "Notifica",
			type: "Tipo di Notifica: ",
			time: "Tempo di Notifica: "
		},
		unsubscribe: {
			watch: "Orologio:",
			title: "Abbonamento Continuo",
			subTitle: "Abbonato",
			unsubscribe: "Annulla Abbonamento",
			packList: ["Abbonamento Premium", "Abbonamento Base", "Abbonamento Standard"],
		},
		refund: {
			title: "Richiedi Rimborso",
			number: "Numero dell'Ordine",
			content: "Per favore spiega il motivo del rimborso",
			packList: ["Pacchetto Sconto Nuovo Utente", "Pacchetto 50 Volte", "Pacchetto 100 Volte",
				"Pacchetto 300 Volte"
			],
		}
	},
	tutorial: {
		index: {
			title: "Ottieni Tutorial Avanzato",
			watchList: ["Accendi l'orologio, trova l'applicazione WearAI e clicca per aprirla.",
				"Clicca sul pulsante dei vantaggi avanzati nella home page.",
				"Tira fuori il tuo cellulare e scansiona il codice QR sull'orologio per entrare nella pagina dei vantaggi."
			]
		}
	},
}