module.exports = {
  language: "English",
  navigator: {
    tools: "AI Tools",
    draw: "Draw",
    healthy: "Healthy",
  },
  aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Recommend", "Study", "Life", "Creation", "Work"],
      presetGreet:
        "Hey, hello~ I am your health steward, always ready to provide interesting and useful health knowledge to help you protect your health!",
      presetQuiz: [
        "What are the causes of chronic fatigue?",
        "How can young people prevent chronic diseases?",
        "How to maintain healthy eating habits?",
        "How to maintain good sleep?",
      ],
      presets: "You can ask me:",
      change: "Change batch",
      uploadImage: "Send image",
      footer:
        "Since the content is generated by an AI model, its authenticity cannot be fully guaranteed.",
      remind: "Todo reminder",
      limit:
        "This conversation has reached the limit, please start a new AI chat session",
      newDialog: "Start a new conversation",
      stopRecording: "Click anywhere or when the countdown ends to recognize",
      send: "Send message",
      say: "Click to talk",
      notImage: "This agent does not support sending files and images",
      talking: "Current conversation in progress",
      drawViolations:
        "AI drawing prompt violates rules, please input a compliant prompt.",
      exhausted:
        "The number of Q&As has been exhausted, please go to the watch end and scan the QR code to continue.",
      serverBusy: "The current network link is unstable, please try again",
      chatHistory: "Chat history",
      chatHistoryDesc: "Q&A records are kept for 3 days only",
      todoList: "Todo list",
      todoDesc: "Todo details",
      todoListDesc: "A maximum of 10 todos can exist",
      confirm: "Confirm",
      cancel: "Cancel",
      text: "Text cannot be empty",
      cancelRemind: "Cancel reminder",
      remindTime: "Reminder time:",
      remindTitle: "Reminder title:",
      remindContent: "Reminder content:",
      edit: "Edit",
      reachLimit: "Added up to the limit",
      errorChat:"No voice detected yet, please try again",
            noSTT:"Permission not obtained, please enable permission to continue",
      error50:"An unknown error occurred, please try again"
    },
    draw: {
      describe: "Describe your image",
      voice: "Voice input",
      press: "Press to talk",
      placeholder: "Describe the image you want to generate",
      style: "Painting style",
      styleList: [
        "Ink style",
        "Cyberpunk",
        "Anime",
        "Origami",
        "Knitting",
        "Graphics",
        "Cartoon",
        "LEGO",
        "Pencil",
        "Draw",
      ],
      record: "Drawing record",
      recordDesc: "Up to 10 drawing records can be saved",
      recordEmpty: "You currently have no drawing records",
      generate: "Generate image",
      result: "Result of image generation",
      download: "Download image",
      regenerate: "Regenerate",
      setDial: "Set as watch face",
      error: "Image generation failed",
      errorDesc: "Unstable network connection, please resubmit.",
      confirm: "Confirm",
      analysiserror: "Analysis failed",
      SetWatchErr:["Watch face setting successful","Cancel sending","Data verification failed","Watch face data file is too large","Watch face synchronization limit reached","Bluetooth disconnected"],
      SetWatchTips:["Setting failed","Setting the watch face","Setting the watch face, please do not exit the current page"],
      progressText:"In sync"
    },
    sports: {
      analyze: "Health Analysis",
      analyzeDesc: "Analyze basic data",
      advice: "Health Advice",
      adviceDesc: "Provide health advice",
      experience: "Experience",
      program: {
        plan: "Weight Loss Plan",
        startDate: "Choose Start Date",
        chooseDate: "Please select 1 day as the start date",
        week: [
          {
            num: "Sun.",
            day: "Sunday",
          },
          {
            num: "Mon.",
            day: "Monday",
          },
          {
            num: "Tue.",
            day: "Tuesday",
          },
          {
            num: "Wed.",
            day: "Wednesday",
          },
          {
            num: "Thur.",
            day: "Thursday",
          },
          {
            num: "Fri.",
            day: "Friday",
          },
          {
            num: "Sat.",
            day: "Saturday",
          },
        ],
        arrange: "Plan Arrangement",
        arrangeDesc:
          "Start from the nearest date you choose, a total of 7 training days; the specific content is as follows:",
        confirm: "Confirm Arrangement",
        success:
          "Congratulations, you have successfully customized the plan! Start your training on the 'Exercise Check-in' page!",
        successButton: "Go to Exercise Check-in",
      },
      newmessage: {
        message1: ["Personal Center", "Health Data"],
        message2: ["Health", "Exercise", "Diet"],
        message3: ["Gender", "Age", "Height", "Weight"],
        message4: [
          "Sleep",
          "HR",
          "Oxygen",
          "Steps",
          "BP",
          "Pressure",
          "Summary",
        ],
        message5: ["Consume", "Suggest", "TodaysSteps", "Exercise Duration "],
        message6: [
          "Exercise Check-in",
          "Fitness Home Edition",
          "Fitness Pro",
          "Weight Loss Plan",
        ],
        message7: [
          "Exercise and live a healthy life",
          "Customize your fitness plan",
          "Customize your fitness plan",
          "Create a weight loss plan for you",
        ],
        message8: [
          "Kcal",
          "Steps",
          "Minutes",
          "Sets",
          "Days",
          "Years",
          "cm",
          "kg",
          "h","Weeks"
        ],
        message9: [
          "Check-in",
          "Training",
          "Exercise Options",
          "My Achievements",
          "Consecutive Check-in Days",
          "Not Checked-in",
        ],
        message10: [
          "Click to Analyze",
          "Analysis History (up to 10 saved)",
          "Analyze",
          "You currently have no historical records",
          "Consecutive Check-in Days",
          "Data Analysis",
        ],
        message11: ["Female", "Male"],
        message12: ["Cancel", "Confirm"],
        message13: ["Years", "Centimeters", "Kilograms"],
        message14: [
          "Manual Input",
          "Expected time (Weeks) ",
          "Gender",
          "Age",
          "Height (Centimeters)",
          "Weight (Kilograms)",
          "Expected Weight (Kilograms)",
        ],
        message15: ["History", "Health Data Analysis", "Health Advice"],
        message16: [
          "Check-in Successful",
          "Checking in",
          "Can only check in on the same day",
        ],
        message17: [
          "Health data analysis can only be obtained once a day, and local data cannot be found",
          "Health advice can only be obtained once a day, and local data cannot be found",
        ],
        message18: [
          "Currently underweight",
          "Your BMI index is",
          "it is not advisable to continue losing weight(Healthy range 18.5<BMI<24)",
        ],
        message19: [
          "Moderate weight loss",
          "It is recommended to lose no more than 500g per day",
        ],
        message20: ["have plans", "Does it cover existing plans"],
        message21: ["Easy", "Normal", "Medium", "High"],
        message22: ["Spirt:", "Low", "High"],
		message23: ["The number of times has been used up. Purchase a subscription to unlock more times"],
      },
    },
    punch: {
      title: "Done",
      title2: "Finish",
      h1: "Exercise Options",
      h2: "My Achievements",
      button: "Check-in",
      day: "Consecutive Check-in Days",
      dayunit: "Days",
    },
    eat: {
      title: "Diet",
      DietaryIntake: "Eating",
      kcal: "Kcal",
      Stillingestible: "Remainder",
      overingest: "Beyond",
      Recommendedintake: "Suggest",
      Motorconsumption: "Motion",
      carbohydrate: "Carbs",
      protein: "Protein",
      fat: "Fat",
      gram: "Grams",
      drinkDk: "Water Check-in",
      drinkDkhealth: "Daily check-in for a healthier body",
      eatsuggest: "Dietary Suggestions",
      analyzefoodglx: "Analyze the rationality of diet",
      eatPlan: "Diet Plan",
      makeeatPlan: "Customize your exclusive diet plan",
      breakfast: "Breakfast",
      lunch: "Lunch",
      dinner: "Dinner",
      snack: "Snack",
      addFood: "Add Food",
      noAddFood: "No food added",
      cancle: "Cancel",
      confirm: "Confirm",
      tips: "Proactively filling in is considered as consent for us to collect this project information and display it in the diet",
      addFooded: "Food added",
      added: "Added",
      numFood: "Items of food",
      record: "Record",
      dkTitle: "Check-in",
      dailygoal: "Daily Goal",
      drinkDw: "Cups/Day",
      drinkWater: "Drink Water",
      dailygoalWater: "Daily water drinking goal",
      myachievement: "My Achievements",
      lxdkDays: "Consecutive Punch Days",
      day: "Days",
      analyzeHistory: "History",
      conclusion: "Summary",
      eatDataAnalyze: "Diet Data Analysis",
      selectStartDate: "Choose Start Date",
      chooseoneDate: "Please select 1 day as the start date",
      scheduling: "Plan Arrangement",
      schedulingDesc:
        "Start from the nearest date you choose, a total of 7 days; the specific content is as follows:",
      eatDetail: "Diet Details",
      analyzeFailed: "Analysis failed",
      noData: "no data",
    },
  },
  error: {
    error1101:"The network connection is unstable, this session timeout",
    error1102:"There is no network at the moment, please reconnect.",
    error1103:"An unknown error occurred",
    error61:"The current service is unavailable",
    error4:"No voice detected yet, please try again",
    error12:"Please input standard words or sentences for AI to understand and draw.",
    error50:"Sorry, the current server is wrong, please try again"
  },
  setting: {
    index: {
      title: "Settings",
      problem: "Problem Feedback",
      order: "Order Records",
      notice: "Notification",
      language: "Language",
      clear: "Clear Cache",
      clearTip: "Are you sure you want to\nclear the cache?",
      confirm: "Confirm",
      cancel: "Cancel",
    },
    language: {
      title: "Language",
      langList: [
        "Simplified Chinese",
        "Traditional Chinese",
        "English",
        "Arabic",
        "Polish",
        "Persian",
        "Danish",
        "German",
        "Russian",
        "French",
        "Dutch",
        "Czech",
        "Romanian",
        "Portuguese",
        "Japanese",
        "Thai",
        "Turkish",
        "Spanish",
        "Greek",
        "Italian",
        "Vietnamese",
        "Hindi",
      ],
    },
    order: {
      title: "Order Records",
      success: "Successfully Retrieved",
      unpaid: "Unpaid",
      paid: "Paid",
      expired: "Expired",
      refunded: "Refunded",
      pTime: "Payment Time",
      eTime: "Expiration Time",
      pMethod: "Payment Method",
      pObject: "Payment Object",
      oNumber: "Order Number",
      pType: "Package Type",
      oTime: "Retrieval Time",
      watch: "View IMEI",
      delete: "Delete Order",
      refund: "Request Refund",
    },
    problem: {
      title: "Problem Feedback",
      feedback: "Feedback Type",
      please: "Please Select",
      content: "Content",
      improve:
        "Please write down your suggestions, such as feature requests, product complaints, etc. We will strive to improve",
      upload: "Upload Image",
      submit: "Submit",
      general: "General Feedback",
      purchase: "Purchase Related",
      bug: "Bug Report",
      suggestion: "Suggestion",
      other: "Other",
    },
    notice: {
      title: "Notification",
      type: "Notification Type: ",
      time: "Notification Time: ",
    },
    unsubscribe: {
      watch: "Watch:",
      title: "Continuous Subscription",
      subTitle: "Subscribed",
      unsubscribe: "Unsubscribe",
      packList: [
        "Premium Subscription",
        "Basic Subscription",
        "Standard Subscription",
      ],
    },
    refund: {
      title: "Request Refund",
      number: "Order Number",
      content: "Please explain the reason for the refund",
      packList: [
        "New User Discount Package",
        "50 Times Package",
        "100 Times Package",
        "300 Times Package",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Get Advanced Tutorial",
      watchList: [
        "Turn on the watch, find the WearAI application and click to open.",
        "Click on the advanced benefits button on the home page.",
        "Take out your mobile phone and scan the QR code on the watch to enter the benefits page.",
      ],
    },
  },
};
