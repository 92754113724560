module.exports = {
	language: "Português",
	navigator: {
		tools: "IA Função",
		draw: "Desenhar",
		healthy: "Dados",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Recomendar", "Estudo", "Vida", "Criação", "Trabalho"],
			presetGreet: "Ei, olá~ Eu sou seu mordomo de saúde, sempre pronto para fornecer conhecimentos interessantes e úteis sobre saúde para ajudar você a proteger sua saúde!",
			presetQuiz: ["Quais são as causas da fadiga crônica?", "Como os jovens podem prevenir doenças crônicas?", "Como manter hábitos alimentares saudáveis?", "Como manter um bom sono?"],
			presets: "Você pode me perguntar:",
			change: "Trocar lote",
			uploadImage: "Enviar imagem",
			footer: "Como o conteúdo é gerado por um modelo de IA, sua autenticidade não pode ser totalmente garantida.",
			remind: "Lembrete de tarefas",
			limit: "Esta conversa atingiu o limite, por favor inicie uma nova sessão de chat com IA",
			newDialog: "Iniciar uma nova conversa",
			stopRecording: "Clique em qualquer lugar ou quando a contagem regressiva terminar para reconhecer",
			send: "Enviar mensagem",
			say: "Clique para falar",
			notImage: "Este agente não suporta o envio de arquivos e imagens",
			talking: "Conversa atual em andamento",
			drawViolations: "O prompt de desenho da IA viola as regras, por favor insira um prompt em conformidade.",
			exhausted: "O número de Q&As foi esgotado, por favor vá para o relógio e escaneie o QR code para continuar.",
			serverBusy: "O link de rede atual está instável, por favor tente novamente",
			chatHistory: "Histórico de bate-papo",
			chatHistoryDesc: "Os registros de Q&A são mantidos por apenas 3 dias",
			todoList: "Lista de tarefas",
			todoDesc: "Detalhes das tarefas",
			todoListDesc: "Pode haver no máximo 10 tarefas",
			confirm: "Confirmar",
			cancel: "Cancelar",
			text: "O texto não pode estar vazio",
			cancelRemind: "Cancelar lembrete",
			remindTime: "Hora do lembrete:",
			remindTitle: "Título do lembrete:",
			remindContent: "Conteúdo do lembrete:",
			edit: "Editar",
			reachLimit: "Adicionado até o limite",
			errorChat:"Voz ainda não detectada, tente novamente",
			noSTT:"Permissão não obtida, Ative a permissão antes de continuar.",
      error50:"Ocorreu um erro desconhecido, tente novamente"
		},
		draw: {
			describe: "Descreva sua imagem",
			voice: "Entrada de voz",
			press: "Pressione para falar",
			placeholder: "Descreva a imagem que você deseja gerar",
			style: "Estilo de pintura",
			styleList: ["Estilo de tinta", "Cyberpunk", "Anime", "Origami", "Tricô",  "Gráficos", "Desenho animado","LEGO","Lápis","Desenhar"],
			record: "Registro de desenho",
			recordDesc: "Até 10 registros de desenhos podem ser salvos",
			recordEmpty: "Ainda não há registo",
			generate: "Gerar imagem",
			result: "Resultado",
			download: "Download",
			regenerate: "Regenerar",
			setDial: "Configurar como mostrador de relógio",
			error: "Falha na geração de imagem",
			errorDesc: "Conexão de rede instável, por favor reenvie.",
			confirm: "Confirmar",
			analysiserror: "Falha na análise",
      SetWatchErr:["Discagem definida com sucesso","Cancelar envio","Falha na verificação de dados","O arquivo de dados do mostrador do relógio é muito grande","A sincronização do mostrador do relógio atingiu o limite superior","O Bluetooth foi desconectado"],
      SetWatchTips:["Falha na configuração","Configuração de discagem em andamento","O mostrador do relógio está sendo configurado, não saia da página atual."],
      progressText:"Em sincronia"

		},
sports: {
			analyze: "Análise de Saúde",
			analyzeDesc: "Analisar dados básicos",
			advice: "Conselho de Saúde",
			adviceDesc: "Conselho razoável",
			experience: "Experiência",
			program: {
				plan: "Plano de Perda de Peso",
				startDate: "Escolha a Data de Início",
				chooseDate: "Por favor, selecione 1 dia como a data de início",
				week: [{
					num: "Dom.",
					day: "Domingo"
				}, {
					num: "Seg.",
					day: "Segunda-feira"
				}, {
					num: "Ter.",
					day: "Terça-feira"
				}, {
					num: "Qua.",
					day: "Quarta-feira"
				}, {
					num: "Qui.",
					day: "Quinta-feira"
				}, {
					num: "Sex.",
					day: "Sexta-feira"
				}, {
					num: "Sáb.",
					day: "Sábado"
				}, ],
				arrange: "Arranjo do Plano",
				arrangeDesc: "Comece na data mais próxima que você escolher, um total de 7 dias de treinamento; o conteúdo específico é o seguinte:",
				confirm: "Confirmar Arranjo",
				success: "Parabéns, você personalizou o plano com sucesso! Comece o seu treinamento na página 'Check-in do Exercício'!",
				successButton: "Ir para o Check-in do Exercício",
			},
newmessage: {
				message1: ['Centro Pessoal', 'Dados de Saúde'],
				message2: ['Saúde', 'Esportes', 'Dieta'],
				message3: ['Gênero', 'Idade', 'Altura', 'Peso'],
				message4: ['Sono', 'FC', 'Oxigênio', 'Passos', 'PA', 'Pressão', 'Resumo'],
				message5: ['Consumir', 'Sugerir', 'Passos de Hoje', 'Esportes tempo'],
				message6: ['Check-in do Exercício', 'Fitness Edição para Casa', 'Fitness Pro', 'Plano de Perda de Peso'],
				message7: ['Exercite-se e viva uma vida saudável', 'Personalize seu plano de fitness', 'Personalize seu plano de fitness', 'Gerar plano de perda de peso'],
				message8: ['Kcal', 'Passos', 'Minutos', 'Séries', 'Dias', 'Anos', 'cm', 'kg', 'h','Semanas'],
				message9: ['Check-in', 'Treinamento', 'Opções de Exercício', 'Minhas Conquistas', 'Dias Consecutivos de Check-in', 'Não Realizou Check-in'],
				message10: ['Clique para Analisar', 'Histórico de Análises (até 10 salvos)', 'Analisar', 'Você atualmente não tem registros históricos', 'Dias Consecutivos de Check-in', 'Análise de Dados'],
				message11: ['Feminino', 'Masculino'],
				message12: ['Cancelar', 'Confirmar'],
				message13: ['Anos', 'Centímetros', 'Quilogramas'],
				message14: ['Entrada Manual', 'Tempo previsto (Semanas)', 'Gênero', 'Idade', 'Altura (Centímetros)', 'Peso (Quilogramas)', 'Peso Esperado (Quilogramas)'],
				message15: ['Histórico', 'Análise de Dados de Saúde', 'Conselho de Saúde'],
				message16: ['Check-in Bem-sucedido', 'Realizando Check-in', 'Só é possível realizar check-in no mesmo dia'],
				message17: ['A análise de dados de saúde só pode ser obtida uma vez por dia, e os dados locais não podem ser encontrados', 'O conselho de saúde só pode ser obtido uma vez por dia, e os dados locais não podem ser encontrados'],
				message18: ['Atualmente com baixo peso', 'Seu índice de IMC é', 'não é aconselhável continuar perdendo peso (Faixa saudável 18.5<BMI<24)'],
				message19: ['Perda de peso moderada', 'Recomenda-se perder não mais que 500g por dia'],
				message20: ['tem planos', 'Cobre os planos existentes'],
				message21: ['Fácil', 'Normal', 'Médio', 'Alto'],
				message22: ['Espírito:', 'Baixo', 'Alto'],
				message23: ['O número de vezes que foi utilizado Comprar uma subscrição para desbloquear mais vezes.'],
			},
		},
		punch: {
			title: "Concluído",
			title2: "Terminar",
			h1: "Opções de Exercício",
			h2: "Minhas Conquistas",
			button: "Check-in",
			day: "Dias Consecutivos de Check-in",
			dayunit: "Dias",
		},
eat: {
			title: "Dieta",
			DietaryIntake: "Comer",
			kcal: "Kcal",
			Stillingestible: "Restante",
			overingest: "Além",
			Recommendedintake: "Sugerir",
			Motorconsumption: "Movimento",
			carbohydrate: "Carboidratos",
			protein: "Proteína",
			fat: "Gordura",
			gram: "Gramas",
			drinkDk: "Check-in de Água",
			drinkDkhealth: "Beber água é mais saudável",
			eatsuggest: "Sugestões Dietéticas",
			analyzefoodglx: "Analisar a racionalidade da dieta",
			eatPlan: "Plano de Dieta",
			makeeatPlan: "Personalize seu plano de dieta exclusivo",
			breakfast: "Manhã",
			lunch: "Almoço",
			dinner: "Jantar",
			snack: "Lanche",
			addFood: "Adicionar Alimento",
			noAddFood: "Nenhum alimento adicionado",
			cancle: "Cancelar",
			confirm: "Confirmar",
			tips: "Preencher proativamente é considerado como consentimento para que coletemos esta informação do projeto e a exibamos na dieta",
			addFooded: "Alimento adicionado",
			added: "Adicionado",
			numFood: "Itens de alimento",
			record: "Registro",
			dkTitle: "Check-in",
			dailygoal: "Meta Diária",
			drinkDw: "Copos/Dia",
			drinkWater: "Beber Água",
			dailygoalWater: "Meta diária de ingestão de água",
			myachievement: "Minhas Conquistas",
			lxdkDays: "Dias Consecutivos de Check-in",
			day: "Dias",
			analyzeHistory: "Histórico",
			conclusion: "Resumo",
			eatDataAnalyze: "Análise de Dados de Dieta",
			selectStartDate: "Escolher Data de Início",
			chooseoneDate: "Por favor, selecione 1 dia como a data de início",
			scheduling: "Arranjo do Plano",
			schedulingDesc: "Comece na data mais próxima que você escolher, um total de 7 dias; o conteúdo específico é o seguinte:",
			eatDetail: "Detalhes da Dieta",
			analyzeFailed: "Análise falhou",
			noData: "sem dados"


		}
	},
  error: {
    error1101:"A conexão de rede é instável, o tempo limite da sessão atual",
    error1102:"De momento não há rede, reconecte",
    error1103:"Ocorreu um erro desconhecido",
    error61:"O serviço atual não está disponível",
    error4:"Voz ainda não detectada, tente novamente",
    error12:"Introduza palavras ou frases padrão para a IA compreender e desenhar",
		error50:"Desculpe, o servidor atual está errado, tente novamente"
  },
	setting: {
		index: {
			title: "Configurações",
			problem: "Feedback de Problemas",
			order: "Registros de Pedidos",
			notice: "Notificação",
			language: "Idioma",
			clear: "Limpar Cache",
			clearTip: "Tem certeza de que deseja\nlimpar o cache?",
			confirm: "Confirmar",
			cancel: "Cancelar",
		},
		language: {
			title: "Idioma",
			langList: ["Chinês Simplificado", "Chinês Tradicional", "Inglês", "Árabe", "Búlgaro", "Bengali", "Tcheco", "Alemão", "Grego", "Espanhol", "Persa", "Filipino", "Francês", "Hindi", "Húngaro", "Indonésio", "Italiano", "Hebraico", "Japonês", "Coreano", "Malaio", "Holandês", "Punjabi", "Polonês", "Português", "Romeno", "Russo", "Eslovaco", "Tailandês", "Turco", "Ucraniano", "Urdu", "Vietnamita"],
		},
		order: {
			title: "Registros de Pedidos",
			success: "Recuperado com Sucesso",
			unpaid: "Não Pago",
			paid: "Pago",
			expired: "Expirado",
			refunded: "Reembolsado",
			pTime: "Hora do Pagamento",
			eTime: "Hora da Expiração",
			pMethod: "Método de Pagamento",
			pObject: "Objeto de Pagamento",
			oNumber: "Número do Pedido",
			pType: "Tipo de Pacote",
			oTime: "Tempo de Recuperação",
			watch: "Ver IMEI",
			delete: "Excluir Pedido",
			refund: "Solicitar Reembolso",
		},
problem: {
			title: "Feedback de Problemas",
			feedback: "Tipo de Feedback",
			please: "Por Favor Selecione",
			content: "Conteúdo",
			improve: "Por favor, escreva suas sugestões, como pedidos de recursos, reclamações de produtos, etc. Nós nos esforçaremos para melhorar",
			upload: "Carregar Imagem",
			submit: "Enviar",
			general: "Feedback Geral",
			purchase: "Relacionado à Compra",
			bug: "Relatório de Bug",
			suggestion: "Sugestão",
			other: "Outro",
		},
		notice: {
			title: "Notificação",
			type: "Tipo de Notificação: ",
			time: "Hora da Notificação: "
		},
		unsubscribe: {
			watch: "Relógio:",
			title: "Assinatura Contínua",
			subTitle: "Assinado",
			unsubscribe: "Cancelar Assinatura",
			packList: ["Assinatura Premium", "Assinatura Básica", "Assinatura Padrão"],
		},
		refund: {
			title: "Solicitar Reembolso",
			number: "Número do Pedido",
			content: "Por favor, explique o motivo do reembolso",
			packList: ["Pacote de Desconto para Novo Usuário", "Pacote de 50 Vezes", "Pacote de 100 Vezes", "Pacote de 300 Vezes"],
		}
	},
	tutorial: {
		index: {
			title: "Obter Tutorial Avançado",
			watchList: ["Ligue o relógio, encontre o aplicativo WearAI e clique para abrir.",
				"Clique no botão de benefícios avançados na página inicial.",
				"Pegue seu celular e escaneie o QR code no relógio para entrar na página de benefícios."
			]
		}
	},
}