export function langChange(langCode) {
        // console.log(langCode);
        if(langCode.includes("en")) {
            return "en-US";
        } else if (langCode.includes("TW") || langCode.includes("HK")) {
            return "zh-HK";
        } else if (langCode.includes("zh")) {
            return "zh-CN";
        } else if (langCode.includes("ar")) {
            return "ar-AE";
        } else if (langCode.includes("pl")) {
            return "pl-PL";
        } else if (langCode.includes("fa")) {
            return "fa-IR";
        } else if (langCode.includes("da")) {
            return "da-DK";
        } else if (langCode.includes("de")) {
            return "de-DE";
        } else if (langCode.includes("ru")) {
            return "ru-RU";
        } else if (langCode.includes("fr")) {
            return "fr-FR";
        } else if (langCode.includes("nl")) {
            return "nl-NL";
        } else if (langCode.includes("cs")) {
            return "cs-CZ";
        } else if (langCode.includes("ro")) {
            return "ro-RO";
        } else if (langCode.includes("pt")) {
            return "pt-BR";
        } else if (langCode.includes("ja")) {
            return "ja-JP";
        } else if (langCode.includes("th")) {
            return "th-TH";
        } else if (langCode.includes("tr")) {
            return "tr-TR";
        } else if (langCode.includes("ca")||langCode.includes("es")) {
            return "ca-ES";
        } else if (langCode.includes("el")) {
            return "el-GR";
        } else if (langCode.includes("it")) {
            return "it-IT";
        } else if (langCode.includes("vi")) {
            return "vi-VN";
        } else if (langCode.includes("hi")) {
            return "hi-IN";
        }else if (langCode.includes("bg")) {
            return "bg";
        }
        else if (langCode.includes("bn")) {
            return "bn";
        }
        else if (langCode.includes("fil")) {
            return "fil";
        }
        else if (langCode.includes("hu")) {
            return "hu";
        }
        else if (langCode.includes("in")) {
            return "in";
        }
        else if (langCode.includes("iw")) {
            return "iw";
        }
        else if (langCode.includes("ko")) {
            return "ko";
        }
        else if (langCode.includes("ms")) {
            return "ms";
        }
        else if (langCode.includes("pa")||langCode.includes("Punjabi") ||langCode.includes("Pnb")) {
            return "Punjabi-Shahmukhi";
        }
        else if (langCode.includes("sk")) {
            return "sk";
        }
        else if (langCode.includes("uk")) {
            return "uk";
        }
        else if (langCode.includes("ur")) {
            return "ur";
        }
		
		else if (langCode.includes("da")) {
		    return "da";
		}
		else if (langCode.includes("fi")) {
		    return "fi";
		}
		else if (langCode.includes("ga")) {
		    return "ga";
		}
		else if (langCode.includes("hr")) {
		    return "hr";
		}
		else if (langCode.includes("km")) {
		    return "km";
		}
		else if (langCode.includes("mn")) {
		    return "mn";
		}
		else if (langCode.includes("no")) {
		    return "no";
		}
		else if (langCode.includes("sl")) {
		    return "sl";
		}
		else if (langCode.includes("sv")) {
		    return "sv";
		}
        return "en-US";

}