export default {

  // 优创亿不做映射版本

  // 手表IMEI 接口
  watchList: [],
  totalNumber: 0,

  channelId: "72f2944938a50422",
  uid: "test_uid",
  os: '',
  language: '',
  osVersion: '',
  deviceId: "4fa2385ded652e60",
  appPkgName: '',
  appVersion: '',
  sdkVersion: '1.1.1',
  resolution: '', // 屏幕分辨率
  model: '', // 设备型号

  // 手表信息
  watchList: '',
  watchId: '',
  watchName: '',
  watchAvatarUrl: '',
  watchPayModel: '',
  watchLicenseModel: '',
  
  notificationId: '',
  notificationType: '',


  // 映射
  pP: 'params',
  pChannelId: 'channelId',
  pUid: 'uid',
  pOs: 'os',
  pLanguage: 'language',
  pOsVersion: 'osVersion',
  pDeviceId: 'deviceId',
  pAppPkgName: 'appPkgName',
  pAppVersion: 'appVersion',
  pSdkVersion: 'sVersion',
  pResolution: 'resolution', //屏幕分辨率
  pModel: 'model', // 设备型号

  // 手表信息
  pWatchList: 'watchlist',
  pWatchId: 'wid',
  pWatchName: 'name',
  pWatchAvatarUrl: 'avatarUrl',
  pWatchPayModel: 'payModel',
  pWatchLicenseModel: 'licenseModel',
  pNotificationId: 'notificationId',
  pNotificationType: 'notificationType',






  obj:"OsJsBridge",
  type: "type",
  subType:"subType",
  msg:"msg",

  audio:"audio",
  image:"image",
  camera: "camera",
  lifeCycle: "lifeCycle",
  data:"data",
  smartChat:"smartChat",
  vue:"vue",

  permission:"permission",
  saveImage:"saveImage",
  recordImage: "recordImage",
  getRecordImage: "getRecordImage",
  deleteRecordImage: "deleteRecordImage",
  startRecordAudio:"startRecordAudio",
  cancelRecordAudio:"cancelRecordAudio",
  finishRecordAudio:"finishRecordAudio",
  saveRecordImage:"saveRecordImage",
  watchToWebWithImgs:"watchToWebWithImgs",

  
  // 生命周期
  backOut: "backOut",
  backStage: "backStage",

  setLanguage:"setLanguage",
  setWatchFace:"setWatchFace",
  dietPlan:"dietPlan",
  fitnessProgram:"fitnessProgram",
  drawStyle:"drawStyle",

    /////////////////////sql操作方法////////////////////////////
    insertSql:"insert",
    deleteSql:"delete",
    updateSql:"update",
    selectSql:"select",
    //操作数据库表参数
    actionColumnName:"columnName",
    actionTableName:"tableName",
    actionData:"data",
    //数据库操作结果参数
    resultTableName:"tableName",
    resultResult:"result",
    //数据库//////////////////////////////////////////////////////////////
    //待办
    databaseAiName:"ai_h5_sdk_db",
    waitDoneTable:"waitDoneTable",
    columnWaitDoneId: "id",
    columnWaitDoneIdd:"idd",
    columnWaitDoneName:"name",
    columnWaitDoneData :"data",
    columnWaitDoneSmartName:"smartName",
    columnWaitDoneSmartIcon :"smartIcon",
    columnWaitDoneContent :"content",
    columnWaitDoneAnswerTime :"answerTime",
    //画图
    generateTable:"generateTable",
    columnGenerateId : "id",
    columnGenerateContent :"content",
    columnGenerateStyle :"drawStyle",
    columnGenerateImgPath :"imgPath",
    
  //非画图字段
  base64Img:"base64Img",


  //文本聊天（AI工具）
  textChat:"textChat",
  //文本生图
  textDraw:"textDraw",
  recognizeResult:"recognizeResult",
  //设置表盘状态码
  setWatchFaceCode:"setWatchFaceCode",
  //表盘设置进度
  setWatchFaceProgress:"setWatchFaceProgress",
  
  setFaceWatchCode:"code",
  setFaceWatchProgress:"progress",
  
  backupHistorySave:"backupHistorySave",
  chatHistorySave:"chatHistorySave",
  backupHistorySelect:"backupHistorySelect",
  chatHistorySelect:"chatHistorySelect",
  chatHistoryResult:"result",

  chatSmartCode:"code",
  chatSmartMessage:"message",
  chatSmartData:"data",
  //vue的事件操作
  initVue:"initVue",
  resultVue:"result",
  clickActionVue:"clickAction",
  actionVue:"action",
  mode: "mode",
  content: "content",
  
  osResult: "osResult",


}