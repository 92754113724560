module.exports = {
  language: "Монгол хэл",
  navigator: {
    tools: "AI хэрэгслүүд",
    draw: "Зурах",
    healthy: "Эрүүл",
  },
  aitools: {
    chat: {
      gpt: "Жарвис",
      categoryList: ["Танилцуулга", "Судалгаа", "Амьдрал", "Бүтээл", "Ажил"],
      presetGreet:
        "Сайн уу, сайн уу~ Би таны эрүүл мэндийн менежер, таны эрүүл мэндийг хамгаалахад туслах сонирхолтой ба полезное эрүүл мэндийн мэдлэгийг үргэлж санал болгоход бэлэн!",
      presetQuiz: [
        "Хэмжээний ядаргаа үүсгэж буй шалтгаанууд юу вэ?",
        "Залуу хүмүүс хэрхэн архаг өвчнөөс сэргийлэх вэ?",
        "Эрүүл хооллолтын зуршлыг хэрхэн хадгалах вэ?",
        "Сайхан унтлагын хэрхэн хадгалах вэ?",
      ],
      presets: "Надад асууж болно:",
      change: "Багц өөрчлөх",
      uploadImage: "Зураг илгээх",
      footer:
        "Энэхүү контентыг AI загвараар боловсруулсан тул түүний үнэн зөвийг бүрэн баталгаажуулах боломжгүй.",
      remind: "Хийх зүйл сануулга",
      limit:
        "Энэхүү ярилцлага хязгаарт хүрсэн, шинэ AI чат сессийг эхлүүлнэ үү",
      newDialog: "Шинэ яриа эхлүүлэх",
      stopRecording: "Хэзээн хаа дурын газраа дарна уу эсвэл тоолох нь дуусахад таньж авах",
      send: "Мэссэж илгээх",
      say: "Ярихыг дарах",
      notImage: "Энэхүү агент файл болон зургийг илгээхийг дэмжихгүй",
      talking: "Одоогоор яриа явагдаж байна",
      drawViolations:
        "AI зургийн даалгавар дүрмийг зөрчсөн, compliant даалгаварыг оруулна уу.",
      exhausted:
        "Q&A тоо дууссан, дуусахын төлөө хүлээж байхыг бэлдэж QR кодыг сканнаарай.",
      serverBusy: "Одоогийн сүлжээний холбоо тогтвортой биш, дахин оролдоно уу",
      chatHistory: "Ярилцлагын түүх",
      chatHistoryDesc: "Q&A бичлэгээр 3 хоногийн турш л хадгалагдана",
      todoList: "Хийх зүйлсийн жагсаалт",
      todoDesc: "Хийх зүйлсийн дэлгэрэнгүй",
      todoListDesc: "Дээд тал нь 10 хийж буй зүйлс байж болно",
      confirm: "Баталгаажуулах",
      cancel: "Цуцлах",
      text: "Текст хоосон байж болохгүй",
      cancelRemind: "Сануулахыг цуцлах",
      remindTime: "Сануулах цаг:",
      remindTitle: "Сануулахын гарчиг:",
      remindContent: "Сануулахын агуулга:",
      edit: "Засварлах",
      reachLimit: "Хязгаар хүрсэн",
      errorChat: "Голос илэрхийлсэнгүй, дахин оролдоно уу",
            noSTT:"Зөвшөөрөл аваагүй тул үргэлжлүүлэхийн өмнө зөвшөөрлийг идэвхжүүлнэ үү.",
      error50:"Үл мэдэгдэх алдаа гарлаа, дахин оролдоно уу"
    },
    draw: {
      describe: "Таны зургыг тодорхойл",
      voice: "Дуугаар оруулах",
      press: "Ярихыг дарах",
      placeholder: "Үүсгэхийг хүсч буй зургаа тодорхойл",
      style: "Зургийн стиль",
      styleList: [
        "Бичгийн стиль",
        "Киберпанк",
        "Аниме",
        "Оригами",
        "Сүлжих",
        "График",
        "Мультфильм",
        "LEGO",
        "Томографи",
        "Зурах",
      ],
      record: "Зургийн бичлэг",
      recordDesc: "Дээд тал нь 10 зургийн бичлэгийг хадгалах боломжтой",
      recordEmpty: "Одоогоор танд зургийн бичлэг байхгүй",
      generate: "Зураг үүсгэх",
      result: "Зураг үүсгэлийн үр дүн",
      download: "Зургийг татаж авах",
      regenerate: "Дахин үүсгэх",
      setDial: "Цагны нүүршилт болгож тохируулах",
      error: "Зураг үүсгэхэд алдаа гарлаа",
      errorDesc: "Сүлжээний холболт тогтвортой биш, дахин оруулахыг хүсч байна.",
      confirm: "Баталгаажуулах",
      analysiserror: "Шинжилгээ амжилтгүй боллоо",
      SetWatchErr:["Дуудлага амжилттай тохирууллаа","Илгээхийг цуцлах","Өгөгдлийн баталгаажуулалт амжилтгүй боллоо","Цагны нүүрний өгөгдлийн файл хэт том байна","Цагны нүүрний синхрончлол дээд хязгаарт хүрсэн","Bluetooth салгагдсан"],
      SetWatchTips:["Тохиргоо амжилтгүй боллоо","Залгах тохиргоо хийгдэж байна","Цагны нүүрийг тохируулж байна, одоогийн хуудаснаас бүү гараарай."],
      progressText:"Синхрончилж байна"

    },
    sports: {
      analyze: "Эрүүл мэндийн шинжилгээ",
      analyzeDesc: "Анхан шатны өгөгдлийг шинжлэх",
      advice: "Эрүүл мэндийн зөвлөгөө",
      adviceDesc: "Эрүүл мэндийн зөвлөгөөг санал болгох",
      experience: "Туршлага",
      program: {
        plan: "Жин хасах төлөвлөгөө",
        startDate: "Эхлэх огноог сонгоно уу",
        chooseDate: "Эхлэх огноо болгон 1 өдрийг сонгоно уу",
        week: [
          {
            num: "Ням.",
            day: "Ням гараг",
          },
          {
            num: "Дава.",
            day: "Даваа гараг",
          },
          {
            num: "Мягм.",
            day: "Мягмар гараг",
          },
          {
            num: "Лхаг.",
            day: "Лхагва гараг",
          },
          {
            num: "Пүр.",
            day: "Пүрэв гараг",
          },
          {
            num: "Баас.",
            day: "Баасан гараг",
          },
          {
            num: "Бямб.",
            day: "Бямба гараг",
          },
        ],
        arrange: "Төлөвлөгөө зохион байгуулалт",
        arrangeDesc:
          "Сонгосон хамгийн ойрын огнооноос эхэлж, нийт 7 сургалтын өдөр; тодорхой агуулга нь дараах байдалтай байна:",
        confirm: "Зохион байгуулалтаа баталгаажуулах",
        success:
          "Баяр хүргэе, та төлөвлөгөөгөө амжилттай тохирууллаа! 'Дасгалын шалгаруулалт' хуудсанд сургалтаа эхлүүлээрэй!",
        successButton: "Дасгалын шалгаруулалтад орох",
      },
      newmessage: {
        message1: ["Хувийн төв", "Эрүүл мэндийн өгөгдөл"],
        message2: ["Эрүүл мэнд", "Дасгал", "Хооллолт"],
        message3: ["Хүйс", "Нас", "Өндөр", "Жин"],
        message4: [
          "Унтлага",
          "HR",
          "Кислород",
          "Алхам",
          "BP",
          "Даралт",
          "Тойм",
        ],
        message5: ["Зарцуулсан", "Зөвлөмж", "Өнөөдрийн алхам", "Дасгалын хугацаа"],
        message6: [
          "Дасгалын шалгаруулалт",
          "Фитнессийн гэрийн хувилбар",
          "Фитнесс Про",
          "Жин хасах төлөвлөгөө",
        ],
        message7: [
          "Дасгал хийж эрүүл амьдраарай",
          "Фитнессийн төлөвлөгөөгөө тохируулах",
          "Фитнессийн төлөвлөгөөгөө тохируулах",
          "Танд зориулсан жин хасах төлөвлөгөөг үүсгэнэ",
        ],
        message8: [
          "Kcal",
          "Алхам",
          "Минут",
          "Сет",
          "Өдөр",
          "Жил",
          "см",
          "кг",
          "ц", "Долоо хоног"
        ],
        message9: [
          "Шалгаруулалт",
          "Сургалт",
          "Дасгалын сонголт",
          "Миний амжилтууд",
          "Давтан шалгаруулалтын өдөр",
          "Шалгаруулалтад ороогүй",
        ],
        message10: [
          "Шинжилгээнд дар",
          "Шинжилгээний түүх (10 хүртэл хадгална)",
          "Шинжлэх",
          "Одоогоор түүхийн бичлэг байхгүй",
          "Давтан шалгаруулалтын өдөр",
          "Өгөгдлийн шинжилгээ",
        ],
        message11: ["Эмэгтэй", "Эрэгтэй"],
        message12: ["Цуцлах", "Баталгаажуулах"],
        message13: ["Жил", "См", "Кг"],
        message14: [
          "Гар аргаар оруулах",
          "Хүлээгдэж буй хугацаа (Долоо хоног)",
          "Хүйс",
          "Нас",
          "Өндөр (См)",
          "Жин (Кг)",
          "Хүлээгдэж буй жин (Кг)",
        ],
        message15: ["Түүх", "Эрүүл мэндийн өгөгдлийн шинжилгээ", "Эрүүл мэндийн зөвлөгөө"],
        message16: [
          "Шалган оруулах амжилттай",
          "Шалган орж байна",
          "Нэг өдрөөр давтан шалгахад л орж болно",
        ],
        message17: [
          "Эрүүл мэндийн өгөгдлийн шинжилгээг өдөрт ганц удаа авах боломжтой бөгөөд орон нутгийн өгөгдлийг олох боломжгүй байна",
          "Эрүүл мэндийн зөвлөгөө өдөрт ганц удаа авах боломжтой бөгөөд орон нутгийн өгөгдлийг олох боломжгүй байна",
        ],
        message18: [
          "Одоогийн байдлаар жин багатай",
          "Таны BMI индекс",
          "Жин хасахыг үргэлжлүүлэх нь зөвшөөрөхгүй (Эрүүл хүрээ 18.5 < BMI < 24)",
        ],
        message19: [
          "Дунд зэргийн жин хасах",
          "Өдөрт 500г-аас илүү жин хасахыг зөвлөдөггүй",
        ],
        message20: ["Төлөвлөгөөтэй", "Ирээдүйд байгаа төлөвлөгөөг хамардаг уу"],
        message21: ["Энгийн", "Дундын", "Дунд зэргийн", "Өндөр"],
        message22: ["Сэтгэл:", "Бага", "Илүү өндөр"],
		message23: ["Цагийн тоо ашиглагдсан. Илүү их тоо нээхийн тулд захиалга хийх шаардлагатай"],
      },
    },
    punch: {
      title: "Биеллээ",
      title2: "Төгсгөл",
      h1: "Дасгалын сонголт",
      h2: "Миний амжилтууд",
      button: "Шалгаруулах",
      day: "Давтан шалгаруулалтын өдөр",
      dayunit: "Өдөр",
    },
    eat: {
      title: "Хооллолт",
      DietaryIntake: "Идэж буй",
      kcal: "Ккал",
      Stillingestible: "Үлдэгдэл",
      overingest: "Хэтрүүлсэн",
      Recommendedintake: "Зөвлөмж",
      Motorconsumption: "Биеийн хөдөлгөөн",
      carbohydrate: "Нүүрс ус",
      protein: "Уураг",
      fat: "Тос",
      gram: "Грамм",
      drinkDk: "Ус шалгаруулах",
      drinkDkhealth: "Эрүүл биетэй болохын тулд өдөр бүр шалгаруулалт",
      eatsuggest: "Хоолны зөвлөмж",
      analyzefoodglx: "Хоол хүнсний рациональ байдлыг шинжлэх",
      eatPlan: "Хооллолтын төлөвлөгөө",
      makeeatPlan: "Таны өвөрмөц хооллолтын төлөвлөгөөг тохируулах",
      breakfast: "Өглөөний хоол",
      lunch: "Үдийн хоол",
      dinner: "Оройн хоол",
      snack: "Гурилан хоол",
      addFood: "Хоол нэмэх",
      noAddFood: "Хоол нэмэгдээгүй",
      cancle: "Цуцлах",
      confirm: "Баталгаажуулах",
      tips: "Идэвхтэйгээр мэдээллээр бөглөх нь энэ төслийн мэдээллийг цуглуулахыг зөвшөөрч байгааг илтгэнэ",
      addFooded: "Хоол нэмэгдлээ",
      added: "Нэмэгдсэн",
      numFood: "Хоолны тоо",
      record: "Бичлэг",
      dkTitle: "Шалгаруулах",
      dailygoal: "Өдрийн зорилго",
      drinkDw: "Кап/Өдөр",
      drinkWater: "Ус уух",
      dailygoalWater: "Өдрийн ус уух зорилго",
      myachievement: "Миний амжилтууд",
      lxdkDays: "Давтан шалгаруулах өдөр",
      day: "Өдрүүд",
      analyzeHistory: "Түүх",
      conclusion: "Тойм",
      eatDataAnalyze: "Хооллолтын өгөгдлийн шинжилгээ",
      selectStartDate: "Эхлэх огноог сонгох",
      chooseoneDate: "Эхлэх огноо болгон 1 өдрийг сонгоно уу",
      scheduling: "Төлөвлөгөө зохион байгуулалт",
      schedulingDesc:
        "Таны сонгосон хамгийн ойрын өдрөөс эхлэн нийт 7 хоног; тусгай агуулга нь дараах байдалтай байна:",
      eatDetail: "Хооллолтын дэлгэрэнгүй",
      analyzeFailed: "Шинжилгээ амжилтгүй боллоо",
      noData: "Мэдээлэл байхгүй",
    },
  },
  error: {
    error1101:"Сүлжээний холболт тогтворгүй, одоогийн сешний хугацаа",
    error1102:"Одоогоор сүлжээ байхгүй, дахин холбогдоно уу",
    error1103:"Үл мэдэгдэх алдаа гарлаа",
    error61:"Одоогийн үйлчилгээ боломжгүй байна",
    error4:"Дуу хоолой хараахан танигдаагүй байна, дахин оролдоно уу",
    error12:"AI-д ойлгох, зурахын тулд стандарт үг эсвэл өгүүлбэр оруулна уу",
    error50:"Уучлаарай, одоогийн сервер дээр алдаа гарлаа, дахин оролдоно уу"

  },
  setting: {
    index: {
      title: "Тохиргоо",
      problem: "Асуудлын хариу",
      order: "Захиалгын бичиг",
      notice: "Мэдээлэл",
      language: "Хэл",
      clear: "Кэшийг цэвэрлэх",
      clearTip: "Та кэшийг цэвэрлэхэд итгэлтэй байна уу?",
      confirm: "Баталгаажуулах",
      cancel: "Канцеллах",
    },
language: {
      title: "Хэл",
      langList: [
        "Энгийн хятад",
        "Уламжлалт хятад",
        "Англи",
        "Араб",
        "Польш",
        "Перс",
        "Дани",
        "Герман",
        "Орос",
        "Франц",
        "Нидерланд",
        "Чех",
        "Румын",
        "Португал",
        "Япон",
        "Тай",
        "Турк",
        "Испани",
        "Грекийн",
        "Итали",
        "Вьетнам",
        "Хинди",
      ],
    },
    order: {
      title: "Захиалгын бичиг",
      success: "Амжилттай хувийн жагсаалт",
      unpaid: "Төлбөргүй",
      paid: "Төлөгдсөн",
      expired: "Дууссан",
      refunded: "Буцаагдсан",
      pTime: "Төлбөрийн цаг",
      eTime: "Дуусах цаг",
      pMethod: "Төлбөрийн арга",
      pObject: "Төлбөрийн объект",
      oNumber: "Захиалгын дугаар",
      pType: "Пакет төрөл",
      oTime: "Хүргэлтийн цаг",
      watch: "IMEI-г үзэх",
      delete: "Захиалгыг устгах",
      refund: "Буцаалт хүсэх",
    },
problem: {
      title: "Асуудлын хариу",
      feedback: "Хариу төрөл",
      please: "Та сонгоно уу",
      content: "Агуулга",
      improve:
        "Та шинэ санаануудыг бичиж өгнө үү, жишээлбэл, шинж чанарын хүсэлт, бүтээгдэхүүний гомдол гэх мэт. Бид сайжруулахын тулд хичээх болно.",
      upload: "Зураг оруулах",
      submit: "Илгээх",
      general: "Ерөнхий хариу",
      purchase: "Захиалттай холбоотой",
      bug: "Бугийн тайлан",
      suggestion: "Санамж",
      other: "Бусад",
    },
    notice: {
      title: "Мэдээлэл",
      type: "Мэдэгдлийн төрөл: ",
      time: "Мэдэгдлийн цаг: ",
    },
    unsubscribe: {
      watch: "Харах:",
      title: "Үргэлжилсэн захиалгаа",
      subTitle: "Захиалсан",
      unsubscribe: "Захиалгаа цуцлах",
      packList: [
        "Premium захиалгаа",
        "Үндсэн захиалгаа",
        "Стандарт захиалгаа",
      ],
    },
refund: {
      title: "Буцаалт хүсэх",
      number: "Захиалгын дугаар",
      content: "Та буцаалтын шалтгааныг тайлбарлана уу",
      packList: [
        "Шинэ хэрэглэгчийн хямдралын пакет",
        "50 удаагийн пакет",
        "100 удаагийн пакет",
        "300 удаагийн пакет",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Д avanzado заавар авах",
      watchList: [
        "Цагийг асаагаад, WearAI аппликэйшнийг олж нээгээрэй.",
        "Нүүр хуудсанд advanced benefits товчийг дарна уу.",
        "Утасныхаа QR кодыг цагны QR код руу скан хийж урагшаа ашиглах хуудас руу ороорой.",
      ],
    },
  },
}
 