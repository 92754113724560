module.exports = {
  language: "Gaeilge",
  navigator: {
    tools: "Uirlisí AI",
    draw: "Tarraing",
    healthy: "Sláintiúil",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Moladh", "Staidéar", "Saol", "Cruthú", "Obair"],
      presetGreet:
        "Hey, hello~ Is mise do chúntóir sláinte, i gcónaí réidh le hagallaimh suimiúla agus úsáideacha a sholáthar chun cabhrú leat do shláinte a chosaint!",
      presetQuiz: [
        "Cad iad na cúiseanna a bhainneann le tuirse chráiteach?",
        "Conas is féidir le daoine óga galar crónach a chosc?",
        "Conas a choinneoidh tú gnáthlaethanta sláintúla?",
        "Conas a choinneoidh tú codladh maith?",
      ],
      presets: "Is féidir leat iarraidh orm:",
      change: "Athraigh grúpa",
      uploadImage: "Seol íomhá",
      footer:
        "Ós rud é go gineann an clónna AI an t-ábhar, ní féidir a fhíorú go hiomlán a fhíor.",
      remind: "Cuimhneacháin Todo",
      limit:
        "Tá an comhoibriú seo faoi bhéim, déan teagmháil le comhoibriú nua AI",
      newDialog: "Tosaigh comhrá nua",
      stopRecording: "Cliceáil áit ar bith nó nuair a chríochnaíonn an cuntas chun aitheantas a fháil",
      send: "Seol teachtaireacht",
      say: "Cliceáil chun labhairt",
      notImage: "Ní thacaíonn an gníomhaire seo le comhoibriú comhoibrithe agus íomhánna",
      talking: "Comhrá reatha ar siúl",
      drawViolations:
        "Tá an t-iarratas tarraingthe AI i gcodarsnachtaí, cuir isteach iarratas comhoiriúnach.",
      exhausted:
        "Tá an líon Q&A críochnaithe, le do thoil téigh chuig an deireadh faire agus scanáil an chód QR chun leanúint ar aghaidh.",
      serverBusy: "Tá an nasc gréasáin atá ann faoi láthair neamhchinnte, déan iarracht arís",
      chatHistory: "Stair comhrá",
      chatHistoryDesc: "Coinnítear taifid Q&A ar feadh 3 lá amháin",
      todoList: "Liosta Todo",
      todoDesc: "Sonraí Todo",
      todoListDesc: "Is éard atá i gceist le 10 todo atá ann",
      confirm: "Deimhnigh",
      cancel: "Cealaigh",
      text: "Ní féidir leis an téacs a bheith folamh",
      cancelRemind: "Cealaigh cuimhneachán",
      remindTime: "Am cuimhneacháin:",
      remindTitle: "Teideal cuimhneacháin:",
      remindContent: "Ábhar cuimhneacháin:",
      edit: "Eagarthóireacht",
      reachLimit: "Tugadh suas go dtí an teorainn",
      errorChat:"Níl aon guth á tharchur fós, déan iarracht arís",
            noSTT:"Ní bhfuarthas cead, cumasaigh cead sula leanann tú ar aghaidh.",
      error50:"Tharla earráid anaithnid, bain triail eile as"
    },
draw: {
      describe: "Cur síos ar do íomhá",
      voice: "Ionchur guth",
      press: "Cliceáil chun labhairt",
      placeholder: "Cur síos ar an íomhá ar mhaith leat a ghiniúint",
      style: "Stíl péintéireachta",
      styleList: [
        "Stíl laiste",
        "Cyberpunk",
        "Anime",
        "Origami",
        "Crochet",
        "Grafach",
        "Cartoon",
        "LEGO",
        "Peann luaidhe",
        "Tarraing",
      ],
      record: "Taifead tarraingthe",
      recordDesc: "Is féidir suas le 10 taifead tarraingthe a shábháil",
      recordEmpty: "Níl taifid tarraingthe agat faoi láthair",
      generate: "Giniúint íomhá",
      result: "Torthaí giniúna íomhá",
      download: "Íomhá a íoslódáil",
      regenerate: "Athginiúint",
      setDial: "Socraigh mar aghaidh faire",
      error: "Ná rith giniúna íomhá",
      errorDesc: "Nasc gréasáin neamhchinnte, le do thoil athsheiceáil.",
      confirm: "Deimhnigh",
      analysiserror: "Theip ar an anailís",
      SetWatchErr:["Diailigh socraithe go rathúil","Cealaigh seoladh","Theip ar fhíorú sonraí","Tá an comhad sonraí aghaidh faire ró-mhór","Tá an t-uasteorainn bainte amach ag sioncrónú aghaidh faire","Tá Bluetooth dícheangailte"],
      SetWatchTips:["Theip ar an socrú","Socrú diail ar siúl","Tá aghaidh an uaireadóra á shocrú, le do thoil ná scoir an leathanach reatha."],
      progressText:"Sioncronú"

    },
    sports: {
      analyze: "Anailís Sláinte",
      analyzeDesc: "Anailís a dhéanamh ar shonraí bunúsacha",
      advice: "Comhairle Sláinte",
      adviceDesc: "Soláthar comhairle sláinte",
      experience: "Taithí",
      program: {
        plan: "Plean Caillteanas Meáchain",
        startDate: "Roghnaigh an Dáta Tús",
        chooseDate: "Le do thoil roghnaigh 1 lá mar an dáta tosaigh",
        week: [
          {
            num: "Dom.",
            day: "Domhnach",
          },
          {
            num: "Luan.",
            day: "Luan",
          },
          {
            num: "Máir.",
            day: "Máirt",
          },
          {
            num: "Céad.",
            day: "Céadaoin",
          },
          {
            num: "Déar.",
            day: "Déardaoin",
          },
          {
            num: "Aoine.",
            day: "Aoine",
          },
          {
            num: "Sath.",
            day: "Satharn",
          },
        ],
arrange: "Plean Socraithe",
        arrangeDesc:
          "Tosaigh ó dháta is gaire duit a roghnaíonn tú, a líon iomlán 7 lá traenála; seo a leanas an t-ábhar ar leith:",
        confirm: "Deimhnigh Socrú",
        success:
          "Comhghairdeas, tá tú tar éis an plean a shaincheapadh go rathúil! Tosaigh do thraenáil ar leathanach 'Seic isteach ar aclaíocht'!",
        successButton: "Téigh chuig Seic isteach ar aclaíocht",
      },
      newmessage: {
        message1: ["Lárionad Pearsanta", "Sonraí Sláinte"],
        message2: ["Sláinte", "Aclaíocht", "Aiste Bia"],
        message3: ["Géineas", "Aois", "Airde", "Meáchan"],
        message4: [
          "Codladh",
          "HR",
          "Ocsaigin",
          "Céimeanna",
          "BP",
          "Brú",
          "Comhoibriú",
        ],
        message5: ["Consume", "Moladh", "Céimeanna na Laethanta", "Tréimhse Aclaíochta "],
        message6: [
          "Seic isteach ar aclaíocht",
          "Leagan Baile Aclaíochta",
          "Pro Aclaíochta",
          "Plean Caillteanas Meáchain",
        ],
        message7: [
          "Aclaíonn agus cónaíonn saol sláintiúil",
          "Saincheap do phlean aclaíochta",
          "Saincheap do phlean aclaíochta",
          "Cruthaigh plean caillteanais meáchain duitse",
        ],
        message8: [
          "Kcal",
          "Céimeanna",
          "Minéachtaí",
          "Sraitheanna",
          "Láithreacha",
          "Blianta",
          "cm",
          "kg",
          "u","Seachtainí"
        ],
        message9: [
          "Seic isteach",
          "Traenáil",
          "Rogha Aclaíochta",
          "Mo Thorthaí",
          "Láithreacha Seic isteach Comhoibrithe",
          "Níl Seic ina ionad",
        ],
        message10: [
          "Cliceáil le hAnailís",
          "Stair Anailíse (suas le 10 a shábháil)",
          "Anailís",
          "Níl taifid stairiúla agat faoi láthair",
          "Láithreacha Seic isteach Comhoibrithe",
          "Anailís Sonraí",
        ],
message11: ["Baineann", "Fireann"],
        message12: ["Cealaigh", "Deimhnigh"],
        message13: ["Blianta", "Centiméadair", "Cileagram"],
        message14: [
          "Ionchur Láimhe",
          "Am a bheadh súil againn (Seachtainí) ",
          "Géineas",
          "Aois",
          "Airde (Centiméadair)",
          "Meáchan (Cileagram)",
          "Meáchan a bheadh súil againn (Cileagram)",
        ],
        message15: ["Stair", "Anailís Sonraí Sláinte", "Comhairle Sláinte"],
        message16: [
          "Seic isteach Ratha",
          "Ag seiceáil isteach",
          "Ní féidir seic isteach ach ar an lá céanna",
        ],
        message17: [
          "Is féidir anailís sonraí sláinte a fháil ach uair amháin in aghaidh an lae, agus ní féidir sonraí áitiúla a fháil",
          "Is féidir comhairle sláinte a fháil ach uair amháin in aghaidh an lae, agus ní féidir sonraí áitiúla a fháil",
        ],
        message18: [
          "Faoi mheáchan faoi láthair",
          "Tá do chomhartha BMI",
          "níor chóir leanúint ar aghaidh le caillteanas meáchain (Raon sláintiúil 18.5<BMI<24)",
        ],
        message19: [
          "Caillteanas meáchain measartha",
          "Moltar gan caillteanas os cionn 500g in aghaidh an lae",
        ],
        message20: ["Tá pleananna ann", "An clúdaigh sé pleananna atá ann cheana"],
        message21: ["Éasca", "Náisiúnta", "Meán", "Ard"],
        message22: ["Spiorad:", "Íseal", "Ard"],
		message23: ["Tá an líon uaireanta críochnaithe. Ceannaigh síntiús chun níos mó uaireanta a dhíghlasáil"],
      },
    },
    punch: {
      title: "Déanta",
      title2: "Críochnaigh",
      h1: "Rogha Aclaíochta",
      h2: "Mo Thorthaí",
      button: "Seic isteach",
      day: "Láithreacha Seic isteach Comhoibrithe",
      dayunit: "Láithreacha",
    },
eat: {
      title: "Aiste Bia",
      DietaryIntake: "Ag ithe",
      kcal: "Kcal",
      Stillingestible: "Fann",
      overingest: "Os cionn",
      Recommendedintake: "Moltar",
      Motorconsumption: "Gluaiseacht",
      carbohydrate: "Carbaihiodráití",
      protein: "Próitéin",
      fat: "Saill",
      gram: "Gramaí",
      drinkDk: "Seiceáil uisce",
      drinkDkhealth: "Seiceáil laethúil le haghaidh comhlacht sláintiúil",
      eatsuggest: "Moltaí Aiste Bia",
      analyzefoodglx: "Déanaimis an aiste bia a anailís a dhéanamh",
      eatPlan: "Plean Aiste Bia",
      makeeatPlan: "Saincheap d’aiste bia eisiach",
      breakfast: "Brunch",
      lunch: "Lón",
      dinner: "Dinnéar",
      snack: "Sneaiceanna",
      addFood: "Cuir bia leis",
      noAddFood: "Níl aon bhia curtha leis",
      cancle: "Cealaigh",
      confirm: "Deimhnigh",
      tips: "Considered as consent for us to collect this project information and display it in the diet",
      addFooded: "Bia curtha leis",
      added: "Curtha leis",
      numFood: "Míreanna bia",
      record: "Taifead",
      dkTitle: "Seiceáil isteach",
      dailygoal: "Cuspóir laethúil",
      drinkDw: "Comhaltas/La",
      drinkWater: "Ól uisce",
      dailygoalWater: "Cuspóir laethúil óla uisce",
      myachievement: "Mo Thorthaí",
      lxdkDays: "Laethanta Punch Comhoibrithe",
      day: "Láitheanna",
      analyzeHistory: "Stair",
      conclusion: "Comhoibriú",
      eatDataAnalyze: "Anailís Sonraí Aiste Bia",
      selectStartDate: "Roghnaigh an Dáta Tús",
      chooseoneDate: "Le do thoil roghnaigh 1 lá mar an dáta tosaigh",
      scheduling: "Plean Socraithe",
      schedulingDesc:
        "Tosaigh ó dháta is gaire duit a roghnaíonn tú, a líon iomlán 7 lá; seo a leanas an t-ábhar ar leith:",
      eatDetail: "Sonraí Aiste Bia",
      analyzeFailed: "Theip ar an anailís",
      noData: "níl sonraí",
    },
  },
  error: {
    error1101:"Tá an nasc líonra éagobhsaí, an t -amchlár seisiúin reatha",
    error1102:"Níl aon líonra ann faoi láthair, athcheangail le do thoil",
    error1103:"Tharla earráid anaithnid",
    error61:"Níl an tseirbhís reatha ar fáil",
    error4:"Níor aithníodh an guth fós, bain triail eile as",
    error12:"Cuir isteach focail nó abairtí caighdeánacha le do thoil chun AI a thuiscint agus a tharraingt",
    error50:"Ár leithscéal, tá earráid ar an bhfreastalaí reatha, bain triail eile as"

  },
  setting: {
    index: {
      title: "Socruithe",
      problem: "Aiseolas ar fhadhb",
      order: "Taifid Ordaithe",
      notice: "Fógra",
      language: "Teanga",
      clear: "Glan Cách",
      clearTip: "An bhfuil tú cinnte go mba mhaith leat\nan cách a ghlanadh?",
      confirm: "Deimhnigh",
      cancel: "Cealaigh",
    },
    language: {
      title: "Teanga",
      langList: [
        "Mandairín Síonach Simplí",
        "Mandairín Síonach Traidisiúnta",
        "Béarla",
        "Araibis",
        "Polainnis",
        "Tuircis",
        "Dánacht",
        "Gearmáinis",
        "Rúisis",
        "Fraincis",
        "Dúitseach",
        "Seiceach",
        "Rómáinis",
        "Portaingéilis",
        "Seapáinis",
        "Tíre",
        "Turcach",
        "Spáinnis",
        "Gréigis",
        "Iodáilis",
        "Vítneamais",
        "Hiondúis",
      ],
    },
order: {
      title: "Taifid Ordaithe",
      success: "Faighte go rathúil",
      unpaid: "Níl paidhthe",
      paid: "Íoctha",
      expired: "Imithe in éag",
      refunded: "Íocaíocht a guí",
      pTime: "Am Íocaíochta",
      eTime: "Am Éagtha",
      pMethod: "Modh Íocaíochta",
      pObject: "Ábhar Íocaíochta",
      oNumber: "Uimhir Ordú",
      pType: "Cineál Pacáiste",
      oTime: "Am Tugtha",
      watch: "Conas IMEI a fheiceáil",
      delete: "Scrios Ordú",
      refund: "Iarr ar Íocaíocht a Ghá",
    },
    problem: {
      title: "Aiseolas ar fhadhb",
      feedback: "Cineál Aiseolais",
      please: "Le do thoil Roghnaigh",
      content: "Ábhar",
      improve:
        "Le do thoil scríobh do mholtaí, mar shampla iarratais ar ghnéithe, gearáin táirgí, etc. Déanfaimid iarracht feabhas a chur air",
      upload: "Uaslódáil Íomhá",
      submit: "Seol",
      general: "Aiseolas Ginearálta",
      purchase: "Gaolmhar le Ceannaigh",
      bug: "Tuarascáil Laige",
      suggestion: "Mholadh",
      other: "Eile",
    },
    notice: {
      title: "Fógra",
      type: "Cineál Fógra: ",
      time: "Am Fógra: ",
    },
unsubscribe: {
      watch: "Faire:",
      title: "Síntiús Leanúnach",
      subTitle: "Sínte",
      unsubscribe: "Cealaigh Síntiús",
      packList: [
        "Síntiús Préimhe",
        "Síntiús Bunúsach",
        "Síntiús Caighdeánach",
      ],
    },
    refund: {
      title: "Iarr ar Íocaíocht a Ghá",
      number: "Uimhir Ordú",
      content: "Le do thoil míneoigh an chúis atá leis an íocaíocht",
      packList: [
        "Pacáiste Laige Úsáidí Nua",
        "Pacáiste 50 Uaireanta",
        "Pacáiste 100 Uaireanta",
        "Pacáiste 300 Uaireanta",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Faigh Ceacht Dúshlánach",
      watchList: [
        "Cuir ar siúl an faire, faigh an feidhmchlár WearAI agus cliceáil chun a oscailt.",
        "Cliceáil ar an gcnaipe sochair ardleibhéil ar an leathanach baile.",
        "Tóg isteach do guthán agus scanáil an cód QR ar an faire chun dul isteach ar leathanach na sochair.",
      ],
    },
  },
}
