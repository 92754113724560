module.exports = {
	language: "Ελληνικά",
	navigator: {
		tools: "AI Εργαλεία",
		draw: "Σχεδιάζω",
		healthy: "υγιή",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Συστήνω", "Μελέτη", "Ζωή", "Δημιουργία", "Εργασία"],
			presetGreet: "Γεια σου, γεια~ είμαι ο διαχειριστής της υγείας σου, πάντα έτοιμος να παρέχει ενδιαφέρουσες και χρήσιμες γνώσεις υγείας για να βοηθήσω να προστατεύσεις την υγεία σου!",
			presetQuiz: ["Ποιες είναι οι αιτίες της χρόνιας κόπωσης;", "Πώς μπορούν οι νέοι να αποτρέψουν τις χρόνιες ασθένειες;", "Πώς να διατηρήσετε υγιείς διατροφικές συνήθειες;", "Πώς να διατηρήσετε καλό ύπνο;"],
			presets: "Μπορείς να με ρωτήσεις:",
			change: "Αλλαγή παρτίδας",
			uploadImage: "Αποστολή εικόνας",
			footer: "Εφόσον το περιεχόμενο δημιουργείται από ένα μοντέλο AI, η αυθεντικότητά του δεν μπορεί να διασφαλιστεί πλήρως.",
			remind: "Υπενθύμιση εντολών",
			limit: "Η συζήτηση έφτασε στο όριο, παρακαλώ ξεκινήστε μια νέα συνεδρία συζήτησης AI",
			newDialog: "Ξεκινήστε νέα συζήτηση",
			stopRecording: "Κάντε κλικ οπουδήποτε ή όταν τελειώσει η αντίστροφη μέτρηση για να αναγνωρίσετε",
			send: "Αποστολή μηνύματος",
			say: "Κάντε κλικ για να μιλήσετε",
			notImage: "Αυτός ο πράκτορας δεν υποστηρίζει την αποστολή αρχείων και εικόνων",
			talking: "Τρέχουσα συνομιλία σε εξέλιξη",
			drawViolations: "Η προτροπή σχεδίασης AI παραβιάζει τους κανόνες, παρακαλούμε εισάγετε μία συμβατή προτροπή.",
			exhausted: "Ο αριθμός των ερωταπαντήσεων εξαντλήθηκε, παρακαλώ πάτε στο τέλος παρακολούθησης και σαρώστε τον κωδικό QR για να συνεχίσετε.",
			serverBusy: "Ο τρέχων σύνδεσμος δικτύου είναι ασταθής, δοκιμάστε ξανά",
			chatHistory: "Ιστορικό συνομιλίας",
			chatHistoryDesc: "Τα αρχεία ερωταπαντήσεων διατηρούνται μόνο για 3 ημέρες",
			todoList: "Λίστα εντολών",
			todoDesc: "Λεπτομέρειες εντολών",
			todoListDesc: "Μπορεί να υπάρχουν έως το πολύ 10 εντολές",
			confirm: "Επιβεβαίωση",
			cancel: "Άκυρο",
			text: "Το κείμενο δεν μπορεί να είναι κενό",
			cancelRemind: "Ακύρωση υπενθύμισης",
			remindTime: "Ώρα υπενθύμισης:",
			remindTitle: "Τίτλος υπενθύμισης:",
			remindContent: "Περιεχόμενο υπενθύμισης:",
			edit: "Επεξεργασία",
			reachLimit: "Προστέθηκε έως το όριο",
			errorChat:"Η φωνή δεν έχει εντοπιστεί ακόμα, δοκιμάστε ξανά",
			      noSTT:"Η άδεια δεν ελήφθη, ενεργοποιήστε την άδεια πριν συνεχίσετε.",
      error50:"Παρουσιάστηκε άγνωστο σφάλμα, δοκιμάστε ξανά"
		},
draw: {
			describe: "Περιγραφή εικόνας",
			voice: "Φωνητική είσοδος",
			press: "Πιέστε για να μιλήσετε",
			placeholder: "Περιγράψτε την εικόνα που θέλετε να δημιουργήσετε",
			style: "Στυλ ζωγραφικής",
			styleList: ["Στυλ μελανιού", "Cyberpunk", "Anime", "Origami", "Πλέξιμο", "Γραφικά", "Καρτούν", "LEGO","Μολύβι","Σχέδιο"],
			record: "Αρχείο σχεδίασης",
			recordDesc: "Μπορούν να αποθηκευτούν έως και 10 αρχεία σχεδίασης",
			recordEmpty: "Κανένα ρεκόρ ακόμα",
			generate: "Δημιουργία εικόνας",
			result: "καταλήγω",
			download: "λήψη",
			regenerate: "ξανά",
			setDial: "Ορισμός ως πρόσοψη ρολογιού",
			error: "Η δημιουργία εικόνας απέτυχε",
			errorDesc: "Ασταθής σύνδεση δικτύου, παρακαλώ υποβάλετε ξανά.",
			confirm: "Επιβεβαίωση",
			analysiserror: "Η ανάλυση απέτυχε",
      SetWatchErr:["Η κλήση ορίστηκε με επιτυχία","Ακύρωση αποστολής","Η επαλήθευση δεδομένων απέτυχε","Το αρχείο δεδομένων προσώπου ρολογιού είναι πολύ μεγάλο","Ο συγχρονισμός προσώπου ρολογιού έχει φτάσει στο ανώτατο όριο","Το Bluetooth έχει αποσυνδεθεί"],
      SetWatchTips:["Η εγκατάσταση απέτυχε","Ρύθμιση κλήσης σε εξέλιξη","Γίνεται ρύθμιση της πρόσοψης του ρολογιού, μην βγείτε από την τρέχουσα σελίδα."],
      progressText:"Συγχρονισμό"

		},
sports: {
			analyze: "Ανάλυση Υγείας",
			analyzeDesc: "ανάλυση δεδομένων",
			advice: "Συμβουλές Υγείας",
			adviceDesc: "Λογικές συμβουλές υγείας",
			experience: "Εμπειρία",
			program: {
				plan: "Πλάνο Απώλειας Βάρους",
				startDate: "Επιλέξτε Ημερομηνία Έναρξης",
				chooseDate: "Παρακαλώ επιλέξτε 1 ημέρα ως ημερομηνία έναρξης",
				week: [{
					num: "Κυρ.",
					day: "Κυριακή"
				}, {
					num: "Δευ.",
					day: "Δευτέρα"
				}, {
					num: "Τρ.",
					day: "Τρίτη"
				}, {
					num: "Τετ.",
					day: "Τετάρτη"
				}, {
					num: "Πέμ.",
					day: "Πέμπτη"
				}, {
					num: "Παρ.",
					day: "Παρασκευή"
				}, {
					num: "Σάβ.",
					day: "Σάββατο"
				}, ],
				arrange: "Διευθέτηση Πλάνου",
				arrangeDesc: "Ξεκινήστε από την πλησιέστερη ημερομηνία που επιλέξατε, συνολικά 7 ημέρες εκπαίδευσης; το συγκεκριμένο περιεχόμενο είναι ως εξής:",
				confirm: "Επιβεβαίωση Διευθέτησης",
				success: "Συγχαρητήρια, έχετε προσαρμόσει επιτυχώς το πλάνο! Ξεκινήστε την εκπαίδευσή σας στη σελίδα 'Καταχώρηση Άσκησης'!",
				successButton: "Μετάβαση στην Καταχώρηση Άσκησης",
			},
newmessage: {
				message1: ['Προσωπικό Κέντρο', 'Δεδομένα Υγείας'],
				message2: ['Υγεία', 'σπορ', 'Διατροφή'],
				message3: ['Φύλο', 'Ηλικία', 'Ύψος', 'Βάρος'],
				message4: ['Ύπνος', 'ρυθμός', 'Οξυγόνο', 'Βήματα', 'Αίμα', 'Πίεση', 'Περίληψη'],
				message5: ['Κατανάλωση', 'Πρόταση', 'Σημερινά Βήματα', 'Διάρκεια Άσκησης'],
				message6: ['Καταχώρηση Άσκησης', 'Αρχική Έκδοση Εκγύμνασης', 'Fitness Pro', 'Πλάνο Απώλειας Βάρους'],
				message7: ['Άσκηση και ζήσε υγιεινά', 'Δημιουργήστε πρόγραμμα άσκησης', 'Προσαρμόστε το πλάνο εκγύμνασής σας', 'Εξατομικευμένες μέθοδοι αδυνατίσματος'],
				message8: ['Kcal', 'Βήματα', 'Λεπτά', 'Σετ', 'Ημέρες', 'Έτη', 'εκ.', 'κλ', 'ώρ.'],
				message9: ['Καταχώρηση', 'Εκπαίδευση', 'Επιλογές Άσκησης', 'Τα Επιτεύγματά μου', 'Συνεχόμενες Ημέρες Καταχώρησης', 'Μη Καταχωρημένο'],
				message10: ['Κάντε Κλικ για Ανάλυση', 'Ιστορικό Ανάλυσης (μέχρι 10 αποθηκευμένες)', 'Ανάλυση', 'Αυτή τη στιγμή δεν έχετε ιστορικά αρχεία', 'Συνεχόμενες Ημέρες Καταχώρησης', 'Ανάλυση Δεδομένων'],
				message11: ['Θηλυκό', 'Αρσενικό'],
				message12: ['Άκυρο', 'Επιβεβαίωση'],
				message13: ['Έτη', 'Εκατοστά', 'Κιλά'],
				message14: ['Χειροκίνητη Εισαγωγή', 'Αναμενόμενος Χρόνος (Ημέρες)', 'Φύλο', 'Ηλικία', 'Ύψος (Εκατοστά)', 'Βάρος (Κιλά)', 'Αναμενόμενο Βάρος (Κιλά)'],
				message15: ['Ιστορικό', 'Ανάλυση Δεδομένων Υγείας', 'Συμβουλές Υγείας'],
				message16: ['Επιτυχής Καταχώρηση', 'Καταχώρηση...', 'Μπορείτε να καταχωρηθείτε μόνο την ίδια ημέρα'],
				message17: ['Η ανάλυση δεδομένων υγείας μπορεί να γίνεται μόνο μία φορά την ημέρα και δεν μπορούν να βρεθούν τοπικά δεδομένα', 'Οι συμβουλές υγείας μπορούν να λαμβάνονται μόνο μία φορά την ημέρα και δεν μπορούν να βρεθούν τοπικά δεδομένα'],
				message18: ['Αυτή τη στιγμή υπολειπόμενα βάρος', 'Ο δείκτης μάζας σώματος σας είναι', 'δεν συνιστάται η συνέχιση της απώλειας βάρους (Υγιές εύρος 18.5<BMI<24)'],
				message19: ['Μέτρια απώλεια βάρους', 'Συνιστάται να μην χάνετε περισσότερα από 500γρ. ανά ημέρα'],
				message20: ['έχετε πλάνα', 'Καλύπτει τα υπάρχοντα πλάνα'],
				message21: ['Εύκολο', 'Κανονικό', 'Μέτριο', 'Υψηλό'],
				message22: ['Πνεύμα:', 'Χαμηλό', 'Υψηλό'],
				message23: ['Ο αριθμός των φορών που έχει εξαντληθεί Αγοράστε μια συνδρομή για να ξεκλειδώσετε περισσότερες φορές.'],
			},
		},
punch: {
			title: "Ολοκληρώθηκε",
	title2: "Ολοκλήρωση",
			h1: "Επιλογές Άσκησης",
			h2: "Τα Επιτεύγματά μου",
			button: "Καταχώρηση",
			day: "Συνεχείς έλεγχος",
			dayunit: "Ημέρες",
		},
		eat: {
			title: "δίαιτα",
			DietaryIntake: "Διατροφή",
			kcal: "Kcal",
			Stillingestible: "Υπόλοιπο",
			overingest: "Πέραν",
			Recommendedintake: "Πρόταση",
			Motorconsumption: "Κίνηση",
			carbohydrate: "Υδατάνθρακες",
			protein: "Πρωτεΐνη",
			fat: "Λίπος",
			gram: "γρ",
			drinkDk: "Καταχώρηση Νερού",
			drinkDkhealth: "Το πόσιμο νερό είναι υγιεινότερο",
			eatsuggest: "Διατροφικές Προτάσεις",
			analyzefoodglx: "Ανάλυση της Λογικότητας της Διατροφής",
			eatPlan: "Πλάνο Διατροφής",
			makeeatPlan: "Δημιουργία σχεδίου διατροφής",
			breakfast: "Πρωινό",
			lunch: "μεσημέρι",
			dinner: "Δείπνο",
			snack: "Σνακ",
			addFood: "Προσθήκη Τροφής",
			noAddFood: "Δεν έχει προστεθεί τροφή",
			cancle: "Άκυρο",
			confirm: "Επιβεβαίωση",
			tips: "Η προληπτική συμπλήρωση θεωρείται ως συγκατάθεση για τη συλλογή πληροφοριών αυτού του έργου και την εμφάνισή τους στη διατροφή",
			addFooded: "Προστέθηκε Τροφή",
			added: "Προστέθηκε",
			numFood: "Είδη τροφής",
			record: "Καταγραφή",
			dkTitle: "Καταχώρηση",
			dailygoal: "Ημερήσιος Στόχος",
			drinkDw: "Κούπες/Ημέρα",
			drinkWater: "Πιείτε Νερό",
			dailygoalWater: "Ημερήσιος στόχος κατανάλωσης νερού",
			myachievement: "Τα Επιτεύγματά μου",
			lxdkDays: "Συνεχείς έλεγχος",
			day: "Ημέρες",
			analyzeHistory: "Ιστορικό",
			conclusion: "Περίληψη",
			eatDataAnalyze: "Ανάλυση Δεδομένων Διατροφής",
			selectStartDate: "Επιλέξτε Ημερομηνία Έναρξης",
			chooseoneDate: "Παρακαλώ επιλέξτε 1 ημέρα ως ημερομηνία έναρξης",
			scheduling: "Διευθέτηση Πλάνου",
			schedulingDesc: "Ξεκινήστε από την πλησιέστερη ημερομηνία που επιλέξατε, συνολικά 7 ημέρες; το συγκεκριμένο περιεχόμενο είναι ως εξής:",
			eatDetail:"Λεπτομέρειες Διατροφής",
			analyzeFailed:"Η ανάλυση απέτυχε",
			noData:"καμία δεδομένα"


		}
	},
  error: {
    error1101:"Η σύνδεση δικτύου είναι ασταθής, το τρέχον χρονικό όριο περιόδου σύνδεσης",
    error1102:"Αυτήν τη στιγμή δεν υπάρχει δίκτυο, επανασυνδεθείτε",
    error1103:"Παρουσιάστηκε άγνωστο σφάλμα",
    error61:"Η τρέχουσα υπηρεσία δεν είναι διαθέσιμη",
    error4:"Η φωνή δεν έχει εντοπιστεί ακόμα, δοκιμάστε ξανά",
    error12:"Εισαγάγετε τυπικές λέξεις ή προτάσεις για να κατανοήσει και να σχεδιάσει η τεχνητή νοημοσύνη",
		error50:"Συγγνώμη, ο τρέχων διακομιστής είναι λάθος, δοκιμάστε ξανά"
	},
	setting: {
		index: {
			title: "Ρυθμίσεις",
			problem: "Ανατροφοδότηση Προβλήματος",
			order: "Αρχεία Παραγγελιών",
			notice: "Ειδοποίηση",
			language: "Γλώσσα",
			clear: "Καθαρισμός Cache",
			clearTip: "Σίγουρα θέλετε να\nκαθαρίσετε την cache;",
			confirm: "Επιβεβαίωση",
			cancel: "Άκυρο",
		},
		language: {
			title: "Γλώσσα",
			langList: ["Απλοποιημένα Κινέζικα", "Παραδοσιακά Κινέζικα", "Αγγλικά", "Αραβικά", "Βουλγάρικα", "Βεγγαλικά", "Τσέχικα", "Γερμανικά", "Ελληνικά", "Ισπανικά", "Περσικά", "Φιλιππινέζικα", "Γαλλικά", "Χίντι", "Ουγγρικά", "Ινδονησιακά", "Ιταλικά", "Εβραϊκά", "Ιαπωνικά", "Κορεάτικα", "Μαλαισιακά", "Ολλανδικά", "Παντζαμπικά", "Πολωνικά", "Πορτογαλικά", "Ρουμανικά", "Ρωσικά", "Σλοβακικά", "Ταϊλανδικά", "Τούρκικα", "Ουκρανικά", "Ουρντού", "Βιετναμικά"],
		},
order: {
			title: "Αρχεία Παραγγελι",
			success: "Επιτυχής Ανάκτηση",
			unpaid: "Απλήρωτο",
			paid: "Πληρωμένο",
			expired: "Ληγμένο",
			refunded: "Επιστραφέν",
			pTime: "Χρόνος Πληρωμής",
			eTime: "Χρόνος Λήξης",
			pMethod: "Μέθοδος Πληρωμής",
			pObject: "Αντικείμενο Πληρωμής",
			oNumber: "Αριθμός Παραγγελίας",
			pType: "Τύπος Πακέτου",
			oTime: "Χρόνος Ανάκτησης",
			watch: "Προβολή IMEI",
			delete: "Διαγραφή Παραγγελίας",
			refund: "Αίτηση Επιστροφής",
		},
		problem: {
			title: "Ανατροφοδότηση Προβλήματος",
			feedback: "Τύπος Ανατροφοδότησης",
			please: "Παρακαλώ Επιλέξτε",
			content: "Περιεχόμενο",
			improve: "Παρακαλώ γράψτε τις προτάσεις σας, όπως αιτήματα για δυνατότητες, παράπονα προϊόντων κ.λπ. Θα προσπαθήσουμε να βελτιωθούμε",
			upload: "Ανέβασμα Εικόνας",
			submit: "Υποβολή",
			general: "Γενική Ανατροφοδότηση",
			purchase: "Σχετικά με την αγορά",
			bug: "Αναφορά Σφάλματος",
			suggestion: "Πρόταση",
			other: "Άλλο",
		},
notice: {
			title: "Ειδοποίηση",
			type: "Τύπος Ειδοποίησης: ",
			time: "Χρόνος Ειδοποίησης: "
		},
		unsubscribe: {
			watch: "Ρολόι:",
			title: "Συνεχής Συνδρομή",
			subTitle: "Εγγραφήκατε",
			unsubscribe: "Αναίρεση Συνδρομής",
			packList: ["Συνδρομή Premium", "Συνδρομή Basic", "Συνδρομή Standard"],
		},
		refund: {
			title: "Αίτηση Επιστροφής",
			number: "Αριθμός Παραγγελίας",
			content: "Παρακαλούμε εξηγήστε το λόγο για την επιστροφή",
			packList: ["Πακέτο Έκπτωσης Νέου Χρήστη", "Πακέτο 50 Φορών", "Πακέτο 100 Φορών", "Πακέτο 300 Φορών"],
		}
	},
	tutorial: {
		index: {
			title: "Αποκτήστε Προηγμένο Οδηγό",
			watchList: ["Ενεργοποιήστε το ρολόι, βρείτε την εφαρμογή WearAI και κάντε κλικ για να ανοίξετε.",
				"Κάντε κλικ στο κουμπί προηγμένων οφελών στην αρχική σελίδα.",
				"Πάρτε το κινητό σας τηλέφωνο και σαρώστε τον κωδικό QR στο ρολόι για να εισέλθετε στη σελίδα των οφελών."
			]
		}
	},
}