// import { watch } from 'vue';
import state from './state/state';

export default {


    getUid: (state) => {
        return state.uid;
    },
    getDeviceId: (state) => {
        return state.deviceId;
    },
    
    getOs:(state)=> {
        return state.os;
    },



    getSdkVersion:(state)=> {
        return state.sdkVersion;
    },
    getChannelId:(state)=> {
        return state.channelId;
    },
    getToken:(state)=> {
        return state.token;
    },
    getWatch:(state)=> {
        return state.watch;
    },
    getWatchId:(state) => {
        return state.watchId;
    },
    getLanguage:(state) => {
        return state.language;
    },
    // 通知
    getNotificationId:(state) => {
        return state.notificationId;
    },
    getNOtificationType:(state) => {
        return state.notificationType;
    },

    // 参数解析
    getParams:(state)=> {
        return {
            channelId: state.channelId,
            uid: state.uid,
            os: state.os,
            language: state.language,
            osVersion: state.osVersion,
            deviceId: state.deviceId,
            appPkgName: state.appPkgName,
            appVersion: state.appVersion,
            sdkVersion: state.sdkVersion,
            resolution: state.resolution,
            model: state.model,
            watchList: state.watchList,
            // watchId: state.pWatchId,
            // watchName: state.pWatchName,
            // watchAvatarUrl: state.pWatchAvatarUrl,
            // watchPayModel: state.pWatchPayModel,
            // watchLicenseModel: state.pWatchLicenseModel,
            notificationId: state.notificationId,
            notificationType: state.notificationType,
        }
    },

    // 参数映射
    getParamsMapping:(state)=> {
        return {
            p: state.pP,
            channelId: state.pChannelId,
            uid: state.pUid,
            os: state.pOs,
            language: state.pLanguage,
            osVersion: state.pOsVersion,
            deviceId: state.pDeviceId,
            appPkgName: state.pAppPkgName,
            appVersion: state.pAppVersion,
            sdkVersion: state.pSdkVersion,
            resolution: state.pResolution,
            model: state.pModel,
            watchList: state.pWatchList,
            watchId: state.pWatchId,
            watchName: state.pWatchName,
            watchAvatarUrl: state.pWatchAvatarUrl,
            watchPayModel: state.pWatchPayModel,
            watchLicenseModel: state.pWatchLicenseModel,
            notificationId: state.pNotificationId,
            notificationType: state.pNotificationType,
        }
    },


    // 交互
    getOsResult:(state)=> {
        return state.osResult;
    },

    // sdk 交互  关键词
    getInteraction:(state)=> {
        return {
            obj: state.obj,
            type: state.type,
            subType: state.subType,
            msg: state.msg,
            audio: state.audio,
            image: state.image,
            permission: state.permission,
            saveImage: state.saveImage,
            startRecordAudio: state.startRecordAudio,
            cancelRecordAudio: state.cancelRecordAudio,
            finishRecordAudio: state.finishRecordAudio,
            saveRecordImage:state.saveRecordImage,
            mode: state.mode,
            content: state.content,

            // 新增 图片历史记录
            recordImage: state.recordImage,
            getRecordImage: state.getRecordImage,
            deleteRecordImage: state.deleteRecordImage,
            lifeCycle: state.lifeCycle,
            backOut: state.backOut,
            backStage: state.backStage,
            setLanguage:state.setLanguage,
            setWatchFace:state.setWatchFace,
            dietPlan:state.dietPlan,
            fitnessProgram:state.fitnessProgram,
            drawStyle:state.drawStyle,
            data:state.data,
            vue:state.vue,

            //sql
            insertSql:state.insertSql,
            deleteSql:state.deleteSql,
            updateSql:state.updateSql,
            selectSql:state.selectSql,
            actionColumnName:state.actionColumnName,
            actionTableName:state.actionTableName,
            actionData:state.actionData,
            resultTableName:state.resultTableName,
            resultResult:state.resultResult,
            databaseAiName:state.databaseAiName,
            waitDoneTable:state.waitDoneTable,
            columnWaitDoneId:state.columnWaitDoneId,
            columnWaitDoneIdd:state.columnWaitDoneIdd,
            columnWaitDoneName:state.columnWaitDoneName,
            columnWaitDoneData:state.columnWaitDoneData,
            columnWaitDoneSmartName:state.columnWaitDoneSmartName,
            columnWaitDoneSmartIcon:state.columnWaitDoneSmartIcon,
            columnWaitDoneContent:state.columnWaitDoneContent,
            columnWaitDoneAnswerTime:state.columnWaitDoneAnswerTime,
            generateTable:state.generateTable,
            columnGenerateId:state.columnGenerateId,
            columnGenerateContent:state.columnGenerateContent,
            columnGenerateStyle:state.columnGenerateStyle,
            columnGenerateImgPath:state.columnGenerateImgPath,
            base64Img:state.base64Img,
            
            recognizeResult:state.recognizeResult,
            smartChat:state.smartChat,
            textChat:state.textChat,
            textDraw:state.textDraw,
            setWatchFaceCode:state.setWatchFaceCode,
            setWatchFaceProgress:state.setWatchFaceProgress,
            setFaceWatchCode:state.setFaceWatchCode,
            setFaceWatchProgress:state.setFaceWatchProgress,
            backupHistorySave:state.backupHistorySave,
            chatHistorySave:state.chatHistorySave,
            backupHistorySelect:state.backupHistorySelect,
            chatHistorySelect:state.chatHistorySelect,
            chatHistoryResult:state.chatHistoryResult,
            chatSmartCode:state.chatSmartCode,
            chatSmartMessage:state.chatSmartMessage,
            chatSmartData:state.chatSmartData,
            initVue:state.initVue,
            clickActionVue:state.clickActionVue,
            resultVue:state.resultVue,
            actionVue:state.actionVue
        }
    },

    // 状态

    getWatchList:(state)=> {
        return state.watchList;
    },
    getTotalNumber:(state)=> {
        return state.totalNumber;
    },
	getchartupload:(state)=> {
	    return state.chartupload;
	},

}