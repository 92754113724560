<template>
	<div v-if="isError">
		<van-popup v-model="show" round get-container="body" :close-on-click-overlay="false">
			<div class="err-message">
				<!-- <p class="err-title">{{ $t("aitools.draw.error") }}</p> -->
				<p class="err-desc">{{desc }}</p>
				<div class="err-button" @click="closeDialog">
					{{ $t("aitools.draw.confirm") }}
				</div>
			</div>
		</van-popup>
	</div>
</template>
<script>
	export default {
		props: {
			errType: Number
		},
		data() {
			return {
				show: true
			}
		},
		computed: {
			isError() {
				if (this.errType == 0) {
					return false
				} else if (this.errType == 1101) { //请求超时
					let noNetWork0 = localStorage.getItem('noNetWork')
					let noNetWork=JSON.parse(noNetWork0)
					if (!noNetWork) {
						return false
					} else {
						return true
					}
				} else if (this.errType == 1102) { //无法访问服务器
					let noNetWork0 = localStorage.getItem('noNetWork')
					let noNetWork=JSON.parse(noNetWork0) 
					if (!noNetWork) {
						return false
					} else {
						return true
					}
				}else if (this.errType == 1103) { //未知错误
					let noNetWork0 = localStorage.getItem('noNetWork')
					let noNetWork=JSON.parse(noNetWork0) 
					if (!noNetWork) {
						return false
					} else {
						return true
					}
				}else if (this.errType == 61) { //用户验证失败
					let noNetWork0 = localStorage.getItem('noNetWork')
					let noNetWork=JSON.parse(noNetWork0) 
					if (!noNetWork) {
						return false
					} else {
						return true
					}
				}else if (this.errType == 4) { //未识别到语音（SDK和手表通用）
					let noNetWork0 = localStorage.getItem('noNetWork')
					let noNetWork=JSON.parse(noNetWork0) 
					if (!noNetWork) {
						return false
					} else {
						return true
					}
				}else if (this.errType == 12) { //问答内容限制
					let noNetWork0 = localStorage.getItem('noNetWork')
					let noNetWork=JSON.parse(noNetWork0) 
					if (!noNetWork) {
						return false
					} else {
						return true
					}
				}else if (this.errType == 50) { //stt问题
					let noNetWork0 = localStorage.getItem('noNetWork')
					let noNetWork=JSON.parse(noNetWork0) 
					if (!noNetWork) {
						return false
					} else {
						return true
					}
				}
			},
			desc() {
				if (this.errType == 0) {
					return ""
				} else if (this.errType == 1101) { //请求超时
					return this.$t("error.error1101");
				} else if (this.errType == 1102) { //无法访问服务器
					return this.$t("error.error1102");
				}else if (this.errType == 1103) { //未知错误
					return this.$t("error.error1103");
				}else if (this.errType == 61) { //用户验证失败
					return this.$t("error.error61");
				}else if (this.errType == 4) { //未识别到语音（SDK和手表通用）
					return this.$t('error.error4');
				}else if (this.errType == 12) { //问答内容限制
					return this.$t('error.error12');
				}else if (this.errType == 50) { //stt问题
					return this.$t('error.error50');
				}
			}
		},
		methods: {
			closeDialog() {
				this.$emit('closeDialog')
			}
		}
	}
</script>
<style lang="scss" scoped>
	.err-message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-width: 80vw;
		padding: 6.4vw;

		.err-title {
			font-size: 4.8vw;
			font-weight: bold;
		}

		.err-desc {
			font-size: 4.2vw;
			margin: 6.4vw 0;
		}

		.err-button {
			color: #ffffff;
			background-color: #37cf6d;
			height: 12vw;
			min-width: 70%;
			line-height: 12vw;
			text-align: center;
			border-radius: 6vw;
			padding: 0 3.2vw;
		}
	}
</style>