module.exports = {
	language: "日本語",
	navigator: {
		tools: "AIツール",
		draw: "描く",
		healthy: "健康",
	},
aitools: {
		chat: {
			gpt: "ジャーヴィス",
			categoryList: ["オススメ", "勉強", "生活", "創造", "仕事"],
			presetGreet: "やあ、こんにちは～私はあなたの健康管理人です。いつでも面白くて役立つ健康知識を提供し、あなたの健康を守るお手伝いをします!",
			presetQuiz: ["慢性疲労の原因は何ですか？", "若者はどうやって慢性疾患を予防しますか？", "健康的な食習慣をどう維持しますか？", "良い睡眠を維持するには？"],
			presets: "私に聞いてください：",
			change: "バッチを変更",
			uploadImage: "画像を送信",
			footer: "この内容はAIモデルが生成したものであり、その信憑性を完全に保証することはできません。",
			remind: "Todoリマインダー",
			limit: "この会話は制限に達しました。新しいAIチャットセッションを始めてください",
			newDialog: "新しい会話を始める",
			stopRecording: "どこでもクリックするか、カウントダウンが終了すると認識されます",
			send: "メッセージを送信",
			say: "クリックして話す",
			notImage: "このエージェントはファイルや画像の送信をサポートしていません",
			talking: "現在会話中",
			drawViolations: "AI描画のプロンプトがルールに違反しています。準拠するプロンプトを入力してください。",
			exhausted: "Q&Aの回数が尽きました。ウォッチエンドに行き、QRコードをスキャンして続けます。",
			serverBusy: "現在のネットワークリンクが不安定です。もう一度お試しください",
			chatHistory: "チャット履歴",
			chatHistoryDesc: "Q&A記録は3日間のみ保存されます",
			todoList: "Todoリスト",
			todoDesc: "Todoの詳細",
			todoListDesc: "最大で10個のTodoが存在できます",
			confirm: "確認",
			cancel: "キャンセル",
			text: "テキストは空にできません",
			cancelRemind: "リマインダーをキャンセル",
			remindTime: "リマインダー時間：",
			remindTitle: "リマインダータイトル：",
			remindContent: "リマインダー内容：",
			edit: "編集",
			reachLimit: "限度に達しました",
			errorChat:"音声がまだ検出されていません。もう一度お試しください。",
			      noSTT:"許可が取得されていません。続行する前に許可を有効にしてください。",
      error50:"不明なエラーが発生しました。もう一度お試しください"
		},
		draw: {
			describe: "画像を説明してください",
			voice: "音声入力",
			press: "押して話す",
			placeholder: "生成したい画像を説明してください",
			style: "絵画スタイル",
			styleList: ["インクスタイル", "サイバーパンク", "アニメ", "折り紙", "編み物", "グラフィックス", "漫画","レゴ","鉛筆","描く"],
			record: "描画記録",
			recordDesc: "最大で10件の描画記録を保存できます",
			recordEmpty: "現在、描画記録がありません",
			generate: "画像を生成",
			result: "画像生成の結果",
			download: "ダウンロード",
			regenerate: "再生成",
			setDial: "ウォッチフェイスとして設定",
			error: "画像生成に失敗しました",
			errorDesc: "ネットワーク接続が不安定です。再提出してください。",
			confirm: "確認",
			analysiserror: "分析に失敗しました",
      SetWatchErr:["ダイヤルが正常に設定されました","送信をキャンセルする","データ検証に失敗しました","ウォッチフェイスデータファイルが大きすぎます","ウォッチフェイスの同期が上限に達しました","Bluetoothが切断されました"],
      SetWatchTips:["セットアップに失敗しました","ダイヤル設定中","ウォッチフェイスを設定中です。現在のページを終了しないでください。"],
      progressText:"同期 中 です"

		},
sports: {
			analyze: "健康分析",
			analyzeDesc: "基本データを分析",
			advice: "健康アドバイス",
			adviceDesc: "健康アドバイスを提供",
			experience: "体験",
			program: {
				plan: "減量計画",
				startDate: "開始日を選択",
				chooseDate: "開始日を1日選択してください",
				week: [{
					num: "日曜",
					day: "日曜日"
				}, {
					num: "月曜",
					day: "月曜日"
				}, {
					num: "火曜",
					day: "火曜日"
				}, {
					num: "水曜",
					day: "水曜日"
				}, {
					num: "木曜",
					day: "木曜日"
				}, {
					num: "金曜",
					day: "金曜日"
				}, {
					num: "土曜",
					day: "土曜日"
				}, ],
				arrange: "計画の配置",
				arrangeDesc: "選択した最も近い日から開始し、計7日間のトレーニング日;具体的な内容は以下の通りです：",
				confirm: "配置を確認",
				success: "おめでとうございます、計画をカスタマイズしました！「運動チェックイン」ページでトレーニングを開始しましょう！",
				successButton: "運動チェックインへ移動",
			},
newmessage: {
				message1: ['個人センター', '健康データ'],
				message2: ['健康', '運動', '食事'],
				message3: ['性別', '年齢', '身長', '体重'],
				message4: ['睡眠', '心拍数', '酸素', '歩数', '血圧', 'ストレス', '概要'],
				message5: ['消費', '提案', '今日の歩数', '運動時間'],
				message6: ['運動チェックイン', 'フィットネスルーチン', 'フィットネスプロ', '減量計画'],
				message7: ['運動して健康な生活を送る', 'フィットネス計画をカスタマイズする', 'フィットネス計画をカスタマイズする', 'あなたのために減量計画を作成する'],
				message8: ['カロリー', '歩数', '分', 'セット', '日', '歳', 'cm', 'kg', '時間','週間'],
				message9: ['チェックイン', 'トレーニング', '運動オプション', '私の成果', '連続チェックイン日数', '未チェックイン'],
				message10: ['クリックして分析', '分析履歴（最大10件保存）', '分析', '現在履歴がありません', '連続チェックイン日数', 'データ分析'],
				message11: ['女性', '男性'],
				message12: ['キャンセル', '確認'],
				message13: ['歳', 'センチメートル', 'キログラム'],
				message14: ['手動入力', '予想される時間 (週間)', '性別', '年齢', '身長（センチメートル）', '体重（キログラム）', '予想体重（キログラム）'],
				message15: ['履歴', '健康データ分析', '健康アドバイス'],
				message16: ['チェックイン成功', 'チェックイン中', '同じ日にのみチェックイン可能'],
				message17: ['健康データ分析は1日1回しか取得できません。ローカルデータは見つかりません', '健康アドバイスは1日1回しか取得できません。ローカルデータは見つかりません'],
				message18:['現在体重不足です','あなたのBMI指数は','減量を続けることはお勧めできません（健康範囲 18.5<BMI<24）'],
				message19:['適度な減量','1日あたり500gを超えないように減量をお勧めします'],
				message20:['予定があります','既存の計画をカバーしますか'],
				message21:['簡単', '普通', '中程度', '高い'],
				message22:['精神:', '低い', '高い'],
				message23:['回数を使い果たしたので、さらにロックを解除するにはサブスクリプションを購入してください。'],
			},
		},
		punch: {
			title: "完了",
			title2: "終了",
			h1: "運動オプション",
			h2: "私の成果",
			button: "チェックイン",
			day: "連続チェックイン日数",
			dayunit: "日",
		},
eat: {
			title: "食事",
			DietaryIntake: "食事の摂取",
			kcal: "カロリー",
			Stillingestible: "残量",
			overingest: "超過",
			Recommendedintake: "推奨摂取量",
			Motorconsumption: "運動消費量",
			carbohydrate: "炭水化物",
			protein: "タンパク質",
			fat: "脂肪",
			gram: "グラム",
			drinkDk: "水分チェックイン",
			drinkDkhealth: "健康な体のための日常のチェックイン",
			eatsuggest: "食事の提案",
			analyzefoodglx: "食事の合理性を分析",
			eatPlan: "食事の計画",
			makeeatPlan: "専用の食事計画をカスタマイズ",
			breakfast: "朝食",
			lunch: "昼食",
			dinner: "夕食",
			snack: "軽食",
			addFood: "食品を追加",
			noAddFood: "まだ食べ物がない",
			cancle: "キャンセル",
			confirm: "確認",
			tips: "積極的に入力することで、このプロジェクトの情報を収集し、食事に表示することに同意したと見なされます",
			addFooded: "食品が追加されました",
			added: "追加済み",
			numFood: "食品の種類",
			record: "記録",
			dkTitle: "チェックイン",
			dailygoal: "日次目標",
			drinkDw: "カップ/日",
			drinkWater: "水を飲む",
			dailygoalWater: "日次の水分摂取目標",
			myachievement: "私の成果",
			lxdkDays: "連続チェックイン日数",
			day: "日",
			analyzeHistory: "履歴",
			conclusion: "まとめ",
			eatDataAnalyze: "食事データの分析",
			selectStartDate: "開始日を選択",
			chooseoneDate: "開始日を1つ選択してください",
			scheduling: "計画の調整",
			schedulingDesc: "選択した最も近い日から開始し、計7日間;具体的な内容は以下の通りです：",
			eatDetail: "食事の詳細",
			analyzeFailed: "分析に失敗しました",
			noData: "データがありません"
		}
	},
  error: {
    error1101:"ネットワーク接続は不安定であり、現在のセッションタイムアウトです",
    error1102:"現在ネットワークが接続されていません。再接続してください",
    error1103:"不明なエラーが発生しました",
    error61:"現在のサービスは利用できません",
    error4:"音声がまだ検出されていません。もう一度お試しください。",
    error12:"AI が理解して描画できるよう、標準的な単語または文章を入力してください",
		error50:"申し訳ありませんが、現在のサーバーが間違っています。もう一度やり直してください"
  },
	setting: {
		index: {
			title: "設定",
			problem: "問題のフィードバック",
			order: "注文記録",
			notice: "通知",
			language: "言語",
			clear: "キャッシュをクリア",
			clearTip: "本当にキャッシュを\nクリアしますか？",
			confirm: "確認",
			cancel: "キャンセル",
		},
		language: {
			title: "言語",
			langList: ["簡体字中国語", "繁体字中国語", "英語", "アラビア語", "ブルガリア語", "ベンガル語", "チェコ語", "ドイツ語", "ギリシャ語", "スペイン語", "ペルシャ語", "フィリピン語", "フランス語", "ヒンディー語", "ハンガリー語", "インドネシア語", "イタリア語", "ヘブライ語", "日本語", "韓国語", "マレー語", "オランダ語", "パンジャブ語", "ポーランド語", "ポルトガル語", "ルーマニア語", "ロシア語", "スロバキア語", "タイ語", "トルコ語", "ウクライナ語", "ウルドゥ語", "ベトナム語"],
		},
		order: {
			title: "注文記録",
			success: "正常に取得されました",
			unpaid: "未払い",
			paid: "支払い済み",
			expired: "期限切れ",
			refunded: "返金済み",
			pTime: "支払時刻",
			eTime: "期限時刻",
			pMethod: "支払方法",
			pObject: "支払対象",
			oNumber: "注文番号",
			pType: "パッケージタイプ",
			oTime: "取得時刻",
			watch: "IMEIを見る",
			delete: "注文を削除",
			refund: "返金を要求",
		},
problem: {
			title: "問題のフィードバック",
			feedback: "フィードバックの種類",
			please: "選択してください",
			content: "内容",
			improve: "機能リクエスト、製品の苦情など、ご意見をお聞かせください。改善に努めます",
			upload: "画像をアップロード",
			submit: "送信",
			general: "一般のフィードバック",
			purchase: "購入関連",
			bug: "バグ報告",
			suggestion: "提案",
			other: "その他",
		},
		notice: {
			title: "通知",
			type: "通知の種類: ",
			time: "通知の時刻: "
		},
		unsubscribe: {
			watch: "ウォッチ:",
			title: "継続サブスクリプション",
			subTitle: "サブスクライブ済み",
			unsubscribe: "サブスクリプションを解除",
			packList: ["プレミアムサブスクリプション", "ベーシックサブスクリプション", "スタンダードサブスクリプション"],
		},
		refund: {
			title: "返金を要求",
			number: "注文番号",
			content: "返金の理由を説明してください",
			packList: ["新規ユーザー割引パッケージ", "50回パッケージ", "100回パッケージ", "300回パッケージ"],
		}
	},
	tutorial: {
		index: {
			title: "高度なチュートリアルを取得",
			watchList: ["ウォッチをオンにし、WearAIアプリケーションを見つけてクリックして開いてください。",
				"ホームページの高度な特典ボタンをクリックしてください。",
				"携帯電話を取り出し、ウォッチのQRコードをスキャンして特典ページにアクセスしてください。"
			]
		}
	},
}

