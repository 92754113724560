module.exports = {
	language: "español",
	navigator: {
		tools: "AI Función",
		draw: "Dibujar",
		healthy: "Sano",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Recomendar", "Estudiar", "Vida", "Creación", "Trabajo"],
			presetGreet: "Hola, ¡hola~ Soy tu administrador de salud, siempre listo para brindarte conocimientos interesantes y útiles sobre la salud para ayudarte a proteger tu bienestar!",
			presetQuiz: ["¿Cuáles son las causas de la fatiga crónica?", "¿Cómo pueden los jóvenes prevenir las enfermedades crónicas?", "¿Cómo mantener hábitos alimenticios saludables?", "¿Cómo mantener un buen sueño?"],
			presets: "Me puedes preguntar:",
			change: "Cambiar lote",
			uploadImage: "Enviar imagen",
			footer: "Dado que el contenido es generado por un modelo de IA, no se puede garantizar completamente su autenticidad.",
			remind: "Recordatorio de Tareas",
			limit: "Esta conversación ha alcanzado el límite, por favor inicia una nueva sesión de chat de IA",
			newDialog: "Iniciar una nueva conversación",
			stopRecording: "Haz clic en cualquier lugar o cuando termine la cuenta regresiva para reconocer",
			send: "Enviar mensaje",
			say: "Haz clic para hablar",
			notImage: "Este agente no admite el envío de archivos e imágenes",
			talking: "Conversación en curso",
			drawViolations: "El aviso de dibujo de IA viola las reglas, por favor ingresa un aviso conforme.",
			exhausted: "Se ha agotado el número de preguntas y respuestas, por favor ve al extremo del reloj y escanea el código QR para continuar.",
			serverBusy: "La conexión de red actual es inestable, por favor inténtalo de nuevo",
			chatHistory: "Historial de chat",
			chatHistoryDesc: "Registros mantenidos durante 3 días",
			todoList: "Lista de tareas",
			todoDesc: "Detalles de tareas",
			todoListDesc: "Pueden existir un máximo de 10 tareas",
			confirm: "Confirmar",
			cancel: "Cancelar",
			text: "El texto no puede estar vacío",
			cancelRemind: "Cancelar recordatorio",
			remindTime: "Hora del recordatorio:",
			remindTitle: "Título del recordatorio:",
			remindContent: "Contenido del recordatorio:",
			edit: "Editar",
			reachLimit: "Se ha alcanzado el límite",
			errorChat:"La voz aún no se ha detectado. Inténtalo de nuevo.",
			noSTT:"Permiso no obtenido, habilite el permiso antes de continuar.",
      error50:"Se produjo un error desconocido, inténtalo de nuevo."
		},
		draw: {
			describe: "Describe tu imagen",
			voice: "Entrada de voz",
			press: "Presiona para hablar",
			placeholder: "Describe la imagen que deseas generar",
			style: "Estilo de pintura",
			styleList: ["Estilo de tinta", "Cyberpunk", "Anime", "Origami", "Tejido",  "Gráficos", "Caricatura","LEGO","Lápiz","Dibujar"],
			record: "Registro de dibujo",
			recordDesc: "Se pueden guardar hasta 10 registros de dibujo",
			recordEmpty: "Actualmente no tienes registros de dibujo",
			generate: "Generar imagen",
			result: "Resultar",
			download: "Descargar",
			regenerate: "Regenerar",
			setDial: "Establecer como esfera del reloj",
			error: "Fallo en la generación de imagen",
			errorDesc: "Conexión de red inestable, por favor reenvía.",
			confirm: "Confirmar",
			analysiserror: "El análisis falló",
      SetWatchErr:["Marcación configurada exitosamente","Cancelar envío","Error en la verificación de datos","El archivo de datos de la esfera del reloj es demasiado grande","La sincronización de la esfera del reloj ha alcanzado el límite superior","Bluetooth ha sido desconectado"],
      SetWatchTips:["Error de configuración","Configuración de marcación en progreso","Se está configurando la esfera del reloj, no salga de la página actual."],
			progressText:"sincronizado"
		},
sports: {
			analyze: "Análisis de Salud",
			analyzeDesc: "Analizar datos básicos",
			advice: "Consejo de Salud",
			adviceDesc: "Buenos consejos",
			experience: "Experiencia",
			program: {
				plan: "Plan de Pérdida de Peso",
				startDate: "Elegir Fecha de Inicio",
				chooseDate: "Por favor selecciona un día como fecha de inicio",
				week: [{
					num: "Dom.",
					day: "Domingo"
				}, {
					num: "Lun.",
					day: "Lunes"
				}, {
					num: "Mar.",
					day: "Martes"
				}, {
					num: "Mié.",
					day: "Miércoles"
				}, {
					num: "Jue.",
					day: "Jueves"
				}, {
					num: "Vie.",
					day: "Viernes"
				}, {
					num: "Sáb.",
					day: "Sábado"
				}, ],
				arrange: "Organización del Plan",
				arrangeDesc: "Comienza desde la fecha más cercana que elijas, un total de 7 días de entrenamiento; el contenido específico es el siguiente:",
				confirm: "Confirmar Organización",
				success: "¡Felicidades, has personalizado con éxito el plan! ¡Comienza tu entrenamiento en la página de 'Registro de Ejercicio'!",
				successButton: "Ir al Registro de Ejercicio",
			},
newmessage: {
				message1: ['Centro Personal', 'Datos de Salud'],
				message2: ['Salud', 'Deporte', 'Dieta'],
				message3: ['Género', 'Edad', 'Altura', 'Peso'],
				message4: ['Sueño', 'FC', 'Oxígeno', 'Pasos', 'PA', 'Presión', 'Resumen'],
				message5: ['Consumir', 'Sugerir', 'Pasos Hoy',
					'Deportes Tiempo'
				],
				message6: ['Registro de Ejercicio', 'Edición Fitness en Casa',
					'Fitness Pro', 'Plan de Pérdida de Peso'
				],
				message7: ['Ejercítate y vive una vida saludable',
					'Personaliza tu plan de ejercicios', 'Personaliza tu plan de ejercicios',
					'Crea un plan de pérdida de peso para ti'
				],
				message8: ['Kcal', 'Semanas', 'Minutos', 'Series', 'Días', 'Años', 'cm', 'kg', 'h','Semanas'],
				message9: ['Registro', 'Entrenamiento', 'Opciones de Ejercicio', 'Mis Logros',
					'Días Consecutivos de Registro', 'No Registrado'
				],
				message10: ['Haz clic para Analizar', 'Historial de Análisis (hasta 10 guardados)', 'Analizar',
					'Actualmente no tienes registros históricos', 'Días Consecutivos de Registro', 'Análisis de Datos'
				],
				message11: ['Femenino', 'Masculino'],
				message12: ['Cancelar', 'Confirmar'],
				message13: ['Años', 'Centímetros', 'Kilogramos'],
				message14: ['Entrada Manual', 'Tiempo esperado (Semanas)  ', 'Género', 'Edad', 'Altura (Centímetros)',
					'Peso (Kilogramos)', 'Peso Esperado (Kilogramos)'
				],
				message15: ['Historial', 'Análisis de Datos de Salud', 'Consejo de Salud'],
				message16: ['Registro Exitoso', 'Registrando', 'Solo se puede registrar el mismo día'],
				message17: ['El análisis de datos de salud solo se puede obtener una vez al día, y no se pueden encontrar datos locales', 'El consejo de salud solo se puede obtener una vez al día, y no se pueden encontrar datos locales'],
				message18:['Actualmente con bajo peso','Tu índice de IMC es','no es recomendable seguir perdiendo peso (Rango saludable 18.5<IMC<24)'],
				message19:['Pérdida de peso moderada','Se recomienda perder no más de 500g por día'],
				message20:['tener planes','¿Cubre los planes existentes?'],
				message21:['Fácil','Normal','Medio','Alto'],
				message22:['Espíritu:','Bajo','Alto'],
				message23:['La cantidad de veces se ha agotado. Compra una suscripción para desbloquear más veces.  '],
			},
		},
		punch: {
			title: "Hecho",
			title2: "Terminado",
			h1: "Opciones de Ejercicio",
			h2: "Mis Logros",
			button: "Registro",
			day: "Días Consecutivos de Registro",
			dayunit: "Días",
		},
eat: {
			title: "Dieta",
			DietaryIntake: "Comer",
			kcal: "Kcal",
			Stillingestible: "Restante",
			overingest: "Más allá",
			Recommendedintake: "Sugerir",
			Motorconsumption: "Movimiento",
			carbohydrate: "Carbohidratos",
			protein: "Proteínas",
			fat: "Grasa",
			gram: "g",
			drinkDk: "Registro de Agua",
			drinkDkhealth: "Beber agua es más saludable",
			eatsuggest: "Sugerencias Dietéticas",
			analyzefoodglx: "Analizar la racionalidad de la dieta",
			eatPlan: "Plan de Dieta",
			makeeatPlan: "Personaliza tu plan de dieta exclusivo",
			breakfast: "Desayuno",
			lunch: "Almuerzo",
			dinner: "Cena",
			snack: "Merienda",
			addFood: "Agregar Comida",
			noAddFood: "No se agregó comida",
			cancle: "Cancelar",
			confirm: "Confirmar",
			tips: "Rellenar proactivamente se considera como consentimiento para que recopilemos esta información del proyecto y la mostremos en la dieta",
			addFooded: "Comida agregada",
			added: "Tener",
			numFood: "Alimento",
			record: "Registrar",
			dkTitle: "Registro",
			dailygoal: "Meta Diaria",
			drinkDw: "Tazas/Día",
			drinkWater: "Beber Agua",
			dailygoalWater: "Meta diaria de consumo de agua",
			myachievement: "Mis Logros",
			lxdkDays: "Días Consecutivos de Registro",
			day: "Días",
			analyzeHistory: "Historial",
			conclusion: "Resumen",
			eatDataAnalyze: "Análisis de Datos de la Dieta",
			selectStartDate: "Elige Fecha de Inicio",
			chooseoneDate: "Por favor selecciona 1 día como fecha de inicio",
			scheduling: "Organización del Plan",
			schedulingDesc: "Comienza desde la fecha más cercana que elijas, un total de 7 días; el contenido específico es el siguiente:",
			eatDetail:"Detalles de la Dieta",
			analyzeFailed:"Análisis fallido",
			noData:"sin datos"
		}
	},
  error: {
    error1101:"La conexión de red es inestable, el tiempo de espera de sesión actual",
    error1102:"Actualmente no hay red, por favor reconéctese",
    error1103:"Se produjo un error desconocido",
    error61:"El servicio actual no está disponible.",
    error4:"La voz aún no se ha detectado. Inténtalo de nuevo.",
    error12:"Ingrese palabras u oraciones estándar para que la IA las entienda y dibuje.",
		error50:"Lo siento, el servidor actual está mal, intente nuevamente"
  },
	setting: {
		index: {
			title: "Configuraciones",
			problem: "Comentarios de Problemas",
			order: "Registros de Pedidos",
			notice: "Notificación",
			language: "Idioma",
			clear: "Borrar Caché",
			clearTip: "¿Estás seguro de que deseas\nborrar la caché?",
			confirm: "Confirmar",
			cancel: "Cancelar",
		},
		language: {
			title: "Idioma",
			langList: ["Chino Simplificado", "Chino Tradicional", "Inglés", "Árabe", "Búlgaro", "Bengalí", "Checo", "Alemán", "Griego", "Español", "Persa", "Filipino", "Francés", "Hindi", "Húngaro", "Indonesio", "Italiano", "Hebreo", "Japonés", "Coreano", "Malayo", "Holandés", "Punjabi", "Polaco", "Portugués", "Rumano", "Ruso", "Eslovaco", "Tailandés", "Turco", "Ucraniano", "Urdu", "Vietnamita"],
		},
		order: {
			title: "Registros de Pedidos",
			success: "Obtenido con éxito",
			unpaid: "No pagado",
			paid: "Pagado",
			expired: "Caducado",
			refunded: "Reembolsado",
			pTime: "Hora de pago",
			eTime: "Hora de caducidad",
			pMethod: "Método de pago",
			pObject: "Objeto de pago",
			oNumber: "Número de pedido",
			pType: "Tipo de paquete",
			oTime: "Hora de obtención",
			watch: "Ver IMEI",
			delete: "Eliminar pedido",
			refund: "Solicitar reembolso",
		},
problem: {
			title: "Comentarios de Problemas",
			feedback: "Tipo de Feedback",
			please: "Por Favor Selecciona",
			content: "Contenido",
			improve: "Por favor escribe tus sugerencias, como solicitudes de funciones, quejas sobre el producto, etc. Nos esforzaremos por mejorar",
			upload: "Subir Imagen",
			submit: "Enviar",
			general: "Comentarios Generales",
			purchase: "Relacionado con la Compra",
			bug: "Informe de Errores",
			suggestion: "Sugerencia",
			other: "Otro",
		},
		notice: {
			title: "Notificación",
			type: "Tipo de Notificación: ",
			time: "Hora de Notificación: "
		},
		unsubscribe: {
			watch: "Reloj:",
			title: "Suscripción Continua",
			subTitle: "Suscrito",
			unsubscribe: "Desuscribirse",
			packList: ["Suscripción Premium", "Suscripción Básica", "Suscripción Estándar"],
		},
		refund: {
			title: "Solicitar Reembolso",
			number: "Número de Pedido",
			content: "Por favor explica la razón del reembolso",
			packList: ["Paquete de Descuento para Nuevos Usuarios", "Paquete de 50 Veces", "Paquete de 100 Veces",
				"Paquete de 300 Veces"
			],
		}
	},
	tutorial: {
		index: {
			title: "Obtener Tutorial Avanzado",
			watchList: ["Enciende el reloj, encuentra la aplicación WearAI y haz clic para abrir.",
				"Haz clic en el botón de beneficios avanzados en la página de inicio.",
				"Saca tu móvil y escanea el código QR en el reloj para entrar a la página de beneficios."
			]
		}
	},
}