module.exports = {
	language: "Deutsch",
	navigator: {
		tools: "AI Tools",
		draw: "Zeichnen",
		healthy: "Gesund",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Empfehlung", "Studium", "Leben", "Kreation", "Arbeit"],
			presetGreet: "Hey, hallo~ Ich bin dein Gesundheitsmanager, immer bereit, interessante und nützliche Gesundheitsinformationen bereitzustellen, um dir zu helfen, deine Gesundheit zu schützen!",
			presetQuiz: ["Was sind die Ursachen für chronische Müdigkeit?", "Wie können junge Menschen chronischen Krankheiten vorbeugen?", "Wie hält man gesunde Essgewohnheiten ein?", "Wie behält man guten Schlaf bei?"],
			presets: "Du kannst mich fragen:",
			change: "Charge wechseln",
			uploadImage: "Bild senden",
			footer: "Da die Inhalte von einem KI-Modell generiert werden, kann ihre Authentizität nicht vollständig garantiert werden.",
			remind: "Erinnerung für Todo",
			limit: "Dieses Gespräch hat das Limit erreicht, bitte starte eine neue KI-Chat-Sitzung",
			newDialog: "Neues Gespräch starten",
			stopRecording: "Klicke irgendwo oder warte, bis der Countdown endet, um zu erkennen",
			send: "Nachricht senden",
			say: "Klicken zum Sprechen",
			notImage: "Dieser Agent unterstützt das Senden von Dateien und Bildern nicht",
			talking: "Aktuelles Gespräch in Arbeit",
			drawViolations: "Die AI-Zeichenaufforderung verstößt gegen die Regeln, bitte gib eine konforme Aufforderung ein.",
			exhausted: "Die Anzahl der Q&As ist erschöpft, bitte gehe zum Uhrenende und scanne den QR-Code, um fortzufahren.",
			serverBusy: "Die aktuelle Netzwerkverbindung ist instabil, bitte versuche es erneut",
			chatHistory: "Chatverlauf",
			chatHistoryDesc: "Q&A-Aufzeichnungen werden nur 3 Tage lang aufbewahrt",
			todoList: "Todo-Liste",
			todoDesc: "Todo-Details",
			todoListDesc: "Es können maximal 10 Todos existieren",
			confirm: "Bestätigen",
			cancel: "Abbrechen",
			text: "Text kann nicht leer sein",
			cancelRemind: "Erinnerung abbrechen",
			remindTime: "Erinnerungszeit:",
			remindTitle: "Erinnerungstitel:",
			remindContent: "Erinnerungsinhalt:",
			edit: "Bearbeiten",
			reachLimit: "Das Limit wurde erreicht",
			errorChat:"Die Stimme wurde noch nicht erkannt. Bitte versuchen Sie es erneut",
			      noSTT:"Keine Berechtigung erhalten. Bitte aktivieren Sie die Berechtigung, bevor Sie fortfahren.",
      error50:"Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut"
		},
draw: {
	describe: "Beschreibe dein Bild",
	voice: "Spracheingabe",
	press: "Zum Sprechen drücken",
	placeholder: "Beschreibe das Bild, das du generieren möchtest",
	style: "Malstil",
	styleList: ["Tuschestil", "Cyberpunk", "Anime", "Origami", "Stricken", "Grafiken", "Cartoon","LEGO","Bleistift","Zeichnen"],
	record: "Zeichenaufzeichnung",
	recordDesc: "Speichern Sie bis zu 10 Bilder",
	recordEmpty: "Noch kein Datensatz",
	generate: "Bild generieren",
	result: "Ergebnis",
	download: "Download",
	regenerate: "Wieder",
	setDial: "Als Zifferblatt einstellen",
	error: "Bildgenerierung fehlgeschlagen",
	errorDesc: "Instabile Netzwerkverbindung, bitte erneut einreichen.",
	confirm: "Bestätigen",
	analysiserror: "Analyse fehlgeschlagen",
	SetWatchErr:["Wähleinstellung erfolgreich","Senden abbrechen","Die Datenüberprüfung ist fehlgeschlagen","Die Datei mit den Zifferblattdaten ist zu groß","Die Synchronisierung des Zifferblatts hat die Obergrenze erreicht","Bluetooth wurde getrennt"],
	SetWatchTips:["Die Einrichtung ist fehlgeschlagen","Wähleinstellung läuft","Das Zifferblatt wird gerade eingestellt. Bitte verlassen Sie die aktuelle Seite nicht."],
	progressText:"Mitten im fusion."

},
sports: {
	analyze: "Datenanalyse",
	analyzeDesc: "Grundsituation",
	advice: "Datenberatung",
	adviceDesc: "Vernünftiger Rat",
	experience: "Erfahrung",
	program: {
		plan: "Gewichtsverlustplan",
		startDate: "Startdatum auswählen",
		chooseDate: "Bitte wähle 1 Tag als Startdatum",
		week: [{
			num: "So.",
			day: "Sonntag"
		}, {
			num: "Mo.",
			day: "Montag"
		}, {
			num: "Di.",
			day: "Dienstag"
		}, {
			num: "Mi.",
			day: "Mittwoch"
		}, {
			num: "Do.",
			day: "Donnerstag"
		}, {
			num: "Fr.",
			day: "Freitag"
		}, {
			num: "Sa.",
			day: "Samstag"
		}, ],
		arrange: "Plan-Aufstellung",
		arrangeDesc: "Beginne mit dem nächstmöglichen Datum, das du auswählst, insgesamt 7 Trainingstage; der spezifische Inhalt ist wie folgt:",
		confirm: "Plan bestätigen",
		success: "Glückwunsch, du hast den Plan erfolgreich angepasst! Beginne dein Training auf der Seite 'Übungs-Check-in'!",
		successButton: "Zum Übungs-Check-in gehen",
	},
newmessage: {
	message1: ['Persönliches Zentrum', 'Gesundheitsdaten'],
	message2: ['Gesundheit', 'Sport', 'Diät'],
	message3: ['Geschlecht', 'Alter', 'Größe', 'Gewicht'],
	message4: ['Schlaf', 'HR', 'Sauerstoff', 'Schritte', 'BP', 'Druck', 'Zusammenfassung'],
	message5: ['Verbrauch', 'Anregung', 'Heutige Schritte',
		'Übungsdauer '],
	message6: ['Übungs-Check-in', 'Fitness Hausedition',
		'Fitness Pro', 'Gewichtsverlustplan'
	],
	message7: ['Üben und ein gesundes Leben führen',
		'Anpassen deines Fitnessplans', 'Anpassen deines Fitnessplans',
		'Erstellen Sie einen Abnehmplan für sich'
	],
	message8: ['Kcal', 'Schritte', 'Minuten', 'Sätze', 'Tage', 'Jahre', 'cm', 'kg', 'h','Wochen'],
	message9: ['Check-in', 'Training', 'Übungsoptionen', 'Meine Erfolge',
		'Konsekutive Check-in-Tage', 'Nicht eingecheckt'
	],
	message10: ['Klicken zum Analysieren', 'Analyseverlauf (bis zu 10 gespeichert)', 'Analysieren',
		'Noch kein Datensatz', 'Konsekutive Check-in-Tage', 'Datenanalyse'
	],
	message11: ['Weiblich', 'Männlich'],
	message12: ['Abbrechen', 'Bestätigen'],
	message13: ['Jahre', 'Zentimeter', 'Kilogramm'],
	message14: ['Manuelle Eingabe', 'Erwartete Zeit (Wochen)    ', 'Geschlecht', 'Alter', 'Größe (Zentimeter)',
		'Gewicht (Kilogramm)', 'Erwartetes Gewicht (Kilogramm)'
	],
	message15: ['Verlauf', 'Gesundheitsdatenanalyse', 'Datenberatung'],
	message16: ['Check-in erfolgreich', 'Einchecken', 'Kann nur am selben Tag einchecken'],
	message17: ['Gesundheitsdatenanalysen können nur einmal täglich erhalten werden, und lokale Daten können nicht gefunden werden', 'Gesundheitsberatung kann nur einmal täglich erhalten werden, und lokale Daten können nicht gefunden werden'],
	message18: ['Derzeit untergewichtig', 'Dein BMI-Index ist', 'es wird nicht empfohlen, weiterhin Gewicht zu verlieren (Gesunder Bereich 18.5<BMI<24)'],
	message19: ['Moderater Gewichtsverlust', 'Es wird empfohlen, nicht mehr als 500g pro Tag zu verlieren'],
	message20: ['Pläne haben', 'Deckt es bestehende Pläne ab'],
	message21: ['Einfach', 'Normal', 'Mittel', 'Hoch'],
	message22: ['Geist:', 'Niedrig', 'Hoch'],
	message23: ['Die Anzahl der Male wurde aufgebraucht. Kaufen Sie ein Abonnement, um weitere Male freizuschalten.'],
},
},
punch: {
	title: "Erledigt",
	title2: "Fertig",
	h1: "Übungsoptionen",
	h2: "Meine Erfolge",
	button: "Check-in",
	day: "Konsekutive Check-in-Tage",
	dayunit: "Tage",
},
eat: {
	title: "Diät",
	DietaryIntake: "Essen",
	kcal: "Kcal",
	Stillingestible: "Rest",
	overingest: "Darüber hinaus",
	Recommendedintake: "Anregung",
	Motorconsumption: "Bewegung",
	carbohydrate: "Kohlenhydrate",
	protein: "Protein",
	fat: "Fett",
	gram: "g",
	drinkDk: "Wasser-Check-in",
	drinkDkhealth: "Trinkwasser ist gesünder",
	eatsuggest: "Ernährungsvorschläge",
	analyzefoodglx: "Analysiere die Rationalität der Ernährung",
	eatPlan: "Ernährungsplan",
	makeeatPlan: "Erstelle deinen exklusiven Ernährungsplan",
	breakfast: "Morgen",
	lunch: "Mittag",
	dinner: "Nacht",
	snack: "Snack",
	addFood: "Ergänzen",
	noAddFood: "Kein Essen",
	cancle: "Abbrechen",
	confirm: "Bestätigen",
	tips: "Proaktives Ausfüllen wird als Zustimmung zur Erfassung dieser Projektdaten betrachtet und in der Ernährung angezeigt",
	addFooded: "Lebensmittel hinzugefügt",
	added: "Hinzugefügt",
	numFood: "Lebensmittel",
	record: "Aufzeichnung",
	dkTitle: "Check-in",
	dailygoal: "Tagesziel",
	drinkDw: "Tassen/Tag",
	drinkWater: "Wasser trinken",
	dailygoalWater: "Tägliches Wassertrinkziel",
	myachievement: "Meine Erfolge",
	lxdkDays: "Konsekutive Punch-Tage",
	day: "Tage",
	analyzeHistory: "Verlauf",
	conclusion: "Zusammenfassung",
	eatDataAnalyze: "Ernährungsdatenanalyse",
	selectStartDate: "Startdatum wählen",
	chooseoneDate: "Bitte wähle 1 Tag als Startdatum",
	scheduling: "Plan-Aufstellung",
	schedulingDesc: "Beginne mit dem nächstmöglichen Datum, das du auswählst, insgesamt 7 Tage; der spezifische Inhalt ist wie folgt:",
	eatDetail: "Ernährungsdetails",
	analyzeFailed: "Analyse fehlgeschlagen",
	noData: "keine Daten"


}
},
error: {
	error1101:"Die Netzwerkverbindung ist instabil, die aktuelle Sitzungszeitüberschreitung",
	error1102:"Derzeit gibt es kein Netzwerk. Bitte stellen Sie die Verbindung wieder her",
	error1103:"Es ist ein unbekannter Fehler aufgetreten",
	error61:"Der aktuelle Dienst ist nicht verfügbar",
	error4:"Die Stimme wurde noch nicht erkannt. Bitte versuchen Sie es erneut",
	error12:"Geben Sie bitte Standardwörter oder -sätze ein, damit die KI sie verstehen und zeichnen kann",
	error50:"Entschuldigung, der aktuelle Server ist falsch, bitte versuchen Sie es erneut"
},
setting: {
	index: {
		title: "Einstellungen",
		problem: "Problem Feedback",
		order: "Bestellaufzeichnungen",
		notice: "Benachrichtigung",
		language: "Sprache",
		clear: "Cache leeren",
		clearTip: "Sind Sie sicher, dass Sie den\nCache leeren möchten?",
		confirm: "Bestätigen",
		cancel: "Abbrechen",
	},
	language: {
		title: "Sprache",
		langList: ["Vereinfachtes Chinesisch", "Traditionelles Chinesisch", "Englisch", "Arabisch", "Bulgarisch", "Bengalisch", "Tschechisch", "Deutsch", "Griechisch", "Spanisch", "Persisch", "Filipino", "Französisch", "Hindi", "Ungarisch", "Indonesisch", "Italienisch", "Hebräisch", "Japanisch", "Koreanisch", "Malaiisch", "Niederländisch", "Panjabi", "Polnisch", "Portugiesisch", "Rumänisch", "Russisch", "Slowakisch", "Thailändisch", "Türkisch", "Ukrainisch", "Urdu", "Vietnamesisch"],
	},
	order: {
		title: "Bestellaufzeichnungen",
		success: "Erfolgreich abgerufen",
		unpaid: "Unbezahlt",
		paid: "Bezahlt",
		expired: "Abgelaufen",
		refunded: "Erstattet",
		pTime: "Zahlungszeit",
		eTime: "Ablaufzeit",
		pMethod: "Zahlungsmethode",
		pObject: "Zahlungsobjekt",
		oNumber: "Bestellnummer",
		pType: "Paket-Typ",
		oTime: "Abrufzeit",
		watch: "IMEI anzeigen",
		delete: "Bestellung löschen",
		refund: "Rückerstattung anfordern",
	},
problem: {
	title: "Problem Feedback",
	feedback: "Feedback-Typ",
	please: "Bitte auswählen",
	content: "Inhalt",
	improve: "Bitte schreiben Sie Ihre Vorschläge nieder, wie z.B. Funktionsanforderungen, Produktbeschwerden usw. Wir werden uns bemühen, uns zu verbessern",
	upload: "Bild hochladen",
	submit: "Einreichen",
	general: "Allgemeines Feedback",
	purchase: "Kaufbezogen",
	bug: "Fehlermeldung",
	suggestion: "Vorschlag",
	other: "Andere",
},
notice: {
	title: "Benachrichtigung",
	type: "Benachrichtigungstyp: ",
	time: "Benachrichtigungszeit: "
},
unsubscribe: {
	watch: "Uhr:",
	title: "Kontinuierliches Abonnement",
	subTitle: "Abonniert",
	unsubscribe: "Abbestellen",
	packList: ["Premium-Abonnement", "Basis-Abonnement", "Standard-Abonnement"],
},
refund: {
	title: "Rückerstattung anfordern",
	number: "Bestellnummer",
	content: "Bitte erklären Sie den Grund für die Rückerstattung",
	packList: ["Neuer Benutzer Rabattpaket", "50er Paket", "100er Paket",
		"300er Paket"
	],
}
},
tutorial: {
	index: {
		title: "Erhalte fortgeschrittenes Tutorial",
		watchList: ["Schalten Sie die Uhr ein, finden Sie die WearAI-Anwendung und klicken Sie, um sie zu öffnen.",
			"Klicken Sie auf der Startseite auf den Button für erweiterte Vorteile.",
			"Nehmen Sie Ihr Mobiltelefon heraus und scannen Sie den QR-Code auf der Uhr, um zur Vorteilseite zu gelangen."
		]
	}
},
}