module.exports = {
	language: "Bahasa Indonesia",
	navigator :{
		tools: "Alat AI",
		draw: "Gambar",
		healthy: "Sehat",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Rekomendasi", "Belajar", "Kehidupan", "Kreasi", "Kerja"],
			presetGreet: "Hey, halo~ Saya adalah pengurus kesehatan Anda, selalu siap untuk memberikan pengetahuan kesehatan yang menarik dan berguna untuk membantu Anda menjaga kesehatan Anda!",
			presetQuiz: ["Apa saja penyebab kelelahan kronis?", "Bagaimana anak muda dapat mencegah penyakit kronis?", "Bagaimana cara menjaga kebiasaan makan yang sehat?", "Bagaimana menjaga tidur yang baik?"],
			presets: "Anda dapat bertanya pada saya:",
			change: "Ganti batch",
			uploadImage: "Kirim gambar",
			footer: "Karena konten dihasilkan oleh model AI, keasliannya tidak dapat dijamin sepenuhnya.",
			remind: "Pengingat tugas",
			limit: "Percakapan ini telah mencapai batas, silakan mulai sesi obrolan AI baru",
			newDialog: "Mulai percakapan baru",
			stopRecording: "Klik di mana saja atau saat hitungan mundur berakhir untuk mengenali",
			send: "Kirim pesan",
			say: "Klik untuk berbicara",
			notImage: "Agen ini tidak mendukung pengiriman file dan gambar",
			talking: "Percakapan saat ini sedang berlangsung",
			drawViolations: "Prompt menggambar AI melanggar aturan, silakan masukkan prompt yang sesuai.",
			exhausted: "Jumlah tanya jawab telah habis, silakan pergi ke ujung jam dan pindai kode QR untuk melanjutkan.",
			serverBusy: "Tautan jaringan saat ini tidak stabil, silakan coba lagi",
			chatHistory: "Riwayat obrolan",
			chatHistoryDesc: "Catatan tanya jawab disimpan hanya selama 3 hari",
			todoList: "Daftar tugas",
			todoDesc: "Rincian tugas",
			todoListDesc: "Maksimal 10 tugas dapat ada",
			confirm: "Konfirmasi",
			cancel: "Batalkan",
			text: "Teks tidak boleh kosong",
			cancelRemind: "Batalkan pengingat",
			remindTime: "Waktu pengingat:",
			remindTitle: "Judul pengingat:",
			remindContent: "Isi pengingat:",
			edit: "Edit",
			reachLimit: "Ditambahkan hingga batas",
			errorChat:"Suara belum terdeteksi, silakan coba lagi",
			      noSTT:"Izin tidak diperoleh, harap aktifkan izin sebelum melanjutkan.",
      error50:"Terjadi kesalahan yang tidak diketahui, silakan coba lagi"
		},
		draw: {
			describe: "Deskripsikan gambar Anda",
			voice: "Input suara",
			press: "Tekan untuk berbicara",
			placeholder: "Deskripsikan gambar yang ingin Anda buat",
			style: "Gaya lukisan",
			styleList: ["Gaya tinta", "Cyberpunk", "Anime", "Origami", "Rajutan", "Grafik", "Kartun","LEGO","Pensil","Gambar"],
			record: "Catatan menggambar",
			recordDesc: "Maksimal 10 catatan menggambar dapat disimpan",
			recordEmpty: "Belum ada rekor",
			generate: "Buat gambar",
			result: "Hasil pembuatan gambar",
			download: "Unduh gambar",
			regenerate: "Buat ulang",
			setDial: "Setel sebagai tampilan jam",
			error: "Pembuatan gambar gagal",
			errorDesc: "Koneksi jaringan tidak stabil, silakan kirim ulang.",
			confirm: "Konfirmasi",
			analysiserror: "Analisis gagal",
      SetWatchErr:["Panggilan berhasil disetel","Batalkan pengiriman","Verifikasi data gagal","File data tampilan jam terlalu besar","Sinkronisasi tampilan jam telah mencapai batas atas","Bluetooth telah terputus"],
      SetWatchTips:["Penyiapan gagal","Pengaturan panggilan sedang berlangsung","Tampilan jam sedang disetel, mohon jangan keluar dari halaman ini."],
 			progressText:"Dalam sinkronisasi"
		},
sports: {
			analyze: "Analisis Kesehatan",
			analyzeDesc: "Analisis data dasar",
			advice: "Nasihat Kesehatan",
			adviceDesc: "Nasihat yang masuk akal",
			experience: "Pengalaman",
			program: {
				plan: "Rencana Penurunan Berat Badan",
				startDate: "Pilih Tanggal Mulai",
				chooseDate: "Silakan pilih 1 hari sebagai tanggal mulai",
				week: [{
					num: "Min.",
					day: "Minggu"
				}, {
					num: "Sen.",
					day: "Senin"
				}, {
					num: "Sel.",
					day: "Selasa"
				}, {
					num: "Rab.",
					day: "Rabu"
				}, {
					num: "Kam.",
					day: "Kamis"
				}, {
					num: "Jum.",
					day: "Jumat"
				}, {
					num: "Sab.",
					day: "Sabtu"
				}, ],
				arrange: "Penjadwalan Rencana",
				arrangeDesc: "Mulai dari tanggal terdekat yang Anda pilih, yang terdiri dari 7 hari pelatihan; konten spesifik adalah sebagai berikut:",
				confirm: "Konfirmasi Penjadwalan",
				success: "Selamat, Anda telah berhasil menyesuaikan rencana! Mulailah pelatihan Anda di halaman 'Check-in Latihan'!",
				successButton: "Pergi ke Check-in Latihan",
			},
newmessage: {
				message1: ['Pusat Pribadi', 'Data Kesehatan'],
				message2: ['Kesehatan', 'Olahraga', 'Diet'],
				message3: ['Jenis Kelamin', 'Usia', 'Tinggi', 'Berat'],
				message4: ['Tidur', 'HR', 'Oksigen', 'Langkah', 'BP', 'Tekanan', 'Ringkasan'],
				message5: ['Konsumsi', 'Saran', 'Langkah Hari Ini', 'Durasi Olahraga'],
				message6: ['Check-in Olahraga', 'Edisi Rumah Kebugaran', 'Fitness Pro', 'Rencana Penurunan Berat Badan'],
				message7: ['Berolahraga dan hidup sehat', 'Sesuaikan rencana kebugaran Anda', 'Sesuaikan rencana kebugaran Anda', 'Penurunan berat badan eksklusif'],
				message8: ['Kcal', 'Langkah', 'Menit', 'Set', 'Hari', 'Tahun', 'cm', 'kg', 'h','Pekan'],
				message9: ['Check-in', 'Pelatihan', 'Pilihan Olahraga', 'Prestasi Saya', 'Hari Check-in Berturut-turut', 'Tidak Check-in'],
				message10: ['Klik untuk Menganalisis', 'Riwayat Analisis (hingga 10 disimpan)', 'Analisis', 'Anda saat ini tidak memiliki catatan sejarah', 'Hari Check-in Berturut-turut', 'Analisis Data'],
				message11: ['Perempuan', 'Laki-laki'],
				message12: ['Batal', 'Konfirmasi'],
				message13: ['Tahun', 'Sentimeter', 'Kilogram'],
				message14: ['Input Manual', 'Perkiraan waktu (Pekan)  ', 'Jenis Kelamin', 'Usia', 'Tinggi (Sentimeter)', 'Berat (Kilogram)', 'Berat Perkiraan (Kilogram)'],
				message15: ['Riwayat', 'Analisis Data Kesehatan', 'Nasihat Kesehatan'],
				message16: ['Check-in Berhasil', 'Sedang Check-in', 'Hanya dapat check-in pada hari yang sama'],
				message17: ['Analisis data kesehatan hanya bisa didapatkan sekali sehari, dan data lokal tidak dapat ditemukan', 'Nasihat kesehatan hanya bisa didapatkan sekali sehari, dan data lokal tidak dapat ditemukan'],
				message18: ['Saat ini kekurangan berat badan', 'Indeks BMI Anda adalah', 'tidak disarankan untuk terus menurunkan berat badan (Kisaran sehat 18.5<BMI<24)'],
				message19: ['Penurunan berat badan moderat', 'Disarankan untuk menurunkan tidak lebih dari 500g per hari'],
				message20: ['memiliki rencana', 'Apakah mencakup rencana yang ada'],
				message21: ['Mudah', 'Normal', 'Sedang', 'Tinggi'],
				message22: ['Semangat:', 'Rendah', 'Tinggi'],
				message23: ['Tidak ada waktu lagi,Beli langganan untuk membuka lebih banyak waktu'],
			},
		},
		punch: {
			title: "Selesai",
			title2: "Selesai",
			h1: "Pilihan Olahraga",
			h2: "Prestasi Saya",
			button: "Check-in",
			day: "Hari Check-in Berturut-turut",
			dayunit: "Hari",
		},
eat: {
			title: "Diet",
			DietaryIntake: "Makan",
			kcal: "Kkal",
			Stillingestible: "Sisa",
			overingest: "Melebihi",
			Recommendedintake: "Saran",
			Motorconsumption: "Gerakan",
			carbohydrate: "Karbohidrat",
			protein: "Protein",
			fat: "Lemak",
			gram: "g",
			drinkDk: "Check-in Air",
			drinkDkhealth: "Minum air putih lebih sehat",
			eatsuggest: "Saran Diet",
			analyzefoodglx: "Analisis rasionalitas diet",
			eatPlan: "Rencana Diet",
			makeeatPlan: "Sesuaikan rencana diet eksklusif Anda",
			breakfast: "Sarapan",
			lunch: "Siang",
			dinner: "Malam",
			snack: "Camilan",
			addFood: "Tambah Makanan",
			noAddFood: "Tanpa makanan",
			cancle: "Batalkan",
			confirm: "Konfirmasi",
			tips: "Mengisi secara proaktif dianggap sebagai persetujuan bagi kami untuk mengumpulkan informasi proyek ini dan menampilkannya dalam diet",
			addFooded: "Makanan ditambahkan",
			added: "Ada",
			numFood: "makanan",
			record: "Catat",
			dkTitle: "Check-in",
			dailygoal: "Tujuan Harian",
			drinkDw: "Cangkir/Hari",
			drinkWater: "Minum Air",
			dailygoalWater: "Tujuan minum air harian",
			myachievement: "Prestasi Saya",
			lxdkDays: "Hari Berturut-turut Check-in",
			day: "Hari",
			analyzeHistory: "Riwayat",
			conclusion: "Ringkasan",
			eatDataAnalyze: "Analisis Data Diet",
			selectStartDate: "Pilih Tanggal Mulai",
			chooseoneDate: "Silakan pilih 1 hari sebagai tanggal mulai",
			scheduling: "Penjadwalan Rencana",
			schedulingDesc: "Mulai dari tanggal terdekat yang Anda pilih, yang terdiri dari 7 hari; konten spesifik adalah sebagai berikut:",
			eatDetail:"Rincian Diet",
			analyzeFailed:"Analisis gagal",
			noData:"tidak ada data"
		}
	},
  error: {
    error1101:"Koneksi jaringan tidak stabil, batas waktu sesi saat ini",
    error1102:"Saat ini tidak ada jaringan, harap sambungkan kembali",
    error1103:"Terjadi kesalahan yang tidak diketahui",
    error61:"Layanan saat ini tidak tersedia",
    error4:"Suara belum terdeteksi, silakan coba lagi",
    error12:"Silakan masukkan kata atau kalimat standar agar AI dapat memahami dan menggambar",
		error50:"Maaf, server saat ini salah, coba lagi"
  },
	setting: {
		index: {
			title: "Pengaturan",
			problem: "Umpan Balik Masalah",
			order: "Catatan Pesanan",
			notice: "Notifikasi",
			language: "Bahasa",
			clear: "Bersihkan Cache",
			clearTip: "Apakah Anda yakin ingin\nmembersihkan cache?",
			confirm: "Konfirmasi",
			cancel: "Batal",
		},
		language: {
			title: "Bahasa",
			langList: ["Tionghoa Sederhana", "Tionghoa Tradisional", "Inggris", "Arab", "Bulgaria", "Bengali", "Ceko", "Jerman", "Yunani", "Spanyol", "Persia", "Filipina", "Perancis", "Hindi", "Hongaria", "Indonesia", "Italia", "Ibrani", "Jepang", "Korea", "Melayu", "Belanda", "Punjabi", "Polandia", "Portugis", "Rumania", "Rusia", "Slovak", "Thailand", "Turki", "Ukraina", "Urdu", "Vietnam"],
		},
		order: {
			title: "Catatan Pesanan",
			success: "Berhasil Diambil",
			unpaid: "Belum Dibayar",
			paid: "Dibayar",
			expired: "Kadaluarsa",
			refunded: "Dikembalikan",
			pTime: "Waktu Pembayaran",
			eTime: "Waktu Kadaluarsa",
			pMethod: "Metode Pembayaran",
			pObject: "Objek Pembayaran",
			oNumber: "Nomor Pesanan",
			pType: "Jenis Paket",
			oTime: "Waktu Pengambilan",
			watch: "Lihat IMEI",
			delete: "Hapus Pesanan",
			refund: "Minta Pengembalian",
		},
problem: {
			title: "Umpan Balik Masalah",
			feedback: "Jenis Umpan Balik",
			please: "Silakan Pilih",
			content: "Konten",
			improve: "Silakan tuliskan saran Anda, seperti permintaan fitur, keluhan produk, dll. Kami akan berusaha untuk memperbaiki",
			upload: "Unggah Gambar",
			submit: "Kirim",
			general: "Umpan Balik Umum",
			purchase: "Terkait Pembelian",
			bug: "Laporan Bug",
			suggestion: "Saran",
			other: "Lainnya",
		},
		notice: {
			title: "Notifikasi",
			type: "Jenis Notifikasi: ",
			time: "Waktu Notifikasi: "
		},
		unsubscribe: {
			watch: "Jam Tangan:",
			title: "Langganan Berkelanjutan",
			subTitle: "Berlangganan",
			unsubscribe: "Berhenti Berlangganan",
			packList: ["Langganan Premium", "Langganan Dasar", "Langganan Standar"],
		},
		refund: {
			title: "Minta Pengembalian",
			number: "Nomor Pesanan",
			content: "Silakan jelaskan alasan pengembalian",
			packList: ["Paket Diskon Pengguna Baru", "Paket 50 Kali", "Paket 100 Kali", "Paket 300 Kali"],
		}
	},
	tutorial: {
		index: {
			title: "Dapatkan Tutorial Lanjutan",
			watchList: ["Nyalakan jam tangan, temukan aplikasi WearAI dan klik untuk membuka.",
				"Klik tombol manfaat lanjutan di halaman utama.",
				"Keluarkan ponsel Anda dan pindai kode QR pada jam tangan untuk masuk ke halaman manfaat."
			]
		}
	},
}