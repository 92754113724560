module.exports = {
    language: "繁體中文",
    navigator: {
        tools: "AI工具",
        draw: "畫圖",
        healthy: "健康",
    },
    aitools: {
        chat: {
            gpt: "Jarvis",
            categoryList: ["推薦", "學習", "生活", "創作", "工作"],
            presetGreet: "嘿，你好呀~我是你的健康小管家，隨時準備為你科普有趣又有用的健康知識，一起守護你的健康！",
            presetQuiz: ["長期疲勞的原因有哪些？","年輕人如何預防慢性病？","怎麼保持健康的飲食習慣?","怎麼保持好的睡眠？"],
            presets: "您可以向我提問：",
            change: "換一批",
            uploadImage: "發送圖片",
            footer: "由於內容是由AI模型生成的，因此無法完全保證真實性。",
            remind: "待辦提醒",
            limit: "此對話已達到限制，請開始新的 Al 聊天會話",
            newDialog: "開啓新對話",
            stopRecording: "點擊任意位置或倒計時結束時識別",
            send: "發送消息",
            say: "點擊說話",
            notImage: "該智能體不支持文件和圖片發送",
            talking: "當前對話進行中",
            drawViolations: "AI繪製提示詞違反規定，請輸入符合規定的提示詞。",
            exhausted: "問答次數已使用完畢，請去手錶端，掃描二維碼即可繼續使用。",
            serverBusy: "當前網絡鏈接不穩定，請再試一次",
            chatHistory: "聊天記錄",
            chatHistoryDesc: "問答記錄僅保存3天",
            todoList: "待辦清單",
            todoDesc: "待辦詳情",
            todoListDesc: "待辦清單最多存在10條",
            confirm: "確定",
            cancel: "取消",
            text: "文本不能為空",
            cancelRemind: "取消提醒",
            remindTime: "提醒時間：",
            remindTitle: "提醒標題：",
            remindContent: "提醒內容：",
            edit: "編輯",
            reachLimit: "添加已達上限",
            errorChat:"暫未辨識語音，請再試一次",
                  noSTT:"未取得權限，請開啟權限後繼續操作",
      error50:"發生了未知錯誤，請再試一次"
        },
        draw: {
            describe: "描述一下你的圖片",
            voice: "語音輸入",
            press: "按住說話",
            placeholder: " 描述你想要生成的圖像",
            style: "繪畫風格",
            styleList: ["水墨風格", "賽博朋克", "動漫風格", "摺紙風格", "針織風格","平面動畫風格", "3D卡通風格","樂高風格","鉛筆畫","畫圖"],
            record: "繪畫記錄",
            recordDesc: "最多可保存10張繪圖記錄",
            recordEmpty: "您當前還沒有繪圖記錄",
            generate: "生成圖片",
            result: "生圖結果",
            download: "下載圖片",
            regenerate: "重新生成",
            setDial: "設置為錶盤",
            error: "圖片生成失敗",
            errorDesc: "網絡連接不穩定，請重新提交。",
            confirm: "確定",
			analysiserror:"分析失敗",
            SetWatchErr:["錶盤設定成功","取消發送","資料校驗失敗","錶盤資料檔太大","錶盤同步已達上限","藍牙已斷開連接"],
            SetWatchTips:["設定失敗","錶盤設定中","正在設定錶盤，請勿退出目前頁面"],
            progressText:"同步中"

        },
        sports: {
            analyze: "健康數據分析",
            analyzeDesc: "分析你的基本數據情況",
            advice: "健康建議",
            adviceDesc: "提供合理的健康建議",
            experience: "立即體驗",
            program: {
                plan: "減肥計劃",
                startDate: "選擇開始日期",
                chooseDate: "請選擇1天作為開始日期",
                week: [{num:"日",day:"週日"},{num:"一",day:"週一"},{num:"二",day:"週二"},{num:"三",day:"週三"},{num:"四",day:"週四"},{num:"五",day:"週五"},{num:"六",day:"週六"},],
                arrange: "計劃安排",
                arrangeDesc: "從你選擇的最近的日期開始，共7個訓練日；具體內容如下:",
                 confirm: "確認編排",
                success: "恭喜你成功定制計劃在“運動打卡”頁開始你的訓練吧！",
                successButton: "進入運動打卡",     
            },
			newmessage:{
				message1:['個人中心','健康數據'],
				message2:['健康','運動','飲食'],
				message3:['性別','年齡','身高','體重'],
				message4:['睡眠','心率','血氧','步數','血壓','壓力','總結'],
				message5:['完成情況','推薦消耗','今日步數','運動時長'],
				message6:['運動打卡','健身計劃(家庭適應版)','健身計劃(專業版)','減肥計劃'],
				message7:['堅持每日運動，健康生活每一天','定制你的專屬健身方案 ','定制你的專屬健身方案 ','定制你的專屬減肥方案 '],
				message8:['千卡','步','分鐘','組','天','歲','厘米','千克','小時','週'],
				message9:['打卡','訓練','運動選擇','我的成就','連續打卡天數','未打卡'],
				message10:['點擊分析','分析歷史(最多保存10個)','分析','您當前還沒有歷史記錄','連續打卡天數','數據分析'],
				message11:['女','男'],
				message12:['取消','確認'],
				message13:['歲','厘米','千克'],
				message14:['手動輸入','預期時間（週）      ','性別','年齡','身高（厘米）','體重（千克）','預期體重（千克）'],
				message15:['歷史','健康數據分析','健康建議'],
				message16:['打卡成功','打卡中','只能當日打卡'],
				message17:['健康數據分析只能每日獲取一次,找不到本地數據','健康建議只能每日獲取一次,找不到本地數據'],
				message18:['目前體重過低','您的BMI指數為','不宜繼續減肥(健康範圍18.5<BMI<24)'],
				message19:['適度減重','建議每日減重不多於500g'],
				message20:['已有計劃','是否覆蓋已有的計劃'],
				message21:['輕鬆','正常','中等','偏高'],
				message22:['單位:','低壓','高壓'],
				message23:['次數已使用完畢,購買訂閱解鎖更多次數'],
			},
        },
		punch: {
            title: "打卡",
			title2: "已打卡",
            h1: "運動選擇",
            h2: "我的成就",
			button: "打卡",
			day: "連續打卡天數",
			dayunit: "天",
        },
        eat:{
            title:"飲食",
            DietaryIntake:"飲食攝入",
            kcal:"千卡",
            Stillingestible:"還可攝入",
            overingest:"多攝入了",
            Recommendedintake:"推薦攝入",
            Motorconsumption:"運動消耗",
            carbohydrate:"碳水",
            protein:"蛋白質",
            fat:"脂肪",
            gram:"克",
            drinkDk:"喝水打卡",
            drinkDkhealth:"每日打卡，身體更健康",
            eatsuggest:"飲食建議",
            analyzefoodglx:"分析攝入的飲食的合理性",
            eatPlan:"飲食計劃",
            makeeatPlan:"定制你的專屬飲食方案",
            breakfast:"早餐",
            lunch:"午餐",
            dinner:"晚餐",
            snack:"加餐",
            addFood:"添加飲食",
            noAddFood:"未添加飲食",
            cancle:"取消",
            confirm:"確定",
            tips:"主動填寫即視為同意我們收集該項目信息並在飲食中展示",
            addFooded:"已添加食物",
            added:"已添加",
            numFood:"項食物",
            record:"記錄",
            dkTitle:"打卡",
            dailygoal:"每日目標",
            drinkDw:"杯/天",
            drinkWater:"喝水",
            dailygoalWater:"每日喝水目標",
            myachievement:"我的成就",
            lxdkDays:"連續打卡天數",
            day:"天",
            analyzeHistory:"歷史",
            conclusion:"總結",
            eatDataAnalyze:"飲食數據分析",
            selectStartDate:"選擇開始日期",
            chooseoneDate:"請選擇1天作為開始日期",
            scheduling:"計劃安排",
            schedulingDesc:"從你選擇的最近的日期開始，共7日;具體內容如下：",
            eatDetail:"飲食詳情",
            analyzeFailed:"分析失敗",
            noData:"暫無數據"
        }
    },
    error: {
        error1101:"網絡連接不穩定，當前會話超時",
        error1102:"目前無網絡，請重新連接",
        error1103:"發生未知錯誤",
        error61:"目前服務不可用",
        error4:"暫未辨識語音，請再試一次",
        error12:"請輸入標準的單字或句子，以便AI理解和繪畫",
        error50:"很抱歉，當前服務器出錯，請再試一次"
      },
	setting: {
        index: {
            title: "設置",
            problem: "問題反饋",
            order: "訂單記錄",
            notice: "通知",
            language: "語言",
            clear: "清除緩存",
            clearTip: "你確定要\n清除緩存嗎？",
            confirm: "確認",
            cancel: "取消",
        },
        language: {
            title: "語言",
            langList: ["中文簡體","中文繁體","英語","阿拉伯語","保加利亞語","孟加拉語","捷克語","德語","希臘語","西班牙語","波斯語","菲律賓語","法語","印度語","匈牙利語","印尼語","意大利語","希伯來語","日語","韓語","馬來語","荷蘭語","旁遮普語","波蘭語","葡萄牙語","羅馬尼亞語","俄語","斯洛伐克語","泰語","土耳其語","烏克蘭語","烏爾都語","越南語"],
        },
    order: {
            title: "訂單記錄",
            success: "獲取成功",
            unpaid: "未支付",
            paid: "已支付",
            expired: "已過期",
            refunded: "已退款",
            pTime: "付款時間",
            eTime: "過期時間",
            pMethod: "付款方式",
            pObject: "付款對象",
            oNumber: "訂單編號",
            pType: "套餐類型",
            oTime: "獲取時間",
            watch: "查看IMEI",
            delete: "刪除訂單",
            refund: "申請退款",
        },
        problem: {
            title: "問題反饋",
            feedback: "反饋類型",
            please: "請選擇",
            content: "內容",
            improve: "請寫下您的建議，如功能需求、產品投訴等，我們會努力改進",
            upload: "上傳圖片",
            submit: "提交",
            general: "一般反饋",
            purchase: "購買相關",
            bug: "漏洞報告",
            suggestion: "建議",
            other: "其他",
        },
        notice: {
            title: "通知",
            type: "通知類型: ",
            time: "通知時間: "
        },
        unsubscribe: {
            watch: "手錶：",
            title: "持續訂閱",
            subTitle: "訂閱中",
            unsubscribe: "取消訂閱",
            packList: ["至尊訂閱", "基礎訂閱", "普通訂閱"],
        },
        refund: {
            title: "申請退款",
            number: "訂單號",
            content: "請說明退款原因",
            packList: ["新用戶優惠次數套餐","50次套餐","100次套餐","300次套餐"],
        }
    },
    tutorial: {
        index: {
            title: "獲取高級教程",
            watchList: ["打開手錶，找到WearAI應用程序並點擊打開。", "點擊主頁上的高級福利按鈕。", "拿出您的手機並掃描手錶上的二維碼以進入福利頁面。"]
        }
    },
}
