module.exports = {
	language: "हिंदी",
	navigator: {
		tools: "एआई टूल्स",
		draw: "ड्रा",
		healthy: "स्वस्थ",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["सिफारिश", "अध्ययन", "जीवन", "रचना", "कार्य"],
			presetGreet: "अरे, नमस्ते~ मैं आपका स्वास्थ्य प्रबंधक हूँ, हमेशा आपको दिलचस्प और उपयोगी स्वास्थ्य ज्ञान प्रदान करने के लिए तैयार हूँ ताकि आपकी सेहत की रक्षा कर सकूँ!",
			presetQuiz: ["जीर्ण थकान के कारण क्या हैं?", "युवा लोग जीर्ण रोगों को कैसे रोक सकते हैं?", "स्वस्थ खाने की आदतों को कैसे बनाए रखें?", "अच्छी नींद कैसे सुनिश्चित करें?"],
			presets: "आप मुझसे पूछ सकते हैं:",
			change: "बैच बदलें",
			uploadImage: "चित्र भेजें",
			footer: "क्योंकि सामग्री AI मॉडल द्वारा उत्पन्न होती है, इसकी प्रामाणिकता को पूरी तरह से सुनिश्चित नहीं किया जा सकता है।",
			remind: "टोडो रिमाइंडर",
			limit: "इस वार्तालाप ने सीमा को पार कर लिया है, कृपया एक नया AI चैट सत्र शुरू करें",
			newDialog: "नई बातचीत शुरू करें",
			stopRecording: "पहचान के लिए कहीं भी क्लिक करें या काउंटडाउन समाप्त होने पर",
			send: "संदेश भेजें",
			say: "बात करने के लिए क्लिक करें",
			notImage: "यह एजेंट फाइलें और चित्र भेजने का समर्थन नहीं करता है",
			talking: "वर्तमान में बातचीत चल रही है",
			drawViolations: "एआई ड्राइंग प्रॉम्प्ट ने नियमों का उल्लंघन किया है, कृपया एक संगत प्रॉम्प्ट दर्ज करें।",
			exhausted: "प्रश्न-उत्तर की संख्या समाप्त हो गई है, कृपया जारी रखने के लिए घड़ी के अंत में जाएं और क्यूआर कोड को स्कैन करें।",
			serverBusy: "वर्तमान नेटवर्क लिंक अस्थिर है, कृपया फिर से प्रयास करें",
			chatHistory: "चैट इतिहास",
			chatHistoryDesc: "प्रश्न-उत्तर रिकॉर्ड केवल 3 दिनों के लिए रखे जाते हैं",
			todoList: "टोडो सूची",
			todoDesc: "टोडो विवरण",
			todoListDesc: "अधिकतम 10 टोडो हो सकते हैं",
			confirm: "पुष्टि करें",
			cancel: "रद्द करें",
			text: "पाठ खाली नहीं हो सकता",
			cancelRemind: "रिमाइंडर रद्द करें",
			remindTime: "रिमाइंडर समय:",
			remindTitle: "रिमाइंडर शीर्षक:",
			remindContent: "रिमाइंडर सामग्री:",
			edit: "संपादित करें",
			reachLimit: "सीमा तक जोड़ दिया गया",
			errorChat:"आवाज़ का अभी तक पता नहीं चला, कृपया पुनः प्रयास करें",
			      noSTT:"अनुमति प्राप्त नहीं हुई, कृपया जारी रखने से पहले अनुमति सक्षम करें।",
      error50:"कोई अज्ञात त्रुटि उत्पन्न हुई, कृपया पुनः प्रयास करें"
		},
		draw: {
			describe: "अपने चित्र का वर्णन करें",
			voice: "वॉयस इनपुट",
			press: "बात करने के लिए दबाएं",
			placeholder: "उस चित्र का वर्णन करें जिसे आप उत्पन्न करना चाहते हैं",
			style: "चित्रण शैली",
			styleList: ["स्याही शैली", "साइबरपंक", "एनीमे", "पेपर फोल्डिंग", "बुनाई", "ग्राफिक्स", "कार्टून","लेगो", "पेंसिल", "ड्रॉ"],
			record: "चित्रण रिकॉर्ड",
			recordDesc: "अधिकतम 10 चित्रण रिकॉर्ड सहेजे जा सकते हैं",
			recordEmpty: "आपके पास वर्तमान में कोई चित्रण रिकॉर्ड नहीं है",
			generate: "चित्र उत्पन्न करें",
			result: "परिणाम",
			download: "डाउनलोड",
			regenerate: "दोबारा",
			setDial: "वॉच फेस के रूप में सेट करें",
			error: "चित्र उत्पन्न करने में विफल",
			errorDesc: "अस्थिर नेटवर्क कनेक्शन, कृपया पुनः सबमिट करें।",
			confirm: "पुष्टि करें",
			analysiserror: "विश्लेषण विफल",
      SetWatchErr:["डायल सफलतापूर्वक सेट हो गया","भेजना रद्द करें","डेटा सत्यापन विफल रहा","वॉच फेस डेटा फ़ाइल बहुत बड़ी है","वॉच फेस डेटा फ़ाइल बहुत बड़ी है","ब्लूटूथ डिस्कनेक्ट कर दिया गया है"],
      SetWatchTips:["सेटअप विफल रहा","डायल सेटिंग चल रही है","घड़ी का चेहरा सेट किया जा रहा है, कृपया वर्तमान पृष्ठ से बाहर न निकलें।"],
      progressText:"मेल में"

		},
		sports: {
			analyze: "स्वास्थ्य विश्लेषण",
			analyzeDesc: "मूल डेटा का विश्लेषण करें",
			advice: "स्वास्थ्य सलाह",
			adviceDesc: "स्वास्थ्य सलाह प्रदान करें",
			experience: "अनुभव",
			program: {
				plan: "वजन घटाने की योजना",
				startDate: "प्रारंभ तिथि चुनें",
				chooseDate: "कृपया प्रारंभ तिथि के रूप में 1 दिन चुनें",
				week: [{
					num: "रवि.",
					day: "रविवार"
				}, {
					num: "सोम.",
					day: "सोमवार"
				}, {
					num: "मंगल.",
					day: "मंगलवार"
				}, {
					num: "बुध.",
					day: "बुधवार"
				}, {
					num: "गुरु.",
					day: "गुरुवार"
				}, {
					num: "शुक्र.",
					day: "शुक्रवार"
				}, {
					num: "शनि.",
					day: "शनिवार"
				}, ],
				arrange: "योजना व्यवस्था",
				arrangeDesc: "आपके द्वारा चुनी गई तिथि के निकटतम दिन से शुरू करें, कुल 7 प्रशिक्षण दिन; विशिष्ट सामग्री निम्न हैं:",
				confirm: "व्यवस्था की पुष्टि करें",
				success: "बधाई हो, आपने योजना को सफलतापूर्वक अनुकूलित किया है! 'व्यायाम चेक-इन' पृष्ठ पर अपना प्रशिक्षण शुरू करें!",
				successButton: "व्यायाम चेक-इन पर जाएं",
			},
			newmessage: {
				message1: ['व्यक्तिगत केंद्र', 'स्वास्थ्य डेटा'],
				message2: ['स्वास्थ्य', 'व्यायाम', 'आहार'],
				message3: ['लिंग', 'आयु', 'ऊंचाई', 'वजन'],
				message4: ['नींद', 'एचआर', 'ऑक्सीजन', 'कदम', 'बीपी', 'दबाव', 'सारांश'],
				message5: ['उपभोग', 'सुझाव', 'आज के कदम', 'व्यायाम अवधि'],
				message6: ['व्यायाम चेक-इन', 'फिटनेस होम संस्करण', 'फिटनेस प्रो', 'वजन घटाने की योजना'],
				message7: ['व्यायाम करें और स्वस्थ जीवन जिएं', 'अपनी फिटनेस योजना अनुकूलित करें', 'अपनी फिटनेस योजना अनुकूलित करें', 'आपके लिए वजन घटाने की योजना बनाएं'],
				message8: ['कैलोरी', 'कदम', 'मिनट', 'सेट', 'दिन', 'वर्ष', 'सेमी', 'किलो', 'घंटा','सप्ताह'],
				message9: ['चेक-इन', 'प्रशिक्षण', 'व्यायाम विकल्प', 'मेरी उपलब्धियां', 'लगातार चेक-इन दिन', 'चेक-इन नहीं किया'],
				message10: ['विश्लेषण के लिए क्लिक करें', 'विश्लेषण इतिहास (अधिकतम 10 सहेजे गए)', 'विश्लेषण करें', 'आपके पास वर्तमान में कोई ऐतिहासिक रिकॉर्ड नहीं है', 'लगातार चेक-इन दिन', 'डेटा विश्लेषण'],
				message11: ['महिला', 'पुरुष'],
				message12: ['रद्द करें', 'पुष्टि करें'],
				message13: ['वर्ष', 'सेंटीमीटर', 'किलोग्राम'],
				message14: ['मैनुअल इनपुट', 'अपेक्षित समय (सप्ताह)', 'लिंग', 'आयु', 'ऊंचाई (सेंटीमीटर)', 'वजन (किलोग्राम)', 'अपेक्षित वजन (किलोग्राम)'],
				message15: ['इतिहास', 'स्वास्थ्य डेटा विश्लेषण', 'स्वास्थ्य सलाह'],
				message16: ['चेक-इन सफल', 'चेक-इन कर रहा है', 'केवल उसी दिन चेक-इन कर सकते हैं'],
				message17: ['स्वास्थ्य डेटा विश्लेषण केवल दिन में एक बार प्राप्त किया जा सकता है, और स्थानीय डेटा नहीं पाया जा सकता है', 'स्वास्थ्य सलाह केवल दिन में एक बार प्राप्त की जा सकती है, और स्थानीय डेटा नहीं मिला'],
				message18: ['वर्तमान में कम वजन', 'आपका बीएमआई सूचकांक है', 'वजन कम करना उचित नहीं है (स्वस्थ सीमा 18.5<BMI<24)'],
				message19: ['मध्यम वजन कम करना', 'प्रति दिन 500 ग्राम से अधिक वजन कम करने की सिफारिश नहीं की जाती है'],
				message20: ['योजनाएँ हैं', 'क्या मौजूदा योजनाओं को कवर किया जाता है'],
				message21: ['आसान', 'सामान्य', 'मध्यम', 'उच्च'],
				message22: ['आत्मा:', 'कम', 'उच्च'],
				message23: [' जितनी बार उपयोग किया जा चुका है उतनी बार अनलॉक करने के लिए सदस्यता खरीदें।'],
			},
		},
		punch: {
			title: "हो गया",
			title2: "समाप्त",
			h1: "व्यायाम विकल्प",
			h2: "मेरी उपलब्धियां",
			button: "चेक-इन",
			day: "लगातार चेक-इन दिन",
			dayunit: "दिन",
		},
		eat: {
			title: "आहार",
			DietaryIntake: "खाना",
			kcal: "कैलोरी",
			Stillingestible: "शेष",
			overingest: "बियॉन्ड",
			Recommendedintake: "सुझाव",
			Motorconsumption: "मोटर",
			carbohydrate: "कार्ब्स",
			protein: "प्रोटीन",
			fat: "फैट",
			gram: "ग्राम",
			drinkDk: "जल चेक-इन",
			drinkDkhealth: "स्वस्थ शरीर के लिए दैनिक चेक-इन",
			eatsuggest: "आहार सुझाव",
			analyzefoodglx: "आहार की तर्कशीलता का विश्लेषण करें",
			eatPlan: "आहार योजना",
			makeeatPlan: "अपनी विशेष आहार योजना अनुकूलित करें",
			breakfast: "नाश्ता",
			lunch: "दोपहर",
			dinner: "रात",
			snack: "स्नैक",
			addFood: "भोजन जोड़ें",
			noAddFood: "कोई भोजन नहीं जोड़ा",
			cancle: "रद्द करें",
			confirm: "पुष्टि करें",
			tips: "स्वेच्छा से भरने को इस परियोजना की जानकारी को एकत्र करने और इसे आहार में दर्शाने की सहमति माना जाता है",
			addFooded: "भोजन जोड़ा",
			added: "जोड़ा गया",
			numFood: "भोजन के आइटम",
			record: "रिकॉर्ड",
			dkTitle: "चेक-इन",
			dailygoal: "दैनिक लक्ष्य",
			drinkDw: "कप/दिन",
			drinkWater: "पानी पियो",
			dailygoalWater: "दैनिक जल पीने का लक्ष्य",
			myachievement: "मेरी उपलब्धियां",
			lxdkDays: "लगातार पंच दिन",
			day: "दिन",
			analyzeHistory: "इतिहास",
			conclusion: "सारांश",
			eatDataAnalyze: "आहार डेटा विश्लेषण",
			selectStartDate: "प्रारंभ तिथि चुनें",
			chooseoneDate: "कृपया प्रारंभ तिथि के रूप में 1 दिन चुनें",
			scheduling: "योजना व्यवस्था",
			schedulingDesc: "आपके द्वारा चुनी गई तिथि के निकटतम दिन से शुरू करें, कुल 7 दिन; विशिष्ट सामग्री निम्न हैं:",
			eatDetail: "आहार विवरण",
			analyzeFailed: "विश्लेषण विफल",
			noData: "कोई डेटा नहीं"
		}
	},
  error: {
    error1101:"नेटवर्क कनेक्शन अस्थिर है, वर्तमान सत्र टाइमआउट",
    error1102:"फिलहाल कोई नेटवर्क नहीं है, कृपया पुनः कनेक्ट करें",
    error1103:"एक अज्ञात त्रुटि हुई",
    error61:"वर्तमान सेवा अनुपलब्ध है",
    error4:"आवाज़ का अभी तक पता नहीं चला, कृपया पुनः प्रयास करें",
    error12:"एआई को समझने और आकर्षित करने के लिए कृपया मानक शब्द या वाक्य दर्ज करें",
		error50:"क्षमा करें, वर्तमान सर्वर गलत है, कृपया पुनः प्रयास करें"
  },
	setting: {
		index: {
			title: "सेटिंग्स",
			problem: "समस्या प्रतिक्रिया",
			order: "ऑर्डर रिकॉर्ड्स",
			notice: "सूचना",
			language: "भाषा",
			clear: "कैश साफ़ करें",
			clearTip: "क्या आप वाकई\nकैश को साफ़ करना चाहते हैं?",
			confirm: "पुष्टि करें",
			cancel: "रद्द करें",
		},
		language: {
			title: "भाषा",
			langList: ["सरल चीनी", "पारंपरिक चीनी", "अंग्रेजी", "अरबी", "बुल्गारियाई", "बंगाली", "चेक", "जर्मन", "यूनानी", "स्पेनिश", "फारसी", "फिलीपीनो", "फ्रेंच", "हिंदी", "हंगेरियन", "इंडोनेशियाई", "इतालवी", "हिब्रू", "जापानी", "कोरियाई", "मलय", "डच", "पंजाबी", "पोलिश", "पुर्तगाली", "रोमानियाई", "रूसी", "स्लोवाक", "थाई", "तुर्की", "यूक्रेनी", "उर्दू", "वियतनामी"],
		},
		order: {
			title: "ऑर्डर रिकॉर्ड्स",
			success: "सफलतापूर्वक प्राप्त किया",
			unpaid: "अवैतनिक",
			paid: "भुगतान किया गया",
			expired: "समाप्त",
			refunded: "वापसी",
			pTime: "भुगतान समय",
			eTime: "समाप्ति समय",
			pMethod: "भुगतान विधि",
			pObject: "भुगतान वस्तु",
			oNumber: "ऑर्डर नंबर",
			pType: "पैकेज प्रकार",
			oTime: "प्राप्ति समय",
			watch: "आईएमईआई देखें",
			delete: "ऑर्डर हटाएं",
			refund: "वापसी अनुरोध",
		},
		problem: {
			title: "समस्या प्रतिक्रिया",
			feedback: "प्रतिक्रिया प्रकार",
			please: "कृपया चुनें",
			content: "सामग्री",
			improve: "कृपया अपने सुझाव लिखें, जैसे कि फीचर अनुरोध, उत्पाद शिकायतें आदि। हम सुधार करने का प्रयास करेंगे",
			upload: "चित्र अपलोड करें",
			submit: "जमा करें",
			general: "सामान्य प्रतिक्रिया",
			purchase: "खरीद से संबंधित",
			bug: "बग रिपोर्ट",
			suggestion: "सुझाव",
			other: "अन्य",
		},
		notice: {
			title: "सूचना",
			type: "सूचना प्रकार: ",
			time: "सूचना समय: "
		},
		unsubscribe: {
			watch: "वॉच:",
			title: "निरंतर सदस्यता",
			subTitle: "सदस्यता ली गई",
			unsubscribe: "सदस्यता समाप्त",
			packList: ["प्रीमियम सदस्यता", "बेसिक सदस्यता", "स्टैंडर्ड सदस्यता"],
		},
		refund: {
			title: "वापसी अनुरोध",
			number: "ऑर्डर नंबर",
			content: "कृपया वापसी का कारण बताएं",
			packList: ["नए उपयोगकर्ता छूट पैकेज", "50 बार पैकेज", "100 बार पैकेज", "300 बार पैकेज"],
		}
	},
	tutorial: {
		index: {
			title: "उन्नत ट्यूटोरियल प्राप्त करें",
			watchList: ["वॉच चालू करें, WearAI एप्लिकेशन खोजें और खोलें।", "होम पेज पर उन्नत लाभ बटन पर क्लिक करें।", "अपने मोबाइल फोन को बाहर निकालें और लाभ पेज में प्रवेश करने के लिए वॉच पर क्यूआर कोड स्कैन करें।"]
		}
	},
}