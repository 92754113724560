module.exports = {
	language: "slovenský jazyk",
	navigator: {
		tools: "AI nástroje",
		draw: "Kresliť",
		healthy: "Zdravý",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Návrh", "Štúdium", "Život", "Tvorba", "Práca"],
			presetGreet: "Ahoj, hello~ Som váš správca zdravia, vždy pripravený poskytnúť zaujímavé a užitočné zdravotné poznatky, aby som vám pomohol chrániť vaše zdravie!",
			presetQuiz: ["Aké sú príčiny chronickej únavy?", "Ako môžu mladí ľudia predchádzať chronickým chorobám?", "Ako udržať zdravé stravovacie návyky?", "Ako udržať dobrý spánok?"],
			presets: "Môžete sa ma spýtať:",
			change: "Zmeniť dávku",
			uploadImage: "Odoslať obrázok",
			footer: "Keďže obsah je generovaný AI modelom, jeho pravdivosť nemožno úplne zaručiť.",
			remind: "Pripomienka úloh",
			limit: "Táto konverzácia dosiahla limit, prosím začnite novú AI chatovú reláciu",
			newDialog: "Začať novú konverzáciu",
			stopRecording: "Kliknite kdekoľvek alebo keď skončí odpočítavanie na rozpoznanie",
			send: "Odoslať správu",
			say: "Kliknite na hovorenie",
			notImage: "Tento agent nepodporuje odosielanie súborov a obrázkov",
			talking: "Prebiehajúca konverzácia",
			drawViolations: "AI grafický podnet porušuje pravidlá, prosím zadajte úplný podnet.",
			exhausted: "Počet otázok a odpovedí bol vyčerpaný, prosím choďte na sledovanie a naskenujte QR kód, aby ste pokračovali.",
			serverBusy: "Aktuálne je sieťový odkaz nestabilný, prosím skúste znova",
			chatHistory: "História chatu",
			chatHistoryDesc: "Záznamy otázok a odpovedí sa uchovávajú len 3 dni",
			todoList: "Zoznam úloh",
			todoDesc: "Podrobnosti úloh",
			todoListDesc: "Môže existovať maximálne 10 úloh",
			confirm: "Potvrdiť",
			cancel: "Zrušiť",
			text: "Text nemôže byť prázdny",
			cancelRemind: "Zrušiť pripomienku",
			remindTime: "Čas pripomenutia:",
			remindTitle: "Názov pripomienky:",
			remindContent: "Obsah pripomienky:",
			edit: "Upraviť",
			reachLimit: "Pridané až do limitu",
			errorChat:"Hlas sa ešte nezistil, skúste to znova",
			noSTT:"Povolenie nebolo získané. Pred pokračovaním povoľte povolenie.",
      error50:"Vyskytla sa neznáma chyba, skúste to znova"
		},
draw: {
			describe: "Popíšte svoj obrázok",
			voice: "Hlasový vstup",
			press: "Stlačte na hovorenie",
			placeholder: "Popíšte obrázok, ktorý chcete generovať",
			style: "Štýl maľby",
			styleList: ["Atramentový štýl", "Kyberpunk", "Anime", "Origami", "Pletenie", "Grafika", "Kreslený","LEGO","Ceruzka","Kresliť"],
			record: "Záznam kreslenia",
			recordDesc: "Môže byť uložených až 10 záznamov kreslenia",
			recordEmpty: "Zatiaľ žiadne údaje",
			generate: "Generovať obrázok",
			result: "Výsledky obrázkov",
			download: "Stiahnuť",
			regenerate: "Ešte raz",
			setDial: "Nastaviť ako ciferník hodín",
			error: "Generovanie obrázka zlyhalo",
			errorDesc: "Nestabilné sieťové pripojenie, prosím skúste znova.",
			confirm: "Potvrdiť",
			analysiserror: "Analýza zlyhala",
      SetWatchErr:["Vytáčanie bolo úspešne nastavené","Zrušiť odosielanie","Overenie údajov zlyhalo","Dátový súbor ciferníka je príliš veľký","Synchronizácia ciferníka dosiahla horný limit","Bluetooth bolo odpojené"],
      SetWatchTips:["Nastavenie zlyhalo","Prebieha nastavenie vytáčania","Nastavuje sa ciferník, neopúšťajte aktuálnu stránku."],
      progressText:"Synchronizáciu"

		},
sports: {
			analyze: "Zdravotná analýza",
			analyzeDesc: "Analyzovať základné údaje",
			advice: "Zdravotné rady",
			adviceDesc: "Poskytnúť zdravotné rady",
			experience: "Skúsenosť",
			program: {
				plan: "Plán chudnutia",
				startDate: "Vyberte dátum začiatku",
				chooseDate: "Prosím vyberte 1 deň ako dátum začiatku",
				week: [{
					num: "Ned.",
					day: "Nedeľa"
				}, {
					num: "Pon.",
					day: "Pondelok"
				}, {
					num: "Ut.",
					day: "Utorok"
				}, {
					num: "Str.",
					day: "Streda"
				}, {
					num: "Štv.",
					day: "Štvrtok"
				}, {
					num: "Pia.",
					day: "Piatok"
				}, {
					num: "Sob.",
					day: "Sobota"
				}],
				arrange: "Plánovanie",
				arrangeDesc: "Začnite od najbližšieho dátumu, ktorý ste vybrali, celkom 7 tréningových dní; konkrétny obsah je nasledujúci:",
				confirm: "Potvrdiť usporiadanie",
				success: "Gratulujeme, úspešne ste si prispôsobili plán! Začnite svoj tréning na stránke 'Cvičné overenie'!",
				successButton: "Prejdite na cvičné overenie",
			},
newmessage: {
				message1: ['Osobné centrum', 'Zdravotné údaje'],
				message2: ['Zdravie', 'Cvičenie', 'Diéta'],
				message3: ['Pohlavie', 'Vek', 'Výška', 'Váha'],
				message4: ['Spánok', 'Tep', 'Kyslík', 'Kroky', 'Krvný tlak', 'Tlak', 'Zhrnutie'],
				message5: ['Konzumácia', 'Návrh', 'Dnešné kroky',
					'Trvanie cvičenia'],
				message6: ['Check-in cvičenia', 'Fitness Home Edition',
					'Fitness Pro', 'Plán chudnutia'
				],
				message7: ['Cvičte a žite zdravý život',
					'Prispôsobte si svoj fitness plán', 'Prispôsobte si svoj fitness plán',
					'Vytvoríte plán chudnutia pre vás'
				],
				message8: ['Kcal', 'Kroky', 'Minúty', 'Série', 'Dni', 'Roky', 'cm', 'kg','h','Týždeň'],
				message9: ['Check-in', 'Tréning', 'Možnosti cvičenia', 'Moje úspechy',
					'Počet dní po sebe check-in', 'Bez check-in'
				],
				message10: ['Kliknite na analýzu', 'História analýz (až 10 uložených)', 'Analyzovať',
					'Zatiaľ žiadne údaje', 'Počet dní po sebe check-in', 'Analýza údajov'
				],
				message11: ['Žena', 'Muž'],
				message12: ['Zrušiť', 'Potvrdiť'],
				message13: ['Roky', 'Centrimetre', 'Kilogramy'],
				message14: ['Manuálny vstup', 'Predpokladaný čas (Týždne) ', 'Pohlavie', 'Vek', 'Výška (centrimetre)',
					'Váha (kilogramy)', 'Očakávaná váha (kilogramy)'
				],
				message15: ['História', 'Analýza zdravotných údajov', 'Zdravotné rady'],
				message16: ['Check-in úspešný', 'Prebieha check-in', 'Check-in je možný len v ten istý deň'],
				message17: ['Analýzu zdravotných údajov možno získať len raz denne, miestne údaje sa nedajú nájsť', 'Zdravotné rady možno získať len raz denne, miestne údaje sa nedajú nájsť'],
				message18:['Momentálne podváha','Váš BMI index je','nie je vhodné pokračovať v chudnutí (Zdravý rozsah 18.5<BMI<24)'],
				message19:['Mierne chudnutie','Odporúča sa neprekročiť 500g denne'],
				message20:['existujú plány','Pokryje existujúce plány'],
				message21:['Jednoduchá', 'Normálna', 'Stredná', 'Vysoká'],
				message22:['Duch:','Nízka','Vysoká'],
				message23:['Počet spotrebovaných,Ak chcete odomknúť viackrát, zakúpte si predplatné'],
			},
		},
punch: {
			title: "Hotovo",
			title2: "Dokončiť",
			h1: "Možnosti cvičenia",
			h2: "Moje úspechy",
			button: "Check-in",
			day: "Počet dní po sebe check-in",
			dayunit: "Dní",
		},
		eat: {
			title: "Diéta",
			DietaryIntake: "Stravovanie",
			kcal: "Kcal",
			Stillingestible: "Zvyšok",
			overingest: "Nad rámec",
			Recommendedintake: "Odporúčané",
			Motorconsumption: "Pohyb",
			carbohydrate: "Sacharidy",
			protein: "Bielkoviny",
			fat: "Tuky",
			gram: "Gramov",
			drinkDk: "Príjem vody",
			drinkDkhealth: "Denný príjem pre zdravšie telo",
			eatsuggest: "Stravovacie odporúčania",
			analyzefoodglx: "Analyzovať racionálnosť stravy",
			eatPlan: "Stravovací plán",
			makeeatPlan: "Prispôsobte svoj jedinečný stravovací plán",
			breakfast: "Raňajky",
			lunch: "Obed",
			dinner: "Večera",
			snack: "Desiata",
			addFood: "Pridať jedlo",
			noAddFood: "Žiadne pridané jedlo",
			cancle: "Zrušiť",
			confirm: "Potvrdiť",
			tips: "Dobrovoľné vyplnenie sa považuje za súhlas s tým, že zbierame tieto informácie projektu a zobrazujeme ich v strave",
			addFooded: "Jedlo pridané",
			added: "Pridané",
			numFood: "Položky jedla",
			record: "Záznam",
			dkTitle: "Check-in",
			dailygoal: "Denný cieľ",
			drinkDw: "Poháre/Deň",
			drinkWater: "Piť vodu",
			dailygoalWater: "Denný cieľ pitia vody",
			myachievement: "Moje úspechy",
			lxdkDays: "Počet dní po sebe check-in",
			day: "Dní",
			analyzeHistory: "História",
			conclusion: "Zhrnutie",
			eatDataAnalyze: "Analýza údajov o strave",
			selectStartDate: "Vyberte dátum začiatku",
			chooseoneDate: "Prosím vyberte 1 deň ako dátum začiatku",
			scheduling: "Plánovanie",
			schedulingDesc: "Začnite od najbližšieho dátumu, ktorý ste vybrali, celkom 7 dní; konkrétny obsah je nasledujúci:",
			eatDetail:"Podrobnosti o strave",
			analyzeFailed:"Analýza zlyhala",
			noData:"žiaden údaj"


		}
	},
  error: {
    error1101:"Sieťové pripojenie je nestabilné, aktuálny časový limit relácie",
    error1102:"Momentálne nie je k dispozícii žiadna sieť, znova sa pripojte",
    error1103:"Vyskytla sa neznáma chyba",
    error61:"Aktuálna služba je nedostupná",
    error4:"Hlas sa ešte nezistil, skúste to znova",
    error12:"Zadajte štandardné slová alebo vety, aby im AI porozumela a nakreslila",
		error50:"Ospravedlňujeme sa, aktuálny server je nesprávny, skúste to znova"
  },
	setting: {
		index: {
			title: "Nastavenia",
			problem: "Spätná väzba problémov",
			order: "Záznamy objednávok",
			notice: "Oznámenie",
			language: "Jazyk",
			clear: "Vyčistiť vyrovnávaciu pamäť",
			clearTip: "Naozaj chcete\nvyčistiť vyrovnávaciu pamäť?",
			confirm: "Potvrdiť",
			cancel: "Zrušiť",
		},
language: {
			title: "Jazyk",
			langList: ["Zjednodušená čínština", "Tradičná čínština", "Angličtina", "Arabčina", "Bulharčina", "Bengálčina", "Čeština", "Nemčina", "Gréčtina", "Španielčina", "Perzština", "Filipínčina", "Francúzština", "Hindčina", "Maďarčina", "Indonézština", "Taliančina", "Hebrejčina", "Japončina", "Kórejčina", "Malajčina", "Holandčina", "Pandžábčina", "Poľština", "Portugalčina", "Rumunčina", "Ruština", "Slovenčina", "Thajčina", "Turečtina", "Ukrajinčina", "Urdčina", "Vietnamčina"],
		},
		order: {
			title: "Záznamy objednávok",
			success: "Úspešne načítané",
			unpaid: "Nezaplatené",
			paid: "Zaplatené",
			expired: "Vypršalo",
			refunded: "Vrátené",
			pTime: "Čas platby",
			eTime: "Čas vypršania platnosti",
			pMethod: "Spôsob platby",
			pObject: "Predmet platby",
			oNumber: "Číslo objednávky",
			pType: "Typ balíka",
			oTime: "Čas načítania",
			watch: "Zobraziť IMEI",
			delete: "Odstrániť objednávku",
			refund: "Požiadať o vrátenie peňazí",
		},
problem: {
			title: "Spätná väzba na problémy",
			feedback: "Typ spätnej väzby",
			please: "Prosím vyberte",
			content: "Obsah",
			improve: "Prosím napíšte svoje návrhy, ako sú požiadavky na funkcie, sťažnosti na produkt atď. Budeme sa snažiť zlepšiť",
			upload: "Nahrať obrázok",
			submit: "Odoslať",
			general: "Všeobecná spätná väzba",
			purchase: "Týkajúce sa nákupu",
			bug: "Hlásenie chyby",
			suggestion: "Návrh",
			other: "Iné",
		},
		notice: {
			title: "Oznámenie",
			type: "Typ oznámenia: ",
			time: "Čas oznámenia: "
		},
		unsubscribe: {
			watch: "Hodinky:",
			title: "Kontinuálne predplatné",
			subTitle: "Predplatené",
			unsubscribe: "Zrušiť predplatné",
			packList: ["Prémiové predplatné", "Základné predplatné", "Štandardné predplatné"],
		},
		refund: {
			title: "Požiadať o vrátenie peňazí",
			number: "Číslo objednávky",
			content: "Prosím vysvetlite dôvod vrátenia peňazí",
			packList: ["Zľavový balík pre nových používateľov", "Balík na 50 použitia", "Balík na 100 použití",
				"Balík na 300 použití"
			],
		}
	},
	tutorial: {
		index: {
			title: "Získať pokročilý návod",
			watchList: ["Zapnite hodinky, nájdite aplikáciu WearAI a kliknite na otvorenie.",
				"Kliknite na tlačidlo pokročilých výhod na domovskej stránke.",
				"Vezmite svoj mobilný telefón a naskenujte QR kód na hodinkách, aby ste sa dostali na stránku výhod."
			]
		}
	},
}