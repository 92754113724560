module.exports = {
  language: "Dansk",
  navigator: {
    tools: "AI Værktøjer",
    draw: "Tegn",
    healthy: "Sund",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Anbefal", "Studie", "Liv", "Oprettelse", "Arbejde"],
      presetGreet:
        "Hej, hej~ Jeg er din sundhedsadministrator, altid klar til at give interessant og nyttig sundhedsinformation for at hjælpe dig med at beskytte dit helbred!",
      presetQuiz: [
        "Hvad er årsagerne til kronisk træthed?",
        "Hvordan kan unge mennesker forhindre kroniske sygdomme?",
        "Hvordan opretholder man sunde spisevaner?",
        "Hvordan opretholder man god søvn?",
      ],
      presets: "Du kan spørge mig:",
      change: "Ændre batch",
      uploadImage: "Send billede",
      footer:
        "Da indholdet er genereret af en AI-model, kan dets ægthed ikke garanteres fuldt ud.",
      remind: "Todo påmindelse",
      limit:
        "Denne samtale har nået grænsen, venligst start en ny AI chat session",
      newDialog: "Start en ny samtale",
      stopRecording: "Klik et hvilket som helst sted eller når nedtællingen slutter for at genkende",
      send: "Send besked",
      say: "Klik for at tale",
      notImage: "Denne agent understøtter ikke sending af filer og billeder",
      talking: "Aktuel samtale er i gang",
      drawViolations:
        "AI tegning prompt overtræder reglerne, venligst indtast en overholdelig prompt.",
      exhausted:
        "Antallet af Q&As er udtømt, venligst gå til slutningen og scan QR-koden for at fortsætte.",
      serverBusy: "Den nuværende netværksforbindelse er ustabil, venligst prøv igen",
      chatHistory: "Chat historie",
      chatHistoryDesc: "Q&A optegnelser opbevares kun i 3 dage",
      todoList: "Todo liste",
      todoDesc: "Todo detaljer",
      todoListDesc: "Maksimalt 10 todo'er kan eksistere",
      confirm: "Bekræft",
      cancel: "Annuller",
      text: "Tekst kan ikke være tom",
      cancelRemind: "Annuller påmindelse",
      remindTime: "Påmindelsestid:",
      remindTitle: "Påmindelsestitel:",
      remindContent: "Påmindelsesindhold:",
      edit: "Rediger",
      reachLimit: "Tilføjet op til grænsen",
      errorChat:"Ingen stemme opdaget endnu, venligst prøv igen",
            noSTT:"Tilladelse ikke opnået. Aktiver venligst tilladelse, før du fortsætter.",
      error50:"Der opstod en ukendt fejl. Prøv venligst igen"
    },
draw: {
      describe: "Beskriv dit billede",
      voice: "Voice input",
      press: "Tryk for at tale",
      placeholder: "Beskriv det billede, du ønsker at generere",
      style: "Malestil",
      styleList: [
        "Tuschteknik",
        "Cyberpunk",
        "Anime",
        "Origami",
        "Strikning",
        "Grafik",
        "Tegneserie",
        "LEGO",
        "Blyant",
        "Tegn",
      ],
      record: "Tegningsoptegnelse",
      recordDesc: "Op til 10 tegneoptegnelser kan gemmes",
      recordEmpty: "Du har i øjeblikket ingen tegneoptegnelser",
      generate: "Generer billede",
      result: "Resultat af billedegenerering",
      download: "Download billede",
      regenerate: "Regenerer",
      setDial: "Sæt som urskive",
      error: "Billedegenerering mislykkedes",
      errorDesc: "Ustabil netværksforbindelse, venligst indsend igen.",
      confirm: "Bekræft",
      analysiserror: "Analyse mislykkedes",
      SetWatchErr:["Opkaldet er indstillet","Annuller afsendelse","Databekræftelse mislykkedes","Urskivens datafil er for stor","Synkronisering af urskive har nået den øvre grænse","Bluetooth er blevet afbrudt"],
      SetWatchTips:["Opsætningen mislykkedes","Opkaldsindstilling i gang","Urskiven indstilles, forlad venligst ikke den aktuelle side."],
      progressText:"Synkronisering"

    },
    sports: {
      analyze: "Sundhedsanalyse",
      analyzeDesc: "Analysér grundlæggende data",
      advice: "Sundhedsredegørelse",
      adviceDesc: "Giv sundhedsrådgivning",
      experience: "Erfaring",
      program: {
        plan: "Vægttabsplan",
        startDate: "Vælg startdato",
        chooseDate: "Vælg venligst 1 dag som startdato",
        week: [
          {
            num: "Søn.",
            day: "Søndag",
          },
          {
            num: "Man.",
            day: "Mandag",
          },
          {
            num: "Tir.",
            day: "Tirsdag",
          },
          {
            num: "Ons.",
            day: "Onsdag",
          },
          {
            num: "Tor.",
            day: "Torsdag",
          },
          {
            num: "Fre.",
            day: "Fredag",
          },
          {
            num: "Lør.",
            day: "Lørdag",
          },
        ],
arrange: "Planlæg Arrangement",
        arrangeDesc:
          "Start fra den nærmeste dato, du vælger, i alt 7 træningsdage; det specifikke indhold er som følger:",
        confirm: "Bekræft Arrangement",
        success:
          "Tillykke, du har med succes tilpasset planen! Start din træning på 'Trænings Check-in' siden!",
        successButton: "Gå til Trænings Check-in",
      },
      newmessage: {
        message1: ["Personligt Center", "Sundhedsdata"],
        message2: ["Sundhed", "Træning", "Kost"],
        message3: ["Køn", "Alder", "Højde", "Vægt"],
        message4: [
          "Søvn",
          "HR",
          "Oxygen",
          "Skridt",
          "BP",
          "Tryk",
          "Sammenfatning",
        ],
        message5: ["Forbruge", "Forslag", "Dagens Skridt", "Træning Varighed"],
        message6: [
          "Trænings Check-in",
          "Fitness Hjemme Edition",
          "Fitness Pro",
          "Vægttabsplan",
        ],
        message7: [
          "Træn og lev et sundt liv",
          "Tilpas din træningsplan",
          "Tilpas din træningsplan",
          "Opret en vægttabsplan til dig",
        ],
        message8: [
          "Kcal",
          "Skridt",
          "Minutter",
          "Sæt",
          "Dage",
          "År",
          "cm",
          "kg",
          "h", "Uger"
        ],
        message9: [
          "Check-in",
          "Træning",
          "Træningsmuligheder",
          "Mine Resultater",
          "Konsekutive Check-in Dage",
          "Ikke Checket-in",
        ],
        message10: [
          "Klik for at Analysere",
          "Analysehistorik (op til 10 gemte)",
          "Analyser",
          "Du har i øjeblikket ingen historiske optegnelser",
          "Konsekutive Check-in Dage",
          "Data Analyse",
        ],
message11: ["Kvinde", "Mand"],
        message12: ["Annuller", "Bekræft"],
        message13: ["År", "Centimeter", "Kilogram"],
        message14: [
          "Manuel Indtastning",
          "Forventet tid (Uger)",
          "Køn",
          "Alder",
          "Højde (Centimeter)",
          "Vægt (Kilogram)",
          "Forventet Vægt (Kilogram)",
        ],
        message15: ["Historik", "Sundhedsdata Analyse", "Sundhedsredegørelse"],
        message16: [
          "Check-in Succesfuld",
          "Tjekker ind",
          "Kan kun tjekkes ind samme dag",
        ],
        message17: [
          "Sundhedsdataanalyse kan kun opnås én gang dagligt, og lokale data kan ikke findes",
          "Sundhedsredegørelse kan kun opnås én gang dagligt, og lokale data kan ikke findes",
        ],
        message18: [
          "Aktuelt undervægtig",
          "Dit BMI indeks er",
          "det frarådes at fortsætte med at tabe sig (Sund rækkevidde 18.5<BMI<24)",
        ],
        message19: [
          "Moderat vægttab",
          "Det anbefales ikke at tabe mere end 500g per dag",
        ],
        message20: ["har planer", "Dækker det eksisterende planer"],
        message21: ["Let", "Normal", "Medium", "Høj"],
        message22: ["Åndedrag:", "Lav", "Høj"],
		message23: ["Antallet af gange er brugt op. Køb et abonnement for at låse op for flere gange"],
      },
    },
    punch: {
      title: "Færdig",
      title2: "Afslut",
      h1: "Træningsmuligheder",
      h2: "Mine Resultater",
      button: "Check-in",
      day: "Konsekutive Check-in Dage",
      dayunit: "Dage",
    },
eat: {
      title: "Kost",
      DietaryIntake: "Spisning",
      kcal: "Kcal",
      Stillingestible: "Rest",
      overingest: "Over",
      Recommendedintake: "Forslag",
      Motorconsumption: "Motion",
      carbohydrate: "Kulhydrater",
      protein: "Protein",
      fat: "Fedt",
      gram: "Gramme",
      drinkDk: "Vand Check-in",
      drinkDkhealth: "Dagligt check-in for en sundere krop",
      eatsuggest: "Kostanbefalinger",
      analyzefoodglx: "Analyserer rationaliteten i kosten",
      eatPlan: "Kostplan",
      makeeatPlan: "Tilpas din eksklusive kostplan",
      breakfast: "Morgenmad",
      lunch: "Frokost",
      dinner: "Aftensmad",
      snack: "Snack",
      addFood: "Tilføj Mad",
      noAddFood: "Ingen mad tilføjet",
      cancle: "Annuller",
      confirm: "Bekræft",
      tips: "Proaktiv udfyldning betragtes som samtykke til, at vi indsamler disse projektoplysninger og viser dem i kosten.",
      addFooded: "Mad tilføjet",
      added: "Tilføjet",
      numFood: "Madvarer",
      record: "Optegnelse",
      dkTitle: "Check-in",
      dailygoal: "Dagligt Mål",
      drinkDw: "Kopper/Day",
      drinkWater: "Drik Vand",
      dailygoalWater: "Dagligt vanddrikke mål",
      myachievement: "Mine Resultater",
      lxdkDays: "Konsekutive Punch Dage",
      day: "Dage",
      analyzeHistory: "Historik",
      conclusion: "Sammenfatning",
      eatDataAnalyze: "Kostdata Analyse",
      selectStartDate: "Vælg Start Dato",
      chooseoneDate: "Vælg venligst 1 dag som startdato",
      scheduling: "Planlægning",
      schedulingDesc:
        "Start fra den nærmeste dato, du vælger, i alt 7 dage; det specifikke indhold er som følger:",
      eatDetail: "Kost Detaljer",
      analyzeFailed: "Analyse mislykkedes",
      noData: "ingen data",
    },
  },
  error: {
    error1101:"Netværksforbindelsen er ustabil, den aktuelle session timeout",
    error1102:"Der er i øjeblikket intet netværk. Tilslut venligst igen",
    error1103:"Der opstod en ukendt fejl",
    error61:"Den aktuelle tjeneste er ikke tilgængelig",
    error4:"Stemmen er ikke blevet genkendt endnu, prøv venligst igen",
    error12:"Indtast venligst standardord eller -sætninger, så AI kan forstå og tegne",
    error50:"Beklager, der er en fejl på den aktuelle server, prøv venligst igen"

  },
  setting: {
    index: {
      title: "Indstillinger",
      problem: "Problem Feedback",
      order: "Ordreoptegnelser",
      notice: "Underretninger",
      language: "Sprog",
      clear: "Ryd Cache",
      clearTip: "Er du sikker på, at du vil\nrydde cachen?",
      confirm: "Bekræft",
      cancel: "Annuller",
    },
    language: {
      title: "Sprog",
      langList: [
        "Forenklet Kinesisk",
        "Traditionelt Kinesisk",
        "Engelsk",
        "Arabisk",
        "Polsk",
        "Persisk",
        "Dansk",
        "Tysk",
        "Russisk",
        "Fransk",
        "Hollandsk",
        "Tjekkisk",
        "Rumænsk",
        "Portugisisk",
        "Japansk",
        "Thailandsk",
        "Tyrkisk",
        "Spansk",
        "Græsk",
        "Italiensk",
        "Vietnamisisk",
        "Hindi",
      ],
    },
    order: {
      title: "Ordreoptegnelser",
      success: "Hentet med succes",
      unpaid: "Ubetalt",
      paid: "Betalt",
      expired: "Udløbet",
      refunded: "Refundert",
      pTime: "Betalingstid",
      eTime: "Udløbstid",
      pMethod: "Betalingsmetode",
      pObject: "Betalingsobjekt",
      oNumber: "Ordrenummer",
      pType: "Pakke Type",
      oTime: "Hentningstid",
      watch: "Vis IMEI",
      delete: "Slet Ordre",
      refund: "Anmod om Refundering",
    },
problem: {
      title: "Problem Feedback",
      feedback: "Feedback Type",
      please: "Vælg venligst",
      content: "Indhold",
      improve:
        "Skriv venligst dine forslag, såsom funktionsanmodninger, produktklager osv. Vi vil stræbe efter at forbedre",
      upload: "Upload Billede",
      submit: "Indsend",
      general: "Generel Feedback",
      purchase: "Købsrelateret",
      bug: "Fejlrapport",
      suggestion: "Forslag",
      other: "Andet",
    },
    notice: {
      title: "Underretning",
      type: "Underretnings Type: ",
      time: "Underretnings Tid: ",
    },
    unsubscribe: {
      watch: "Ur:",
      title: "Kontinuerligt Abonnement",
      subTitle: "Abonneret",
      unsubscribe: "Afmeld",
      packList: [
        "Premium Abonnement",
        "Basis Abonnement",
        "Standard Abonnement",
      ],
    },
refund: {
      title: "Anmod om Refundering",
      number: "Ordrenummer",
      content: "Forklar venligst årsagen til refunderingen",
      packList: [
        "Ny Bruger Rabatpakke",
        "50 Gange Pakke",
        "100 Gange Pakke",
        "300 Gange Pakke",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Få Avanceret Tutorial",
      watchList: [
        "Tænd uret, find WearAI-applikationen og klik for at åbne.",
        "Klik på knappen for avancerede fordele på startsiden.",
        "Tag din mobiltelefon og scan QR-koden på uret for at komme ind på fordelssiden.",
      ],
    },
  },
}