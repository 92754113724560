module.exports = {
    language: "Türkçe",
    navigator: {
    tools: "AI Araçları",
    draw: "Çizmek",
    healthy: "Sağlıklı",
    },
    aitools: {
            chat: {
                gpt: "Jarvis",
                categoryList: ["Öneri", "Çalışma", "Yaşam", "Yaratım", "İş"],
                presetGreet: "Merhaba, ben senin sağlık yöneticinim, sana ilginç ve faydalı sağlık bilgileri sunmaya her zaman hazırım, sağlığını korumana yardımcı olmak için buradayım!",
                presetQuiz: ["Kronik yorgunluk nedenleri nelerdir?", "Gençler kronik hastalıkları nasıl önleyebilir?", "Sağlıklı beslenme alışkanlıkları nasıl korunur?", "İyi uyku nasıl sağlanır?"],
                presets: "Bana sorabilirsin:",
                change: "Partiyi değiştir",
                uploadImage: "Resim gönder",
                footer: "İçerik bir AI modeli tarafından oluşturulduğu için, doğruluğu tamamen garanti edilemez.",
                remind: "Yapılacak hatırlatıcı",
                limit: "Bu sohbet sınırına ulaştı, lütfen yeni bir AI sohbet oturumu başlatın",
                newDialog: "Yeni bir konuşma başlat",
                stopRecording: "Herhangi bir yere tıklayın veya geri sayım bittiğinde tanıyın",
                send: "Mesaj gönder",
                say: "Konuşmak için tıkla",
                notImage: "Bu ajan dosya ve resim göndermeyi desteklemiyor",
                talking: "Mevcut sohbet devam ediyor",
                drawViolations: "AI çizim istemi kuralları ihlal ediyor, lütfen kurallara uygun bir istem girin.",
                exhausted: "Soru-cevap sayısı tükenmiş, lütfen saat sonuna gidin ve devam etmek için QR kodunu tarayın.",
                serverBusy: "Mevcut ağ bağlantısı kararsız, lütfen tekrar deneyin",
                chatHistory: "Sohbet geçmişi",
                chatHistoryDesc: "Soru-cevap kayıtları sadece 3 gün saklanır",
                todoList: "Yapılacaklar listesi",
                todoDesc: "Yapılacaklar detayları",
                todoListDesc: "En fazla 10 yapılacak madde mevcut olabilir",
                confirm: "Onayla",
                cancel: "İptal",
                text: "Metin boş olamaz",
                cancelRemind: "Hatırlatıcıyı iptal et",
                remindTime: "Hatırlatma zamanı:",
                remindTitle: "Hatırlatma başlığı:",
                remindContent: "Hatırlatma içeriği:",
                edit: "Düzenle",
                reachLimit: "Limitine ulaşıldı",
                errorChat:"Ses henüz algılanmadı, lütfen tekrar deneyin",
                      noSTT:"İzin alınamadı. Devam etmeden önce lütfen izni etkinleştirin.",
      error50:"Bilinmeyen bir hata oluştu, lütfen tekrar deneyin"
            },
            draw: {
                describe: "Resminizi tanımlayın",
                voice: "Sesli giriş",
                press: "Konuşmak için basın",
                placeholder: "Oluşturmak istediğiniz resmi tanımlayın",
                style: "Resim stili",
                styleList: ["Mürekkep stili", "Siberpunk", "Anime", "Origami", "Örgü",  "Grafik", "Çizgi film","LEGO","Kalem","Çizmek"],
                record: "Çizim kaydı",
                recordDesc: "En fazla 10 çizim kaydı saklanabilir",
                recordEmpty: "Şu anda çizim kaydınız yok",
                generate: "Görüntü oluştur",
                result: "Görüntü oluşturma sonucu",
                download: "Görüntüyü indir",
                regenerate: "Yeniden oluştur",
                setDial: "Kadran olarak ayarla",
                error: "Görüntü oluşturma başarısız oldu",
                errorDesc: "Kararsız ağ bağlantısı, lütfen yeniden gönderin.",
                confirm: "Onayla",
                analysiserror: "Analiz başarısız oldu",
                SetWatchErr:["Arama başarıyla ayarlandı","Göndermeyi iptal et","Veri doğrulama başarısız oldu","Saat yüzü veri dosyası çok büyük","Saat yüzü senkronizasyonu üst sınıra ulaştı","Bluetooth bağlantısı kesildi"],
                SetWatchTips:["Kurulum başarısız oldu","Arama ayarı yapılıyor","Saat görünümü ayarlanıyor, lütfen mevcut sayfadan çıkmayın."],
                progressText:"Senkronizasyonu"

            },
    sports: {
                analyze: "Sağlık Analizi",
                analyzeDesc: "Temel verileri analiz et",
                advice: "Sağlık Tavsiyesi",
                adviceDesc: "Sağlık tavsiyesi sağla",
                experience: "Deneyim",
                program: {
                    plan: "Kilo Verme Planı",
                    startDate: "Başlangıç Tarihini Seçin",
                    chooseDate: "Lütfen başlangıç tarihi olarak 1 gün seçin",
                    week: [{
                        num: "Paz.",
                        day: "Pazar"
                    }, {
                        num: "Pzt.",
                        day: "Pazartesi"
                    }, {
                        num: "Sal.",
                        day: "Salı"
                    }, {
                        num: "Çar.",
                        day: "Çarşamba"
                    }, {
                        num: "Per.",
                        day: "Perşembe"
                    }, {
                        num: "Cum.",
                        day: "Cuma"
                    }, {
                        num: "Cmt.",
                        day: "Cumartesi"
                    }, ],
                    arrange: "Plan Düzeni",
                    arrangeDesc: "Seçtiğiniz en yakın tarihten itibaren başlayın, toplamda 7 antrenman günü; spesifik içerik şu şekildedir:",
                    confirm: "Düzeni Onayla",
                    success: "Tebrikler, planı başarıyla özelleştirdiniz! Antrenmanınıza 'Egzersiz Girişi' sayfasında başlayın!",
                    successButton: "Egzersiz Girişine Git",
                },
    newmessage: {
                    message1: ['Kişisel Merkez', 'Sağlık Verileri'],
                    message2: ['Sağlık', 'Egzersiz', 'Diyet'],
                    message3: ['Cinsiyet', 'Yaş', 'Boy', 'Ağırlık'],
                    message4: ['Uyku', 'HR', 'Oksijen', 'Adımlar', 'BP', 'Basınç', 'Özet'],
                    message5: ['Tüketim', 'Öneri', 'Bugünkü Adımlar', 'Egzersiz Süresi'],
                    message6: ['Egzersiz Girişi', 'Ev Egzersizi', 'Fitness Pro', 'Kilo Verme Planı'],
                    message7: ['Egzersiz yap ve sağlıklı yaşa', 'Fitness planını özelleştir', 'Fitness planını özelleştir', 'Senin için kilo verme planı oluştur'],
                    message8: ['Kcal', 'Adımlar', 'Dakikalar', 'Tekrarlar', 'Günler', 'Yıllar', 'cm', 'kg','h','Hafta'],
                    message9: ['Giriş', 'Antrenman', 'Egzersiz Seçenekleri', 'Başarılarım', 'Art Arda Giriş Günleri', 'Girilmemiş'],
                    message10: ['Analiz için Tıkla', 'Analiz Geçmişi (en fazla 10 kayıt saklanır)', 'Analiz', 'Şu anda geçmiş kaydınız yok', 'Art Arda Giriş Günleri', 'Veri Analizi'],
                    message11: ['Kadın', 'Erkek'],
                    message12: ['İptal', 'Onayla'],
                    message13: ['Yıllar', 'Santimetre', 'Kilogram'],
                    message14: ['Manuel Giriş', 'Beklenen süre Hafta)', 'Cinsiyet', 'Yaş', 'Boy (Santimetre)', 'Ağırlık (Kilogram)', 'Beklenen Ağırlık (Kilogram)'],
                    message15: ['Geçmiş', 'Sağlık Verileri Analizi', 'Sağlık Tavsiyesi'],
                    message16: ['Giriş Başarılı', 'Giriş Yapılıyor', 'Sadece aynı gün giriş yapılabilir'],
                    message17: ['Sağlık verileri analizi günde sadece bir kez alınabilir ve yerel veriler bulunamaz', 'Sağlık tavsiyesi günde sadece bir kez alınabilir ve yerel veriler bulunamaz'],
                    message18:['Şu anda düşük kilolu','BMI indeksiniz','kilo vermeye devam etmek önerilmez (Sağlıklı aralık 18.5<BMI<24)'],
                    message19:['Orta çapta kilo kaybı','Günde 500 gdan fazla kaybetmemek önerilir'],
                    message20:['planlar var mı','Mevcut planları kapsıyor mu'],
                    message21:['Kolay','Normal','Orta','Yüksek'],
                    message22:['Ruh:','Düşük','Yüksek'],
					message23:['Kullanım sayısı doldu. Daha fazla kez kilidini açmak için bir abonelik satın alın.'],
                },
            },
    punch: {
                title: "Tamamlandı",
                title2: "Bitti",
                h1: "Egzersiz Seçenekleri",
                h2: "Başarılarım",
                button: "Giriş",
                day: "Art Arda Giriş Günleri",
                dayunit: "Günler",
            },
            eat: {
                title: "Diyet",
                DietaryIntake: "Yeme",
                kcal: "Kcal",
                Stillingestible: "Kalan",
                overingest: "Aşan",
                Recommendedintake: "Öneri",
                Motorconsumption: "Hareket",
                carbohydrate: "Karbonhidrat",
                protein: "Protein",
                fat: "Yağ",
                gram: "Gram",
                drinkDk: "Su Girişi",
                drinkDkhealth: "Daha sağlıklı bir beden için günlük giriş yap",
                eatsuggest: "Diyet Önerileri",
                analyzefoodglx: "Diyetin rasyonelliğini analiz et",
                eatPlan: "Diyet Planı",
                makeeatPlan: "Özel diyet planını özelleştir",
                breakfast: "Kahvaltı",
                lunch: "öğlen",
                dinner: "yemeği",
                snack: "ek",
                addFood: "Yemek Ekle",
                noAddFood: "Yemek eklenmedi",
                cancle: "İptal",
                confirm: "Onayla",
                tips: "Bu proje bilgisini toplamamıza ve diyette göstermemize izin verdiğinizi kabul ederek gönüllü bir şekilde doldurulur",
                addFooded: "Yemek eklendi",
                added: "Eklendi",
                numFood: "Yemek Öğeleri",
                record: "Kayıt",
                dkTitle: "Giriş",
                dailygoal: "Günlük Hedef",
                drinkDw: "Bardak/Gün",
                drinkWater: "Su İçmek",
                dailygoalWater: "Günlük su içme hedefi",
                myachievement: "Başarılarım",
                lxdkDays: "Art Arda Giriş Günleri",
                day: "Günler",
                analyzeHistory: "Geçmiş",
                conclusion: "Özet",
                eatDataAnalyze: "Diyet Verileri Analizi",
                selectStartDate: "Başlangıç Tarihini Seçin",
                chooseoneDate: "Lütfen başlangıç tarihi olarak 1 gün seçin",
                scheduling: "Plan Düzeni",
                schedulingDesc: "Seçtiğiniz en yakın tarihten başlayarak, toplamda 7 gün; spesifik içerik şu şekildedir:",
                eatDetail:"Diyet Detayları",
                analyzeFailed:"Analiz başarısız oldu",
                noData:"veri yok"
            }
        },
        error: {
            error1101:"Ağ bağlantısı kararsız, geçerli oturum zaman aşımı",
            error1102:"Şu anda ağ yok, lütfen yeniden bağlanın",
            error1103:"Bilinmeyen bir hata oluştu",
            error61:"Mevcut hizmet kullanılamıyor",
            error4:"Ses henüz algılanmadı, lütfen tekrar deneyin",
            error12:"Lütfen yapay zekanın anlayabilmesi ve çizebilmesi için standart kelimeleri veya cümleleri girin",
            error50:"Üzgünüm, geçerli sunucu yanlış, lütfen tekrar deneyin"
        },
        setting: {
            index: {
                title: "Ayarlar",
                problem: "Sorun Geri Bildirimi",
                order: "Sipariş Kayıtları",
                notice: "Bildirim",
                language: "Dil",
                clear: "Önbelleği Temizle",
                clearTip: "Önbelleği temizlemek istediğinizden emin misiniz?",
                confirm: "Onayla",
                cancel: "İptal",
            },
            language: {
                title: "Dil",
                langList: ["Basitleştirilmiş Çince", "Geleneksel Çince", "İngilizce", "Arapça", "Bulgarca", "Bengalce", "Çekçe", "Almanca", "Yunanca", "İspanyolca", "Farsça", "Filipince", "Fransızca", "Hintçe", "Macarca", "Endonezyaca", "İtalyanca", "İbranice", "Japonca", "Korece", "Malayca", "Hollandaca", "Pencapça", "Lehçe", "Portekizce", "Romence", "Rusça", "Slovakça", "Tayca", "Türkçe", "Ukraynaca", "Urduca", "Vietnamca"],
            },
            order: {
                title: "Sipariş Kayıtları",
                success: "Başarıyla Alındı",
                unpaid: "Ödenmemiş",
                paid: "Ödenmiş",
                expired: "Süresi Dolmuş",
                refunded: "İade Edilmiş",
                pTime: "Ödeme Zamanı",
                eTime: "Son Kullanma Tarihi",
                pMethod: "Ödeme Yöntemi",
                pObject: "Ödeme Nesnesi",
                oNumber: "Sipariş Numarası",
                pType: "Paket Türü",
                oTime: "Alım Zamanı",
                watch: "IMEI Görüntüle",
                delete: "Siparişi Sil",
                refund: "İade Talebi",
            },
    problem: {
                title: "Sorun Geri Bildirimi",
                feedback: "Geri Bildirim Türü",
                please: "Lütfen Seçin",
                content: "İçerik",
                improve: "Önerilerinizi yazınız, örneğin özellik talepleri, ürün şikayetleri, vb. İyileştirmek için çabalayacağız",
                upload: "Resim Yükleyin",
                submit: "Gönder",
                general: "Genel Geri Bildirim",
                purchase: "Satın Alma İle İlgili",
                bug: "Hata Bildirimi",
                suggestion: "Öneri",
                other: "Diğer",
            },
            notice: {
                title: "Bildirim",
                type: "Bildirim Türü: ",
                time: "Bildirim Zamanı: "
            },
            unsubscribe: {
                watch: "Saat:",
                title: "Sürekli Abonelik",
                subTitle: "Abone Olundu",
                unsubscribe: "Aboneliği İptal Et",
                packList: ["Premium Abonelik", "Temel Abonelik", "Standart Abonelik"],
            },
            refund: {
                title: "İade Talebi",
                number: "Sipariş Numarası",
                content: "Lütfen iade nedenini açıklayın",
                packList: ["Yeni Kullanıcı İndirim Paketi", "50 Kere Paket", "100 Kere Paket",
                     "300 Kere Paket"
                ],
            }
        },
        tutorial: {
            index: {
                title: "Gelişmiş Eğitim Alın",
                watchList: ["Saati açın, WearAI uygulamasını bulun ve açmak için tıklayın.",
                    "Ana sayfada gelişmiş avantajlar düğmesine tıklayın.",
                    "Cep telefonunuzu çıkarın ve avantajlar sayfasına girmek için saatteki QR kodunu taratın."
                ]
            }
        },
    }