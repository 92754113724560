module.exports = {
	language: "עברית",
	navigator: {
		tools: "כלי AI",
		draw: "לִמְשׁוֹך",
		healthy: "בָּרִיא",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["המלצה", "למידה", "חיים", "יצירה", "עבודה"],
			presetGreet: "שלום, היי~ אני שומר הבריאות שלך, תמיד מוכן לספק ידע בריאותי מעניין ומועיל כדי לעזור לך לשמור על הבריאות שלך!",
			presetQuiz: ["מהם הגורמים לעייפות כרונית?", "כיצד יכולים צעירים למנוע מחלות כרוניות?", "כיצד לשמור על הרגלי אכילה בריאים?", "כיצד לשמור על שינה טובה?"],
			presets: "אתה יכול לשאול אותי:",
			change: "רדף חבילה",
			uploadImage: "שלח תמונה",
			footer: "מכיוון שתוכן נוצר על ידי מודל AI, לא ניתן להבטיח את מהימנותו במלואה.",
			remind: "תזכורת משימה",
			limit: "השיחה הגיעה למגבלה, אנא התחל מושב שיחה חדש עם AI",
			newDialog: "התחל שיחה חדשה",
			stopRecording: "לחץ בכל מקום או כשהספירה לאחור מסתיימת לזיהוי",
			send: "שלח הודעה",
			say: "לחץ כדי לדבר",
			notImage: "סוכן זה לא תומך בשליחת קבצים ותמונות",
			talking: "שיחה נוכחית בעיצומה",
			drawViolations: "הנחיית הציור של ה-AI מפרה כללים, אנא הכנס הנחיה תואמת.",
			exhausted: "מספר השאלות והתשובות מוצו, אנא עבור לקצה השעון וסרוק את קוד ה-QR להמשך.",
			serverBusy: "חיבור הרשת הנוכחי לא יציב, אנא נסה שוב",
			chatHistory: "היסטוריית שיחות",
			chatHistoryDesc: "תיעוד השאלות והתשובות נשמר למשך 3 ימים בלבד",
			todoList: "רשימת משימות",
			todoDesc: "פרטי משימות",
			todoListDesc: "יכולות להתקיים מקסימום 10 משימות",
			confirm: "אשר",
			cancel: "בטל",
			text: "טקסט לא יכול להיות ריק",
			cancelRemind: "בטל תזכורת",
			remindTime: "זמן תזכורת:",
			remindTitle: "כותרת תזכורת:",
			remindContent: "תוכן התזכורת:",
			edit: "ערוך",
			reachLimit: "הוספת משימות מקסימאלית הושגה",
			errorChat:"הקול עדיין לא זוהה, נסה שוב",
			      noSTT:"הרשאה לא הושגה, אנא הפעל הרשאה לפני שתמשיך.",
      error50:"אירעה שגיאה לא ידועה, אנא נסה שוב"
		},
		draw: {
			describe: "תאר את התמונה שלך",
			voice: "קלט קול",
			press: "לחץ כדי לדבר",
			placeholder: "תאר את התמונה שאתה רוצה ליצור",
			style: "סגנון ציור",
			styleList: ["סגנון דיו", "סייברפאנק", "אנימה", "אוריגמי", "סריגה", "גרפיקה", "קומיקס","לֶגוֹ","עִפָּרוֹן",  "ציור"],
			record: "היסטוריית ציורים",
			recordDesc: "ניתן לשמור עד 10 רשומות ציור",
			recordEmpty: "אין לך כרגע רשומות ציור",
			generate: "צור תמונה",
			result: "תוצאת יצירת התמונה",
			download: "הורד תמונה",
			regenerate: "צור מחדש",
			setDial: "הגדר כעיצוב שעון",
			error: "יצירת התמונה נכשלה",
			errorDesc: "חיבור הרשת לא יציב, אנא שלח שוב.",
			confirm: "אשר",
			analysiserror: "הניתוח נכשל",
      SetWatchErr:["החיוג הוגדר בהצלחה","בטל את השליחה","אימות הנתונים נכשל","קובץ הנתונים של פני השעון גדול מדי","סנכרון פני השעון הגיע לגבול העליון","Bluetooth נותק"],
      SetWatchTips:["ההגדרה נכשלה","הגדרת החיוג מתבצעת","לוח השעון נמצא בהגדרה, נא לא לצאת מהדף הנוכחי."],
      progressText:"סינכרון"

		},
sports: {
			analyze: "ניתוח בריאות",
      analyzeDesc: "נתח נתונים בסיסיים",
			advice: "טיפ בריאותי",
			adviceDesc: "מתן עצות בריאותיות",
			experience: "ניסיון",
			program: {
				plan: "תוכנית לירידה במשקל",
				startDate: "בחר תאריך התחלה",
				chooseDate: "יש לבחור יום אחד כתאריך התחלה",
				week: [{
					num: "א'",
					day: "ראשון"
				}, {
					num: "ב'",
					day: "שני"
				}, {
					num: "ג'",
					day: "שלישי"
				}, {
					num: "ד'",
					day: "רביעי"
				}, {
					num: "ה'",
					day: "חמישי"
				}, {
					num: "ו'",
					day: "שישי"
				}, {
					num: "ש'",
					day: "שבת"
				}, ],
				arrange: "סידור תוכנית",
        arrangeDesc: "התחל מהתאריך הקרוב ביותר שבחרת, סהכ 7 ימי אימון; התוכן הספציפי הוא כדלקמן:",				confirm: "אשר סידור",
				success: "ברכות, הצלחת להתאים את התוכנית בהצלחה! התחל את האימון שלך בדף 'צ'ק-אין לאימון'!",
				successButton: "עבור לצ'ק-אין לאימון",
			},
newmessage: {
				message1: ['מרכז אישי', 'נתוני בריאות'],
				message2: ['בריאות', 'אימון', 'דיאטה'],
				message3: ['מין', 'גיל', 'גובה', 'משקל'],
				message4: ['שינה', 'קצב לב', 'חמצן', 'צעדים', 'לחץ דם', 'לחץ', 'סיכום'],
				message5: ['צריכה', 'המלצה', 'צעדים היום',
					'משך האימון '],
          message6: ['תרגיל אגרוף פנימה','תוכנית כושר (הסתגלות ביתית)',
            'תוכנית כושר (Pro)','תוכנית הרזיה'
          ],
				message7: ['התאמנו וחיו חיים בריאים',
					'התאם אישית את תוכנית הכושר שלך', 'התאם אישית את תוכנית הכושר שלך',
					'צרו תוכנית לירידה במשקל עבורך'
				],
				message8: ['קק"ל', 'צעדים', 'דקות', 'סטים', 'ימים', 'שנים', 'ס"מ', 'ק"ג', 'שעות','שָׁבוּעַ '],
				message9: ['שעון','הדרכה','אפשרויות מימוש','ההישגים שלי','ההישגים שלי','לא נכנס'
				],
				message10: ['לחץ לניתוח', 'היסטוריית ניתוחים (נשמרות עד 10)', 'ניתוח',
					'אין לך כרגע רשומות היסטוריות', 'מספר ימי שעון רצופים', 'ניתוח נתונים'
				],
				message11: ['נקבה', 'זכר'],
				message12: ['ביטול', 'אישור'],
				message13: ['שנים', 'סנטימטרים', 'קילוגרמים'],
				message14: ['קלט ידני',  ' זמן צפוי (שבועות)', 'מין', 'גיל', 'גובה (סנטימטרים)',
					'משקל (קילוגרמים)', 'משקל מצופה (קילוגרמים)'
				],
				message15: ['היסטוריה', 'ניתוח נתוני בריאות', 'טיפ בריאותי'],
				message16: ['אגרוף פנימה בהצלחה', 'שעון נכנס',"ניתן לבצע צ'ק-אין באותו היום"],
				message17: ['ניתוח נתוני בריאות ניתן להשגה פעם ביום בלבד, ולא ניתן למצוא נתונים מקומיים', 'טיפים בריאותיים ניתנים לא רק פעם ביום, ולא ניתן למצוא נתונים מקומיים'],
				message18:['כרגע בתת משקל','מדד ה-BMI שלך הוא','לא מומלץ להמשיך לרדת במשקל (טווח בריא 18.5<BMI<24)'],
				message19:['ירידה מתונה במשקל','מומלץ לא לרדת יותר מ-500 גרם ביום'],
				message20:['יש תוכניות','האם היא מכסה תוכניות קיימות'],
				message21:['קל','רגיל','בינוני','גבוה'],
				message22:['רוח:', 'נמוך', 'גבוה'],
				message23:['מספר הפעמים נוצל קנה מנוי כדי לפתוח עוד פעמים. '],
			},
		},
		punch: {
			title: "בוצע",
			title2: "סיום",
			h1: "אפשרויות אימון",
			h2: "הישגיי",
			button: "צ'ק-אין",
			day: "ימי צ'ק-אין רצופים",
			dayunit: "ימים",
		},
eat: {
			title: "דיאטה",
			DietaryIntake: "אכילה",
			kcal: 'קק"ל',
			Stillingestible: "נותר",
			overingest: "מעבר",
			Recommendedintake: "המלצה",
			Motorconsumption: "תזוזה",
			carbohydrate: "פחמימות",
			protein: "חלבון",
			fat: "שומנים",
			gram: "גרמים",
			drinkDk: "צ'ק-אין למים",
			drinkDkhealth: "צ'ק-אין יומי לגוף בריא יותר",
			eatsuggest: "המלצות תזונה",
			analyzefoodglx: "לנתח את הרציונליות של הדיאטה",
			eatPlan: "תוכנית דיאטה",
			makeeatPlan: "תוכנית דיאטה בלעדית",
			breakfast: "ארוחת בוקר",
			lunch: "צהריים",
			dinner: "ארוחת ערב",
			snack: "חטיף",
			addFood: "הוסף מזון",
			noAddFood: "לא הוסף מזון",
			cancle: "ביטול",
			confirm: "אישור",
			tips: "המילוי היזום נחשב כהסכמה שלנו לאסוף את המידע של הפרויקט הזה ולהציגו בתזונה",
			addFooded: "מזון נוסף",
			added: "נוסף",
			numFood: "פריטי מזון",
			record: "תיעוד",
			dkTitle: "צ'ק-אין",
			dailygoal: "מטרה יומית",
			drinkDw: "כוסות/יום",
			drinkWater: "שתיית מים",
			dailygoalWater: "מטרה יומית לשתיית מים",
			myachievement: "הישגיי",
			lxdkDays: "ימי צ'ק-אין רצופים",
			day: "ימים",
			analyzeHistory: "היסטוריה",
			conclusion: "סיכום",
			eatDataAnalyze: "ניתוח נתוני דיאטה",
			selectStartDate: "בחר תאריך התחלה",
			chooseoneDate: "יש לבחור יום אחד כתאריך התחלה",
			scheduling: "סידור תוכנית",
			schedulingDesc: "7 ימים מהתאריך הקרוב ביותר שתבחר; להלן הפרטים:",
			eatDetail: "פרטי הדיאטה",
			analyzeFailed: "הניתוח נכשל",
			noData: "אין נתונים"


		}
	},
  error: {
    error1101:"חיבור הרשת אינו יציב, פסק זמן ההפעלה הנוכחי",
    error1102:"כרגע אין רשת, אנא התחבר מחדש",
    error1103:"אירעה שגיאה לא ידועה",
    error61:"השירות הנוכחי אינו זמין",
    error4:"השירות הנוכחי אינו זמין",
    error12:"אנא הזן מילים או משפטים סטנדרטיים כדי ש-AI יבין וצייר",
		error50:"מצטער, השרת הנוכחי טועה, אנא נסה שוב"
  },
	setting: {
		index: {
			title: "הגדרות",
			problem: "משוב בעיות",
			order: "תיעוד הזמנות",
			notice: "הודעות",
			language: "שפה",
			clear: "נקה מטמון",
			clearTip: "האם אתה בטוח שברצונך\nלנקות את המטמון?",
			confirm: "אישור",
			cancel: "ביטול",
		},
		language: {
			title: "שפה",
			langList: ["סינית פשוטה", "סינית מסורתית", "אנגלית", "ערבית", "בולגרית", "בנגלית", "צ'כית", "גרמנית", "יוונית", "ספרדית", "פרסית", "פיליפינית", "צרפתית", "הינדית", "הונגרית", "אינדונזית", "איטלקית", "עברית", "יפנית", "קוריאנית", "מלאית", "הולנדית", "פנג'אבי", "פולנית", "פורטוגזית", "רומנית", "רוסית", "סלובקית", "תאילנדית", "טורקית", "אוקראינית", "אורדו", "וייטנאמית"],
		},
		order: {
			title: "תיעוד הזמנות",
			success: "נמצא בהצלחה",
			unpaid: "לא שולם",
			paid: "שולם",
			expired: "פג תוקף",
			refunded: "הוחזר",
			pTime: "זמן תשלום",
			eTime: "זמן תפוגה",
			pMethod: "אמצעי תשלום",
			pObject: "מושא התשלום",
			oNumber: "מספר הזמנה",
			pType: "סוג חבילה",
			oTime: "זמן מציאה",
			watch: "הצג IMEI",
			delete: "מחק הזמנה",
			refund: "בקש החזר",
		},
problem: {
			title: "משוב בעיות",
			feedback: "סוג משוב",
			please: "בבקשה בחר",
			content: "תוכן",
			improve: "אנא כתוב את ההמלצות שלך, כגון בקשות למאפיינים, תלונות על המוצר וכו'. נשתדל לשפר",
			upload: "העלה תמונה",
			submit: "שלח",
			general: "משוב כללי",
			purchase: "קשור לרכישה",
			bug: "דיווח על באגים",
			suggestion: "המלצה",
			other: "אחר",
		},
		notice: {
			title: "הודעות",
			type: "סוג הודעה: ",
			time: "זמן הודעה: "
		},
		unsubscribe: {
			watch: "שעון:",
			title: "מנוי רציף",
			subTitle: "מנוי",
			unsubscribe: "בטל מנוי",
			packList: ["מנוי פרימיום", "מנוי בסיסי", "מנוי סטנדרטי"],
		},
		refund: {
			title: "בקש החזר",
			number: "מספר הזמנה",
			content: "אנא הסבר את סיבת ההחזר",
			packList: ["חבילת הנחה למשתמש חדש", "חבילת 50 פעמים", "חבילת 100 פעמים",
				"חבילת 300 פעמים"
			],
		}
	},
	tutorial: {
		index: {
			title: "קבל הדרכה מתקדמת",
			watchList: ["הפעל את השעון, מצא את אפליקציית WearAI ולחץ לפתוח.",
				"לחץ על כפתור ההטבות המתקדמות בעמוד הבית.",
				"הוצא את הטלפון הנייד וסרוק את קוד ה-QR על השעון כדי להיכנס לעמוד ההטבות."
			]
		}
	},
}