module.exports = {
  language: "ខ្មែរ",
  navigator: {
    tools: "ឧបករណ៍ AI",
    draw: "គូរ",
    healthy: "សុខភាព",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["ផ្ដល់អនុសាសន៍", "សិក្សា", "ជីវិត", "កសាង", "ការងារ"],
      presetGreet:
        "ហេ ជំរាបសួរ ~ ខ្ញុំជាជំនួយការសុខភាពតូចរបស់អ្នក តែងតែត្រៀមផ្តល់ជូនអ្នកនូវចំណេះដឹងសុខភាពដែលគួរឱ្យចាប់អារម្មណ៍ និងមានប្រយោជន៍ និងការពារសុខភាពរបស់អ្នកទាំងអស់គ្នា!",
      presetQuiz: [
        "អ្វីទៅជា​មូលហេតុ​នៃ​ការបំពុល​រំខាន?",
        "យើងធ្វើដូចម្តេចដើម្បីការពារការបានច្រើននៃជំងឺ​គំនិត?",
        "តើធ្វើដូចម្តេចដើម្បីរក្សានូវអាហារពេញនិយម?",
        "តើធ្វើដូចម្តេចដើម្បីរក្សានូវគេងល្អ?",
      ],
      presets: "អ្នកអាចសួរ​ខ្ញុំ:",
      change: "ផ្លាស់ប្តូរ",
      uploadImage: "ផ្ញើរូបភាព",
      footer:
        "ដោយមិនដែលមែនទែនថាអ្នកបង្កើតមាតិកាគឺជាគំរូ AI មិនអាចធានាបានទាំងស្រុង។",
      remind: "ការពិតត្រូវក្នុងកិច្ច",
      limit:
        "ការប្រាស្រ័យទាក់ទងនេះបានឈានដល់កំណត់ហើយ, សូមចាប់ផ្តើមសន្ទនា AI ថ្មី",
      newDialog: "ចាប់ផ្តើមសន្ទនាថ្មី",
      stopRecording: "ចុចទីណាមួយឬនៅពេលចុះរាប់ម៉ោងចាត់ការទទួលស្គាល់",
      send: "ផ្ញើសារទៅ",
      say: "ចុចដើម្បីនិយាយ",
      notImage: "អ្នកប្រើផ្នែកនេះមិនគាំទ្រដើម្បីផ្ញើឯកសារ និងរូបភាព",
      talking: "ការសន្ទនា​បច្ចុប្បន្នកំពុងចាប់ផ្តើម",
      drawViolations:
        "ការផ្តល់ស្នាដៃ AI ខុសនីតិវិធី, សូមបញ្ចូលស្នាដៃដែលនឹងអនុលោមនីតិវិធី។",
      exhausted:
        "ចំនួន Q&A បានផ្ទេរ, សូមចូលទៅក្នុងការ​ដែលត្រូវមើលចប់ ហើយស្កេន កូដ QR ដើម្បីបន្ត។",
      serverBusy: "លីងសាកល្បងបច្ចុប្បន្នមិនមានស្ថានភាពប្រសើរ, សូមសាកល្បងម្តងទៀត",
      chatHistory: "ប្រវិត្តសន្ទនា",
      chatHistoryDesc: "កំណត់ត្រា Q&A នឹងរក្សាទុកសម្រាប់ ៣ ថ្ងៃប៉ុណ្ណោះ",
      todoList: "បញ្ជី Todo",
      todoDesc: "លម្អិត Todo",
      todoListDesc: "អាចមាន Todo ថែមទឹក ១០",
      confirm: "បញ្ជាក់",
      cancel: "បោះបង់",
      text: "អត្ថបទមិនអាចទទេ",
      cancelRemind: "បោះបង់ការរំភ្រើន",
      remindTime: "ពេលវេលារំលឹក៖:",
      remindTitle: "ចំណងជើង​ការរំភ្រើន:",
      remindContent: "មាតិកាការរំភ្រើន:",
      edit: "កែតម្រូវ",
      reachLimit: "បានបញ្ចូលដែលដល់កំណត់",
      errorChat:"មិនពិនិត្យឮសម្លេងទេ, សូមសាកល្បងម្តងទៀត",
            noSTT:"មិនបានទទួលការអនុញ្ញាតទេ សូមបើកការអនុញ្ញាតមុននឹងបន្ត។",
      error50:"កំហុសមិនស្គាល់បានកើតឡើង សូមព្យាយាមម្តងទៀត"
    },
draw: {
      describe: "ពិពណ៌នារូបភាពរបស់អ្នក",
      voice: "បញ្ចូលដោយសំឡេង",
      press: "ចុចដើម្បីនិយាយ",
      placeholder: "ពិពណ៌នារូបភាពដែលអ្នកចង់បង្កើត",
      style: "រចនាសម្ព័ន្ធស្លាប",
      styleList: [
        "រចនាបថច្រាស",
        "Cyberpunk",
        "សត្វ",
 "អូរីហ្គាមី",
        "ការបូល",
        "ក្រាហ្វិក",
        "ការកំប៉ុង",
        "ឡេហ្គោ",
        "ប៊ិច",
        "គូរ",
      ],
      record: "កំណត់ត្រាស្នាដៃ",
      recordDesc: "អាចរក្សាស្នាដៃបានមែនទេ ១០",
      recordEmpty: "អ្នកមិនទាន់មានកំណត់ត្រាស្នាដៃទេ",
      generate: "ប្រើរូបភាព",
      result: "លទ្ធផលនៃការបង្កើតរូបភាព",
      download: "ទាញយករូបភាព",
      regenerate: "ឧបត្ថម្ភឡើងវិញ",
      setDial: "កំណត់ជាសាឡ័ន",
      error: "ការបង្កើតរូបភាពបានបរាជ័យ",
      errorDesc: "ការតភ្ជាប់បណ្ដាញមិនមានស្ថានភាពសុខស្រួល, សូមដាក់ស្នើម្តងទៀត។",
      confirm: "បញ្ជាក់",
      analysiserror: "ការវិភាគបានបរាជ័យ",
      SetWatchErr:["កំណត់ការហៅទូរសព្ទដោយជោគជ័យ","កំណត់ការហៅទូរសព្ទដោយជោគជ័យ","ការផ្ទៀងផ្ទាត់ទិន្នន័យបានបរាជ័យ","ឯកសារទិន្នន័យមុខនាឡិកាធំពេក","ការធ្វើសមកាលកម្មមុខនាឡិកាបានឈានដល់ដែនកំណត់ខាងលើ","ប៊្លូធូសត្រូវបានផ្តាច់"],
      SetWatchTips:["ការដំឡើងបរាជ័យ","ការដំឡើងបរាជ័យ","មុខនាឡិកាកំពុងត្រូវបានកំណត់ សូមកុំចេញពីទំព័របច្ចុប្បន្ន។"],
      progressText:"ធ្វើសមកាលកម្ម"

    },
    sports: {
      analyze: "ការវិភាគសុខភាព",
      analyzeDesc: "វិភាគទិន្នន័យមូលដ្ឋាន",
      advice: "គន្លឹះសុខភាព",
      adviceDesc: "ផ្តល់គន្លឹះសុខភាព",
      experience: "បទពិសោធន៍",
      program: {
        plan: "ការរៀបចំការកាត់ទម្ងន់",
        startDate: "ជ្រើសរើសថ្ងៃចាប់ផ្តើម",
        chooseDate: "សូមជ្រើសរើស១ថ្ងៃជាថ្ងៃចាប់ផ្តើម",
        week: [
          {
            num: "អាទិត្យ",
            day: "ថ្ងៃអាទិត្យ",
          },
          {
            num: "ចន្ទ",
            day: "ថ្ងៃច័ន្ទ",
          },
          {
            num: "អង្គារ",
            day: "ថ្ងៃអង្គារ",
          },
          {
            num: "ពុធ",
            day: "ថ្ងៃពុធ",
          },
          {
            num: "ព្រហស្បតិ៍",
            day: "ថ្ងៃព្រហស្បតិ៍",
          },
          {
            num: "សុក្រ",
            day: "ថ្ងៃសុក្រ",
          },
          {
            num: "សៅរ៍",
            day: "ថ្ងៃសៅរ៍",
          },
        ],
arrange: "ការរៀបចំផែនការ",
        arrangeDesc:
          "ចាប់ផ្តើមពីថ្ងៃដែលជួយនៅជិតបំផុតដែលអ្នកជ្រើស, មានសរុប ៧ ថ្ងៃហាត់ប្រាណ; មាតិកាធម្មតាមានដូចខាងក្រោម:",
        confirm: "បញ្ជាក់ការរៀបចំ",
        success:
          "អបអរសាទរ, អ្នកបានគ្រប់គ្រាន់ក្នុងការប្ដូរផែនការ! ចូរសាច់ដិតហាត់នៅលើទំព័រ 'ចុះឈ្មោះហាត់ប្រាណ'!",
        successButton: "ទៅកាន់ចុះឈ្មោះហាត់ប្រាណ",
      },
      newmessage: {
        message1: ["មជ្ឈមណ្ឌលផ្ទាល់ខ្លួន", "ទិន្នន័យសុខភាព"],
        message2: ["សុខភាព", "ហាត់ប្រាណ", "អាហារ"],
        message3: ["ភេទ", "អាយុ", "កម្ពស់", "ទំងន់"],
        message4: [
          "គេង",
          "HR",
          "អុកស៊ីសែន",
          "ជំហាន",
          "BP",
          "សម្ពាធ",
          "សង្ខេប",
        ],
        message5: ["ចំណាយ", "សូមផ្តល់អនុសាសន៍", "ជំហានថ្ងៃនេះ", "កាលបរិច្ឆេទហាត់ប្រាណ"],
        message6: [
          "ចុះឈ្មោះហាត់ប្រាណ",
          "កម្រិតហាត់ប្រាណជាជាង",
          "Fitness Pro",
          "កាយប្លុកកាត់ទម្ងន់",
        ],
        message7: [
          "ហាត់ប្រាណ និងរស់នៅជីវិតសុខភាព",
          "កែច្នៃផែនការហាត់ប្រាណរបស់អ្នក",
          "កែច្នៃផែនការហាត់ប្រាណរបស់អ្នក",
          "បង្កើតផែនការកាត់ទម្ងន់សម្រាប់អ្នក",
        ],
        message8: [
          "Kcal",
          "ជំហាន",
          "នាទី",
          "កំណត់",
          "ថ្ងៃ",
          "ឆ្នាំ",
          "cm",
          "kg",
          "h","សប្តាហ៍"
        ],
        message9: [
          "ចុះឈ្មោះ",
          "ហាត់ប្រាណ",
          "ជម្រើសហាត់ប្រាជ្ញ",
          "បានបង្កើតឲ្យខ្ញុំ",
          "ចុះឈ្មោះហាត់ប្រាណជាបន្តបន្ទាប់មក",
          "មិនបានចុះឈ្មោះ",
        ],
        message10: [
          "ចុចដើម្បីវិភាគ",
          "បណ្ណសារវិភាគ (រហូតដល់ ១០ រក្សាទុក)",
          "វិភាគ",
          "អ្នកមិនទាន់មានកំណត់ត្រាប្រវត្តិទេ",
          "ចុះឈ្មោះហាត់ប្រាណជាបន្តបន្ទាប់",
          "ការវិភាគទិន្នន័យ",
        ],
message11: ["ស្រី", "ប្រុស"],
        message12: ["បោះបង់", "បញ្ជាក់"],
        message13: ["ឆ្នាំ", "សង់ទីមេត្រ", "គីឡូក្រាម"],
        message14: [
          "បញ្ចូលដោយដៃ",
          "ពេលវេលារង់ចាំ (សប្តាហ៍)",
          "ភេទ",
          "អាយុ",
          "កម្ពស់ (សង់ទីមេត្រ)",
          "ទំងន់ (គីឡូក្រាម)",
          "ទំងន់ដែលរំពឹង (គីឡូក្រាម)",
        ],
        message15: ["ប្រវិត្តិ", "វិភាគទិន្នន័យសុខភាព", "គន្លឹះសុខភាព"],
        message16: [
          "ចុះឈ្មោះបានជោគជ័យ",
          "កំពុងចុះឈ្មោះ",
          "អាចចុះឈ្មោះបានតែមួយថ្ងៃ",
        ],
        message17: [
          "ការវិភាគទិន្នន័យសុខភាពអាចត្រូវបានទទួលត្រឹមមួយថ្ងៃ, ហើយទិន្នន័យក្នុងតំបន់មិនអាចរកឃើញបាន",
          "គន្លឹះសុខភាពអាចត្រូវបានទទួលត្រឹមមួយថ្ងៃ, ហើយទិន្នន័យក្នុងតំបន់មិនអាចរកឃើញបាន",
        ],
        message18: [
          "បច្ចុប្បន្ននេះមានទំងន់តិចជាង",
          "ប្រតិកម្ម BMI របស់អ្នកគឺ",
          "មិនគួរគិតធ្វើបន្តពេលឆក់ទម្ងន់ទៀតទេ (មធ្យមសុខភាព 18.5<BMI<24)",
        ],
        message19: [
          "ការកាត់ទម្ងន់មធ្យម",
          "មានការផ្តល់អនុសាសន៍ឲ្យកាត់មិនឲ្យលើស 500g ក្នុងមួយថ្ងៃ",
        ],
        message20: ["មានផែនការ", "តើវាបញ្ចូលជាមួយផែនការដែលមានទេ"],
        message21: ["ងាយ", "ធម្មតា", "មធ្យម", "ខ្ពស់"],
        message22: ["ស្បែក:", "ទាប", "ខ្ពស់"],
		message23: ["ចំនួនដងបានប្រើហើយ។ សូមទិញការកក់សម្រាប់បើកការប្រើប្រាស់បន្ថែម"], 
      },
    },
punch: {
      title: "សម្រេច",
      title2: "បញ្ចប់",
      h1: "ជម្រើសហាត់ប្រាណ",
      h2: "ការសម្រេចខ្លួនរបស់ខ្ញុំ",
      button: "ចុះឈ្មោះ",
      day: "ចុះឈ្មោះជាបន្តបន្ទាប់",
      dayunit: "ថ្ងៃ",
    },
    eat: {
      title: "អាហារ",
      DietaryIntake: "អាហារទទួល",
      kcal: "Kcal",
      Stillingestible: "សានសំគាល់",
      overingest: "លើស",
      Recommendedintake: "សូមផ្តល់អនុសាសន៍",
      Motorconsumption: "ចលនាដែលក្នុង",
      carbohydrate: "កាបូហួត",
      protein: "ព្រូតេអ៊ីន",
      fat: "ខ្លាញ់",
      gram: "ក្រាម",
      drinkDk: "ចុះឈ្មោះទឹក",
      drinkDkhealth: "ចុះឈ្មោះប្រចាំថ្ងៃសម្រាប់រាងកាយសុខភាព",
      eatsuggest: "គំនិតអាហារ",
      analyzefoodglx: "វិភាគពីគុណភាពអាហារ",
      eatPlan: "ផែនការ​អាហារ",
      makeeatPlan: "កែច្នៃផែនការអាហារផ្ទាល់ខ្លួន",
      breakfast: "អាហារ​ព្រេង",
      lunch: "អាហារថ្ងៃត្រង់",
      dinner: "អាហារពេលយប់",
      snack: "ស៊ីជាតិ",
      addFood: "បញ្ចូលអាហារ",
      noAddFood: "មិនមានអាហារបន្ថែម",
      cancle: "បោះបង់",
      confirm: "បញ្ជាក់",
      tips: "ការបំពេញដោយសកម្មនឹងត្រូវបានតកំណត់ថាជាច្បាប់អនុញ្ញាតសម្រាប់យើងឲ្យប្រមូលព័ត៌មាននេះនិងបង្ហាញវានៅក្នុងអាហារ",
      addFooded: "អាហារបន្ថែម",
      added: "បានបន្ថែម",
      numFood: "ត្រូវការអាហារ",
      record: "កំណត់ត្រា",
      dkTitle: "ចុះឈ្មោះ",
      dailygoal: "គោលបំណងប្រចាំថ្ងៃ",
      drinkDw: "កែវ/ថ្ងៃ",
      drinkWater: "ទឹកផឹក",
      dailygoalWater: "គោលបំណងភ្លៀងទឹកប្រចាំថ្ងៃ",
      myachievement: "ការសម្រេចខ្លួនរបស់ខ្ញុំ",
      lxdkDays: "ថ្ងៃចុះឈ្មោះដូចគ្នា",
      day: "ថ្ងៃ",
      analyzeHistory: "ប្រវត្តិបន្ទាន់",
      conclusion: "សង្ខេប",
      eatDataAnalyze: "ការវិភាគទិន្នន័យអាហារ",
      selectStartDate: "ជ្រើសរើសថ្ងៃចាប់ផ្តើម",
      chooseoneDate: "សូមជ្រើសរើស១ថ្ងៃជាថ្ងៃចាប់ផ្តើម",
      scheduling: "ការរៀបចំផែនការ",
      schedulingDesc:
        "ចាប់ផ្តើមពីថ្ងៃដែលជួយនៅជិតបំផុតដែលអ្នកជ្រើស, មានសរុប ៧ ថ្ងៃ; មាតិកាធម្មតាមានដូចខាងក្រោម:",
      eatDetail: "លម្អិតអាហារ",
      analyzeFailed: "ការវិភាគបានបរាជ័យ",
      noData: "មិនមានទិន្នន័យ",
    },
  },
  error: {
    error1101:"ការភ្ជាប់បណ្តាញគឺមិនស្ថិតស្ថេរដែលជាអស់ពេលសម័យបច្ចុប្បន្ន",
    error1102:"បច្ចុប្បន្នមិនមានបណ្តាញទេ សូមភ្ជាប់ឡើងវិញ",
    error1103:"កំហុសមិនស្គាល់បានកើតឡើង",
    error61:"សេវាកម្មបច្ចុប្បន្នមិនអាចប្រើបានទេ។",
    error4:"សំឡេងមិនទាន់ត្រូវបានទទួលស្គាល់នៅឡើយទេ សូមព្យាយាមម្តងទៀត",
    error12:"សូមបញ្ចូលពាក្យ ឬប្រយោគស្តង់ដារសម្រាប់ AI ដើម្បីយល់ និងគូរ",
    error50:"សូមអភ័យទោស មានកំហុសនៅលើម៉ាស៊ីនមេបច្ចុប្បន្ន សូមព្យាយាមម្តងទៀត"

  },
  setting: {
    index: {
      title: "ការកំណត់",
      problem: "មីយ៉ាបញ្ហា",
      order: "កំណត់ត្រាការបញ្ជាទិញ",
      notice: "អزابជូនដំណឹង",
      language: "ភាសា",
      clear: "លុបCache",
      clearTip: "តើអ្នកប្រាកដថាចង់\nសម្អាតឃ្លាំងសម្ងាត់មែនទេ?",
      confirm: "បញ្ជាក់",
      cancel: "បោះបង់",
    },
 language: {
      title: "ភាសា",
      langList: [
        "ភាសាចូវអ៊ីនទិន",
        "ភាសាជាន់ខ្ពស់",
        "អង់គ្លេស",
        "អារ៉ាប់",
        "ភ្លូយ",
        "ភេស៊ី",
        "ដាណីស",
        "អាល្លឺម៉ង់",
        "រុស្ស៊ី",
        "ហ្វ្រាំង",
        "ហូឡង់",
        "ចេក",
        "រ៉ូម៉ានី",
        "ប៉ុរទូកីស",
        "ជប៉ុន",
        "ថៃ",
        "តួកគី",
        "ស్పាណ៉ូល",
        "ក្រិក",
        "អ៊ីតាលី",
        "វៀតណាម",
        "ហ៊ីនឌី",
      ],
    },
    order: {
      title: "កំណត់ត្រាការបញ្ជាទិញ",
      success: "បានទទួលជោគជ័យ",
      unpaid: "មិនបានបង់ប្រាក់",
      paid: "បានបង់ប្រាក់",
      expired: "បានផុតកំណត់",
      refunded: "បានស្ទង់ប្រាក់",
      pTime: "ម៉ោងបង់ប្រាក់",
      eTime: "ម៉ោងផុតកំណត់",
      pMethod: "របៀបបង់ប្រាក់",
      pObject: "វត្ថុបង់ប្រាក់",
      oNumber: "លេខការបញ្ជាទិញ",
      pType: "ប្រភេទកញ្ចប់",
      oTime: "ម៉ោងទទួល",
      watch: "មើល IMEI",
      delete: "លុបការបញ្ជាទិញ",
      refund: "ស្នើសុំស្ទង់ប្រាក់",
    },
    problem: {
      title: "មីយ៉ាបញ្ហា",
      feedback: "ប្រភេទមីយ៉ា",
      please: "សូមជ្រើសរើស",
      content: "មាតិកា",
      improve:
        "សូមសរសេរពីយោបល់របស់អ្នក ដូចជា ការស្នើសុំមុខងារ ការពិបាក​ក្នុងផលិតផលនៅលើស្តង់ ឬផ្សេងៗទៀត។ យើងនឹងប្រឹងប្រែងដើម្បីធ្វើអោយប្រសើរ",
      upload: "បញ្ជូលរូបភាព",
      submit: "ដាក់បញ្ចូល",
      general: "មីយ៉ាទូទៅ",
      purchase: "ទាក់ទងនឹងការទិញ",
      bug: "ការប្រើប្រាស់ចេញ",
      suggestion: "យោបល់",
      other: "ផ្សេងទៀត",
    },
notice: {
      title: "ការជូនដំណឹង",
      type: "ប្រភេទការជូនដំណឹង: ",
      time: "ពេលវេលាការជូនដំណឹង: ",
    },
    unsubscribe: {
      watch: "នាឡិកា:",
      title: "ការធានាដោយចាំ​បាច់",
      subTitle: "បានជាវ",
      unsubscribe: "បោះបង់ការជាវ",
      packList: [
        "ការជាវថ្លៃឯកត្តសិទ្ធ",
        "ការជាវមូលដ្ឋាន",
        "ការជាវធម្មតា",
      ],
    },
    refund: {
      title: "ស្នើសុំស្ទង់ប្រាក់",
      number: "លេខការបញ្ជាទិញ",
      content: "សូមពន្យល់មូលហេតុនៃការស្ទង់ប្រាក់",
      packList: [
        "កញ្ចប់បញ្ចុះតម្លៃអ្នកប្រើថ្មី",
        "កញ្ចប់ 50 ដង",
        "កញ្ចប់ 100 ដង",
        "កញ្ចប់ 300 ដង",
      ],
    },
  },
  tutorial: {
    index: {
      title: "យកមគ្គុទេសក์ប្រសើរ",
      watchList: [
        "បើកនាឡិកា រកយ៉ាងWearAI ហើយចុចដើម្បីបើក។",
        "ចុចលើប៊ូតុងអត្ថប្រយោជន៍ដ៏សំខាន់នៅលើទំព័រមូលដ្ឋាន។",
        "យកទូរសព្ទដៃរបស់អ្នក និងស្កេន QR Code នៅលើនាឡិកាដើម្បីចូលទៅកាន់ទំព័រ​អត្ថប្រយោជន៍។",
      ],
    },
  },
}