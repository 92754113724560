<template>
    <div>
        <div class="container">
            <keep-alive >
                <router-view></router-view>
            </keep-alive>
        </div>
        <div class="navbar">
            <div :class="['nav-item',isActive('/')?'active':'']" @click="jumpGPT">
                <img src="@/assets/img/navbar/icon_h_aitool@2x.png" alt="">
                <p>{{ $t('navigator.tools') }}</p>
            </div>
            <div :class="['nav-item',isActive('/draw')?'active':'']" @click="jumpDraw">
                <img src="@/assets/img/navbar/icon_draw@2x.png" alt="">
                <p>{{ $t('navigator.draw') }}</p>
            </div>
            <!-- <div :class="['nav-item',isActive('/healthy')?'active':'']"  @click="jumpHealthy">
                <img src="@/assets/img/navbar/icon_h_healthy@2x.png" alt="">
                <p>{{ $t('navigator.healthy') }}</p> 
            </div> -->

        </div>
    </div>
</template>

<script>
export default {
    name: 'navbar',

    data() {
        return {
            
        };
    },
    activated() {
    },
    methods: {
        isActive (path) {
            // 判断当前路由是否激活，如果是则添加类名，否则不添加
            return this.$route.path === path
        },
        // isNone() {
        //     // sdk 1.1.1 显示扫描
        //     if(this.isVersionGreater(this.getSdkVersion,'1.1.1')) {
        //         return false;
        //     }else {
        //         return true;
        //     }
        // },
        // isVersionGreater(versionA, versionB) {
        //     // 将版本号转换为数字数组，并移除任何的前导零
        //     const partsA = versionA.split('.').map(part => parseInt(part, 10));
        //     const partsB = versionB.split('.').map(part => parseInt(part, 10));
        //     // 遍历数组，比较每一个部分
        //     for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
        //         const numA = partsA[i] || 0;
        //         const numB = partsB[i] || 0;
        //         if (numA > numB) return true; // 版本A大于版本B
        //         if (numB > numA) return false; // 版本B大于等于版本A
        //     }
        //     // 如果经过上面的比较版本号相同，则返回true
        //     return true;
        // },
        jumpGPT(){
            this.$router.push("/");
        },
        jumpDraw() {
            this.$router.push("/draw");
        },
        jumpHealthy() {
            this.$router.push("/healthy");
        },

    },
};
</script>

<style lang="scss" scoped>

.container {
    top: 0;
    bottom: 17vw;
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
}
.navbar {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 13.6vw;
    background-color: #ffffff;
    .nav-item {
        width: 50vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        filter: grayscale(100%);
        &.active {
            filter: none;
            p {
                color: #37cf6d;
            }
        }
        img {
            width: 7vw;
            height: 7vw;
        }
        p {
            font-size: 2.5vw;
            margin: 0;
            color: gray;
        }
    }
}

</style>