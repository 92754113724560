module.exports = {
  language: "中文",
  navigator: {
    tools: "AI工具",
    draw: "画图",
    healthy: "健康",
  },
  aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["推荐", "学习", "生活", "创作", "工作"],
      presetGreet:
        "嘿，你好呀~我是你的健康小管家，随时准备为你科普有趣又有用的健康知识，一起守护你的健康！",
      presetQuiz: [
        "长期疲劳的原因有哪些？",
        "年轻人如何预防慢性病？",
        "怎么保持健康的饮食习惯?",
        "怎么保持好的睡眠？",
      ],
      presets: "您可以向我提问：",
      change: "换一批",
      uploadImage: "发送图片",
      footer: "由于内容是由AI模型生成的，因此无法完全保证真实性。",
      remind: "待办提醒",
      limit: "此对话已达到限制，请开始新的 Al 聊天会话",
      newDialog: "开启新对话",
      stopRecording: "点击任意位置或倒计时结束时识别",
      send: "发送消息",
      say: "点击说话",
      notImage: "该智能体不支持文件和图片发送",
      talking: "当前对话进行中",
      drawViolations: "AI绘制提示词违反规定，请输入符合规定的提示词。",
      exhausted: "问答次数已使用完毕，请去手表端，扫描二维码即可继续使用。",
      serverBusy: "当前网络链接不稳定，请再试一次",
      chatHistory: "聊天记录",
      chatHistoryDesc: "问答记录仅保存3天",
      todoList: "待办清单",
      todoDesc: "待办详情",
      todoListDesc: "待办清单最多存在10条",
      confirm: "确定",
      cancel: "取消",
      text: "文本不能为空",
      cancelRemind: "取消提醒",
      remindTime: "提醒时间：",
      remindTitle: "提醒标题：",
      remindContent: "提醒内容：",
      edit: "编辑",
      reachLimit: "添加已达上限",
      errorChat:"暂未识别到语音，请再试一次",
      noSTT:"未获取到权限，请开启权限后继续操作",
      error50:"发生了未知错误，请再试一次"
    },
    draw: {
      describe: "描述一下你的图片",
      voice: "语音输入",
      press: "按住说话",
      placeholder: " 描述你想要生成的图像",
      style: "绘画风格",
      styleList: [
        "水墨风格",
        "赛博朋克",
        "动漫风格",
        "折纸风格",
        "针织风格",
        "平面动画风格",
        "3D卡通风格",
        "乐高风格",
        "铅笔画",
        "画图",
      ],
      record: "绘画记录",
      recordDesc: "最多可保存10张绘图记录",
      recordEmpty: "您当前还没有绘图记录",
      generate: "生成图片",
      result: "生图结果",
      download: "下载图片",
      regenerate: "重新生成",
      setDial: "设置为表盘",
      error: "图片生成失败",
      errorDesc: "网络连接不稳定，请重新提交。",
      confirm: "确定",
      analysiserror: "分析失败",
      SetWatchErr:["表盘设置成功","取消发送","数据校验失败","表盘数据文件太大","表盘同步已达上限","蓝牙已断开连接"],
      SetWatchTips:["设置失败","表盘设置中","正在设置表盘，请勿退出当前页面"],
      progressText:"同步中"
    },
    sports: {
      analyze: "健康数据分析",
      analyzeDesc: "分析你的基本数据情况",
      advice: "健康建议",
      adviceDesc: "提供合理的健康建议",
      experience: "立即体验",
      program: {
        plan: "减肥计划",
        startDate: "选择开始日期",
        chooseDate: "请选择1天作为开始日期",
        week: [
          { num: "日", day: "周日" },
          { num: "一", day: "周一" },
          { num: "二", day: "周二" },
          { num: "三", day: "周三" },
          { num: "四", day: "周四" },
          { num: "五", day: "周五" },
          { num: "六", day: "周六" },
        ],
        arrange: "计划安排",
        arrangeDesc: "从你选择的最近的日期开始，共7个训练日；具体内容如下:",
        confirm: "确认编排",
        success: "恭喜你成功定制计划在“运动打卡”页开始你的训练吧！",
        successButton: "进入运动打卡",
      },
      newmessage: {
        message1: ["个人中心", "健康数据"],
        message2: ["健康", "运动", "饮食"],
        message3: ["性别", "年龄", "身高", "体重"],
        message4: ["睡眠", "心率", "血氧", "步数", "血压", "压力", "总结"],
        message5: ["完成情况", "推荐消耗", "今日步数", "运动时长"],
        message6: [
          "运动打卡",
          "健身计划(家庭适应版)",
          "健身计划(专业版)",
          "减肥计划",
        ],
        message7: [
          "坚持每日运动，健康生活每一天",
          "定制你的专属健身方案 ",
          "定制你的专属健身方案 ",
          "定制你的专属减肥方案 ",
        ],
        message8: [
          "千卡",
          "步",
          "分钟",
          "组",
          "天",
          "岁",
          "厘米",
          "千克",
          "小时",
		  "周",
        ],
        message9: [
          "打卡",
          "训练",
          "运动选择",
          "我的成就",
          "连续打卡天数",
          "未打卡",
        ],
        message10: [
          "点击分析",
          "分析历史(最多保存10个)",
          "分析",
          "您当前还没有历史记录",
          "连续打卡天数",
          "数据分析",
        ],
        message11: ["女", "男"],
        message12: ["取消", "确认"],
        message13: ["岁", "厘米", "千克"],
        message14: [
          "手动输入",
          "预期时间（周）",
          "性别",
          "年龄",
          "身高（厘米）",
          "体重（千克）",
          "预期体重（千克）",
        ],
        message15: ["历史", "健康数据分析", "健康建议"],
        message16: ["打卡成功", "打卡中", "只能当日打卡"],
        message17: [
          "健康数据分析只能每日获取一次,找不到本地数据",
          "健康建议只能每日获取一次,找不到本地数据",
        ],
        message18: [
          "目前体重过低",
          "您的BMI指数为",
          "不宜继续减肥(健康范围18.5<BMI<24)",
        ],
        message19: ["适度减重", "建议每日减重不多于500g"],
        message20: ["已有计划", "是否覆盖已有的计划"],
        message21: ["轻松", "正常", "中等", "偏高"],
        message22: ["单位:", "低压", "高压"],
		    message23: ["次数已使用完毕,购买订阅解锁更多次数"],
      },
    },
    punch: {
      title: "打卡",
      title2: "已打卡",
      h1: "运动选择",
      h2: "我的成就",
      button: "打卡",
      day: "连续打卡天数",
      dayunit: "天",
    },
    eat: {
      title: "饮食",
      DietaryIntake: "饮食摄入",
      kcal: "千卡",
      Stillingestible: "还可摄入",
      overingest: "多摄入了",
      Recommendedintake: "推荐摄入",
      Motorconsumption: "运动消耗",
      carbohydrate: "碳水",
      protein: "蛋白质",
      fat: "脂肪",
      gram: "克",
      drinkDk: "喝水打卡",
      drinkDkhealth: "每日打卡，身体更健康",
      eatsuggest: "饮食建议",
      analyzefoodglx: "分析摄入的饮食的合理性",
      eatPlan: "饮食计划",
      makeeatPlan: "定制你的专属饮食方案",
      breakfast: "早餐",
      lunch: "午餐",
      dinner: "晚餐",
      snack: "加餐",
      addFood: "添加饮食",
      noAddFood: "未添加饮食",
      cancle: "取消",
      confirm: "确定",
      tips: "主动填写即视为同意我们收集该项目信息并在饮食中展示",
      addFooded: "已添加食物",
      added: "已添加",
      numFood: "项食物",
      record: "记录",
      dkTitle: "打卡",
      dailygoal: "每日目标",
      drinkDw: "杯/天",
      drinkWater: "喝水",
      dailygoalWater: "每日喝水目标",
      myachievement: "我的成就",
      lxdkDays: "连续打卡天数",
      day: "天",
      analyzeHistory: "历史",
      conclusion: "总结",
      eatDataAnalyze: "饮食数据分析",
      selectStartDate: "选择开始日期",
      chooseoneDate: "请选择1天作为开始日期",
      scheduling: "计划安排",
      schedulingDesc: "从你选择的最近的日期开始，共7日;具体内容如下：",
      eatDetail: "饮食详情",
      analyzeFailed: "分析失败",
      noData: "暂无数据",
    },
  },
  error: {
    error1101:"网络连接不稳定，当前会话超时",
    error1102:"当前无网络，请重新连接",
    error1103:"发生未知错误",
    error61:"当前服务不可用",
    error4:"暂未识别到语音，请再试一次",
    error12:"请输入标准的单词或句子，以便AI理解和绘画",
    error50:"很抱歉，当前服务器出错，请再试一次"
  },
  setting: {
    index: {
      title: "设置",
      problem: "问题反馈",
      order: "订单记录",
      notice: "通知",
      language: "语言",
      clear: "清除缓存",
      clearTip: "你确定要\n清除缓存吗？",
      confirm: "确认",
      cancel: "取消",
    },
    language: {
      title: "语言",
      langList: [
        "中文简体",
        "中文繁体",
        "英语",
        "阿拉伯语",
        "保加利亚语",
        "孟加拉语",
        "捷克语",
        "德语",
        "希腊语",
        "西班牙语",
        "波斯语",
        "菲律宾语",
        "法语",
        "印地语",
        "匈牙利语",
        "印尼语",
        "意大利语",
        "希伯来语",
        "日语",
        "韩语",
        "马来语",
        "荷兰语",
        "旁遮普语",
        "波兰语",
        "葡萄牙语",
        "罗马尼亚语",
        "俄语",
        "斯洛伐克语",
        "泰语",
        "土耳其语",
        "乌克兰语",
        "乌尔都语",
        "越南语",
      ],
    },
    order: {
      title: "订单记录",
      success: "获取成功",
      unpaid: "未支付",
      paid: "已支付",
      expired: "已过期",
      refunded: "已退款",
      pTime: "付款时间",
      eTime: "过期时间",
      pMethod: "付款方式",
      pObject: "付款对象",
      oNumber: "订单编号",
      pType: "套餐类型",
      oTime: "获取时间",
      watch: "查看IMEI",
      delete: "删除订单",
      refund: "申请退款",
    },
    problem: {
      title: "问题反馈",
      feedback: "反馈类型",
      please: "请选择",
      content: "内容",
      improve: "请写下您的建议，如功能需求、产品投诉等，我们会努力改进",
      upload: "上传图片",
      submit: "提交",
      general: "一般反馈",
      purchase: "购买相关",
      bug: "漏洞报告",
      suggestion: "建议",
      other: "其他",
    },
    notice: {
      title: "通知",
      type: "通知类型: ",
      time: "通知时间: ",
    },
    unsubscribe: {
      watch: "手表：",
      title: "持续订阅",
      subTitle: "订阅中",
      unsubscribe: "取消订阅",
      packList: ["至尊订阅", "基础订阅", "普通订阅"],
    },
    refund: {
      title: "申请退款",
      number: "订单号",
      content: "请说明退款原因",
      packList: ["新用户优惠次数套餐", "50次套餐", "100次套餐", "300次套餐"],
    },
  },
  tutorial: {
    index: {
      title: "获取高级教程",
      watchList: [
        "打开手表，找到WearAI应用程序并点击打开。",
        "点击主页上的高级福利按钮。",
        "拿出您的手机并扫描手表上的二维码以进入福利页面。",
      ],
    },
  },
};
