module.exports = {
	language: "فارسی",
	navigator: {
		tools: "ابزار Ai",
		draw: "نقاشی",
		healthy: "سالم",
	},
	aitools: {
		chat: {
			gpt: "جارویس",
			categoryList: ["پیشنهاد", "مطالعه", "زندگی", "خلق", "کار"],
			presetGreet: "سلام، من سرپرست سلامت شما هستم، همیشه آماده‌ایم اطلاعات جالب و مفید درباره سلامت به شما بدهیم تا به حفظ سلامت شما کمک کنیم!",
			presetQuiz: ["دلایل خستگی مزمن چیست؟", "چگونه جوان‌ها می‌توانند از بیماری‌های مزمن پیشگیری کنند؟", "چگونه عادات غذایی سالمی داشته باشیم؟", "چگونه خواب خوبی داشته باشیم؟"],
			presets: "می‌توانید از من بپرسید:",
			change: "تغییر دسته",
			uploadImage: "ارسال تصویر",
			footer: "از آنجا که محتوا توسط یک مدل هوش مصنوعی تولید شده است، نمی‌توان به طور کامل از صحت آن اطمینان داشت.",
			remind: "یادآوری کارها",
			limit: "این گفتگو به حد خود رسیده است، لطفاً یک جلسه چت هوش مصنوعی جدید شروع کنید",
			newDialog: "شروع مکالمه جدید",
			stopRecording: "برای تشخیص کلیک کنید یا وقتی شمارش معکوس تمام شد",
			send: "ارسال پیام",
			say: "برای صحبت کلیک کنید",
			notImage: "این عامل از ارسال فایل‌ها و تصاویر پشتیبانی نمی‌کند",
			talking: "گفتگوی جاری در حال انجام است",
			drawViolations: "درخواست ترسیم AI از قوانین تجاوز کرده است، لطفاً یک درخواست مطابقت وارد کنید.",
			exhausted: "تعداد سؤالات و پاسخ‌ها تمام شده است، لطفاً به پایان تماشا بروید و کد QR را اسکن کنید تا ادامه دهید.",
			serverBusy: "ارتباط شبکه فعلی ناپایدار است، لطفاً دوباره تلاش کنید",
			chatHistory: "سابقه چت",
			chatHistoryDesc: "سوابق سؤالات و پاسخ‌ها فقط برای ۳ روز نگهداری می‌شوند",
			todoList: "لیست کارها",
			todoDesc: "جزئیات کارها",
			todoListDesc: "حداکثر ۱۰ کار می‌تواند وجود داشته باشد",
			confirm: "تأیید",
			cancel: "لغو",
			text: "متن نمی‌تواند خالی باشد",
			cancelRemind: "لغو یادآوری",
			remindTime: "زمان یادآوری:",
			remindTitle: "عنوان یادآوری:",
			remindContent: "محتوای یادآوری:",
			edit: "ویرایش",
			reachLimit: "به حداکثر حد اضافه شده است",
			errorChat:"صدا هنوز شناسایی نشده است، لطفاً دوباره امتحان کنید",
			      noSTT:"مجوز دریافت نشد، لطفاً قبل از ادامه، مجوز را فعال کنید.",
      error50:"یک خطای ناشناخته روی داد، لطفاً دوباره امتحان کنید"
		},
		draw: {
			describe: "تصویر خود را توصیف کنید",
			voice: "ورودی صوتی",
			press: "برای صحبت کردن فشار دهید",
			placeholder: "تصویری که می‌خواهید تولید کنید را توصیف کنید",
			style: "سبک نقاشی",
			styleList: ["سبک جوهر", "سایبرپانک", "انیمه", "ارگامی", "بافتنی",  "گرافیک", "کارتون", "لگو","مداد","نقاشی"],
			record: "سابقه نقاشی",
			recordDesc: "تا ۱۰ سابقه نقاشی می‌تواند ذخیره شود",
			recordEmpty: "شما در حال حاضر هیچ سابقه نقاشی ندارید",
			generate: "تولید تصویر",
			result: "نتیجه تولید تصویر",
			download: "دانلود تصویر",
			regenerate: "تولید مجدد",
			setDial: "به عنوان صفحه ساعت تنظیم کنید",
			error: "تولید تصویر ناکام ماند",
			errorDesc: "ارتباط شبکه ناپایدار است، لطفاً دوباره ارسال کنید.",
			confirm: "تأیید",
			analysiserror: "تجزیه و تحلیل ناموفق بود",
      SetWatchErr:["شماره گیری با موفقیت تنظیم شد","لغو ارسال","تأیید داده انجام نشد","تأیید داده انجام نشد","تأیید داده انجام نشد","بلوتوث قطع شده است"],
      SetWatchTips:["راه اندازی انجام نشد","تنظیم شماره گیری در حال انجام است","صفحه ساعت در حال تنظیم است، لطفاً از صفحه فعلی خارج نشوید."],
      progressText:"همگام سازی"
		},
		sports: {
			analyze: "تحلیل سلامت",
			analyzeDesc: "وضعیت اولیه",
			advice: "مشاوره سلامت",
			adviceDesc: "ارائه مشاوره سلامت",
			experience: "تجربه",
			program: {
				plan: "طرح کاهش وزن",
				startDate: "انتخاب تاریخ شروع",
				chooseDate: "لطفاً یک روز را به عنوان تاریخ شروع انتخاب کنید",
				week: [{
					num: "یک.",
					day: "یکشنبه"
				}, {
					num: "دو.",
					day: "دوشنبه"
				}, {
					num: "سه.",
					day: "سه‌شنبه"
				}, {
					num: "چهار.",
					day: "چهارشنبه"
				}, {
					num: "پنج.",
					day: "پنج‌شنبه"
				}, {
					num: "جمعه.",
					day: "جمعه"
				}, {
					num: "شنبه.",
					day: "شنبه"
				}, ],
				arrange: "ترتیب برنامه",
				arrangeDesc: "از نزدیکترین تاریخی که انتخاب می‌کنید شروع کنید، مجموعاً 7 روز تمرین است؛ محتوای خاص به صورت زیر است:",
				confirm: "تأیید ترتیب",
				success: "تبریک می‌گویم، شما با موفقیت برنامه را سفارشی کردید! تمرین خود را در صفحه 'بررسی تمرین' شروع کنید!",
				successButton: "به بررسی تمرین بروید",
			},
			newmessage: {
				message1: ['مرکز شخصی', 'داده‌های سلامت'],
				message2: ['سلامت', 'تمرین', 'رژیم'],
				message3: ['جنسیت', 'سن', 'قد', 'وزن'],
				message4: ['خواب', 'ضربان قلب', 'اکسیژن', 'گام‌ها', 'فشار خون', 'فشار', 'خلاصه'],
				message5: ['مصرف', 'پیشنهاد', 'گام‌های امروز', 'مدت زمان تمرین '],
				message6: ['بررسی تمرین', 'نسخه خانگی تناسب اندام', 'تناسب اندام حرفه‌ای', 'طرح کاهش وزن'],
				message7: ['تمرین کنید و زندگی سالمی داشته باشید', 'برنامه تناسب اندام خود را سفارشی کنید', 'برنامه تناسب اندام خود را سفارشی کنید', 'یک طرح کاهش وزن برای شما ایجاد کنید'],
				message8: ['شاید', 'گام‌ها', 'دقیقه‌ها', 'ست‌ها', 'روزها', 'سال‌ها', 'سانتی‌متر', 'کیلوگرم', 'ساعت‌ها','هفته '],
				message9: ['بررسی', 'تمرین', 'گزینه‌های تمرین', 'دستاوردهای من', 'روزهای پیاپی بررسی', 'بررسی نشده'],
				message10: ['برای تحلیل کلیک کنید', 'تاریخچه تحلیل (حداکثر 10 ذخیره شده)', 'تحلیل', 'شما در حال حاضر هیچ سوابق تاریخی ندارید', 'روزهای پیاپی بررسی', 'تحلیل داده‌ها'],
				message11: ['زن', 'مرد'],
				message12: ['لغو', 'تأیید'],
				message13: ['سال‌ها', 'سانتی‌متر', 'کیلوگرم'],
				message14: ['ورود دستی',' زمان مورد انتظار (هفته)', 'جنسیت', 'سن', 'قد (سانتی‌متر)', 'وزن (کیلوگرم)', 'وزن مورد انتظار (کیلوگرم)'],
				message15: ['تاریخچه', 'تحلیل داده‌های سلامت', 'مشاوره سلامت'],
				message16: ['بررسی موفقیت‌آمیز', 'در حال بررسی', 'فقط در همان روز می‌توانید بررسی کنید'],
				message17: ['تحلیل داده‌های سلامت تنها یکبار در روز قابل دریافت است و نمی‌توان داده‌های محلی را یافت', 'مشاوره سلامت تنها یکبار در روز قابل دریافت است و نمی‌توان داده‌های محلی را یافت'],
				message18: ['در حال حاضر کم‌وزن هستید', 'شاخص BMI شما', 'ادامه کاهش وزن توصیه نمی‌شود (دامنه سلامت 18.5<BMI<24)'],
				message19: ['کاهش وزن متوسط', 'توصیه می‌شود که بیشتر از 500 گرم در روز وزن کم نکنید'],
				message20: ['برنامه دارید', 'آیا شامل برنامه‌های موجود می‌شود'],
				message21: ['آسان', 'عادی', 'متوسط', 'بالا'],
				message22: ['روحیه:', 'کم', 'زیاد'],
				message23: ['تعداد دفعات استفاده شده است برای باز کردن قفل بارهای بیشتری اشتراک بخرید'],
			},
		},
		punch: {
			title: "انجام شده",
			title2: "پایان",
			h1: "گزینه‌های تمرین",
			h2: "دستاوردهای من",
			button: "بررسی",
			day: "روزهای متوالی بررسی",
			dayunit: "روزها",
		},
		eat: {
			title: "رژیم",
			DietaryIntake: "خوردن",
			kcal: "کیلوکالری",
			Stillingestible: "باقیمانده",
			overingest: "فراتر از حد",
			Recommendedintake: "پیشنهاد",
			Motorconsumption: "حرکت",
			carbohydrate: "کربوهیدرات",
			protein: "پروتئین",
			fat: "چربی",
			gram: "گرم",
			drinkDk: "بررسی آب",
			drinkDkhealth: "بررسی روزانه برای بدنی سالم‌تر",
			eatsuggest: "پیشنهادات رژیم غذایی",
			analyzefoodglx: "تحلیل منطقی بودن رژیم غذایی",
			eatPlan: "برنامه غذایی",
			makeeatPlan: "برنامه غذایی اختصاصی خود را سفارشی کنید",
			breakfast: "صبحانه",
			lunch: "ناهار",
			dinner: "شام",
			snack: "میان‌وعده",
			addFood: "افزودن غذا",
			noAddFood: "بدون غذا",
			cancle: "لغو",
			confirm: "تایید",
			tips: "پر کردن به صورت پیش‌رقاد قبول می‌شود که ما اطلاعات این پروژه را جمع‌آوری کنیم و آن را در رژیم غذایی نشان دهیم",
			addFooded: "غذا اضافه شده",
			added: "افزوده شده",
			numFood: "مورد غذا",
			record: "ثبت",
			dkTitle: "بررسی",
			dailygoal: "هدف روزانه",
			drinkDw: "فنجان/روز",
			drinkWater: "نوشیدن آب",
			dailygoalWater: "هدف روزانه نوشیدن آب",
			myachievement: "دستاوردهای من",
			lxdkDays: "روزهای متوالی بررسی",
			day: "روزها",
			analyzeHistory: "تاریخچه",
			conclusion: "خلاصه",
			eatDataAnalyze: "تحلیل داده‌های رژیم غذایی",
			selectStartDate: "انتخاب تاریخ شروع",
			chooseoneDate: "لطفاً یک روز را به عنوان تاریخ شروع انتخاب کنید",
			scheduling: "ترتیب برنامه",
			schedulingDesc: "از نزدیکترین تاریخی که انتخاب می‌کنید شروع کنید، مجموعاً 7 روز؛ محتوای خاص به صورت زیر است:",
			eatDetail: "جزئیات رژیم غذایی",
			analyzeFailed: "تحلیل ناموفق",
			noData: "بدون داده"
		}
	},
  error: {
    error1101:"اتصال شبکه ناپایدار است ، زمان فعلی جلسه فعلی",
    error1102:"در حال حاضر هیچ شبکه ای وجود ندارد، لطفا دوباره وصل شوید",
    error1103:"یک خطای ناشناخته رخ داد",
    error61:"سرویس فعلی در دسترس نیست",
    error4:"صدا هنوز شناسایی نشده است، لطفاً دوباره امتحان کنید",
    error12:"لطفاً کلمات یا جملات استاندارد را برای درک و ترسیم هوش مصنوعی وارد کنید",
		error50:"با عرض پوزش ، سرور فعلی اشتباه است ، لطفا دوباره امتحان کنید"
  },
	setting: {
		index: {
			title: "تنظیمات",
			problem: "بازخورد مشکل",
			order: "سوابق سفارش",
			notice: "اطلاعیه",
			language: "زبان",
			clear: "پاک کردن کش",
			clearTip: "آیا مطمئن هستید که\nکش را پاک کنید?",
			confirm: "تأیید",
			cancel: "لغو",
		},
		language: {
			title: "زبان",
			langList: ["چینی ساده", "چینی سنتی", "انگلیسی", "عربی", "بلغاری", "بنگالی", "چکی", "آلمانی", "یونانی", "اسپانیایی", "فارسی", "فیلیپینی", "فرانسوی", "هندی", "مجاری", "اندونزیایی", "ایتالیایی", "عبری", "ژاپنی", "کره‌ای", "مالایی", "هلندی", "پنجابی", "لهستانی", "پرتغالی", "رومانیایی", "روسی", "اسلواکی", "تایلندی", "ترکی", "اوکراینی", "اردو", "ویتنامی"],
		},
		order: {
			title: "سوابق سفارش",
			success: "با موفقیت بازیابی شد",
			unpaid: "پرداخت نشده",
			paid: "پرداخت شده",
			expired: "منقضی شده",
			refunded: "بازپرداخت شده",
			pTime: "زمان پرداخت",
			eTime: "زمان انقضا",
			pMethod: "روش پرداخت",
			pObject: "مورد پرداخت",
			oNumber: "شماره سفارش",
			pType: "نوع بسته",
			oTime: "زمان بازیابی",
			watch: "مشاهده IMEI",
			delete: "حذف سفارش",
			refund: "درخواست بازپرداخت",
		},
		problem: {
			title: "بازخورد مشکل",
			feedback: "نوع بازخورد",
			please: "لطفاً انتخاب کنید",
			content: "محتوا",
			improve: "لطفاً پیشنهادات خود را بنویسید، مانند درخواست ویژگی‌ها، شکایات محصول و غیره. ما سعی خواهیم کرد بهبود دهیم",
			upload: "آپلود تصویر",
			submit: "ارسال",
			general: "بازخورد عمومی",
			purchase: "مربوط به خرید",
			bug: "گزارش باگ",
			suggestion: "پیشنهاد",
			other: "دیگر",
		},
		notice: {
			title: "اطلاعیه",
			type: "نوع اطلاعیه: ",
			time: "زمان اطلاعیه: "
		},
		unsubscribe: {
			watch: "ساعت:",
			title: "اشتراک متوالی",
			subTitle: "اشتراک شده",
			unsubscribe: "لغو اشتراک",
			packList: ["اشتراک پریمیوم", "اشتراک پایه", "اشتراک استاندارد"],
		},
		refund: {
			title: "درخواست بازپرداخت",
			number: "شماره سفارش",
			content: "لطفاً دلیل بازپرداخت را توضیح دهید",
			packList: ["بسته تخفیف کاربر جدید", "بسته 50 تایی", "بسته 100 تایی", "بسته 300 تایی"],
		}
	},
	tutorial: {
		index: {
			title: "دریافت آموزش پیشرفته",
			watchList: ["ساعت را روشن کنید، برنامه WearAI را پیدا کنید و کلیک کنید تا باز شود.",
				"روی دکمه منافع پیشرفته در صفحه اصلی کلیک کنید.",
				"گوشی خود را برداشته و کد QR روی ساعت را اسکن کنید تا وارد صفحه منافع شوید."
			]
		}
	},
}