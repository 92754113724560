module.exports = {
  language: "norsk",
  navigator: {
    tools: "AI-verktøy",
    draw: "Tegne",
    healthy: "Sunn",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Anbefale", "Studere", "Liv", "Kreatjon", "Arbeid"],
      presetGreet:
        "Hei, hallo~ Jeg er din helseforvalter, alltid klar til å gi interessant og nyttig helseinformasjon for å hjelpe deg med å beskytte helsen din!",
      presetQuiz: [
        "Hva er årsakene til kronisk tretthet?",
        "Hvordan kan unge mennesker forebygge kroniske sykdommer?",
        "Hvordan opprettholde sunne spisevaner?",
        "Hvordan opprettholde god søvn?",
      ],
      presets: "Du kan spørre meg:",
      change: "Endre batch",
      uploadImage: "Send bilde",
      footer:
        "Siden innholdet genereres av en AI-modell, kan ikke ektheten garanteres fullt ut.",
      remind: "Todo-påminnelse",
      limit:
        "Denne samtalen har nådd grensen, vennligst start en ny AI-chatsesjon",
      newDialog: "Start en ny samtale",
      stopRecording: "Klikk hvor som helst eller når nedtellingen er over for å gjenkjenne",
      send: "Send melding",
      say: "Klikk for å snakke",
      notImage: "Denne agenten støtter ikke sending av filer og bilder",
      talking: "Nåværende samtale pågår",
      drawViolations:
        "AI-tegneforespørsel bryter reglene, vennligst skriv inn en overholdelig forespørsel.",
      exhausted:
        "Antall spørsmål og svar er oppbrukt, vennligst gå til seeren og skann QR-koden for å fortsette.",
      serverBusy: "Den nåværende nettverkskoblingen er ustabil, vennligst prøv igjen",
      chatHistory: "Samtalehistorikk",
      chatHistoryDesc: "Spørsmål og svar-opptegnelser beholdes i 3 dager bare",
      todoList: "Todo-liste",
      todoDesc: "Todo-detaljer",
      todoListDesc: "Maksimalt 10 todoer kan eksistere",
      confirm: "Bekreft",
      cancel: "Avbryt",
      text: "Tekst kan ikke være tom",
      cancelRemind: "Avbryt påminnelse",
      remindTime: "Påminnelsestid:",
      remindTitle: "Påminnelsestittel:",
      remindContent: "Påminnelsesinnhold:",
      edit: "Rediger",
      reachLimit: "Lagt til opp til grensen",
      errorChat:"Ingen stemme oppdaget ennå, vennligst prøv igjen",
            noSTT:"Tillatelse ikke oppnådd. Aktiver tillatelse før du fortsetter.",
      error50:"Det oppstod en ukjent feil, prøv igjen"
    },
draw: {
      describe: "Beskriv bildet ditt",
      voice: "Taleinngang",
      press: "Trykk for å snakke",
      placeholder: "Beskriv bildet du ønsker å generere",
      style: "Malestil",
      styleList: [
        "Blekkstil",
        "Cyberpunk",
        "Anime",
        "Origami",
        "Strikking",
        "Grafikk",
        "Tegneserie",
        "LEGO",
        "Blyant",
        "Tegne",
      ],
      record: "Tegneopptak",
      recordDesc: "Maksimalt 10 tegneopptak kan lagres",
      recordEmpty: "Du har for øyeblikket ingen tegneopptak",
      generate: "Generer bilde",
      result: "Resultat av bildegenerering",
      download: "Last ned bilde",
      regenerate: "Generer på nytt",
      setDial: "Sett som klokker",
      error: "Bildet generering mislyktes",
      errorDesc: "Ustabil nettverkstilkobling, vennligst send på nytt.",
      confirm: "Bekreft",
      analysiserror: "Analyse mislyktes",
      SetWatchErr:["Ringen er satt inn","Avbryt sending","Dataverifisering mislyktes","Klokkeskivens datafil er for stor","Synkronisering av urskive har nådd den øvre grensen","Bluetooth er koblet fra"],
      SetWatchTips:["Konfigurasjonen mislyktes","Ringeinnstilling pågår","Urskiven stilles inn, vennligst ikke gå ut av gjeldende side."],
      progressText:"Synkronisering"

    },
    sports: {
      analyze: "Helseanalyse",
      analyzeDesc: "Analyser grunnleggende data",
      advice: "Helseråd",
      adviceDesc: "Gi helseråd",
      experience: "Erfaring",
      program: {
        plan: "Vektnedgangsplan",
        startDate: "Velg startdato",
        chooseDate: "Vennligst velg 1 dag som startdato",
        week: [
          {
            num: "Søn.",
            day: "Søndag",
          },
          {
            num: "Man.",
            day: "Mandag",
          },
          {
            num: "Tirs.",
            day: "Tirsdag",
          },
          {
            num: "Ons.",
            day: "Onsdag",
          },
          {
            num: "Tors.",
            day: "Torsdag",
          },
          {
            num: "Fre.",
            day: "Fredag",
          },
          {
            num: "Lør.",
            day: "Lørdag",
          },
        ],
arrange: "Plan Arrangement",
        arrangeDesc:
          "Start fra nærmeste dato du velger, totalt 7 treningsdager; det spesifikke innholdet er som følger:",
        confirm: "Bekreft Arrangement",
        success:
          "Gratulerer, du har lykkes med å tilpasse planen! Start treningen din på 'Treningsinnsjekk'-siden!",
        successButton: "Gå til Treningsinnsjekk",
      },
      newmessage: {
        message1: ["Personlig Senter", "Helse Data"],
        message2: ["Helse", "Trening", "Kosthold"],
        message3: ["Kjønn", "Alder", "Høyde", "Vekt"],
        message4: [
          "Søvn",
          "HR",
          "Oksygen",
          "Skritt",
          "BP",
          "Trykk",
          "Sammendrag",
        ],
        message5: ["Forbruk", "Foreslå", "DagensSkritt", "Trening Varighet "],
        message6: [
          "Treningsinnsjekk",
          "Fitness Hjemmeutgave",
          "Fitness Pro",
          "Vektnedgangsplan",
        ],
        message7: [
          "Tren og lev et sunt liv",
          "Skreddersy din treningsplan",
          "Skreddersy din treningsplan",
          "Lag en vektnedgangsplan for deg",
        ],
        message8: [
          "Kcal",
          "Skritt",
          "Minutter",
          "Sett",
          "Dager",
          "År",
          "cm",
          "kg",
          "t","Uker"
        ],
message9: [
          "Innsjekk",
          "Trening",
          "Treningsalternativer",
          "Mine Prestasjoner",
          "Fortsatte Innsjekkdager",
          "Ikke Innsjekket",
        ],
        message10: [
          "Klikk for å Analysere",
          "Analysehistorikk (opptil 10 lagret)",
          "Analyser",
          "Du har for øyeblikket ingen historiske opptegnelser",
          "Fortsatte Innsjekkdager",
          "Dataanalyse",
        ],
        message11: ["Kvinne", "Mann"],
        message12: ["Avbryt", "Bekreft"],
        message13: ["År", "Sentimeter", "Kilogram"],
        message14: [
          "Manuell Inndata",
          "Forventet tid (Uker) ",
          "Kjønn",
          "Alder",
          "Høyde (Sentimeter)",
          "Vekt (Kilogram)",
          "Forventet Vekt (Kilogram)",
        ],
        message15: ["Historikk", "Helse Dataanalyse", "Helseråd"],
        message16: [
          "Innsjekk Succesfull",
          "Innsjekking",
          "Kan bare sjekke inn samme dag",
        ],
        message17: [
          "Helsedataanalyse kan kun fås én gang per dag, og lokale data kan ikke bli funnet",
          "Helseråd kan kun fås én gang per dag, og lokale data kan ikke bli funnet",
        ],
        message18: [
          "For tiden undervektig",
          "Din BMI-indeks er",
          "det er ikke tilrådelig å fortsette å gå ned i vekt (Sunn rekke 18.5<BMI<24)",
        ],
        message19: [
          "Moderat vekttap",
          "Det anbefales å ikke gå ned mer enn 500g per dag",
        ],
        message20: ["har planer", "Dekker det eksisterende planer"],
        message21: ["Lett", "Normal", "Moderat", "Høy"],
        message22: ["Lyst:", "Lav", "Høy"],
		message23: ["Antallet ganger har blitt brukt opp. Kjøp et abonnement for å låse opp flere ganger"],
      },
    },
punch: {
      title: "Ferdig",
      title2: "Avslutt",
      h1: "Treningsalternativer",
      h2: "Mine Prestasjoner",
      button: "Innsjekk",
      day: "Fortsatte Innsjekkdager",
      dayunit: "Dager",
    },
    eat: {
      title: "Kosthold",
      DietaryIntake: "Spising",
      kcal: "Kcal",
      Stillingestible: "Restbeløp",
      overingest: "Over",
      Recommendedintake: "Forslag",
      Motorconsumption: "Bevegelse",
      carbohydrate: "Karbohydrater",
      protein: "Protein",
      fat: "Fett",
      gram: "Grammer",
      drinkDk: "Vann Innsjekk",
      drinkDkhealth: "Daglig innsjekk for en sunnere kropp",
      eatsuggest: "Kostholdsforslag",
      analyzefoodglx: "Analyser rimeligheten av kostholdet",
      eatPlan: "Kostholdsplan",
      makeeatPlan: "Skreddersy din eksklusive kostholdsplan",
      breakfast: "Frokost",
      lunch: "Lunsj",
      dinner: "Middag",
      snack: "Småsnacks",
      addFood: "Legg til Mat",
      noAddFood: "Ingen mat lagt til",
      cancle: "Avbryt",
      confirm: "Bekreft",
      tips: "Proaktiv utfylling anses som samtykke til at vi samler inn denne prosjektinformasjonen og viser den i dietten",
      addFooded: "Mat lagt til",
      added: "Lagt til",
      numFood: "Matvarer",
      record: "Registrer",
      dkTitle: "Innsjekk",
      dailygoal: "Daglig Mål",
      drinkDw: "Kopper/Dag",
      drinkWater: "Drikk Vann",
      dailygoalWater: "Daglig vanninntaksmål",
      myachievement: "Mine Prestasjoner",
      lxdkDays: "Fortsatte Punch Dager",
      day: "Dager",
      analyzeHistory: "Historikk",
      conclusion: "Sammendrag",
      eatDataAnalyze: "Kostholdsdataanalyse",
      selectStartDate: "Velg Startdato",
      chooseoneDate: "Vennligst velg 1 dag som startdato",
      scheduling: "Plan Arrangement",
      schedulingDesc:
        "Start fra nærmeste dato du velger, totalt 7 dager; det spesifikke innholdet er som følger:",
      eatDetail: "Kostholdsdetaljer",
      analyzeFailed: "Analyse mislyktes",
      noData: "ingen data",
    },
  },
  error: {
    error1101:"Nettverkstilkoblingen er ustabil, den nåværende tidsavbruddet",
    error1102:"Det er ikke noe nettverk for øyeblikket. Koble til på nytt",
    error1103:"Det oppstod en ukjent feil",
    error61:"Den nåværende tjenesten er utilgjengelig",
    error4:"Stemmen er ikke gjenkjent ennå. prøv igjen",
    error12:"Skriv inn standard ord eller setninger for AI å forstå og tegne",
    error50:"Beklager, det er en feil på gjeldende server, prøv igjen"

  },
  setting: {
    index: {
      title: "Innstillinger",
      problem: "Problemer Tilbakemelding",
      order: "Bestillingslogger",
      notice: "Varsling",
      language: "Språk",
      clear: "Tøm Cache",
      clearTip: "Er du sikker på at du vil\ntømme cachen?",
      confirm: "Bekreft",
      cancel: "Avbryt",
    },
language: {
      title: "Språk",
      langList: [
        "Forenklet Kinesisk",
        "Tradisjonelt Kinesisk",
        "Engelsk",
        "Arabisk",
        "Polsk",
        "Persisk",
        "Dansk",
        "Tysk",
        "Russisk",
        "Fransk",
        "Nederlandsk",
        "Tsjekkisk",
        "Rumensk",
        "Portugisisk",
        "Japansk",
        "Thai",
        "Tyrkisk",
        "Spansk",
        "Gresk",
        "Italiensk",
        "Vietnamesisk",
        "Hindi",
      ],
    },
    order: {
      title: "Bestillingslogger",
      success: "Hentet med suksess",
      unpaid: "Ubetalt",
      paid: "Betalt",
      expired: "Utløpt",
      refunded: "Refundert",
      pTime: "Betalingstid",
      eTime: "Utløpstid",
      pMethod: "Betalingsmetode",
      pObject: "Betalingsobjekt",
      oNumber: "Ordrenummer",
      pType: "Pakke Type",
      oTime: "Hentetid",
      watch: "Se IMEI",
      delete: "Slett Ordre",
      refund: "Be om Refusjon",
    },
    problem: {
      title: "Problemer Tilbakemelding",
      feedback: "Tilbakemeldingstype",
      please: "Vennligst Velg",
      content: "Innhold",
      improve:
        "Vennligst skriv ned forslagene dine, som funksjonsforespørsel, produktklager, osv. Vi vil jobbe for å forbedre",
      upload: "Last opp Bilde",
      submit: "Send inn",
      general: "Generell Tilbakemelding",
      purchase: "Kjøpsrelatert",
      bug: "Feilmelding",
      suggestion: "Forslag",
      other: "Annet",
    },
notice: {
      title: "Varsling",
      type: "Varslingstype: ",
      time: "Varslingstid: ",
    },
    unsubscribe: {
      watch: "Klokke:",
      title: "Kontinuerlig Abonnement",
      subTitle: "Abonnert",
      unsubscribe: "Avslutt Abonnement",
      packList: [
        "Premium Abonnement",
        "Grunnleggende Abonnement",
        "Standard Abonnement",
      ],
    },
    refund: {
      title: "Be om Refusjon",
      number: "Ordrenummer",
      content: "Vennligst forklar grunnen til refusjonen",
      packList: [
        "Ny Bruker Rabattpakke",
        "50 Ganger Pakke",
        "100 Ganger Pakke",
        "300 Ganger Pakke",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Få Avansert Veiledning",
      watchList: [
        "Slå på klokken, finn WearAI-applikasjonen og klikk for å åpne.",
        "Klikk på knappen for avanserte fordeler på startsiden.",
        "Ta ut mobiltelefonen din og skann QR-koden på klokken for å komme til fordelene siden.",
      ],
    },
  },
}