module.exports = {
	language: "বাংলা",
	navigator: {
		tools: "AI টুলস",
		draw: "ড্র",
		healthy: "সুস্থ",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["সুপারিশ", "অধ্যয়ন", "জীবন", "সৃষ্টি", "কাজ"],
			presetGreet: "আরে, হ্যালো~ আমি আপনার স্বাস্থ্য প্রহরী, সর্বদা আপনাকে মজাদার এবং দরকারী স্বাস্থ্য জ্ঞান প্রদান করার জন্য প্রস্তুত, যা আপনাকে আপনার স্বাস্থ্য রক্ষা করতে সহায়তা করবে!",
			presetQuiz: ["দীর্ঘস্থায়ী ক্লান্তির কারণগুলি কি?", "তরুণরা কিভাবে দীর্ঘস্থায়ী রোগগুলি প্রতিরোধ করতে পারে?", "স্বাস্থ্যকর খাদ্যাভ্যাস কিভাবে বজায় রাখবেন?", "ভাল ঘুম কিভাবে বজায় রাখবেন?"],
			presets: "আপনি আমাকে জিজ্ঞাসা করতে পারেন:",
			change: "ব্যাচ পরিবর্তন",
			uploadImage: "ছবি পাঠান",
			footer: "কারণ বিষয়বস্তু একটি এআই মডেল দ্বারা উত্পন্ন হয়, তার যথার্থতা সম্পূর্ণরূপে নিশ্চিত করা যাবে না।",
			remind: "টুডো রিমাইন্ডার",
			limit: "এই কথোপকথন সীমায় পৌঁছেছে, দয়া করে একটি নতুন এআই চ্যাট সেশন শুরু করুন",
			newDialog: "নতুন কথোপকথন শুরু করুন",
			stopRecording: "কোথাও ক্লিক করুন বা কাউন্টডাউন শেষ হলে শনাক্ত করুন",
			send: "বার্তা পাঠান",
			say: "কথা বলার জন্য ক্লিক করুন",
			notImage: "এই এজেন্ট ফাইল এবং ছবি পাঠানো সমর্থন করে না",
			talking: "বর্তমান কথোপকথন চলমান",
			drawViolations: "এআই অঙ্কন প্রম্পট নিয়ম লঙ্ঘন করেছে, দয়া করে একটি নিয়মিত প্রম্পট ইনপুট করুন।",
			exhausted: "প্রশ্নোত্তরের সংখ্যা শেষ হয়ে গিয়েছে, অবিরত রাখতে দয়া করে ওয়াচ এন্ডে যান এবং কিউআর কোড স্ক্যান করুন।",
			serverBusy: "বর্তমান নেটওয়ার্ক লিংক অস্থির, দয়া করে আবার চেষ্টা করুন",
			chatHistory: "চ্যাটের ইতিহাস",
			chatHistoryDesc: "প্রশ্নোত্তরের রেকর্ড শুধুমাত্র ৩ দিন সংরক্ষিত থাকবে",
			todoList: "টুডো তালিকা",
			todoDesc: "টুডো বিবরণ",
			todoListDesc: "সর্বাধিক ১০টি টুডো থাকতে পারে",
			confirm: "নিশ্চিত করুন",
			cancel: "বাতিল করুন",
			text: "পাঠ্য খালি থাকতে পারে না",
			cancelRemind: "রিমাইন্ডার বাতিল করুন",
			remindTime: "রিমাইন্ডার সময়:",
			remindTitle: "রিমাইন্ডার শিরোনাম:",
			remindContent: "রিমাইন্ডার বিষয়বস্তু:",
			edit: "সম্পাদনা করুন",
			reachLimit: "সীমায় পৌঁছেছে",
			errorChat:"ভয়েস এখনও সনাক্ত করা যায়নি, আবার চেষ্টা করুন",
			      noSTT:"অনুমতি পাওয়া যায়নি, অনুগ্রহ করে চালিয়ে যাওয়ার আগে অনুমতি সক্ষম করুন।",
      error50:"একটি অজানা ত্রুটি ঘটেছে, আবার চেষ্টা করুন"
		},
		draw: {
			describe: "আপনার ছবিটি বর্ণনা করুন",
			voice: "ভয়েস ইনপুট",
			press: "বলতে চাপুন",
			placeholder: "আপনি যে ছবি তৈরি করতে চান তা বর্ণনা করুন",
			style: "চিত্রের ধরণ",
			styleList: ["ইঙ্ক স্টাইল", "সাইবারপাঙ্ক", "অ্যানিমে", "অরিগ্যামি", "নিটিং", "গ্রাফিক্স", "কার্টুন", "লেগো","পেন্সিল","আঁকা"],
			record: "অঙ্কনের রেকর্ড",
			recordDesc: "সর্বাধিক ১০টি অঙ্কন রেকর্ড সংরক্ষিত থাকতে পারে",
			recordEmpty: "আপনার বর্তমানে কোনও অঙ্কন রেকর্ড নেই",
			generate: "ছবি তৈরি করুন",
			result: "ফলাফল",
			download: "ডাউনলোড",
			regenerate: "আবার",
			setDial: "ঘড়ির মুখ সেট করুন",
			error: "ছবি তৈরি ব্যর্থ",
			errorDesc: "নেটওয়ার্ক সংযোগ অস্থির, দয়া করে পুনরায় জমা দিন।",
			confirm: "নিশ্চিত করুন",
		  analysiserror: "বিশ্লেষণ ব্যর্থ হয়েছে",
			SetWatchErr:["সফলভাবে সেট ডায়াল করুন","পাঠানো বাতিল করুন","ডেটা যাচাইকরণ ব্যর্থ হয়েছে৷","ঘড়ির মুখের ডেটা ফাইলটি খুব বড়৷","ওয়াচ ফেস সিঙ্ক্রোনাইজেশন ঊর্ধ্ব সীমায় পৌঁছেছে","ব্লুটুথ সংযোগ বিচ্ছিন্ন করা হয়েছে৷"],
      SetWatchTips:["সেটআপ ব্যর্থ হয়েছে৷","ডায়াল সেটিং চলছে","ঘড়ির মুখ সেট করা হচ্ছে, দয়া করে বর্তমান পৃষ্ঠা থেকে প্রস্থান করবেন না।"],
      progressText:"সিঙ্ক করা হচ্ছে"

		},
		sports: {
			analyze: "স্বাস্থ্য বিশ্লেষণ",
			analyzeDesc: "মৌলিক তথ্য বিশ্লেষণ",
			advice: "স্বাস্থ্য পরামর্শ",
			adviceDesc: "স্বাস্থ্য পরামর্শ প্রদান",
			experience: "অভিজ্ঞতা",
			program: {
				plan: "ওজন হ্রাস পরিকল্পনা",
				startDate: "শুরুর তারিখ নির্বাচন করুন",
				chooseDate: "অনুগ্রহ করে ১ দিন শুরুর তারিখ হিসাবে নির্বাচন করুন",
				week: [{
					num: "রবি.",
					day: "রবিবার"
				}, {
					num: "সোম.",
					day: "সোমবার"
				}, {
					num: "মঙ্গল.",
					day: "মঙ্গলবার"
				}, {
					num: "বুধ.",
					day: "বুধবার"
				}, {
					num: "বৃহঃ.",
					day: "বৃহস্পতিবার"
				}, {
					num: "শুক্র.",
					day: "শুক্রবার"
				}, {
					num: "শনি.",
					day: "শনিবার"
				}, ],
				arrange: "পরিকল্পনা বিন্যাস",
				arrangeDesc: "আপনি যে নিকটতম তারিখ নির্বাচন করবেন সেই তারিখ থেকে শুরু করুন, মোট ৭ টি প্রশিক্ষণ দিন; নির্দিষ্ট বিষয়বস্তু নিম্নরূপ:",
				confirm: "বিন্যাস নিশ্চিত করুন",
				success: "অভিনন্দন, আপনি সফলভাবে পরিকল্পনা কাস্টমাইজ করেছেন! 'ব্যায়াম চেক-ইন' পৃষ্ঠায় আপনার প্রশিক্ষণ শুরু করুন!",
				successButton: "ব্যায়াম চেক-ইন এ যান",
			},
newmessage: {
				message1: ['ব্যক্তিগত কেন্দ্র', 'স্বাস্থ্য তথ্য'],
				message2: ['স্বাস্থ্য', 'ব্যায়াম', 'ডায়েট'],
				message3: ['লিঙ্গ', 'বয়স', 'উচ্চতা', 'ওজন'],
				message4: ['ঘুম', 'হার্ট রেট', 'অক্সিজেন', 'ধাপ', 'রক্তচাপ', 'চাপ', 'সারাংশ'],
				message5: ['গ্রহণ', 'প্রস্তাবনা', 'আজকের পদক্ষেপ',
					'ব্যায়াম সময়কাল'
				],
				message6: ['ব্যায়াম চেক-ইন', 'ফিটনেস হোম এডিশন',
					'ফিটনেস প্রো', 'ওজন হ্রাস পরিকল্পনা'
				],
				message7: ['ব্যায়াম এবং সুস্থ জীবন যাপন করুন',
					'আপনার ফিটনেস পরিকল্পনা কাস্টমাইজ করুন', 'আপনার ফিটনেস পরিকল্পনা কাস্টমাইজ করুন',
					'কাস্টমাইজড ওজন কমানোর পদ্ধতি'
				],
				message8: ['Kcal', 'ধাপ', 'মিনিট', 'প্রতিটি', 'দিন', 'বছর', 'সেমি', 'কেজি','ঘন্টা','সপ্তাহ'],
				message9: ['চেক-ইন', 'প্রশিক্ষণ', 'ব্যায়ামের বিকল্পগুলি', 'আমার অর্জনগুলি',
					'একটানা চেক-ইন দিনের সংখ্যা', 'চেক-ইন হয়নি'
				],
				message10: ['বিশ্লেষণ করতে ক্লিক করুন', 'বিশ্লেষণ ইতিহাস (সর্বাধিক ১০টি সংরক্ষিত)', 'বিশ্লেষণ',
					'আপনার বর্তমানে কোন ঐতিহাসিক রেকর্ড নেই', 'একটানা চেক-ইন দিনের সংখ্যা', 'তথ্য বিশ্লেষণ'
				],
				message11: ['মহিলা', 'পুরুষ'],
				message12: ['বাতিল করুন', 'নিশ্চিত করুন'],
				message13: ['বছর', 'সেন্টিমিটার', 'কিলোগ্রাম'],
				message14: ['ম্যানুয়াল ইনপুট', 'প্রত্যাশিত সময় (সপ্তাহ)    ', 'লিঙ্গ', 'বয়স', 'উচ্চতা (সেন্টিমিটার)',
					'ওজন (কিলোগ্রাম)', 'প্রত্যাশিত ওজন (কিলোগ্রাম)'
				],
				message15: ['ইতিহাস', 'স্বাস্থ্য তথ্য বিশ্লেষণ', 'স্বাস্থ্য পরামর্শ'],
				message16: ['চেক-ইন সফল', 'চেক-ইন হচ্ছে', 'শুধুমাত্র একই দিনে চেক-ইন করা যেতে পারে'],
				message17: ['স্বাস্থ্য তথ্য বিশ্লেষণ দিনে একবারই প্রাপ্ত করা যায়, এবং স্থানীয় তথ্য খুঁজে পাওয়া যায়নি', 'স্বাস্থ্য পরামর্শ দিনে একবারই প্রাপ্ত করা যায়, এবং স্থানীয় তথ্য খুঁজে পাওয়া যায়নি'],
				message18:['বর্তমানে আন্ডারওয়েট','আপনার বিএমআই সূচক','ওজন কমিয়ে যাওয়া উচিত নয় (সুস্থ পরিসীমা 18.5<BMI<24)'],
				message19:['মধ্যবর্তী ওজন হ্রাস','প্রতিদিন ৫০০ গ্রাম এর বেশি ওজন কমানোর সুপারিশ করা হয় না'],
				message20:['পরিকল্পনা আছে','এটি বিদ্যমান পরিকল্পনাগুলি কভার করে কিনা'],
				message21:['সহজ','সাধারণ','মাঝারি','উচ্চ'],
				message22:['আত্মা:','নিম্ন','উচ্চ'],
				message23:['আরো বার আনলক করতে একটি সদস্যতা কিনুন বার ব্যবহার করা হয়েছে.  ্চ'],
			},
		},
		punch: {
			title: "সম্পন্ন",
			title2: "শেষ",
			h1: "ব্যায়ামের বিকল্পগুলি",
			h2: "আমার অর্জনগুলি",
			button: "চেক-ইন",
			day: "একটানা চেক-ইন দিনের সংখ্যা",
			dayunit: "দিন",
		},
eat: {
			title: "ডায়েট",
			DietaryIntake: "খাদ্য গ্রহণ",
			kcal: "Kcal",
			Stillingestible: "অবশিষ্ট",
			overingest: "অতিক্রম",
			Recommendedintake: "প্রস্তাবনা",
			Motorconsumption: "আন্দোলন",
			carbohydrate: "কার্বস",
			protein: "প্রোটিন",
			fat: "চর্বি",
			gram: "গ্রাম",
			drinkDk: "পানি চেক-ইন",
			drinkDkhealth: "স্বাস্থ্যকর শরীরের জন্য দৈনিক চেক-ইন",
			eatsuggest: "খাদ্য প্রস্তাবনা",
			analyzefoodglx: "খাদ্যের যৌক্তিকতা বিশ্লেষণ করুন",
			eatPlan: "ডায়েট পরিকল্পনা",
			makeeatPlan: "এক্সক্লুসিভ ডায়েট প্ল্যান",
			breakfast: "প্রাতঃরাশ",
			lunch: "লাঞ্চ",
			dinner: "ডিনার",
			snack: "নাস্তা",
			addFood: "খাদ্য যোগ করুন",
			noAddFood: "খাবার নেই",
			cancle: "বাতিল করুন",
			confirm: "নিশ্চিত করুন",
			tips: "স্বেচ্ছায় পূরণ করা আমাদের এই প্রকল্পের তথ্য সংগ্রহ এবং ডায়েটে প্রদর্শন করার জন্য সম্মতি বলে গণনা করা হয়",
			addFooded: "খাদ্য যোগ করা হয়েছে",
			added: "আছে",
			numFood: "খাবার",
			record: "রেকর্ড",
			dkTitle: "চেক-ইন",
			dailygoal: "দৈনিক লক্ষ্য",
			drinkDw: "কাপ/দিন",
			drinkWater: "পানি পান করুন",
			dailygoalWater: "দৈনিক পানীয় জলের লক্ষ্য",
			myachievement: "আমার অর্জন",
			lxdkDays: "একটানা পাঞ্চ দিন",
			day: "দিন",
			analyzeHistory: "ইতিহাস",
			conclusion: "সারাংশ",
			eatDataAnalyze: "খাদ্য তথ্য বিশ্লেষণ",
			selectStartDate: "শুরুর তারিখ নির্বাচন করুন",
			chooseoneDate: "অনুগ্রহ করে ১ দিন শুরুর তারিখ হিসাবে নির্বাচন করুন",
			scheduling: "পরিকল্পনা বিন্যাস",
			schedulingDesc: "আপনি যে নিকটতম তারিখ নির্বাচন করবেন সেই তারিখ থেকে শুরু করুন, মোট ৭ দিন; নির্দিষ্ট বিষয়বস্তু নিম্নরূপ:",
			eatDetail:"খাদ্য বিস্তারিত",
			analyzeFailed:"বিশ্লেষণ ব্যর্থ",
			noData:"তথ্য নেই"

		}
	},
  error: {
    error1101:"নেটওয়ার্ক সংযোগটি অস্থির, বর্তমান সেশনের সময়সীমা",
    error1102:"বর্তমানে কোনো নেটওয়ার্ক নেই, অনুগ্রহ করে পুনরায় সংযোগ করুন৷",
    error1103:"একটি অজানা ত্রুটি ঘটেছে",
    error61:"বর্তমান পরিষেবা অনুপলব্ধ",
    error4:"ভয়েস এখনও সনাক্ত করা যায়নি, আবার চেষ্টা করুন",
    error12:"অনুগ্রহ করে AI বোঝার এবং আঁকার জন্য আদর্শ শব্দ বা বাক্য লিখুন",
		error50:"দুঃখিত, বর্তমান সার্ভারটি ভুল, দয়া করে আবার চেষ্টা করুন"
  },
	setting: {
		index: {
			title: "সেটিংস",
			problem: "সমস্যা ফিডব্যাক",
			order: "অর্ডার রেকর্ড",
			notice: "বিজ্ঞপ্তি",
			language: "ভাষা",
			clear: "ক্যাশে পরিষ্কার করুন",
			clearTip: "আপনি কি নিশ্চিত যে আপনি\nক্যাশে পরিষ্কার করতে চান?",
			confirm: "নিশ্চিত করুন",
			cancel: "বাতিল করুন",
		},
		language: {
			title: "ভাষা",
			langList: ["সরলীকৃত চীনা", "পারম্পরিক চীনা", "ইংরেজি", "আরবি", "বুলগেরিয়ান", "বাংলা", "চেক", "জার্মান", "গ্রীক", "স্প্যানিশ", "ফারসি", "ফিলিপিনো", "ফরাসি", "হিন্দি", "হাঙ্গেরিয়ান", "ইন্দোনেশিয়ান", "ইতালিয়ান", "হিব্রু", "জাপানি", "কোরিয়ান", "মালয়", "ডাচ", "পাঞ্জাবি", "পোলিশ", "পর্তুগিজ", "রোমানিয়ান", "রাশিয়ান", "স্লোভাক", "থাই", "তুর্কি", "ইউক্রেনীয়", "উর্দু", "ভিয়েতনামী"],
		},
		order: {
			title: "অর্ডার রেকর্ড",
			success: "সফলভাবে প্রাপ্ত",
			unpaid: "অবৈতনিক",
			paid: "প্রদত্ত",
			expired: "মেয়াদোত্তীর্ণ",
			refunded: "ফেরত হয়েছে",
			pTime: "পেমেন্ট সময়",
			eTime: "মেয়াদ শেষের সময়",
			pMethod: "পেমেন্ট পদ্ধতি",
			pObject: "পেমেন্ট অবজেক্ট",
			oNumber: "অর্ডার নম্বর",
			pType: "প্যাকেজ প্রকার",
			oTime: "প্রাপ্তির সময়",
			watch: "IMEI দেখুন",
			delete: "অর্ডার মুছুন",
			refund: "ফেরত অনুরোধ করুন",
		},
problem: {
			title: "সমস্যা ফিডব্যাক",
			feedback: "ফিডব্যাক প্রকার",
			please: "অনুগ্রহ করে নির্বাচন করুন",
			content: "বিষয়বস্তু",
			improve: "আপনার পরামর্শগুলি লিখুন, যেমন বৈশিষ্ট্য অনুরোধ, পণ্য অভিযোগ, ইত্যাদি। আমরা উন্নতি করার প্রচেষ্টা করব",
			upload: "ছবি আপলোড করুন",
			submit: "জমা দিন",
			general: "সাধারণ ফিডব্যাক",
			purchase: "ক্রয়ের সাথে সম্পর্কিত",
			bug: "বাগ রিপোর্ট",
			suggestion: "পরামর্শ",
			other: "অন্যান্য",
		},
		notice: {
			title: "বিজ্ঞপ্তি",
			type: "বিজ্ঞপ্তি প্রকার: ",
			time: "বিজ্ঞপ্তি সময়: "
		},
		unsubscribe: {
			watch: "ঘড়ি:",
			title: "নিরবচ্ছিন্ন সাবস্ক্রিপশন",
			subTitle: "সাবস্ক্রাইব করা হয়েছে",
			unsubscribe: "সাবস্ক্রিপশন বাতিল করুন",
			packList: ["প্রিমিয়াম সাবস্ক্রিপশন", "বেসিক সাবস্ক্রিপশন", "স্ট্যান্ডার্ড সাবস্ক্রিপশন"],
		},
		refund: {
			title: "ফেরত অনুরোধ",
			number: "অর্ডার নম্বর",
			content: "দয়া করে ফেরতের কারণ ব্যাখ্যা করুন",
			packList: ["নতুন ব্যবহারকারী ছাড় প্যাকেজ", "৫০ টাইমস প্যাকেজ", "১০০ টাইমস প্যাকেজ",
				"৩০০ টাইমস প্যাকেজ"
			],
		}
	},
	tutorial: {
		index: {
			title: "উন্নত টিউটোরিয়াল পান",
			watchList: ["ঘড়ি চালু করুন, WearAI অ্যাপ্লিকেশনটি খুঁজুন এবং খুলতে ক্লিক করুন।",
				"হোম পৃষ্ঠায় উন্নত সুবিধাগুলি বোতামে ক্লিক করুন।",
				"আপনার মোবাইল ফোনটি নিন এবং সুবিধাগুলি পৃষ্ঠায় প্রবেশ করতে ঘড়ির কিউআর কোডটি স্ক্যান করুন।"
			]
		}
	},
}