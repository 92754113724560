<template>
	<div id="app">
		<keep-alive include="navbar,AitoolsIndex,DrawIndex,SportsIndex">
			<router-view />
		</keep-alive>
		<div v-if="!noNetWork" :class="[!noNetWork?'scale-up-center':'','netBox']">
			<netWorkTip />
		</div>
	</div>
</template>

<script>
	import {
		Encrypt,
		Decrypt
	} from "@/utils/encryp.js";
	import {
		langChange
	} from '@/utils/language';
	import netWorkTip from '@/components/netWorkTip.vue';

	export default {
		name: "App",
		data() {
			return {
				params: {},
				noNetWork: true
			};
		},
		components: {
		  netWorkTip
		},
		mounted() {
			localStorage.setItem('noNetWork',true)
			window.addEventListener('offline', () => {
				// 网络由正常到异常时才会触发
				this.noNetWork = false;
				localStorage.setItem('noNetWork',false)
			});
			window.addEventListener('online', () => {
				this.noNetWork = true;
				localStorage.setItem('noNetWork',true)
			});
			// this.initSdkBtn();
		},
		computed: {
			getParamsMapping() {
				return this.$store.getters.getParamsMapping;
			},
			getParams() {
				return this.$store.getters.getParams;
			},
			getLanguage() {
				return this.$store.getters.getLanguage;
			},
			getOs() {
        return this.$store.getters.getOs;
      },
      inter() {
        return this.$store.getters.getInteraction;
      },
		},
		beforeUpdate() {
			this.encryp();

			if (this.getLanguage != "" && this.getLanguage != null) {
				this.$i18n.locale = langChange(this.getLanguage);
			}
			// if(localStorage.getItem("i18nLanguage") != null && localStorage.getItem("i18nLanguage") != '') {
			//     this.$i18n.locale = localStorage.getItem("i18nLanguage");
			//     // console.log(localStorage.getItem("i18nLanguage"));
			// }

		},
		methods: {
			initSdkBtn(){
            const p = this.inter;
            let params={
                [p.resultVue]:true
            }
            const str = `'{"` +p.type +`":"` +p.vue +`","` +p.subType +`":"` +p.initVue +`","` +p.msg +`":`+JSON.stringify(params)+`}'`;
            if (this.getOs == "ios") {
                // window.webkit.messageHandlers.OsJsBridge.postMessage(`{"type":"audio","subType":"permission","msg":""}`);
                const ios =`window.webkit.messageHandlers.` +p.obj +`.postMessage(` +str +`);`;
                eval(ios);
            } else {
                // OsJsBridge.execute(`{"type":"audio","subType":"permission","msg":""}`);
                const android = p.obj + `.execute(` + str + `);`;
                eval(android);
            }
        },

			encryp() {
				const p = this.getParamsMapping;

				if (this.$route.query[p.p] != null) {
					const str = this.$route.query[p.p].replace(/ /g, "+").replace(/\n/g, '').replace(/\r/g, "");
					// console.log(str);
					// console.log(Decrypt(str));
					var pr = JSON.parse(Decrypt(str));
					console.log(pr,'pr');

					this.$store.commit("SET_CHANNEL_ID", pr[p.channelId]);
					this.$store.commit("SET_UID", pr[p.uid]);
					this.$store.commit("SET_OS", pr[p.os]);
					this.$store.commit("SET_LANGUAGE", pr[p.language]);
					this.$store.commit("SET_OS_VERSION", pr[p.osVersion]);
					this.$store.commit("SET_DEVICE_ID", pr[p.deviceId]);
					this.$store.commit("SET_APP_PKG_NAME", pr[p.appPkgName]);
					this.$store.commit("SET_APP_VERSION", pr[p.appVersion]);
					this.$store.commit("SET_SDK_VERSION", pr[p.sdkVersion]);
					this.$store.commit("SET_RESOLUTION", pr[p.resolution]);
					this.$store.commit("SET_MODEL", pr[p.model]);

					this.$store.commit("SET_NOTIFICATION_ID", pr[p.notificationId]);
					this.$store.commit("SET_NOTIFICATION_TYPE", pr[p.notificationType]);

					let watchList = []
					for (let i = 0; i < pr[p.watchList].length; i++) {
						const item = pr[p.watchList][i];
						// console.log(item);
						const watchItem = {
							watchId: item[p.watchId],
							watchName: item[p.watchName],
							watchAvatarUrl: item[p.watchAvatarUrl],
							watchPayModel: item[p.watchPayModel],
							watchLicenseModel: item[p.watchLicenseModel],
						};
						watchList.push(watchItem);
					}
					this.$store.commit("SET_WATCH_LIST", watchList);




					// 通知跳转页面
					if (pr[this.getPNotificationType] == "1") {
						// 手表问答通知
						this.$router.push({
							name: 'chatPage',
							params: {
								activity: 2,
								watchId: pr[this.getPWatchId]
							}
						});
						this.$nextTick(() => {
							this.$store.commit("SET_NOTIFICATION_TYPE", '0');
						})
					} else if (pr[this.getPNotificationType] == "2") {
						// 手表画图通知
						this.$router.push({
							name: 'drawPage',
							params: {
								activity: 2
							}
						});
						this.$nextTick(() => {
							this.$store.commit("SET_NOTIFICATION_TYPE", '0');
						})
					}
				}
			},
			test() {
				// console.log("test____");s
				// this.$router.push({name:'drawPage', params: { activity: 2}});
				this.$router.push({
					name: 'chatPage',
					params: {
						activity: 2,
						watchId: '862300141424755'
					}
				});
			}
		},
	};
</script>

<style>
	.netBox {
		width: 97vw;
		height: 19vw;
		background-color: rgb(252, 206, 213);
		border-radius: 3vw;
		position: fixed;
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 12vw;
		margin-left: 1.5vw;
	}

	.scale-up-center {
		animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	}

	img,
	p {
		user-select: none;
	}

	* {
		margin: 0;
		padding: 0;
		border: 0;
	}

	#app {
		/* font-family: Avenir, Helvetica, Arial, sans-serif; */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@import "./assets/font/font.css";

	p,
	div {
		font-family: "SFUIDisplay-Regular";
	}

	/* .popper-scrollbar-show .el-scrollbar > .el-scrollbar__bar.is-vertical {
    opacity: 1 !important;
} */
	.el-scrollbar .el-scrollbar__bar {
		opacity: 1 !important;
	}
</style>