module.exports = {
	language: "پنجابی",
	navigator: {
		tools: "اے آئی ٹولز",
		draw: "بنانا",
		healthy: "سلامت",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["سفارش", "مطالعہ","زندگی", "تخلیق", "کم"],
			presetGreet: "ارے، ہیلو~ میں تہاڈا صحت دا سٹورڈ آں، تہاڈی صحت دی حفاظت کرن چ تہاڈی مدد لئی دلچسپ تے مفید صحت دا علم فراہم کرن لئی ہمیشہ تیار آں!",
			presetQuiz: ["دائمی تھکاوٹ دی وجوہات کی نیں؟", "نوجوان دائمی بیماریاں توں کیویں بچ سکدے نیں؟", "سلامت کھان پین دیاں عادتاں نوں کیویں برقرار رکھیا جاوے؟", "چنگی نیندر نوں کیویں برقرار رکھیا جاوے؟"],
			presets: "تسی مینوں پچھ سکدے او:",
			change: "بیچ تبدیل کرو",
			uploadImage: "تصویر بھیجو",
			footer: "چونکہ مواد اک اے آئی ماڈل دے ذریعہ تیار کیتا جاندا اے، اس لی اس دی صداقت دی مکمل ضمانت نئیں دتی جا سکدی۔",
			remind: "Todo reminder",
			limit: "ایہہ گل بات حد تک پہنچ گئی اے، مہربانی کر کے اک نواں اے آئی چیٹ سیشن شروع کرو",
			newDialog: "نویں گل بات شروع کرو",
			stopRecording: "تسلیم کرن لئی کدھرے وی یا جدوں الٹی گنتی ختم ہووے تے کلک کرو",
			send: "سنیہا بھیجو",
			say: "گل کرن لئی کلک کرو",
			notImage: "ایہ ایجنٹ فائلاں تے تصویراں بھیجن دی حمایت نئیں کردا",
			talking: "موجودہ گل بات جاری اے",
			drawViolations: "اے آئی ڈرائنگ پرامپٹ قوانین دی خلاف ورزی کردا اے، براہ کرم اک تعمیل پرامپٹ ان پٹ کرو۔",
			exhausted: "سوال جواب دی تعداد ختم ہو چکی اے، مہربانی کر کے واچ اینڈ تے جاؤ تے جاری رکھن لئی کیو آر کوڈ سکین کرو۔",
			serverBusy: "موجودہ نیٹ ورک دا لنک غیر مستحکم اے، مہربانی کر کے دوبارہ کوشش کرو",
			chatHistory: "چیٹ دی تاریخ",
			chatHistoryDesc: "سوال جواب دے ریکارڈ صرف 3 دن لئی رکھے جاندے نیں",
			todoList: "سب کجھ تیار",
			todoDesc: "ساری تفصیلات",
			todoListDesc: "ودھ توں ودھ 10 ٹوڈو موجود ہو سکدے نیں",
			confirm: "تصدیق کرنا",
			cancel: "منسوخ کرو",
			text: "متن خالی نئیں ہو سکدا",
			cancelRemind: "یاد دہانی منسوخ کرو",
			remindTime: "یاد دہانی دا ویلہ:",
			remindTitle: "یاد دہانی دا عنوان:",
			remindContent: "یاد دہانی دا مواد:",
			edit: "تدوین کرنا",
			reachLimit: "حد تک شامل کیتا گیا",
			errorChat:"آواز ہلے نئیں لبھی، مہربانی کر کے دوبارہ کوشش کرو",
			      noSTT:"اجازت حاصل نئیں کیتی گئی، مہربانی کر کے جاری رکھن توں پہلے اجازت فعال کرو۔",
      error50:"اک نامعلوم غلطی ہوئی اے، مہربانی کر کے دوبارہ کوشش کرو"
		},
		draw: {
			describe: "اپنی تصویر دسو",
			voice: "وائس ان پٹ",
			press: "گل کرن لئی دباؤ",
			placeholder: "اوہ تصویر دسو جہڑی تسی بنانا چاہندے او",
			style: "پینٹنگ سٹائل",
			styleList: ["انک سٹائل", "سائبرپنک", "انیمی", "اوریگامی", "بنائی",  "گرافکس", "کارٹون","لیگو","پنسل","ڈرا"],
			record: "ریکارڈ ڈرائنگ",
			recordDesc: "10 تک دی تصویریں سنبھالی جا سکدیاں نیں",
recordEmpty: "تہاڈے کول ہن ویلے کوئی وی تصویریں نہیں نے",
generate: "تصویر تیار کرو",
result: "تصویتصویری نتیجے",
download: "تصویر ڈاؤنلوڈ کرو",
regenerate: "دوارہ تیار کرو",
setDial: "واچ فیس دے طور تے سیٹ کرو",
error: "تصویر تیار کرنے چ ناکام",
errorDesc: "نیٹ ورک کنکشن غیر مستحکم اے، دوبارہ جمع کرواؤ۔",
confirm: "پکّی کرو",
analysiserror: "تجزیہ ناکام",
SetWatchErr:["ڈائل کامیابی نال سیٹ ہو گیا","بھیجنا منسوخ کرو","ڈیٹا دی تصدیق ناکام ہو گئی","ڈیٹا دی تصدیق ناکام ہو گئی","واچ چہرے دی مطابقت پذیری اوپری حد تک پہنچ گئی اے","بلوٹوتھ منقطع کر دتا گیا اے"],
SetWatchTips:["سیٹ اپ ناکام ہو گیا","ڈائل سیٹنگ جاری اے","گھڑی دا چہرہ سیٹ کیتا جا رہیا اے، مہربانی کر کے موجودہ صفحے توں باہر نہ نکلو۔"],
progressText:"ਸਿੰਕ ਕਰਨਾ"

},
		sports: {
			analyze: "صحت دا تجزیہ",
			analyzeDesc: "بنیادی ڈیٹا دا تجزیہ کرو",
			advice: "صحت دا مشورہ",
			adviceDesc: "چنگا مشورہ",
			experience: "تجربہ",
			program: {
				plan: "وزن وچ کمی دا منصوبہ",
				startDate: "شروعات دی تاریخ منتخب کرو",
				chooseDate: "براہ کرم ایک دن نوں شروعات دی تاریخ ویکھو",
				week: [{
					num: "اتوار",
					day: "اتوار"
				}, {
					num: "پیر",
					day: "سوموار"
				}, {
					num: "منگل",
					day: "منگل"
				}, {
					num: "بدھ",
					day: "بدھ"
				}, {
					num: "جمعرات",
					day: "ستیرا"
				}, {
					num: "جمعہ",
					day: "جمعہ"
				}, {
					num: "ہفتہ",
					day: "ہفتہ"
				}, ],
				arrange: "پلان بندوبست",
				arrangeDesc: "سب توں نیڑے دی تاریخ توں شروعات کرو، کل 7 ٹریننگ دیاں دن؛ خاص مواد درج ذیل اے:",
				confirm: "بندوبست کی تصدیق کرو",
				success: "مبارکاں، تسی پلا نوں مکمل طور تے تیار پا لیندی اے! 'ورزش دی موجودگی' صفحے توں اپنی ٹریننگ شروع کرو!",
				successButton: "ورزش دی موجودگی نوں جاؤ",
			},
			newmessage: {
				message1: ['سنہری مرکز', 'صحت کا ڈیٹا'],
				message2: ['صحت', 'ورزش', 'غذا'],
				message3: ['جندر', 'عمر', 'قد', 'وزن'],
				message4: ['نیند', 'HR', 'آکسیجن', 'قدم', 'BP', 'پریشر', 'خلاصہ'],
				message5: ['خرچ کرنا', 'کیلوری', 'آج کے قدم',
					'ورزش کا دورانیہ '],
				message6: ['ورزش چیک-ان', 'فٹنس ہوم ایڈیشن',
					'فٹنس پرو', 'وزن کم کرنے کا منصوبہ'
				],
				message7: ['ورزش کریں اور سلامت زندگی گزاریں',
					'اپنے فٹنس پلان کو خود بنائیں', 'اپنے فٹنس پلان کو خود بنائیں',
					'آپ کے لئے وزن کم کرنے کا منصوبہ بنائیں'
				],
				message8: ['تجویز', 'قدم', 'منٹ', 'سیٹ', 'دن', 'سال', 'سینٹی میٹر', 'کلو','گھنٹے','ہفتہ '],
				message9: ['چیک-ان', 'ٹریننگ', 'ورزش کے اختیارات', 'میری کامیابیاں',
					'مسلسل چیک-ان دن', 'چیک-ان نہیں ہوا'
				],
				message10: ['تجزیہ کے لئے کلک کریں', 'تجزیہ کی تاریخ (زیادہ سے زیادہ 10 محفوظ شدہ)', 'تجزیہ کریں',
					'آپ کے پاس فی الحال کوئی تاریخی ریکارڈ نہیں ہے', 'مسلسل چیک-ان دن', 'ڈیٹا تجزیہ'
				],
				message11: ['عورت', 'مرد'],
				message12: ['منسوخ کریں', 'تصدیق کریں'],
				message13: ['سال', 'سینٹی میٹر', 'کلوگرام'],
				message14: ['دستی ان پٹ',  ' متوقع ویلہ (ہفتے)', 'جنس', 'عمر', 'قد (سینٹی میٹر)',
					'وزن (کلوگرام)', 'متوقع وزن (کلوگرام)'
				],
				message15: ['تاریخ', 'صحت کے ڈیٹا کا تجزیہ', 'صحت کی مشورہ'],
				message16: ['چیک-ان کامیاب', 'چیک - ان ہو رہا ہے', 'صرف اسی دن چیک-ان کیا جاسکتا ہے'],
				message17: ['صحت کے ڈیٹا کا تجزیہ ایک دن میں صرف ایک بار حاصل کیا جاسکتا ہے, اور مقامی ڈیٹا نہیں مل سکتا', 'صحت کی مشورہ ایک دن میں صرف ایک بار حاصل کی جاسکتی ہے، اور مقامی ڈیٹا نہیں مل سکتا'],
				message18:['فی الحال کم وزن','آپ کا BMI انڈیکس ہے','وزن کم کرنا مناسب نہیں ہے (سلامت حد 18.5<BMI<24)'],
				message19:['معتدل وزن کم کریں','روزانہ 500 گرام سے زیادہ وزن کم کرنے کی سفارش نہیں کی جاتی ہے'],
				message20:['منصوبے ہیں','کیا یہ موجودہ منصوبوں کو پورا کرتا ہے'],
				message21:['آسان','عام','درمیانہ','اعلی'],
				message22:['روح:','کم','زیادہ'],
				message23:['جنی وار استعمال ہو چکیا اے,ہور وار انلاک کرن لئی اک سبسکرپشن خریدو'],
			},
		},
		punch: {
			title: "مکمل ہوگیا",
			title2: "ختم کرنا",
			h1: "ورزش کے اختیارات",
			h2: "میری کامیابیاں",
			button: "چیک-ان",
			day: "مسلسل چیک-ان دن",
			dayunit: "دن",
		},
		eat: {
			title: "غذا",
			DietaryIntake: "کھانا",
			kcal: "کلو کیلوریز",
			Stillingestible: "باقی",
			overingest: "بے حد",
			Recommendedintake: "تجویز کریں",
			Motorconsumption: "حرکت",
			carbohydrate: "کاربوہائڈریٹس",
			protein: "پروٹین",
			fat: "چربی",
			gram: "گرام",
			drinkDk: "پانی چیک-ان",
			drinkDkhealth: "روزانہ سلامت جسم کیلئے چیک-ان",
			eatsuggest: "غذائی تجاویز",
			analyzefoodglx: "غذا کی معقولیت کا تجزیہ کریں",
			eatPlan: "غذائی منصوبہ",
			makeeatPlan: "اپنے خصوصی غذائی منصوبے کو خود بنائیں",
			breakfast: "ناشتہ",
			lunch: "لنچ",
			dinner: "رات کا کھانا",
			snack: "سنیکس",
			addFood: "کھانا شامل کریں",
			noAddFood: "کوئی کھانا شامل نہیں کیا گیا",
			cancle: "منسوخ کریں",
			confirm: "تصدیق کریں",
			tips: "خود سے بھرنا ہمیں اس پراجیکٹ کی معلومات جمع کرنے کی اور غذائی میں ظاہر کرنے کی اجازت سمجھا جائے گا",
			addFooded: "کھانا شامل ہوا",
			added: "شامل ہوا",
			numFood: "کھانے کی اشیاء",
			record: "ریکارڈ",
			dkTitle: "چیک-ان",
			dailygoal: "روزانہ کا ہدف",
			drinkDw: "کپ/دن",
			drinkWater: "پانی پیو",
			dailygoalWater: "روزانہ پانی پینے کا ہدف",
			myachievement: "میری کامیابیاں",
			lxdkDays: "مسلسل چیک-ان دن",
			day: "دن",
			analyzeHistory: "تاریخ",
			conclusion: "خلاصہ",
			eatDataAnalyze: "غذائی ڈیٹا کا تجزیہ",
			selectStartDate: "شروع ہونے کی تاریخ منتخب کریں",
			chooseoneDate: "براہ کرم شروع ہونے کے لئے 1 دن منتخب کریں",
			scheduling: "منصوبہ ترتیب",
			schedulingDesc: "آپ کے منتخب کردہ قریب ترین تاریخ سے شروع کریں، کل 7 دن؛ مخصوص مواد مندرجہ ذیل ہے:",
			eatDetail:"غذائی تفصیلات",
			analyzeFailed:"تجزیہ ناکام ہوا",
			noData:"کوئی ڈیٹا نہیں"
		
		}
	},
  error: {
    error1101:"نیٹ ورک کنکشن غیر مستحکم اے، موجودہ سیشن ٹائم آؤٹ",
    error1102:"ایس ویلے کوئی نیٹ ورک نئیں، مہربانی کر کے دوبارہ جڑو",
    error1103:"اک نامعلوم غلطی ہو گئی",
    error61:"موجودہ خدمت دستیاب نئیں اے",
    error4:"آواز ہلے نئیں لبھی، مہربانی کر کے دوبارہ کوشش کرو",
    error12:"مہربانی کر کے اے آئی نوں سمجھن تے کھچن لئی معیاری الفاظ یا جملے درج کرو",
		error50:"معذرت، موجودہ سرور تے کوئی غلطی اے، مہربانی کر کے دوبارہ کوشش کرو"

  },
	setting: {
		index: {
			title: "ترتیبات",
			problem: "مسئلہ کی رائے",
			order: "آرڈر ریکارڈز",
			notice: "نوٹیفکیشن",
			language: "زبان",
			clear: "کیچے کو صاف کریں",
			clearTip: "کیا آپ واقعی\n کیچے کو صاف کرنا چاہتے ہیں؟",
			confirm: "تصدیق کریں",
			cancel: "منسوخ کریں",
		},
		language: {
			title: "زبان",
			langList: ["آسان چینی", "روایتی چینی", "انگریزی", "عربی", "بلغی", "بنگالی", "چیک", "جرمن", "یونانی", "ہسپانوی", "فارسی", "فلپائنی", "فرانسیسی", "ہندی", "ہنگیرین", "انڈونیشی", "اطالوی", "عبرانی", "جاپانی", "کوریائی", "مالے", "ڈچ", "پنجابی", "پولش", "پرتگالی", "رومانی", "روسی", "سلوواک", "تھائی", "ترکی", "یوکرینی", "اردو", "ویتنامی"],
		},
		order: {
			title: "آرڈر ریکارڈز",
			success: "کامیابی سے حاصل ہوا",
			unpaid: "غیر ادا شدہ",
			paid: "ادا شدہ",
			expired: "ختم شدہ",
			refunded: "واپس کیا گیا",
			pTime: "ادائیگی کا وقت",
			eTime: "ختم ہونے کا وقت",
			pMethod: "ادائیگی کا طریقہ",
			pObject: "ادائیگی کا مقصد",
			oNumber: "آرڈر نمبر",
			pType: "پیکج کی قسم",
			oTime: "حاصل کرنے کا وقت",
			watch: "IMEI دیکھیں",
			delete: "آرڈر حذف کریں",
			refund: "واپسی کی درخواست کریں",
		},
		problem: {
			title: "مسئلہ کی رائے",
			feedback: "رائے کی قسم",
			please: "براہ کرم منتخب کریں",
			content: "مواد",
			improve: "براہ کرم اپنے مشورے لکھیں، جیسے فیچر کی درخواستیں، پراڈکٹ کی شکایات وغیرہ۔ ہم بہتر بنانے کے لئے کوشش کریں گے",
			upload: "تصویر اپ لوڈ کریں",
			submit: "جمع کرائیں",
			general: "عام رائے",
			purchase: "خریداری متعلق",
			bug: "بگ رپورٹ",
			suggestion: "مشورہ",
			other: "دیگر",
		},
		notice: {
			title: "نوٹیفکیشن",
			type: "نوٹیفکیشن کی قسم: ",
			time: "نوٹیفکیشن کا وقت: "
		},
		unsubscribe: {
			watch: "گھڑی:",
			title: "مسلسل سبسکرپشن",
			subTitle: "سبسکرائب کیا گیا",
			unsubscribe: "ان سبسکرائب کریں",
			packList: ["پریمیئم سبسکرپشن", "بنیادی سبسکرپشن", "معیاری سبسکرپشن"],
		},
		refund: {
			title: "واپسی کی درخواست کریں",
			number: "آرڈر نمبر",
			content: "براہ کرم واپسی کی وجہ کی وضاحت کریں",
			packList: ["نئے صارف کی رعایتی پیکیج", "50 مرات پیکیج", "100 مرات پیکیج",
				"300 مرات پیکیج"
			],
		}
	},
	tutorial: {
		index: {
			title: "ایڈوانسڈ ٹیوٹوریل حاصل کریں",
			watchList: ["گھڑی کو آن کریں, WearAI ایپلیکیشن کو تلاش کریں اور کھولنے کے لئے کلک کریں.",
				"ہوم پیج پر ایڈوانسڈ فوائد بٹن پر کلک کریں.",
				"اپنے موبائل فون کو نکالیں اور فوائد کے صفحہ میں داخل ہونے کے لئے گھڑی پر QR کوڈ کو اسکین کریں."
			]
		}
	},
}