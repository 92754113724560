module.exports = {
	language: "Français",
	navigator: {
		tools: "Outils d'IA",
		draw: "Dessiner",
		healthy: "Sain",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Recommander", "Étudier", "Vie", "Création", "Travail"],
			presetGreet: "Hey, bonjour~ Je suis votre intendant de la santé, toujours prêt à vous fournir des connaissances intéressantes et utiles sur la santé pour vous aider à protéger votre santé!",
			presetQuiz: ["Quelles sont les causes de la fatigue chronique?", "Comment les jeunes peuvent-ils prévenir les maladies chroniques?", "Comment maintenir des habitudes alimentaires saines?", "Comment maintenir un bon sommeil?"],
			presets: "Vous pouvez me demander:",
			change: "Changer de lot",
			uploadImage: "Envoyer une image",
			footer: "Étant donné que le contenu est généré par un modèle d'IA, son authenticité ne peut être entièrement garantie.",
			remind: "Rappel Todo",
			limit: "Cette conversation a atteint la limite, veuillez commencer une nouvelle session de chat IA",
			newDialog: "Commencer une nouvelle conversation",
			stopRecording: "Cliquez n'importe où ou attendez la fin du compte à rebours pour reconnaître",
			send: "Envoyer le message",
			say: "Cliquez pour parler",
			notImage: "Cet agent ne prend pas en charge l'envoi de fichiers et d'images",
			talking: "Conversation en cours",
			drawViolations: "L'invite de dessin de l'IA viole les règles, veuillez entrer une invite conforme.",
			exhausted: "Le nombre de questions-réponses est épuisé, veuillez vous rendre à la fin de la montre et scanner le QR code pour continuer.",
			serverBusy: "La connexion réseau actuelle est instable, veuillez réessayer",
			chatHistory: "Historique du chat",
			chatHistoryDesc: "Les enregistrements de questions-réponses ne sont conservés que pendant 3 jours",
			todoList: "Liste de tâches",
			todoDesc: "Détails de la tâche",
			todoListDesc: "Un maximum de 10 tâches peuvent exister",
			confirm: "Confirmer",
			cancel: "Annuler",
			text: "Le texte ne peut pas être vide",
			cancelRemind: "Annuler le rappel",
			remindTime: "Heure du rappel:",
			remindTitle: "Titre du rappel:",
			remindContent: "Contenu du rappel:",
			edit: "Éditer",
			reachLimit: "Ajouté jusqu'à la limite",
			errorChat:"La voix n'est pas encore détectée, veuillez réessayer",
			noSTT:"Autorisation non obtenue, veuillez activer l'autorisation avant de continuer.",
      error50:"Une erreur inconnue s'est produite, veuillez réessayer"
		},
		draw: {
			describe: "Décrivez votre image",
			voice: "Saisie vocale",
			press: "Appuyer pour parler",
			placeholder: "Décrivez l'image que vous souhaitez générer",
			style: "Style de peinture",
			styleList: ["Style encre", "Cyberpunk", "Anime", "Origami", "Tricotage", "Graphiques", "Dessin animé","LEGO","Crayon", "Dessiner"],
			record: "Enregistrement de dessin",
			recordDesc: "Enregistrez 10 photos dans l'historique",
			recordEmpty: "Pas encore d'enregistrement",
			generate: "Générer l'image",
			result: "Résultat",
			download: "Télécharger",
			regenerate: "Encore",
			setDial: "Définir comme cadran de montre",
			error: "Échec de la génération d'image",
			errorDesc: "Connexion réseau instable, veuillez renvoyer.",
			confirm: "Confirmer",
			analysiserror: "L'analyse a échoué",
      SetWatchErr:["Le numéro a été défini avec succès","Annuler l'envoi","La vérification des données a échoué","Le fichier de données du cadran de la montre est trop volumineux","La synchronisation du cadran de la montre a atteint la limite supérieure","Le Bluetooth a été déconnecté"],
      SetWatchTips:["Échec de l'installation","Réglage du cadran en cours","Le cadran de la montre est en cours de réglage, veuillez ne pas quitter la page en cours."],
      progressText:"En synchronisation avec"

		},
		sports: {
			analyze: "Analyse de santé",
			analyzeDesc: "Données de base",
			advice: "Conseils de santé",
			adviceDesc: "Des conseils judicieux",
			experience: "Expérience",
			program: {
				plan: "Plan de perte de poids",
				startDate: "Choisir la date de début",
				chooseDate: "Veuillez sélectionner 1 jour comme date de début",
				week: [{
					num: "Dim.",
					day: "Dimanche"
				}, {
					num: "Lun.",
					day: "Lundi"
				}, {
					num: "Mar.",
					day: "Mardi"
				}, {
					num: "Mer.",
					day: "Mercredi"
				}, {
					num: "Jeu.",
					day: "Jeudi"
				}, {
					num: "Ven.",
					day: "Vendredi"
				}, {
					num: "Sam.",
					day: "Samedi"
				}, ],
				arrange: "Plan d'organisation",
				arrangeDesc: "Commencez à partir de la date la plus proche que vous choisissez, un total de 7 jours d'entraînement ; le contenu spécifique est le suivant :",
				confirm: "Confirmer l'organisation",
				success: "Félicitations, vous avez personnalisé le plan avec succès ! Commencez votre entraînement sur la page 'Enregistrement d'exercice' !",
				successButton: "Aller à l'enregistrement d'exercice",
			},
			newmessage: {
				message1: ['Centre personnel', 'Données de santé'],
				message2: ['Santé', 'Exercice', 'Régime alimentaire'],
				message3: ['Genre', 'Âge', 'Taille', 'Poids'],
				message4: ['Sommeil', 'FR', 'Oxygène', 'Pas', 'TA', 'Pression', 'Résumé'],
				message5: ['État', 'Suggérer', 'Pas du jour',
					'Durée de l\'exercice '
				],
				message6: ['Enregistrement d\'exercice', 'Édition maison de fitness',
					'Fitness Pro', 'Plan de perte de poids'
				],
				message7: ['Faire de l\'exercice et mener une vie saine',
					'Personnalisez votre plan de fitness', 'Personnalisez votre plan de fitness',
					'Créer un plan de perte de poids pour vous'
				],
				message8: ['Kcal', 'Pas', 'Minutes', 'Séries', 'Jours', 'Années', 'cm', 'kg', 'h','Semaines'],
				message9: ['Enregistrer', 'Entraînement', 'Options d\'exercice', 'Mes réalisations',
					'Jours de consécutifs d\'enregistrement', 'Pas d\'enregistrement'
				],
				message10: ['Cliquer pour analyser', 'Historique des analyses (jusqu\'à 10 sauvegardés)', 'Analyser',
					'Vous n\'avez actuellement aucun enregistrement historique', 'Jours de consécutifs d\'enregistrement', 'Analyse de données'
				],
				message11: ['Femme', 'Homme'],
				message12: ['Annuler', 'Confirmer'],
				message13: ['Années', 'Centimètres', 'Kilogrammes'],
				message14: ['Saisie manuelle', 'Délai prévu (Semaines)    ', 'Genre', 'Âge', 'Taille (Centimètres)',
					'Poids (Kilogrammes)', 'Poids attendu (Kilogrammes)'
				],
				message15: ['Historique', 'Analyse des données de santé', 'Conseils de santé'],
				message16: ['Enregistrement réussi', 'Enregistrement en cours', 'Peut s\'enregistrer uniquement le jour même'],
				message17: ['L\'analyse des données de santé ne peut être obtenue qu\'une fois par jour, et les données locales ne peuvent pas être trouvées', 'Les conseils de santé ne peuvent être obtenus qu\'une fois par jour, et les données locales ne peuvent pas être trouvées'],
				message18: ['Actuellement en sous-poids', 'Votre indice IMC est', 'il n\'est pas conseillé de continuer à perdre du poids (Plage saine 18.5<IMC<24)'],
				message19: ['Perte de poids modérée', 'Il est recommandé de ne pas perdre plus de 500g par jour'],
				message20: ['avoir des plans', 'Cela couvre-t-il les plans existants'],
				message21: ['Facile', 'Normal', 'Moyen', 'Élevé'],
				message22: ['Esprit:', 'Faible', 'Élevé'],
				message23: ['Le nombre de fois a été utilisé. Achetez un abonnement pour débloquer plus de fois.'],
			},
		},
		punch: {
			title: "Terminé",
			title2: "Finir",
			h1: "Options d'exercice",
			h2: "Mes réalisations",
			button: "Enregistrer",
			day: "Check-ins continu",
			dayunit: "Jours",
		},
		eat: {
			title: "Régime",
			DietaryIntake: "Alimentation",
			kcal: "Kcal",
			Stillingestible: "Restant",
			overingest: "Au-delà",
			Recommendedintake: "Suggérer",
			Motorconsumption: "Mouvement",
			carbohydrate: "Glucides",
			protein: "Protéines",
			fat: "Lipides",
			gram: "g",
			drinkDk: "Enregistrement d'eau",
			drinkDkhealth: "L'eau potable est plus saine",
			eatsuggest: "Suggestions alimentaires",
			analyzefoodglx: "Analyser la rationalité du régime",
			eatPlan: "Plan de régime",
			makeeatPlan: "Personnaliser votre plan de régime exclusif",
			breakfast: "Matin",
			lunch: "Déjeuner",
			dinner: "Dîner",
			snack: "Snack",
			addFood: "Ajouter de la nourriture",
			noAddFood: "Pas de nourriture",
			cancle: "Annuler",
			confirm: "Confirmer",
			tips: "Compléter de manière proactive est considéré comme un consentement à notre collecte de ces informations de projet et les afficher dans le régime",
			addFooded: "Nourriture ajoutée",
			added: "Ajouter",
			numFood: "aliment",
			record: "Enregistrer",
			dkTitle: "Enregistrer",
			dailygoal: "Objectif quotidien",
			drinkDw: "Tasses/Jour",
			drinkWater: "Boire de l'eau",
			dailygoalWater: "Objectif eau potable",
			myachievement: "Mes réalisations",
			lxdkDays: "Check-ins continu",
			day: "Jours",
			analyzeHistory: "Historique",
			conclusion: "Résumé",
			eatDataAnalyze: "Analyse des données alimentaires",
			selectStartDate: "Choisissez la date de début",
			chooseoneDate: "Veuillez sélectionner 1 jour comme date de début",
			scheduling: "Plan d'organisation",
			schedulingDesc: "Commencez à partir de la date la plus proche que vous choisissez, un total de 7 jours; le contenu spécifique est le suivant:",
			eatDetail: "Détails du régime",
			analyzeFailed: "Échec de l'analyse",
			noData: "pas de données"
		}
	},
  error: {
    error1101:"La connexion réseau est instable, le délai d'expiration de session actuel",
    error1102:"Il n'y a actuellement aucun réseau, veuillez vous reconnecter.",
    error1103:"Une erreur inconnue s'est produite",
    error61:"Le service actuel n'est pas disponible",
    error4:"La voix n'est pas encore détectée, veuillez réessayer",
    error12:"Veuillez saisir des mots ou des phrases standard pour que l'IA puisse comprendre et dessiner.",
  	error50:"Désolé, le serveur actuel est faux, veuillez réessayer"
	},
	setting: {
		index: {
			title: "Paramètres",
			problem: "Retour d'information",
			order: "Historique des commandes",
			notice: "Notification",
			language: "Langue",
			clear: "Vider le cache",
			clearTip: "Êtes-vous sûr de vouloir\nvider le cache ?",
			confirm: "Confirmer",
			cancel: "Annuler",
		},
		language: {
			title: "Langue",
			langList: ["Chinois simplifié", "Chinois traditionnel", "Anglais", "Arabe", "Bulgare", "Bengali", "Tchèque", "Allemand", "Grec", "Espagnol", "Persan", "Filipino", "Français", "Hindi", "Hongrois", "Indonésien", "Italien", "Hébreu", "Japonais", "Coréen", "Malais", "Néerlandais", "Panjabi", "Polonais", "Portugais", "Roumain", "Russe", "Slovaque", "Thaï", "Turc", "Ukrainien", "Ourdou", "Vietnamien"],
		},
		order: {
			title: "Historique des commandes",
			success: "Récupéré avec succès",
			unpaid: "Non payé",
			paid: "Payé",
			expired: "Expiré",
			refunded: "Remboursé",
			pTime: "Heure de paiement",
			eTime: "Heure d'expiration",
			pMethod: "Méthode de paiement",
			pObject: "Objet de paiement",
			oNumber: "Numéro de commande",
			pType: "Type de forfait",
			oTime: "Heure de récupération",
			watch: "Voir IMEI",
			delete: "Supprimer la commande",
			refund: "Demander un remboursement",
		},
		problem: {
			title: "Retour d'information",
			feedback: "Type de retour",
			please: "Veuillez sélectionner",
			content: "Contenu",
			improve: "Veuillez écrire vos suggestions, telles que des demandes de fonctionnalités, des plaintes sur le produit, etc. Nous nous efforcerons d'améliorer",
			upload: "Télécharger une image",
			submit: "Soumettre",
			general: "Retour général",
			purchase: "Relatif à l'achat",
			bug: "Rapport de bug",
			suggestion: "Suggestion",
			other: "Autre",
		},
		notice: {
			title: "Notification",
			type: "Type de notification : ",
			time: "Heure de notification : "
		},
		unsubscribe: {
			watch: "Montre :",
			title: "Abonnement continu",
			subTitle: "Abonné",
			unsubscribe: "Se désabonner",
			packList: ["Abonnement Premium", "Abonnement de base", "Abonnement Standard"],
		},
		refund: {
			title: "Demander un remboursement",
			number: "Numéro de commande",
			content: "Veuillez expliquer la raison du remboursement",
			packList: ["Nouveau forfait à tarif réduit", "Forfait 50 fois", "Forfait 100 fois",
				"Forfait 300 fois"
			],
		}
	},
	tutorial: {
		index: {
			title: "Obtenir le tutoriel avancé",
			watchList: ["Allumez la montre, trouvez l'application WearAI et cliquez pour ouvrir.",
				"Cliquez sur le bouton avantages avancés sur la page d'accueil.",
				"Sortez votre téléphone portable et scannez le QR code sur la montre pour accéder à la page des avantages."
			]
		}
	},
}