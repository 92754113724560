import Vue from 'vue'
import Router from 'vue-router'

// import layout from '../layout/layout';
import navbar from '../layout/navbar';


Vue.use(Router)

export const constantRoutes = [
  // {
  //   path: '/',
  //   component: layout,
  //   children: [
      {
        path: '',
        component: navbar,
        children: [
          {
            name: 'aitools',
            path: '/',
            component: () => import('@/views/aitools'),
          },
          {name: 'healthy',
            path: '/healthy',
            component: () => import('@/views/sports'),
          },
          {
            name: 'drawPage',
            path: '/draw',
            component: () => import('@/views/draw'),
          },
          {
            path: '/dial',
            component: () => import('@/views/dial'),
          },
          // {
          //   path: 'astrol',
          //   component: () => import('@/views/astrol'),
          // },
          // {
          //   path: 'scan',
          //   component: () => import('@/views/scan'),
          // },
        ]
      },
      {
        name: 'chatPage',
        path: '/chatPage',
        component: () => import('@/views/aitools/chatPage')
      },
      {
        name: 'historyPage',
        path: '/historyPage',
        component: () => import('@/views/aitools/historyPage')
      },
      {
        name: 'historyDisplay',
        path: '/historyDisplay',
        component: () => import('@/views/aitools/historyDisplay')
      },
      {
        name: 'remindPage',
        path: '/remindPage',
        component: () => import('@/views/aitools/remindPage')
      },
      {
        name: 'remindDisplay',
        path: '/remindDisplay',
        component: () => import('@/views/aitools/remindDisplay')
      },
      {
        name: 'createPage',
        path: '/create',
        component: () => import('@/views/draw/create')
      },
      {
        name: 'taskPage',
        path: '/task',
        component: () => import('@/views/draw/task')
      },
      // {
      //   name: 'astrology',
      //   path: 'astrology',
      //   component: () => import('@/views/astrol/astrology')
      // },
	  {
	    name: 'lose-weight',
	    path: '/lose-weight',
	    component: () => import('@/views/sports/lose-weight')
	  },
      {
        name: 'analyze',
        path: '/analyze',
        component: () => import('@/views/sports/analyze')
      },
	  {
	    name: 'analyze-history',
	    path: '/analyze-history',
	    component: () => import('@/views/sports/analyze-history')
	  },
    {
	    name: 'analyzejy-history',
	    path: '/analyzejy-history',
	    component: () => import('@/views/sports/analyzejy-history')
	  },
	  {
	    name: 'analyzeone',
	    path: '/analyzeone',
	    component: () => import('@/views/sports/analyzeone')
	  },
    {
	    name: 'analyzeDetail',
	    path: '/analyzeDetail',
	    component: () => import('@/views/sports/analyzeDetail')
	  },
    {
	    name: 'analyzejyDetail',
	    path: '/analyzejyDetail',
	    component: () => import('@/views/sports/analyzejyDetail')
	  },
    
	  {
	    name: 'analyzeone-history',
	    path: '/analyzeone-history',
	    component: () => import('@/views/sports/analyzeone-history')
	  },
	  {
	    name: 'analyzeoneshow',
	    path: '/analyzeoneshow',
	    component: () => import('@/views/sports/analyzeoneshow')
	  },
	  {
	    name: 'suggestions',
	    path: '/suggestions',
	    component: () => import('@/views/sports/suggestions')
	  },
	  {
	    name: 'suggestions-history',
	    path: '/suggestions-history',
	    component: () => import('@/views/sports/suggestions-history')
	  },
	  {
	    name: 'suggestionspro',
	    path: '/suggestionspro',
	    component: () => import('@/views/sports/suggestionspro')
	  },
	  {
	    name: 'suggestionspro-history',
	    path: '/suggestionspro-history',
	    component: () => import('@/views/sports/suggestionspro-history')
	  },
      {
        name: 'program',
        path: '/program',
        component: () => import('@/views/sports/program')
      },
	  //打卡
	  {
	    name: 'punch',
	    path: '/punch',
	    component: () => import('@/views/sports/punch')
	  },
      // 扫描相关
      // {
      //   name: 'scanPage',
      //   path: 'scanPage/:id',
      //   component: () => import('@/views/scan/scanPage')
      // },
      // {
      //   name: 'cropPage',
      //   path: 'cropPage/:id',
      //   component: () => import('@/views/scan/cropPage')
      // },
      // {
      //   name: 'resultPage',
      //   path: 'resultPage/:id',
      //   component: () => import('@/views/scan/resultPage')
      // },

  //   ]
  // },
  {
    name: 'settingPage',
    path: '/setting',
    component: () => import('@/views/set/index')
  },
  {
    path: '/order',
    component: () => import('@/views/set/orderPage')
  },
  {
    path: '/unsubscribe',
    component: () => import('@/views/set/unsubscribe')
  },
  {
    name: 'refund',
    path: '/refund',
    component: () => import('@/views/set/refundPage')
  },
  {
    path: '/problem',
    component: () => import('@/views/set/problemPage')
  },
  {
    path: '/notice',
    component: () => import('@/views/set/noticePage')
  },
  {
    path: '/notice/detail/:id',
    component: () => import('@/views/set/noticeDetail')
  },
  {
    name: 'language',
    path: '/language',
    component: () => import('@/views/set/languagePage')
  },
  {
    name: 'tutorial',
    path: '/tutorial',
    component: () => import('@/views/tutorial/index')
  },
  {
    name: 'modelAitools',
    path: '/model/aitools',
    component: () => import('@/views/aitools'),
  },
  {
    name: "modelHealthy",
    path: '/model/healthy',
    component: () => import('@/views/sports'),
  },
  {
    name: 'modelDrawPage',
    path: '/model/draw',
    component: () => import('@/views/draw'),
  },
  {
    name: 'eatSuggest',
    path: '/eatSuggest',
    component: () => import('@/views/sports/components/eatSuggest')
  },
  {
    name: 'eatPlan',
    path: '/eatPlan',
    component: () => import('@/views/sports/components/eatPlan')
  },
  {
    name: 'eatPunch',
    path: '/eatPunch',
    component: () => import('@/views/sports/components/eatPunch')
  },
  {
    name: 'eat-analyze-history',
    path: '/eat-analyze-history',
    component: () => import('@/views/sports/components/eat-analyze-history')
  },
  {
    name: 'eatHistoryDetail',
    path: '/eatHistoryDetail',
    component: () => import('@/views/sports/components/eatHistoryDetail')
  },
]


let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
// 防止连续点击多次路由报错
