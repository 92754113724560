module.exports = {
	language: "Tiếng Việt",
	navigator: {
		tools: "Công cụ AI",
		draw: "Vẽ",
		healthy: "khỏe",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Gợi ý", "Học tập", "Cuộc sống", "Sáng tạo", "Công việc"],
			presetGreet: "Chào bạn! Tôi là người quản lý sức khỏe của bạn, luôn sẵn sàng cung cấp những kiến thức thú vị và hữu ích về sức khỏe để giúp bạn bảo vệ sức khỏe của mình!",
			presetQuiz: ["Nguyên nhân gây ra mệt mỏi mãn tính là gì?", "Làm thế nào để giới trẻ ngăn ngừa bệnh mãn tính?", "Làm thế nào để duy trì thói quen ăn uống lành mạnh?", "Làm thế nào để duy trì giấc ngủ ngon?"],
			presets: "Bạn có thể hỏi tôi:",
			change: "Thay đổi lô",
			uploadImage: "Gửi hình ảnh",
			footer: "Vì nội dung được tạo ra bởi mô hình AI, tính xác thực của nó không thể được đảm bảo hoàn toàn.",
			remind: "Nhắc nhở todo",
			limit: "Cuộc trò chuyện này đã đạt giới hạn, vui lòng bắt đầu một phiên trò chuyện AI mới",
			newDialog: "Bắt đầu một cuộc hội thoại mới",
			stopRecording: "Nhấn vào bất kỳ đâu hoặc khi đếm ngược kết thúc để nhận diện",
			send: "Gửi tin nhắn",
			say: "Nhấn vào đây để nói",
			notImage: "Đại lý này không hỗ trợ gửi tệp và hình ảnh",
			talking: "Cuộc trò chuyện hiện tại đang diễn ra",
			drawViolations: "Lời nhắc vẽ AI vi phạm quy tắc, vui lòng nhập lời nhắc hợp lệ.",
			exhausted: "Số lượng câu hỏi & câu trả lời đã hết, vui lòng đi đến phần kết thúc và quét mã QR để tiếp tục.",
			serverBusy: "Liên kết mạng hiện tại không ổn định, vui lòng thử lại",
			chatHistory: "Lịch sử trò chuyện",
			chatHistoryDesc: "Ghi chép Q&A chỉ được giữ trong 3 ngày",
			todoList: "Danh sách todo",
			todoDesc: "Chi tiết todo",
			todoListDesc: "Tối đa 10 công việc có thể tồn tại",
			confirm: "Xác nhận",
			cancel: "Hủy bỏ",
			text: "Văn bản không được để trống",
			cancelRemind: "Hủy nhắc nhở",
			remindTime: "Thời gian nhắc nhở:",
			remindTitle: "Tiêu đề nhắc nhở:",
			remindContent: "Nội dung nhắc nhở:",
			edit: "Chỉnh sửa",
			reachLimit: "Đã thêm đến giới hạn",
			errorChat:"Chưa phát hiện được giọng nói, vui lòng thử lại",
			      noSTT:"Không có quyền, vui lòng cho phép trước khi tiếp tục.",
      error50:"Đã xảy ra lỗi không xác định, vui lòng thử lại"
		},
		draw: {
			describe: "Mô tả hình ảnh của bạn",
			voice: "Nhập giọng nói",
			press: "Nhấn để nói",
			placeholder: "Mô tả hình ảnh bạn muốn tạo",
			style: "Phong cách vẽ",
			styleList: ["Phong cách mực", "Cyberpunk", "Anime", "Origami", "Đan len",  "Đồ họa", "Hoạt hình","LEGO","Bút chì","Vẽ"],
			record: "Ghi lại bản vẽ",
			recordDesc: "Có thể lưu tối đa 10 bản vẽ",
			recordEmpty: "Bạn hiện không có bản vẽ nào",
			generate: "Tạo hình ảnh",
			result: "Kết quả tạo hình ảnh",
			download: "Tải hình ảnh",
			regenerate: "Tạo lại",
			setDial: "Đặt làm mặt đồng hồ",
			error: "Tạo hình ảnh thất bại",
			errorDesc: "Kết nối mạng không ổn định, vui lòng gửi lại.",
			confirm: "Xác nhận",
			analysiserror: "Phân tích thất bại",
      SetWatchErr:["Quay số được đặt thành công","Hủy gửi","Xác minh dữ liệu không thành công","Tệp dữ liệu mặt đồng hồ quá lớn","Đồng bộ hóa mặt đồng hồ đã đạt đến giới hạn trên","Bluetooth đã bị ngắt kết nối"],
      SetWatchTips:["Thiết lập không thành công","Đang cài đặt quay số","Mặt đồng hồ đang được đặt, vui lòng không thoát khỏi trang hiện tại."],
			progressText:"Trong bộ đồng bộ"
		},
		sports: {
			analyze: "Phân tích sức khỏe",
			analyzeDesc: "Phân tích dữ liệu cơ bản",
			advice: "Lời khuyên sức khỏe",
			adviceDesc: "lời khuyên đúng đắn",
			experience: "Kinh nghiệm",
			program: {
				plan: "Kế hoạch giảm cân",
				startDate: "Chọn ngày bắt đầu",
				chooseDate: "Vui lòng chọn 1 ngày làm ngày bắt đầu",
				week: [{
					num: "CN.",
					day: "Chủ nhật"
				}, {
					num: "Thứ 2.",
					day: "Thứ hai"
				}, {
					num: "Thứ 3.",
					day: "Thứ ba"
				}, {
					num: "Thứ 4.",
					day: "Thứ tư"
				}, {
					num: "Thứ 5.",
					day: "Thứ năm"
				}, {
					num: "Thứ 6.",
					day: "Thứ sáu"
				}, {
					num: "Thứ 7.",
					day: "Thứ bảy"
				}, ],
				arrange: "Sắp xếp kế hoạch",
				arrangeDesc: "Bắt đầu từ ngày gần nhất bạn chọn, tổng cộng 7 ngày tập luyện; nội dung cụ thể như sau:",
				confirm: "Xác nhận sắp xếp",
				success: "Chúc mừng, bạn đã tùy chỉnh kế hoạch thành công! Bắt đầu tập luyện ở trang 'Check-in tập luyện'!",
				successButton: "Đi đến Check-in tập luyện",
			},
			newmessage: {
				message1: ['Trung tâm cá nhân', 'Dữ liệu sức khỏe'],
				message2: ['Sức khỏe', 'thao', 'Chế độ ăn uống'],
				message3: ['Giới tính', 'Tuổi', 'Chiều cao', 'Cân nặng'],
				message4: ['Giấc ngủ', 'HR', 'Oxy', 'Bước chân', 'HA', 'Áp lực', 'Tóm tắt'],
				message5: ['Tiêu hao', 'Đề xuất', 'Số bước hôm nay',
					'Thời gian tập luyện'],
				message6: ['Check-in tập luyện', 'Phiên bản tập luyện tại nhà',
					'Chuyên nghiệp tập luyện', 'Kế hoạch giảm cân'
				],
				message7: ['Tập luyện và sống khỏe mạnh',
					'Tùy chỉnh kế hoạch tập luyện của bạn', 'Tùy chỉnh kế hoạch tập luyện của bạn',
					'Tạo kế hoạch giảm cân cho bạn'
				],
				message8: ['Kcal', 'bước', 'Phút', 'Bộ', 'Ngày', 'Năm', 'cm', 'kg', 'h','Седмици'],
				message9: ['Check-in', 'Đào tạo', 'Tuỳ chọn tập luyện', 'Thành tích của tôi',
					'Số ngày Check-in liên tiếp', 'Chưa Check-in'
				],
				message10: ['Nhấn để phân tích', 'Lịch sử phân tích (lưu tối đa 10)', 'Phân tích',
					'Hiện tại bạn không có lịch sử lưu trữ', 'Số ngày Check-in liên tiếp', 'Phân tích dữ liệu'
				],
				message11: ['Nữ', 'Nam'],
				message12: ['Hủy', 'Xác nhận'],
				message13: ['Năm', 'Centimet', 'Kilôgam'],
				message14: ['Nhập tay', 'Очаквано време (Cедмици)', 'Giới tính', 'Tuổi', 'Chiều cao (Centimet)',
					'Cân nặng (Kilôgam)', 'Cân nặng dự kiến (Kilôgam)'
				],
				message15: ['Lịch sử', 'Phân tích dữ liệu sức khỏe', 'Lời khuyên sức khỏe'],
				message16: ['Check-in thành công', 'Đang check-in', 'Chỉ có thể check-in trong ngày'],
				message17: ['Phân tích dữ liệu sức khỏe chỉ có thể được nhận một lần mỗi ngày, và không tìm thấy dữ liệu cục bộ', 'Lời khuyên sức khỏe chỉ có thể được nhận một lần mỗi ngày, và không tìm thấy dữ liệu cục bộ'],
				message18: ['Hiện tại đang thiếu cân', 'Chỉ số BMI của bạn là', 'không nên tiếp tục giảm cân (Phạm vi khỏe mạnh 18.5<BMI<24)'],
				message19: ['Giảm cân vừa phải', 'Khuyến nghị giảm không quá 500g mỗi ngày'],
				message20: ['Đã có kế hoạch', 'Kế hoạch này có bao trùm kế hoạch hiện có không'],
				message21: ['Dễ', 'Bình thường', 'Vừa', 'Cao'],
				message22: ['Tinh thần:', 'Thấp', 'Cao'],
				message23: ["Số lần đã được sử dụng hết. Mua đăng ký để mở khóa nhiều lần hơn."],
			},
		},
		punch: {
			title: "Xong",
			title2: "Hoàn thành",
			h1: "Tùy chọn tập luyện",
			h2: "Thành tích của tôi",
			button: "Check-in",
			day: "Số ngày Check-in liên tiếp",
			dayunit: "Ngày",
		},
		eat: {
			title: "ăn kiêng",
			DietaryIntake: "Ăn uống",
			kcal: "Kcal",
			Stillingestible: "Còn lại",
			overingest: "Vượt quá",
			Recommendedintake: "Đề xuất",
			Motorconsumption: "Vận động",
			carbohydrate: "Tinh bột",
			protein: "Protein",
			fat: "Chất béo",
			gram: "Gam",
			drinkDk: "Check-in nước uống",
			drinkDkhealth: "Uống nước sẽ tốt cho sức khỏe hơn",
			eatsuggest: "Gợi ý chế độ ăn uống",
			analyzefoodglx: "Phân tích hợp lý của chế độ ăn uống",
			eatPlan: "Kế hoạch ăn uống",
			makeeatPlan: "Tùy chỉnh kế hoạch ăn uống riêng của bạn",
			breakfast: "Bữa sáng",
			lunch: "Bữa trưa",
			dinner: "Bữa tối",
			snack: "Đồ ăn nhẹ",
			addFood: "Thêm thực phẩm",
			noAddFood: "Không thêm thực phẩm",
			cancle: "Hủy",
			confirm: "Xác nhận",
			tips: "Việc tự nguyện điền vào được coi là bạn đồng ý cho chúng tôi thu thập thông tin dự án này và hiển thị nó trong chế độ ăn uống",
			addFooded: "Thực phẩm đã thêm",
			added: "Đã thêm",
			numFood: "Món ăn",
			record: "Ghi lại",
			dkTitle: "Check-in",
			dailygoal: "Mục tiêu hàng ngày",
			drinkDw: "Cốc/Ngày",
			drinkWater: "Uống nước",
			dailygoalWater: "Mục tiêu uống nước hàng ngày",
			myachievement: "Thành tựu của tôi",
			lxdkDays: "Số ngày Check-in liên tiếp",
			day: "Ngày",
			analyzeHistory: "Lịch sử",
			conclusion: "Tóm tắt",
			eatDataAnalyze: "Phân tích dữ liệu chế độ ăn uống",
			selectStartDate: "Chọn ngày bắt đầu",
			chooseoneDate: "Vui lòng chọn 1 ngày làm ngày bắt đầu",
			scheduling: "Sắp xếp kế hoạch",
			schedulingDesc: "Bắt đầu từ ngày gần nhất bạn chọn, tổng cộng 7 ngày; nội dung cụ thể như sau:",
			eatDetail: "Chi tiết chế độ ăn uống",
			analyzeFailed: "Phân tích thất bại",
			noData: "không có dữ liệu"


		}
	},
  error: {
    error1101:"Kết nối mạng không ổn định, thời gian chờ phiên hiện tại",
    error1102:"Hiện tại không có mạng, vui lòng kết nối lại",
    error1103:"Đã xảy ra lỗi không xác định",
    error61:"Dịch vụ hiện tại không khả dụng",
    error4:"Chưa phát hiện được giọng nói, vui lòng thử lại",
    error12:"Hãy nhập từ hoặc câu chuẩn để AI hiểu và rút ra",
		error50:"Xin lỗi, máy chủ hiện tại đã sai, vui lòng thử lại"
  },
	setting: {
		index: {
			title: "Cài đặt",
			problem: "Phản hồi vấn đề",
			order: "Lịch sử đặt hàng",
			notice: "Thông báo",
			language: "Ngôn ngữ",
			clear: "Xóa cache",
			clearTip: "Bạn có chắc chắn muốn\nxóa cache không?",
			confirm: "Xác nhận",
			cancel: "Hủy",
		},
		language: {
			title: "Ngôn ngữ",
			langList: ["Tiếng Trung giản thể", "Tiếng Trung phồn thể", "Tiếng Anh", "Tiếng Ả Rập", "Tiếng Bulgaria", "Tiếng Bengal", "Tiếng Séc", "Tiếng Đức", "Tiếng Hy Lạp", "Tiếng Tây Ban Nha", "Tiếng Ba Tư", "Tiếng Philippines", "Tiếng Pháp", "Tiếng Hindi", "Tiếng Hungary", "Tiếng Indonesia", "Tiếng Ý", "Tiếng Hebrew", "Tiếng Nhật", "Tiếng Hàn", "Tiếng Mã Lai", "Tiếng Hà Lan", "Tiếng Punjab", "Tiếng Ba Lan", "Tiếng Bồ Đào Nha", "Tiếng Romania", "Tiếng Nga", "Tiếng Slovak", "Tiếng Thái", "Tiếng Thổ Nhĩ Kỳ", "Tiếng Ukraina", "Tiếng Urdu", "Tiếng Việt"],
		},
		order: {
			title: "Lịch sử đặt hàng",
			success: "Lấy thành công",
			unpaid: "Chưa thanh toán",
			paid: "Đã thanh toán",
			expired: "Hết hạn",
			refunded: "Đã hoàn tiền",
			pTime: "Thời gian thanh toán",
			eTime: "Thời gian hết hạn",
			pMethod: "Phương thức thanh toán",
			pObject: "Đối tượng thanh toán",
			oNumber: "Số đơn hàng",
			pType: "Loại gói",
			oTime: "Thời gian lấy",
			watch: "Xem IMEI",
			delete: "Xóa đơn hàng",
			refund: "Yêu cầu hoàn tiền",
		},
		problem: {
			title: "Phản hồi vấn đề",
			feedback: "Loại phản hồi",
			please: "Vui lòng chọn",
			content: "Nội dung",
			improve: "Vui lòng ghi lại những đề xuất của bạn, chẳng hạn như yêu cầu tính năng, khiếu nại sản phẩm, v.v. Chúng tôi sẽ nỗ lực cải thiện",
			upload: "Tải lên hình ảnh",
			submit: "Gửi",
			general: "Phản hồi chung",
			purchase: "Liên quan đến mua sắm",
			bug: "Báo cáo lỗi",
			suggestion: "Đề xuất",
			other: "Khác",
		},
		notice: {
			title: "Thông báo",
			type: "Loại thông báo: ",
			time: "Thời gian thông báo: "
		},
		unsubscribe: {
			watch: "Đồng hồ:",
			title: "Hủy đăng ký liên tục",
			subTitle: "Đã đăng ký",
			unsubscribe: "Hủy đăng ký",
			packList: ["Gói đăng ký cao cấp", "Gói đăng ký cơ bản", "Gói đăng ký tiêu chuẩn"],
		},
		refund: {
			title: "Yêu cầu hoàn tiền",
			number: "Số đơn hàng",
			content: "Vui lòng giải thích lý do hoàn tiền",
			packList: ["Gói giảm giá cho người dùng mới", "Gói 50 lần", "Gói 100 lần",
				"Gói 300 lần"
			],
		}
	},
	tutorial: {
		index: {
			title: "Nhận hướng dẫn nâng cao",
			watchList: ["Bật đồng hồ, tìm ứng dụng WearAI và nhấp để mở.",
				"Nhấp vào nút lợi ích nâng cao trên trang chủ.",
				"Lấy điện thoại di động của bạn và quét mã QR trên đồng hồ để vào trang lợi ích."
			]
		}
	},
}