module.exports = {
	language: "українська",
	navigator: {
		tools: "ШІ засіб",
		draw: "малювати",
		healthy: "здорові",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Рекомендувати", "Навчання", "Життя", "Творчість", "Робота"],
			presetGreet: "Привіт, привіт~ Я ваш стюард здоров'я, завжди готовий надавати цікаві та корисні знання про здоров'я, щоб допомогти вам захистити своє здоров'я!",
			presetQuiz: ["Які причини хронічної втоми?", "Як молоді люди можуть запобігати хронічним захворюванням?", "Як підтримувати здорові харчові звички?", "Як підтримувати добрий сон?"],
			presets: "Ви можете запитати мене:",
			change: "Змінити партію",
			uploadImage: "Надіслати зображення",
			footer: "Оскільки вміст генерується AI моделлю, його автентичність не може бути повністю гарантована.",
			remind: "Нагадування про завдання",
			limit: "Ця бесіда досягла межі, почніть нову сесію AI чату",
			newDialog: "Почати нову бесіду",
			stopRecording: "Натисніть будь-де або зачекайте, поки не закінчиться зворотний відлік для розпізнавання",
			send: "Надіслати повідомлення",
			say: "Натисніть, щоб поговорити",
			notImage: "Цей агент не підтримує надсилання файлів та зображень",
			talking: "Триває поточна бесіда",
			drawViolations: "Підказка для малювання AI порушує правила, будь ласка, введіть відповідну підказку.",
			exhausted: "Кількість запитань та відповідей вичерпано, перейдіть на годинник і скануйте QR-код, щоб продовжити.",
			serverBusy: "Поточне мережеве з'єднання нестабільне, будь ласка, спробуйте ще раз",
			chatHistory: "Історія чату",
			chatHistoryDesc: "Записи зберігаються 3 дні",
			todoList: "Список справ",
			todoDesc: "Деталі завдань",
			todoListDesc: "Максимально може існувати до 10 справ",
			confirm: "Підтвердити",
			cancel: "Скасувати",
			text: "Текст не може бути порожнім",
			cancelRemind: "Скасувати нагадування",
			remindTime: "Час нагадування:",
			remindTitle: "Назва нагадування:",
			remindContent: "Зміст нагадування:",
			edit: "Редагувати",
			reachLimit: "Додано до межі",
			errorChat:"Голос ще не виявлено, спробуйте ще раз",
			      noSTT:"Дозвіл не отримано, увімкніть дозвіл, перш ніж продовжити.",
      error50:"Сталася невідома помилка, спробуйте ще раз"
		},
		draw: {
			describe: "Опишіть малюнок",
			voice: "Введення голосом",
			press: "Натисніть, щоб говорити",
			placeholder: "Опишіть зображення, яке хочете створити",
			style: "Стиль малювання",
			styleList: ["Стиль туші", "Кіберпанк", "Аніме", "Орігамі", "В'язання",  "Графіка", "Мультфільм","LEGO","Олівець","Малювати"],
			record: "Запис малювання",
			recordDesc: "Можна зберегти до 10 записів малювання",
			recordEmpty: "У вас наразі немає записів малювання",
			generate: "Створити зображення",
			result: "Pезультат",
			download: "Cкачати",
			regenerate: "знову",
			setDial: "Встановити як циферблат",
			error: "Створення зображення не вдалося",
			errorDesc: "Нестабільне мережеве з'єднання, будь ласка, відправте знову.",
			confirm: "Підтвердити",
			analysiserror: "Аналіз не вдався",
      SetWatchErr:["Циферблат встановлено успішно","Скасувати відправку","Не вдалося перевірити дані","Файл даних циферблата завеликий","Синхронізація циферблата досягла верхньої межі","Bluetooth відключено"],
      SetWatchTips:["Помилка налаштування","Триває налаштування циферблата","Циферблат налаштовується, будь ласка, не виходьте з поточної сторінки."],
      progressText:"Синхронізацію"

		},
		sports: {
			analyze: "Аналіз здоров'я",
			analyzeDesc: "Аналіз базових даних",
			advice: "поради щодо тіла",
			adviceDesc: "слушна порада",
			experience: "Досвід",
			program: {
				plan: "План схуднення",
				startDate: "Виберіть дату початку",
				chooseDate: "Будь ласка, виберіть 1 день як дату початку",
				week: [{
					num: "Нд.",
					day: "Неділя"
				}, {
					num: "Пн.",
					day: "Понеділок"
				}, {
					num: "Вт.",
					day: "Вівторок"
				}, {
					num: "Ср.",
					day: "Середа"
				}, {
					num: "Чт.",
					day: "Четвер"
				}, {
					num: "Пт.",
					day: "П'ятниця"
				}, {
					num: "Сб.",
					day: "Субота"
				}, ],
				arrange: "Розклад плану",
				arrangeDesc: "Почніть з найближчої обраної дати, всього 7 днів тренувань; конкретний вміст наступний:",
				confirm: "Підтвердити розклад",
				success: "Вітаємо, ви успішно налаштували план! Почніть свої тренування на сторінці 'Відмітка вправ'!",
				successButton: "Перейти до відмітки вправ",
			},
			newmessage: {
				message1: ['Особистий центр', 'Дані про здоров\'я'],
				message2: ['Здоров\'я', 'спорт', 'дієта'],
				message3: ['Стать', 'Вік', 'Зріст', 'Вага'],
				message4: ['Сон', 'ЧСС', 'Кисень', 'Кроки', 'АТ', 'Тиск', 'Резюме'],
				message5: ['Спожито', 'щодня', 'Сьогоднішні кроки',
					'Тривалість вправ'
				],
				message6: ['Відмітка вправ', 'Фітнес-домашня версія',
					'Фітнес-про', 'План схуднення'
				],
				message7: ['виконувати вправи щодня',
					'Налаштуйте свій фітнес-план', 'Налаштуйте свій фітнес-план',
					'Створіть для вас план схуднення'
				],
				message8: ['Ккал', 'Кроки', 'Хвилини', 'Сети', 'Дні', 'Роки', 'см', 'кг', 'год'],
				message9: ['Відмітити', 'Тренування', 'Опції вправ', 'Мої досягнення',
					'Дні поспіль',
					'Не відмічено'
				],
				message10: ['Натисніть для аналізу', 'Історія аналізу (до 10 збережених)', 'Аналізувати',
					'У вас зараз немає історичних записів', 'Послідовні дні відмітки', 'Аналіз даних'
				],
				message11: ['Жіноча', 'Чоловіча'],
				message12: ['Скасувати', 'Підтвердити'],
				message13: ['Роки', 'Сантиметри', 'Кілограми'],
				message14: ['Ручний ввід', 'Очікуваний час (дні)', 'Стать', 'Вік', 'Зріст (сантиметри)',
					'Вага (кілограми)', 'Очікувана вага (кілограми)'
				],
				message15: ['Історія', 'Аналіз даних про здоров\'я', 'Поради щодо здоров\'я'],
				message16: ['Відмітка успішна', 'Відмічається', 'Відмітку можна зробити лише в той самий день'],
				message17: ['Аналіз даних про здоровя можна отримати лише раз на день, локальні дані не знайдені ', 'Поради щодо здоров\ я можна отримати лише раз на день, локальні дані не знайдені'
				],
				message18: ['Зараз недостатня вага', 'Ваш індекс BMI', 'не рекомендується продовжувати втрачати вагу (Здоровий діапазон 18.5<BMI<24)'],
				message19: ['Помірне схуднення', 'Рекомендується втрачати не більше 500г на день до цього діапазону'],
				message20: ['є плани', 'Чи покриває існуючі плани'],
				message21: ['Легко', 'Нормально', 'Середнє', 'Високе'],
				message22: ['Дух:', 'Низький', 'Високий'],
				message23: ['Придбайте підписку, щоб розблокувати більше разів. '],
			},
		},
		punch: {
			title: "Зроблено",
			title2: "Завершити",
			h1: "Опції вправ",
			h2: "Мої досягнення",
			button: "Відмітка",
			day: "Послідовні дні відмітки",
			dayunit: "Дні",
		},
		eat: {
			title: "дієта",
			DietaryIntake: "Їжа",
			kcal: "Ккал",
			Stillingestible: "Залишок",
			overingest: "Понад",
			Recommendedintake: "щодня",
			Motorconsumption: "Рух",
			carbohydrate: "Вуглеводи",
			protein: "Білки",
			fat: "Жири",
			gram: "Грами",
			drinkDk: "Відмітка води",
			drinkDkhealth: "Пити воду корисніше",
			eatsuggest: "Рекомендації щодо дієти",
			analyzefoodglx: "Аналіз раціональності дієти",
			eatPlan: "План дієти",
			makeeatPlan: "Ексклюзивний план дієти",
			breakfast: "Сніданок",
			lunch: "Обід",
			dinner: "Вечеря",
			snack: "Перекус",
			addFood: "Додати їжу",
			noAddFood: "Їжу не додано",
			cancle: "Скасувати",
			confirm: "Підтвердити",
			tips: "Активне заповнення вважається згодою на збір цієї інформації та відображення її в дієті",
			addFooded: "Їжу додано",
			added: "Додано",
			numFood: "Пункти їжі",
			record: "Запис",
			dkTitle: "Відмітка",
			dailygoal: "Щоденна ціль",
			drinkDw: "Чашки/День",
			drinkWater: "Пити воду",
			dailygoalWater: "Питна вода мета",
			myachievement: "Мої досягнення",
			lxdkDays: "Послідовні дні відміток",
			day: "Дні",
			analyzeHistory: "Історія",
			conclusion: "Резюме",
			eatDataAnalyze: "Аналіз даних про дієту",
			selectStartDate: "Виберіть дату початку",
			chooseoneDate: "Будь ласка, виберіть 1 день як дату початку",
			scheduling: "Розклад плану",
			schedulingDesc: "Почніть з найближчої обраної дати, всього 7 днів; конкретний вміст наступний:",
			eatDetail: "Деталі дієти",
			analyzeFailed: "Аналіз не вдався",
			noData: "немає даних"


		}
	},
  error: {
    error1101:"Мережеве з'єднання нестабільне, поточний тайм -аут сеансу",
    error1102:"Наразі немає мережі, підключіться повторно",
    error1103:"Сталася невідома помилка",
    error61:"Поточна послуга недоступна",
    error4:"Голос ще не виявлено, спробуйте ще раз",
    error12:"Будь ласка, введіть стандартні слова або речення, щоб штучний інтелект міг їх зрозуміти та намалювати",
		error50:"Вибачте, сталася помилка на поточному сервері, спробуйте ще раз"
  },
	setting: {
		index: {
			title: "Налаштування",
			problem: "Зворотний зв'язок з проблемами",
			order: "Записи замовлень",
			notice: "Повідомлення",
			language: "Мова",
			clear: "Очистити кеш",
			clearTip: "Ви впевнені, що хочете\nочистити кеш?",
			confirm: "Підтвердити",
			cancel: "Скасувати",
		},
		language: {
			title: "Мова",
			langList: ["Спрощена китайська", "Традиційна китайська", "Англійська", "Арабська", "Болгарська", "Бенгальська", "Чеська", "Німецька", "Грецька", "Іспанська", "Перська", "Філіппінська", "Французька", "Гінді", "Угорська", "Індонезійська", "Італійська", "Іврит", "Японська", "Корейська", "Малайська", "Нідерландська", "Панджабі", "Польська", "Португальська", "Румунська", "Російська", "Словацька", "Тайська", "Турецька", "Українська", "Урду", "В’єтнамська"],
		},
		order: {
			title: "Записи замовлень",
			success: "Успішно отримано",
			unpaid: "Не оплачено",
			paid: "Оплачено",
			expired: "Закінчився термін",
			refunded: "Повернуто",
			pTime: "Час оплати",
			eTime: "Час закінчення терміну",
			pMethod: "Метод оплати",
			pObject: "Об'єкт оплати",
			oNumber: "Номер замовлення",
			pType: "Тип пакета",
			oTime: "Час отримання",
			watch: "Переглянути IMEI",
			delete: "Видалити замовлення",
			refund: "Запит на повернення коштів",
		},
		problem: {
			title: "Зворотний зв'язок з проблемами",
			feedback: "Тип зворотного зв'язку",
			please: "Будь ласка, оберіть",
			content: "Зміст",
			improve: "Будь ласка, напишіть свої пропозиції, наприклад, запити на функції, скарги на продукт тощо. Ми будемо прагнути до покращення",
			upload: "Cкачати",
			submit: "Надіслати",
			general: "Загальний зворотний зв'язок",
			purchase: "Пов'язане із покупкою",
			bug: "Звіт про помилку",
			suggestion: "Пропозиція",
			other: "Інше",
		},
		notice: {
			title: "Повідомлення",
			type: "Тип повідомлення: ",
			time: "Час повідомлення: "
		},
		unsubscribe: {
			watch: "Годинник:",
			title: "Безперервна підписка",
			subTitle: "Підписано",
			unsubscribe: "Відмінити підписку",
			packList: ["Преміум підписка", "Базова підписка", "Стандартна підписка"],
		},
		refund: {
			title: "Запит на повернення коштів",
			number: "Номер замовлення",
			content: "Будь ласка, поясніть причину повернення коштів",
			packList: ["Пакет зі знижкою для нового користувача", "Пакет на 50 разів", "Пакет на 100 разів",
				"Пакет на 300 разів"
			],
		}
	},
	tutorial: {
		index: {
			title: "Отримати розширений посібник",
			watchList: ["Увімкніть годинник, знайдіть застосунок WearAI та натисніть, щоб відкрити.",
				"Натисніть кнопку розширених переваг на домашній сторінці.",
				"Візьміть свій мобільний телефон і відскануйте QR-код на годиннику, щоб увійти на сторінку переваг."
			]
		}
	},
}