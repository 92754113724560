module.exports = {
	language: "لغة عربية",
	navigator: {
		tools: "وظيفة AI",
		draw: "رسم",
		healthy: "صحي",
	},
	aitools: {
		chat: {
			gpt: "جارفيس",
			categoryList: ["توصية", "دراسة", "حياة", "إبداع", "عمل"],
			presetGreet: "مرحبًا، أهلاً~ أنا مدير صحتك، دائمًا مستعد لتقديم معلومات صحية مثيرة ومفيدة لمساعدتك في حماية صحتك!",
			presetQuiz: ["ما هي أسباب التعب المزمن؟", "كيف يمكن للشباب الوقاية من الأمراض المزمنة؟",
				"كيف تحافظ على عادات غذائية صحية؟", "كيف تحافظ على نوم جيد؟"
			],
			presets: "يمكنك أن تسألني:",
			change: "تغيير الدفعة",
			uploadImage: "إرسال صورة",
			footer: "نظرًا لأن المحتوى يتم إنشاؤه بواسطة نموذج الذكاء الاصطناعي، فلا يمكن ضمان مصداقيته بالكامل.",
			remind: "تذكير للقيام به",
			limit: "لقد وصلت هذه المحادثة إلى الحد الأقصى، يرجى بدء جلسة دردشة جديدة مع الذكاء الاصطناعي",
			newDialog: "ابدأ محادثة جديدة",
			stopRecording: "انقر في أي مكان أو عند انتهاء العد التنازلي للتعرف",
			send: "إرسال الرسالة",
			say: "انقر للتحدث",
			notImage: "هذا الوكيل لا يدعم إرسال الملفات والصور",
			talking: "المحادثة الحالية جارية",
			drawViolations: "تلميح الرسم بالذكاء الاصطناعي ينتهك القواعد، يرجى إدخال تلميح متوافق.",
			exhausted: "تم استنفاد عدد الأسئلة والأجوبة، يرجى الانتقال إلى نهاية الساعة ومسح رمز الاستجابة السريعة لمتابعة.",
			serverBusy: "رابط الشبكة الحالي غير مستقر، يرجى المحاولة مرة أخرى",
			chatHistory: "سجل الدردشة",
			chatHistoryDesc: "يتم الاحتفاظ بسجلات الأسئلة والأجوبة لمدة 3 أيام فقط",
			todoList: "قائمة المهام",
			todoDesc: "تفاصيل المهمة",
			todoListDesc: "يمكن أن توجد بحد أقصى 10 مهام",
			confirm: "تأكيد",
			cancel: "إلغاء",
			text: "النص لا يمكن أن يكون فارغًا",
			cancelRemind: "إلغاء التذكير",
			remindTime: "وقت التذكير:",
			remindTitle: "عنوان التذكير:",
			remindContent: "محتوى التذكير:",
			edit: "تحرير",
			reachLimit: "تمت الإضافة إلى الحد الأقصى",
			errorChat:"لم يتم اكتشاف الصوت بعد، يرجى المحاولة مرة أخرى",
			      noSTT:"لم يتم الحصول على الإذن، يرجى تمكين الإذن قبل المتابعة.",
      error50:"حدث خطأ غير معروف، يرجى المحاولة مرة أخرى"
		},
		draw: {
			describe: "صف صورتك",
			voice: "إدخال صوتي",
			press: "اضغط للتحدث",
			placeholder: "صف الصورة التي تريد إنشاؤها",
			style: "أسلوب الرسم",
			styleList: ["أسلوب الحبر", "السايبربانك", "أنمي", "الأوريغامي", "الحياكة", "رسوم بيانية", "كرتون",
				"أسلوب ليغو", "الرسم بقلم الرصاص", "رسم"
			],
			record: "سجل الرسم",
			recordDesc: "يمكن حفظ ما يصل إلى 10 سجلات رسم",
			recordEmpty: "ليس لديك حاليًا سجلات رسم",
			generate: "إنشاء صورة",
			result: "نتيجة توليد الصورة",
			download: "تنزيل الصورة",
			regenerate: "إعادة التوليد",
			setDial: "تعيين كوجه ساعة",
			error: "فشل توليد الصورة",
			errorDesc: "اتصال الشبكة غير مستقر، يرجى إعادة الإرسال.",
			confirm: "تأكيد",
			analysiserror: "فشل التحليل",
      SetWatchErr:["تم ضبط الطلب بنجاح","إلغاء الإرسال","فشل التحقق من البيانات","ملف بيانات وجه الساعة كبير جدًا","وصلت مزامنة وجه الساعة إلى الحد الأعلى","تم قطع اتصال البلوتوث"],
      SetWatchTips:["فشل الإعداد","إعداد الطلب قيد التقدم","يتم الآن ضبط وجه الساعة، يرجى عدم الخروج من الصفحة الحالية."],
      progressText:"مزامنة"

		},
		sports: {
			analyze: "تحليل الصحة",
			analyzeDesc: "تحليل البيانات الأساسية",
			advice: "نصيحة صحية",
			adviceDesc: "تقديم نصائح صحية",
			experience: "تجربة",
			program: {
				plan: "خطة فقدان الوزن",
				startDate: "اختر تاريخ البدء",
				chooseDate: "يرجى اختيار يوم واحد كتاريخ البدء",
				week: [{
					num: "الأحد",
					day: "الأحد"
				}, {
					num: "الاثنين",
					day: "الاثنين"
				}, {
					num: "الثلاثاء",
					day: "الثلاثاء"
				}, {
					num: "الأربعاء",
					day: "الأربعاء"
				}, {
					num: "الخميس",
					day: "الخميس"
				}, {
					num: "الجمعة",
					day: "الجمعة"
				}, {
					num: "السبت",
					day: "السبت"
				}, ],
				arrange: "ترتيب الخطة",
				arrangeDesc: "ابدأ من أقرب تاريخ تختاره، بإجمالي 7 أيام تدريب؛ المحتوى المحدد كالتالي:",
				confirm: "تأكيد الترتيب",
				success: "تهانينا، لقد نجحت في تخصيص الخطة! ابدأ تدريباتك على صفحة 'تسجيل التمارين'!",
				successButton: "الذهاب إلى تسجيل التمارين",
			},
			newmessage: {
				message1: ['المركز الشخصي', 'بيانات الصحة'],
				message2: ['الصحة', 'رياضة', ' حمية'],
				message3: ['الجنس', 'العمر', 'الطول', 'الوزن'],
				message4: ['النوم', 'معدل النبض', 'الأكسجين', 'الخطوات', 'ضغط الدم', 'الضغط', 'الملخص'],
				message5: ['الاستهلاك', 'الاقتراح', 'خطوات اليوم', 'مدة التمرين'],
				message6: ['تسجيل التمارين', 'الإصدار المنزلي للياقة البدنية', 'اللياقة برو', 'خطة فقدان الوزن'],
				message7: ['تمرن وعش حياة صحية', 'خصص خطة لياقتك', 'خصص خطة لياقتك', 'أنشئ خطة لفقدان الوزن لك'],
				message8: ['يوصي', 'خطوات', 'دقائق', 'مجموعات', 'أيام', 'سنوات', 'سم', 'كج', 'ساعات', 'أسبوع'],
				message9: ['تسجيل الدخول', 'التدريب', 'خيارات التمرين', 'إنجازاتي', 'أيام تسجيل الدخول المتتالية',
					'لم يتم تسجيل الدخول'
				],
				message10: ['انقر للتحليل', 'سجل التحليل (يتم حفظ ما يصل إلى 10)', 'تحليل',
					'ليس لديك حاليًا سجلات تاريخية', 'أيام تسجيل الدخول المتتالية', 'تحليل البيانات'
				],
				message11: ['أنثى', 'ذكر'],
				message12: ['إلغاء', 'تأكيد'],
				message13: ['سنوات', 'سنتيمترات', 'كيلوغرامات'],
				message14: ['إدخال يدوي', ' الوقت المتوقع (أسابيع)', 'الجنس', 'العمر', 'الطول (سنتيمترات)',
					'الوزن (كيلوغرامات)', 'الوزن المتوقع (كيلوغرامات)'
				],
				message15: ['التاريخ', 'تحليل بيانات الصحة', 'النصيحة الصحية'],
				message16: ['تم تسجيل الدخول بنجاح', 'جاري تسجيل الدخول', 'يمكن تسجيل الدخول فقط في نفس اليوم'],
				message17: [
					'يمكن الحصول على تحليل بيانات الصحة مرة واحدة فقط في اليوم، ولا يمكن العثور على البيانات المحلية',
					'يمكن الحصول على النصيحة الصحية مرة واحدة فقط في اليوم، ولا يمكن العثور على البيانات المحلية'
				],
				message18: ['تعاني حالياً من نقص في الوزن', 'مؤشر كتلة الجسم الخاص بك هو',
					'لا يُنصح بمواصلة فقدان الوزن (النطاق الصحي 18.5<BMI<24)'
				],
				message19: ['فقدان وزن معتدل', 'يُنصح بعدم فقدان أكثر من 500 جرام يومياً'],
				message20: ['لديك خطط', 'هل تغطي الخطط الحالية'],
				message21: ['سهل', 'عادي', 'متوسط', 'مرتفع'],
				message22: ['الروح:', 'منخفض', 'مرتفع'],
				message23:[' م استخدام عدد المرات. قم بشراء اشتراك لفتح المزيد من المرات.'],
			},
		},
		punch: {
			title: "تم",
			title2: "انهاء",
			h1: "خيارات التمرين",
			h2: "إنجازاتي",
			button: "تسجيل الدخول",
			day: "أيام تسجيل الدخول المتتالية",
			dayunit: "أيام",
		},
		eat: {
			title: "حمية",
			DietaryIntake: "الأكل",
			kcal: "سعرات حرارية",
			Stillingestible: "الباقي",
			overingest: "يتجاوز",
			Recommendedintake: "الاقتراح",
			Motorconsumption: "الحركة",
			carbohydrate: "الكربوهيدرات",
			protein: "البروتين",
			fat: "الدهون",
			gram: "جرامات",
			drinkDk: "تسجيل شرب الماء",
			drinkDkhealth: "تسجيل يومي لجسم أكثر صحة",
			eatsuggest: "اقتراحات النظام الغذائي",
			analyzefoodglx: "تحليل منطقية النظام الغذائي",
			eatPlan: "خطة النظام الغذائي",
			makeeatPlan: "خصص خطتك الغذائية الحصرية",
			breakfast: "فطور",
			lunch: "غداء",
			dinner: "عشاء",
			snack: "سناك",
			addFood: "إضافة طعام",
			noAddFood: "لم تتم إضافة طعام",
			cancle: "إلغاء",
			confirm: "تأكيد",
			tips: "يعتبر ملء البيانات بشكل تلقائي موافقة لنا على جمع هذه المعلومات وعرضها في النظام الغذائي",
			addFooded: "تمت إضافة الطعام",
			added: "مضاف",
			numFood: "عناصر الطعام",
			record: "السجل",
			dkTitle: "تسجيل الدخول",
			dailygoal: "الهدف اليومي",
			drinkDw: "أكواب/يوم",
			drinkWater: "شرب الماء",
			dailygoalWater: "هدف شرب الماء اليومي",
			myachievement: "إنجازاتي",
			lxdkDays: "أيام تسجيل الدخول المتتالية",
			day: "أيام",
			analyzeHistory: "التاريخ",
			conclusion: "الملخص",
			eatDataAnalyze: "تحليل بيانات النظام الغذائي",
			selectStartDate: "اختر تاريخ البدء",
			chooseoneDate: "يرجى اختيار يوم واحد كتاريخ البدء",
			scheduling: "ترتيب الخطة",
			schedulingDesc: "ابدأ من التاريخ الأقرب الذي تختاره، بمجموع 7 أيام؛ المحتوى المحدد كالتالي:",
			eatDetail: "تفاصيل النظام الغذائي",
			analyzeFailed: "فشل التحليل",
			noData: "لا توجد بيانات"
		}
	},
  error: {
    error1101:"اتصال الشبكة غير مستقر ، مهلة الجلسة الحالية",
    error1102:"لا توجد شبكة حاليا، يرجى إعادة الاتصال",
    error1103:"حدث خطأ غير معروف",
    error61:"الخدمة الحالية غير متوفرة",
    error4:"لم يتم اكتشاف الصوت بعد، يرجى المحاولة مرة أخرى",
    error12:"يرجى إدخال كلمات أو جمل قياسية ليتمكن الذكاء الاصطناعي من فهمها ورسمها",
		error50:"آسف ، الخادم الحالي خاطئ ، يرجى المحاولة مرة أخرى"
  },
	setting: {
		index: {
			title: "الإعدادات",
			problem: "ملاحظات المشكلة",
			order: "سجلات الطلب",
			notice: "الإشعارات",
			language: "اللغة",
			clear: "مسح الذاكرة المخبأة",
			clearTip: "هل أنت متأكد من أنك تريد\nمسح الذاكرة المخبأة؟",
			confirm: "تأكيد",
			cancel: "إلغاء",
		},
		language: {
			title: "اللغة",
			langList: ["الصينية المبسطة", "الصينية التقليدية", "الإنجليزية", "العربية", "البلغارية", "البنغالية",
				"التشيكية", "الألمانية", "اليونانية", "الإسبانية", "الفارسية", "الفلبينية", "الفرنسية",
				"الهندية", "الهنغارية", "الإندونيسية", "الإيطالية", "العبرية", "اليابانية", "الكورية",
				"الملايو", "الهولندية", "البنجابية", "البولندية", "البرتغالية", "الرومانية", "الروسية",
				"السلوفاكية", "التايلاندية", "التركية", "الأوكرانية", "الأردية", "الفيتنامية"
			],
		},
		order: {
			title: "سجلات الطلب",
			success: "تم الاسترجاع بنجاح",
			unpaid: "غير مدفوعة",
			paid: "مدفوعة",
			expired: "منتهية الصلاحية",
			refunded: "تم الاسترداد",
			pTime: "وقت الدفع",
			eTime: "وقت الانتهاء",
			pMethod: "طريقة الدفع",
			pObject: "موضوع الدفع",
			oNumber: "رقم الطلب",
			pType: "نوع الحزمة",
			oTime: "وقت الاسترجاع",
			watch: "عرض IMEI",
			delete: "حذف الطلب",
			refund: "طلب استرداد",
		},
		problem: {
			title: "ملاحظات المشكلة",
			feedback: "نوع الملاحظات",
			please: "يرجى الاختيار",
			content: "المحتوى",
			improve: "يرجى كتابة اقتراحاتك، مثل طلبات المزايا، شكاوى المنتج، الخ. سنسعى للتحسين",
			upload: "تحميل صورة",
			submit: "إرسال",
			general: "ملاحظات عامة",
			purchase: "متعلق بالشراء",
			bug: "تقرير خطأ",
			suggestion: "اقتراح",
			other: "أخرى",
		},
		notice: {
			title: "الإشعارات",
			type: "نوع الإشعار: ",
			time: "وقت الإشعار: "
		},
		unsubscribe: {
			watch: "ساعة:",
			title: "الاشتراك المستمر",
			subTitle: "مشترك",
			unsubscribe: "إلغاء الاشتراك",
			packList: ["الاشتراك المميز", "الاشتراك الأساسي", "الاشتراك القياسي"],
		},
		refund: {
			title: "طلب استرداد",
			number: "رقم الطلب",
			content: "يرجى شرح سبب الاسترداد",
			packList: ["حزمة خصم المستخدم الجديد", "حزمة 50 مرة", "حزمة 100 مرة",
				"حزمة 300 مرة"
			],
		}
	},
	tutorial: {
		index: {
			title: "احصل على البرنامج التعليمي المتقدم",
			watchList: ["قم بتشغيل الساعة، ابحث عن تطبيق WearAI وانقر لفتحه.",
				"انقر على زر المزايا المتقدمة على الصفحة الرئيسية.",
				"اخرج هاتفك وامسح رمز الاستجابة السريعة الموجود على الساعة للدخول إلى صفحة المزايا."
			]
		}
	},
}