module.exports = {
	language: "Română",
	navigator: {
		tools: "Instrumente AI",
		draw: "Remiză",
		healthy: "Sănătos",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Recomandă", "Studiu", "Viață", "Creație", "Muncă"],
			presetGreet: "Hey, bună~ Sunt administratorul tău de sănătate, întotdeauna pregătit să îți ofer cunoștințe interesante și utile despre sănătate pentru a te ajuta să îți protejezi sănătatea!",
			presetQuiz: ["Care sunt cauzele oboselii cronice?", "Cum pot tinerii preveni bolile cronice?", "Cum să menții obiceiuri alimentare sănătoase?", "Cum să menții un somn bun?"],
			presets: "Mă poți întreba:",
			change: "Schimbă setul",
			uploadImage: "Trimite imagine",
			footer: "Deoarece conținutul este generat de un model AI, autenticitatea acestuia nu poate fi garantată pe deplin.",
			remind: "Memento sarcini",
			limit: "Această conversație a atins limita, vă rugăm să începeți o nouă sesiune de chat AI",
			newDialog: "Începe o conversație nouă",
			stopRecording: "Click oriunde sau la sfârșitul numărării inverse pentru a recunoaște",
			send: "Trimite mesaj",
			say: "Click pentru a vorbi",
			notImage: "Acest agent nu suportă trimiterea de fișiere și imagini",
			talking: "Conversație în desfășurare",
			drawViolations: "Prompt de desen AI încalcă regulile, te rog introdu un prompt conform.",
			exhausted: "Numărul de întrebări și răspunsuri a fost epuizat, te rog accesează finalul ecranului și scanează codul QR pentru a continua.",
			serverBusy: "Link-ul rețelei actuale este instabil, te rog încearcă din nou",
			chatHistory: "Istoric conversații",
			chatHistoryDesc: "Înregistrările Q&A sunt păstrate doar pentru 3 zile",
			todoList: "Lista de sarcini",
			todoDesc: "Detalii sarcini",
			todoListDesc: "Pot exista maximum 10 sarcini",
			confirm: "Confirmă",
			cancel: "Anulează",
			text: "Textul nu poate fi gol",
			cancelRemind: "Anulează memento",
			remindTime: "Timpul de memento:",
			remindTitle: "Titlul memento:",
			remindContent: "Conținutul memento:",
			edit: "Editează",
			reachLimit: "Adăugat până la limită",
			errorChat:"Vocea nu a fost detectată încă, încercați din nou",
			      noSTT:"Permisiunea nu a fost obținută, vă rugăm să activați permisiunea înainte de a continua.",
      error50:"A apărut o eroare necunoscută, vă rugăm să încercați din nou"
		},
draw: {
			describe: "Deschreiben Sie Ihr Bild",
			voice: "Eingabe per Sprache",
			press: "Zum Sprechen drücken",
			placeholder: "Beschreiben Sie das Bild, das Sie generieren möchten",
			style: "Malstil",
			styleList: ["Stil der Tinte", "Cyberpunk", "Anime", "Origami", "Stricken", "Grafiken", "Karikatur","LEGO","Creion","zeichnen"],
			record: "Zeichnungsrekord",
			recordDesc: "Es können bis zu 10 Aufzeichnungen gespeichert werden",
			recordEmpty: "Sie haben derzeit keine Zeichnungsrekorde",
			generate: "Bild generieren",
			result: "Rezultat",
			download: "Salvează ",
			regenerate: "Din nou",
			setDial: "Als Zifferblatt einstellen",
			error: "Bildgenerierung fehlgeschlagen",
			errorDesc: "Instabile Netzwerkverbindung, bitte erneut einreichen.",
			confirm: "Bestätigen",
			analysiserror: "Analyse fehlgeschlagen",
			SetWatchErr:["Apelarea a fost setată cu succes","Apelarea a fost setată cu succes","Verificarea datelor a eșuat","Verificarea datelor a eșuat","Verificarea datelor a eșuat","Bluetooth a fost deconectat"],
      SetWatchTips:["Configurarea a eșuat","Setarea apelului este în curs","Cadrul ceasului este setat, vă rugăm să nu părăsiți pagina curentă."],
      progressText:"Sincronizarea"

		},
		sports: {
			analyze: "Analiza sănătății",
			analyzeDesc: "Analizați datele de bază",
			advice: "Sfaturi pentru sănătate",
			adviceDesc: "Furniza sfat rezonabil",
			experience: "Experiență",
			program: {
				plan: "Plan de scădere în greutate",
				startDate: "Alegeți data de începere",
				chooseDate: "Vă rugăm să selectați 1 zi ca dată de începere",
				week: [{
					num: "Dum.",
					day: "Duminică"
				}, {
					num: "Lun.",
					day: "Luni"
				}, {
					num: "Mar.",
					day: "Marți"
				}, {
					num: "Mie.",
					day: "Miercuri"
				}, {
					num: "Joi.",
					day: "Joi"
				}, {
					num: "Vin.",
					day: "Vineri"
				}, {
					num: "Sâm.",
					day: "Sâmbătă"
				}, ],
				arrange: "Aranjarea planului",
				arrangeDesc: "Începeți de la cea mai apropiată dată pe care ați ales-o, un total de 7 zile de antrenament; conținutul specific este următorul:",
				confirm: "Confirmarea aranjamentului",
				success: "Felicitări, ați personalizat cu succes planul! Începeți antrenamentul pe pagina 'Exerciții Check-in'!",
				successButton: "Accesați Exerciții Check-in",
			},
newmessage: {
				message1: ['Centru personal', 'Date de sănătate'],
				message2: ['Sănătate', 'Exerciții', 'Dietă'],
				message3: ['Gen', 'Vârstă', 'Înălțime', 'Greutate'],
				message4: ['Somn', 'HR', 'Oxigen', 'Pași', 'BP', 'Presiune', 'Rezumat'],
				message5: ['Consumul', 'Sugestie', 'Pașii de azi',
					'Sport Timp'
				],
				message6: ['Exerciții Check-in', 'Fitness Home Edition',
					'Fitness Pro', 'Plan de scădere în greutate'
				],
				message7: ['Fă exerciții și trăiește o viață sănătoasă',
					'Personalizează-ți planul de fitness', 'Personalizează-ți planul de fitness',
					'Program exclusiv de slabit'
				],
				message8: ['Kcal', 'Pași', 'Minute', 'Seturi', 'Zile', 'Ani', 'cm', 'kg','h','Săptămâni'],
				message9: ['Check-in', 'Antrenament', 'Opțiuni de exerciții', 'Realizările mele',
					'Zile consecutive de Check-in', 'Nu ai făcut Check-in'
				],
				message10: ['Click pentru a analiza', 'Istoric analiză (până la 10 salvate)', 'Analizează',
					'Momentan nu aveți înregistrări istorice', 'Zile consecutive de Check-in', 'Analiza datelor'
				],
				message11: ['Femeie', 'Bărbat'],
				message12: ['Anulează', 'Confirmă'],
				message13: ['Ani', 'Centimetri', 'Kilograme'],
				message14: ['Intrare manuală', 'Timp estimat (Săptămâni)', 'Gen', 'Vârsta', 'Înălțime (Centimetri)',
					'Greutate (Kilograme)', 'Greutate estimată (Kilograme)'
				],
				message15: ['Istoric', 'Analiza datelor de sănătate', 'Sfaturi pentru sănătate'],
				message16: ['Check-in reușit', 'Check-in în curs', 'Se poate face check-in doar în aceeași zi'],
				message17: ['Analiza datelor de sănătate poate fi obținută o singură dată pe zi, iar datele locale nu pot fi găsite', 'Sfaturile pentru sănătate pot fi obținute o singură dată pe zi, iar datele locale nu pot fi găsite'],
				message18:['În prezent subponderal','Indicele tău BMI este','nu este recomandat să continui să pierzi în greutate(Gama de sănătate 18.5<BMI<24)'],
				message19:['Pierdere moderată în greutate','Se recomandă să pierzi nu mai mult de 500g pe zi'],
				message20:['au planuri','Acoperă planurile existente'],
				message21:['Ușor','Normal','Mediu','Ridicat'],
				message22:['Spirit:', 'Scăzut', 'Ridicat'],
				message23:['Numărul de ori a fost epuizat Achiziționați un abonament pentru a debloca de mai multe ori.'],
			},
		},
		punch: {
			title: "Terminat",
			title2: "Finalizat",
			h1: "Opțiuni de exerciții",
			h2: "Realizările mele",
			button: "Check-in",
			day: "Zile consecutive de Check-in",
			dayunit: "Zile",
		},
eat: {
			title: "Dietă",
			DietaryIntake: "Consum",
			kcal: "Kcal",
			Stillingestible: "Rămășițe",
			overingest: "Peste",
			Recommendedintake: "Sugerat",
			Motorconsumption: "Mișcare",
			carbohydrate: "Carbohidrați",
			protein: "Proteine",
			fat: "Grăsimi",
			gram: "Grame",
			drinkDk: "Check-in apă",
			drinkDkhealth: "Check-in zilnic pentru un corp mai sănătos",
			eatsuggest: "Sugestii alimentare",
			analyzefoodglx: "Analiza raționalității dietei",
			eatPlan: "Plan dietetic",
			makeeatPlan: "Personalizează-ți planul dietetic exclusiv",
			breakfast: "Dejun",
			lunch: "Prânz",
			dinner: "Cină",
			snack: "Gustare",
			addFood: "Adaugă hrană",
			noAddFood: "Nici o hrană adăugată",
			cancle: "Anulează",
			confirm: "Confirmă",
			tips: "Completarea proactivă este considerată consimțământul dvs. pentru ca noi să colectăm aceste informații despre acest proiect și să le afișăm în dieta",
			addFooded: "Hrană adăugată",
			added: "Adăugat",
			numFood: "Elemente de hrană",
			record: "Înregistrare",
			dkTitle: "Check-in",
			dailygoal: "Obiectiv zilnic",
			drinkDw: "Căni/Zi",
			drinkWater: "Bea apă",
			dailygoalWater: "Obiectivele zilnice",
			myachievement: "Realizările mele",
			lxdkDays: "Zile consecutive de punch",
			day: "Zile",
			analyzeHistory: "Istoric",
			conclusion: "Rezumat",
			eatDataAnalyze: "Analiza datelor dietetice",
			selectStartDate: "Alegeți data de începere",
			chooseoneDate: "Vă rugăm să selectați 1 zi ca dată de începere",
			scheduling: "Aranjarea planului",
			schedulingDesc: "Începeți de la cea mai apropiată dată pe care ați ales-o, un total de 7 zile; conținutul specific este următorul:",
			eatDetail: "Detalii dietă",
			analyzeFailed: "Analiza a eșuat",
			noData: "nu există date"


		}
	},
  error: {
    error1101:"Conexiunea de rețea este instabilă, perioada de timp a sesiunii curente",
    error1102:"În prezent, nu există rețea, vă rugăm să vă reconectați",
    error1103:"A apărut o eroare necunoscută",
    error61:"Serviciul actual nu este disponibil",
    error4:"Vocea nu a fost detectată încă, încercați din nou",
    error12:"Vă rugăm să introduceți cuvinte sau propoziții standard pentru ca AI să înțeleagă și să deseneze",
		error50:"Ne pare rău, serverul actual este greșit, vă rugăm să încercați din nou"
  },
	setting: {
		index: {
			title: "Setări",
			problem: "Feedback problema",
			order: "Înregistrări ordine",
			notice: "Notificare",
			language: "Limbă",
			clear: "Golește cache",
			clearTip: "Sigur doriți să\ngoliți cache-ul?",
			confirm: "Confirmă",
			cancel: "Anulează",
		},
		language: {
			title: "Limbă",
			langList: ["Chineză Simplificată", "Chineză Tradițională", "Engleză", "Arabă", "Bulgară", "Bengali", "Cehă", "Germană", "Greacă", "Spaniolă", "Persană", "Filipineză", "Franceză", "Hindi", "Ungară", "Indoneziană", "Italiană", "Hebraică", "Japoneză", "Coreeană", "Malayeză", "Olandeză", "Punjabi", "Poloneză", "Portugheză", "Română", "Rusă", "Slovacă", "Tailandeză", "Turcă", "Ucraineană", "Urdu", "Vietnameză"],
		},
order: {
			title: "Înregistrări comenzi",
			success: "Preluat cu succes",
			unpaid: "Neachitat",
			paid: "Achitat",
			expired: "Expirat",
			refunded: "Rambursat",
			pTime: "Ora plății",
			eTime: "Ora expirării",
			pMethod: "Metoda de plată",
			pObject: "Obiectul plății",
			oNumber: "Număr comandă",
			pType: "Tip pachet",
			oTime: "Ora preluării",
			watch: "Vizualizați IMEI",
			delete: "Șterge comanda",
			refund: "Solicită rambursare",
		},
		problem: {
			title: "Feedback problemă",
			feedback: "Tip feedback",
			please: "Vă rugăm să selectați",
			content: "Conținut",
			improve: "Vă rugăm să scrieți sugestiile dvs., cum ar fi cereri de funcții, plângeri despre produse etc. Vom încerca să îmbunătățim",
			upload: "Încărcați imagine",
			submit: "Trimiteți",
			general: "Feedback general",
			purchase: "Legat de achiziție",
			bug: "Raportați un bug",
			suggestion: "Sugestie",
			other: "Altceva",
		},
		notice: {
			title: "Notificare",
			type: "Tip notificare: ",
			time: "Ora notificării: "
		},
		unsubscribe: {
			watch: "Ceas:",
			title: "Abonament continuu",
			subTitle: "Abonat",
			unsubscribe: "Dezabonare",
			packList: ["Abonament Premium", "Abonament Basic", "Abonament Standard"],
		},
		refund: {
			title: "Solicită rambursare",
			number: "Număr comandă",
			content: "Vă rugăm să explicați motivul rambursării",
			packList: ["Pachet reducere pentru utilizatori noi", "Pachet de 50 ori", "Pachet de 100 ori",
				"Pachet de 300 ori"
			],
		}
	},
	tutorial: {
		index: {
			title: "Obțineți tutorial avansat",
			watchList: ["Porniți ceasul, găsiți aplicația WearAI și faceți clic pentru a o deschide.",
				"Faceți clic pe butonul de beneficii avansate de pe pagina principală.",
				"Scoateți-vă telefonul mobil și scanați codul QR de pe ceas pentru a intra pe pagina beneficiilor."
			]
		}
	},
}