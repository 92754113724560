module.exports = {
  language: "Hrvatski",
  navigator: {
    tools: "AI alati",
    draw: "Crtati",
    healthy: "Zdrav",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Preporuči", "Učenje", "Život", "Kreacija", "Rad"],
      presetGreet:
        "Hej, zdravo~ Ja sam tvoj zdravstveni upravitelj, uvijek spreman pružiti zanimljive i korisne zdravstvene informacije kako bih ti pomogao zaštititi tvoje zdravlje!",
      presetQuiz: [
        "Koji su uzroci kroničnog umora?",
        "Kako mogu mladi ljudi spriječiti kronične bolesti?",
        "Kako održavati zdrave prehrambene navike?",
        "Kako održavati dobar san?",
      ],
      presets: "Možeš me pitati:",
      change: "Promijeni seriju",
      uploadImage: "Pošalji sliku",
      footer:
        "Budući da sadržaj generira AI model, autentičnost se ne može potpuno jamčiti.",
      remind: "Podsjetnik za obavljanje",
      limit:
        "Ova konverzacija je dostigla limit, molimo započnite novu AI chat sesiju",
      newDialog: "Započni novu razgovor",
      stopRecording: "Kliknite bilo gdje ili kada odbrojavanje završava za prepoznavanje",
      send: "Pošalji poruku",
      say: "Klikni za razgovor",
      notImage: "Ovaj agent ne podržava slanje datoteka i slika",
      talking: "Trenutni razgovor u tijeku",
      drawViolations:
        "AI crtežni upit krši pravila, molimo unesite usklađen upit.",
      exhausted:
        "Broj pitanja i odgovora je iscrpljen, molimo idite do kraja gledanja i skenirajte QR kod za nastavak.",
      serverBusy: "Trenutna mrežna veza je nestabilna, molimo pokušajte ponovo",
      chatHistory: "Povijest razgovora",
      chatHistoryDesc: "Zapisnici pitanja i odgovora čuvaju se samo 3 dana",
      todoList: "Lista obaveza",
      todoDesc: "Detalji obaveza",
      todoListDesc: "Maksimalno može postojati 10 obaveza",
      confirm: "Potvrdi",
      cancel: "Otkaži",
      text: "Tekst ne može biti prazan",
      cancelRemind: "Otkaži podsjetnik",
      remindTime: "Vrijeme podsjetnika:",
      remindTitle: "Naslov podsjetnika:",
      remindContent: "Sadržaj podsjetnika:",
      edit: "Uredi",
      reachLimit: "Dodano do limita",
      errorChat:"Nema detektiranog glasa, molimo pokušajte ponovo",
            noSTT:"Dopuštenje nije dobiveno, omogućite dopuštenje prije nastavka.",
      error50:"Došlo je do nepoznate pogreške, molimo pokušajte ponovno"
    },
draw: {
      describe: "Opiši svoju sliku",
      voice: "Ulaz glasom",
      press: "Pritisni za razgovor",
      placeholder: "Opiši sliku koju želiš generirati",
      style: "Stil slikanja",
      styleList: [
        "Tintna tehnika",
        "Cyberpunk",
        "Anime",
        "Origami",
        "Pletenje",
        "Grafika",
        "Crtić",
        "LEGO",
        "Olovka",
        "Crtanje",
      ],
      record: "Zapis o crtanju",
      recordDesc: "Maksimalno se može spremiti 10 zapisa o crtanju",
      recordEmpty: "Trenutno nemaš zapisa o crtanju",
      generate: "Generiraj sliku",
      result: "Rezultat generiranja slike",
      download: "Preuzmi sliku",
      regenerate: "Ponovno generiraj",
      setDial: "Postavi kao brojčanik",
      error: "Generiranje slike nije uspjelo",
      errorDesc: "Nestabilna mrežna veza, molimo ponovo pošaljite.",
      confirm: "Potvrdi",
      analysiserror: "Analiza nije uspjela",
      SetWatchErr:["Brojčanik je uspješno postavljen","Otkaži slanje","Provjera podataka nije uspjela","Datoteka s podacima o broju sata je prevelika","Sinkronizacija brojčanika dosegla je gornju granicu","Bluetooth je prekinut"],
      SetWatchTips:["Postavljanje nije uspjelo","Podešavanje brojčanika u tijeku","Brojčanik sata se postavlja, nemojte napuštati trenutnu stranicu."],
      progressText:"Sinkronizaciju"

    },
    sports: {
      analyze: "Analiza zdravlja",
      analyzeDesc: "Analiziraj osnovne podatke",
      advice: "Zdravstveni savjeti",
      adviceDesc: "Pružite savjete za zdravlje",
      experience: "Iskustvo",
      program: {
        plan: "Plan mršavljenja",
        startDate: "Odaberi datum početka",
        chooseDate: "Molimo odaberite 1 dan kao datum početka",
        week: [
          {
            num: "Ned.",
            day: "Nedjelja",
          },
          {
            num: "Pon.",
            day: "Ponedjeljak",
          },
          {
            num: "Uto.",
            day: "Utorak",
          },
          {
            num: "Sri.",
            day: "Srijeda",
          },
          {
            num: "Čet.",
            day: "Četvrtak",
          },
          {
            num: "Pet.",
            day: "Petak",
          },
          {
            num: "Sub.",
            day: "Subota",
          },
        ],
arrange: "Plan rasporeda",
        arrangeDesc:
          "Počnite od najbližeg datuma koji ste odabrali, ukupno 7 dana treninga; specifičan sadržaj je sljedeći:",
        confirm: "Potvrdi raspored",
        success:
          "Čestitamo, uspješno ste prilagodili plan! Započnite svoj trening na stranici 'Provjera vježbanja'!",
        successButton: "Idi na provjeru vježbanja",
      },
      newmessage: {
        message1: ["Osobni centar", "Zdravstveni podaci"],
        message2: ["Zdravlje", "Vježbanje", "Prehrana"],
        message3: ["Spol", "Dob", "Visina", "Težina"],
        message4: [
          "San",
          "HR",
          "Kisik",
          "Koraci",
          "BP",
          "Pritisak",
          "Sažetak",
        ],
        message5: ["Potrošnja", "Preporučeno", "Današnji koraci", "Trajanje vježbanja "],
        message6: [
          "Provjera vježbanja",
          "Fitness kućna verzija",
          "Fitness Pro",
          "Plan mršavljenja",
        ],
        message7: [
          "Vježbajte i živite zdrav život",
          "Prilagodite svoj plan fitnessa",
          "Prilagodite svoj plan fitnessa",
          "Kreirajte plan mršavljenja za vas",
        ],
        message8: [
          "Kcal",
          "Koraci",
          "Minute",
          "Serije",
          "Dani",
          "Godine",
          "cm",
          "kg",
          "h", "Tjedni"
        ],
        message9: [
          "Provjera",
          "Trening",
          "Opcije vježbanja",
          "Moja postignuća",
          "Uzastopni dani provjere",
          "Nije provjereno",
        ],
        message10: [
          "Kliknite za analizu",
          "Povijest analiza (max 10 spremljenih)",
          "Analiziraj",
          "Trenutno nemate povijesnih zapisa",
          "Uzastopni dani provjere",
          "Analiza podataka",
        ],
message11: ["Ženski", "Muški"],
        message12: ["Otkaži", "Potvrdi"],
        message13: ["Godine", "Centimetri", "Kilogrami"],
        message14: [
          "Ručno unos",
          "Očekivano vrijeme (Tjedni) ",
          "Spol",
          "Dob",
          "Visina (Centimetri)",
          "Težina (Kilogrami)",
          "Očekivana težina (Kilogrami)",
        ],
        message15: ["Povijest", "Analiza zdravstvenih podataka", "Zdravstveni savjeti"],
        message16: [
          "Provjera uspješna",
          "Provjeravam",
          "Može se provjeriti samo na isti dan",
        ],
        message17: [
          "Analizu zdravstvenih podataka možete dobiti samo jednom dnevno, a lokalni podaci ne mogu se pronaći",
          "Zdravstveni savjeti mogu se dobiti samo jednom dnevno, a lokalni podaci ne mogu se pronaći",
        ],
        message18: [
          "Trenutno ste pod težinom",
          "Vaš BMI indeks je",
          "nije preporučljivo nastaviti gubiti težinu (Zdravi raspon 18.5<BMI<24)",
        ],
        message19: [
          "Umjereno mršavljenje",
          "Preporučuje se gubiti ne više od 500g dnevno",
        ],
        message20: ["imati planove", "Pokreće li postojeće planove"],
        message21: ["Lako", "Normalno", "Srednje", "Visoko"],
        message22: ["Duh:", "Nizak", "Visok"],
		message23: ["Broj korištenja je potrošen. Kupite pretplatu za otključavanje više korištenja"],
      },
    },
punch: {
      title: "Završeno",
      title2: "Kraj",
      h1: "Opcije vježbanja",
      h2: "Moja postignuća",
      button: "Provjera",
      day: "Uzastopni dani provjere",
      dayunit: "Dani",
    },
    eat: {
      title: "Prehrana",
      DietaryIntake: "Jedenje",
      kcal: "Kcal",
      Stillingestible: "Ostatak",
      overingest: "Prekomjerno",
      Recommendedintake: "Preporučeno",
      Motorconsumption: "Kretanje",
      carbohydrate: "Ugljikohidrati",
      protein: "Protein",
      fat: "Masti",
      gram: "Grami",
      drinkDk: "Provjera vode",
      drinkDkhealth: "Dnevna provjera za zdravije tijelo",
      eatsuggest: "Preporuke za prehranu",
      analyzefoodglx: "Analizirati racionalnost prehrane",
      eatPlan: "Plan prehrane",
      makeeatPlan: "Prilagodite svoj ekskluzivni plan prehrane",
      breakfast: "Doručak",
      lunch: "Ruča",
      dinner: "Večera",
      snack: "Grickalica",
      addFood: "Dodaj hranu",
      noAddFood: "Nema dodane hrane",
      cancle: "Otkaži",
      confirm: "Potvrdi",
      tips: "Proaktivno ispunjavanje smatra se pristankom za prikupljanje ovih informacija o projektu i njihovim prikazivanjem u prehrani.",
      addFooded: "Hrana dodana",
      added: "Dodano",
      numFood: "Predmeti hrane",
      record: "Zapis",
      dkTitle: "Provjera",
      dailygoal: "Dnevni cilj",
      drinkDw: "Šalice/Dan",
      drinkWater: "Pij vodu",
      dailygoalWater: "Dnevni cilj pijenja vode",
      myachievement: "Moja postignuća",
      lxdkDays: "Uzastopni dani provjere",
      day: "Dani",
      analyzeHistory: "Povijest",
      conclusion: "Sažetak",
      eatDataAnalyze: "Analiza podataka o prehrani",
      selectStartDate: "Odaberite datum početka",
      chooseoneDate: "Molimo odaberite 1 dan kao datum početka",
      scheduling: "Raspored",
      schedulingDesc:
        "Počnite od najbližeg datuma koji ste odabrali, ukupno 7 dana; specifičan sadržaj je sljedeći:",
      eatDetail: "Detalji prehrane",
      analyzeFailed: "Analiza nije uspjela",
      noData: "nema podataka",
    },
  },
  error: {
    error1101:"Mrežna veza je nestabilna, trenutni prekid sesije",
    error1102:"Trenutno nema mreže, ponovno se povežite",
    error1103:"Došlo je do nepoznate pogreške",
    error61:"Trenutna usluga nije dostupna",
    error4:"Glas još nije prepoznat, pokušajte ponovno",
    error12:"Unesite standardne riječi ili rečenice kako bi ih AI razumjela i crtala",
    error50:"Žao nam je, postoji pogreška na trenutnom poslužitelju, pokušajte ponovno"

  },
  setting: {
    index: {
      title: "Postavke",
      problem: "Povratne informacije o problemima",
      order: "Zapisnici narudžbi",
      notice: "Obavijest",
      language: "Jezik",
      clear: "Obriši predmemoriju",
      clearTip: "Jeste li sigurni da želite\nizbrisati predmemoriju?",
      confirm: "Potvrdi",
      cancel: "Otkaži",
    },
    language: {
      title: "Jezik",
      langList: [
        "Pojednostavljeni kineski",
        "Tradicionalni kineski",
        "Engleski",
        "Arapski",
        "Poljski",
        "Perzijski",
        "Danski",
        "Njemački",
        "Ruski",
        "Francuski",
        "Nizozemski",
        "Češki",
        "Rumunjski",
        "Portugalski",
        "Japanski",
        "Tajlandski",
        "Turski",
        "Španjolski",
        "Grčki",
        "Talijanski",
        "Vijetnamski",
        "Hindski",
      ],
    },
order: {
      title: "Zapisnici narudžbi",
      success: "Uspješno preuzeto",
      unpaid: "Neplaćeno",
      paid: "Plaćeno",
      expired: "Isteklo",
      refunded: "Vraćeno",
      pTime: "Vrijeme plaćanja",
      eTime: "Vrijeme isteka",
      pMethod: "Metoda plaćanja",
      pObject: "Objekt plaćanja",
      oNumber: "Broj narudžbe",
      pType: "Tip paketa",
      oTime: "Vrijeme preuzimanja",
      watch: "Pogledaj IMEI",
      delete: "Obriši narudžbu",
      refund: "Zatraži povrat",
    },
    problem: {
      title: "Povratne informacije o problemima",
      feedback: "Vrsta povratne informacije",
      please: "Molimo odaberite",
      content: "Sadržaj",
      improve:
        "Molimo vas da zapišete svoje prijedloge, poput zahtjeva za funkcijama, pritužbi na proizvode itd. Pokušat ćemo se poboljšati",
      upload: "Učitaj sliku",
      submit: "Pošalji",
      general: "Opće povratne informacije",
      purchase: "Povezano s kupnjom",
      bug: "Izvještaj o grešci",
      suggestion: "Prijedlog",
      other: "Drugo",
    },
    notice: {
      title: "Obavijest",
      type: "Vrsta obavijesti: ",
      time: "Vrijeme obavijesti: ",
    },
    unsubscribe: {
      watch: "Sat:",
      title: "Neprekidna pretplata",
      subTitle: "Pretplaćeno",
      unsubscribe: "Otkaži pretplatu",
      packList: [
        "Premium pretplata",
        "Osnovna pretplata",
        "Standardna pretplata",
      ],
    },
    refund: {
      title: "Zatraži povrat",
      number: "Broj narudžbe",
      content: "Molimo objasnite razlog za povrat",
      packList: [
        "Paket popusta za nove korisnike",
        "Paket 50 puta",
        "Paket 100 puta",
        "Paket 300 puta",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Dohvati napredne upute",
      watchList: [
        "Uključite sat, pronađite WearAI aplikaciju i kliknite za otvaranje.",
        "Kliknite na gumb naprednih pogodnosti na početnoj stranici.",
        "Izvadite svoj mobilni telefon i skenirajte QR kod na satu da biste ušli na stranicu pogodnosti.",
      ],
    },
  },
}