module.exports = {
	language: "Melayu",
	navigator: {
		tools: "Alat AI",
		draw: "Lukis",
		healthy: "Sihat",
	},
aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Saranan", "Belajar", "Kehidupan", "Ciptaan", "Kerja"],
			presetGreet: "Hei, hello~ Saya adalah pembantu kesihatan anda, sentiasa bersedia untuk memberikan pengetahuan kesihatan yang menarik dan berguna untuk membantu anda menjaga kesihatan anda!",
			presetQuiz: ["Apakah punca keletihan kronik?", "Bagaimana golongan muda boleh mencegah penyakit kronik?", "Bagaimana untuk menjaga tabiat makan yang sihat?", "Bagaimana untuk mengekalkan tidur yang baik?"],
			presets: "Anda boleh bertanya kepada saya:",
			change: "Tukar batch",
			uploadImage: "Hantar imej",
			footer: "Oleh kerana kandungan dihasilkan oleh model AI, kesahihannya tidak dapat dijamin sepenuhnya.",
			remind: "Peringatan tugasan",
			limit: "Perbualan ini telah mencapai hadnya, sila mulakan sesi sembang AI baru",
			newDialog: "Mulakan perbualan baru",
			stopRecording: "Klik di mana-mana atau apabila undur tamat untuk mengenal pasti",
			send: "Hantar mesej",
			say: "Klik untuk bercakap",
			notImage: "Ejen ini tidak menyokong penghantaran fail dan imej",
			talking: "Perbualan semasa sedang berlangsung",
			drawViolations: "Arahan lukisan AI melanggar peraturan, sila masukkan arahan yang mematuhi.",
			exhausted: "Bilangan Q&A telah habis, sila pergi ke akhir tontonan dan imbas kod QR untuk meneruskan.",
			serverBusy: "Pautan rangkaian semasa tidak stabil, sila cuba lagi",
			chatHistory: "Sejarah sembang",
			chatHistoryDesc: "Rekod Q&A disimpan selama 3 hari sahaja",
			todoList: "Senarai tugasan",
			todoDesc: "Butiran tugasan",
			todoListDesc: "Maksimum 10 tugasan boleh wujud",
			confirm: "Sahkan",
			cancel: "Batalkan",
			text: "Teks tidak boleh kosong",
			cancelRemind: "Batalkan peringatan",
			remindTime: "Masa peringatan:",
			remindTitle: "Tajuk peringatan:",
			remindContent: "Kandungan peringatan:",
			edit: "Edit",
			reachLimit: "Ditambah sehingga had",
			errorChat:"Suara belum dikesan lagi, sila cuba lagi",
			      noSTT:"Kebenaran tidak diperoleh, sila dayakan kebenaran sebelum meneruskan.",
      error50:"Ralat tidak diketahui berlaku, sila cuba lagi"
		},
		draw: {
			describe: "Huraikan imej anda",
			voice: "Input suara",
			press: "Tekan untuk bercakap",
			placeholder: "Huraikan imej yang anda ingin jana",
			style: "Gaya lukisan",
			styleList: ["Gaya dakwat", "Cyberpunk", "Anime", "Origami", "Kait","Grafik", "Kartun","LEGO","Pensel", "Lukis"],
			record: "Rekod lukisan",
			recordDesc: "Sehingga 10 rekod lukisan boleh disimpan",
			recordEmpty: "Tiada rekod lagi",
			generate: "Jana imej",
			result: "Keputusan penjanaan imej",
			download: "Muat turun imej",
			regenerate: "Jana semula",
			setDial: "Tetapkan sebagai muka jam",
			error: "Penjanaan imej gagal",
			errorDesc: "Sambungan rangkaian tidak stabil, sila hantar semula.",
			confirm: "Sahkan",
			analysiserror: "Analisis gagal",
      SetWatchErr:["Dail ditetapkan dengan jayanya","Batalkan penghantaran","Pengesahan data gagal","Fail data muka jam tangan terlalu besar","Penyegerakan muka jam tangan telah mencapai had atas","Bluetooth telah diputuskan sambungan"],
      SetWatchTips:["Persediaan gagal","Tetapan dail sedang dijalankan","Muka jam sedang ditetapkan, sila jangan keluar dari halaman semasa."],
      progressText:"Menyegerak"

		},
sports: {
			analyze: "Analisis Kesihatan",
			analyzeDesc: "Menganalisis data asas",
			advice: "Nasihat Kesihatan",
			adviceDesc: "Memberi nasihat kesihatan",
			experience: "Pengalaman",
			program: {
				plan: "Pelan Penurunan Berat Badan",
				startDate: "Pilih Tarikh Mula",
				chooseDate: "Sila pilih 1 hari sebagai tarikh mula",
				week: [{
					num: "Ahad",
					day: "Ahad"
				}, {
					num: "Isnin",
					day: "Isnin"
				}, {
					num: "Selasa",
					day: "Selasa"
				}, {
					num: "Rabu",
					day: "Rabu"
				}, {
					num: "Khamis",
					day: "Khamis"
				}, {
					num: "Jumaat",
					day: "Jumaat"
				}, {
					num: "Sabtu",
					day: "Sabtu"
				}, ],
				arrange: "Pengaturan Pelan",
				arrangeDesc: "Mulakan dari tarikh terdekat yang anda pilih, jumlah 7 hari latihan; kandungan spesifik adalah seperti berikut:",
				confirm: "Sahkan Pengaturan",
				success: "Tahniah, anda berjaya menyesuaikan pelan ini! Mulakan latihan anda di halaman 'Daftar Masuk Latihan'!",
				successButton: "Pergi ke Daftar Masuk Latihan",
			},
newmessage: {
				message1: ['Pusat Peribadi', 'Data Kesihatan'],
				message2: ['Kesihatan', 'Senaman', 'Diet'],
				message3: ['Jantina', 'Umur', 'Ketinggian', 'Berat'],
				message4: ['Tidur', 'HR', 'Oksigen', 'Langkah', 'BP', 'Tekanan', 'Ringkasan'],
				message5: ['Konsum', 'Saranan', 'Langkah Hari Ini',
					'Tempoh Senaman'
				],
				message6: ['Daftar Masuk Latihan', 'Edisi Rumah Kecergasan',
					'Kecergasan Pro', 'Pelan Penurunan Berat Badan'
				],
				message7: ['Senaman dan hidup sihat',
					'Sesuaikan pelan kecergasan anda', 'Sesuaikan pelan kecergasan anda',
					'Penurunan berat badan eksklusif'
				],
				message8: ['Kcal', 'Langkah', 'Minit', 'Set', 'Hari', 'Tahun', 'cm', 'kg','j','Minggu'],
				message9: ['Daftar Masuk', 'Latihan', 'Pilihan Senaman', 'Pencapaian Saya',
					'Berterusan Log masuk', 'Tidak Mendaftar Masuk'
				],
				message10: ['Klik untuk Menganalisis', 'Sejarah Analisis (sehingga 10 disimpan)', 'Analisis',
					'Tiada data lagi', 'Berterusan Log masuk', 'Analisis Data'
				],
				message11: ['Perempuan', 'Lelaki'],
				message12: ['Batal', 'Sahkan'],
				message13: ['Tahun', 'Sentimeter', 'Kilogram'],
				message14: ['Input Manual', 'Jangkaan masa (Minggu)', 'Jantina', 'Umur', 'Ketinggian (Sentimeter)',
					'Berat (Kilogram)', 'Berat Jangkaan (Kilogram)'
				],
				message15: ['Sejarah', 'Analisis Data Kesihatan', 'Nasihat Kesihatan'],
				message16: ['Daftar Masuk Berjaya', 'Mendaftar Masuk', 'Hanya boleh mendaftar masuk pada hari yang sama'],
				message17: ['Analisis data kesihatan hanya boleh diperoleh sekali dalam sehari, dan data tempatan tidak dapat dijumpai', 'Nasihat kesihatan hanya boleh diperoleh sekali dalam sehari, dan data tempatan tidak dapat dijumpai'],
				message18: ['Berat badan kurang buat masa ini', 'Indeks BMI anda adalah', 'tidak dinasihatkan untuk terus menurunkan berat badan (Julat sihat 18.5<BMI<24)'],
				message19: ['Penurunan berat badan sederhana', 'Disyorkan untuk menurunkan tidak lebih daripada 500g sehari'],
				message20: ['ada rancangan', 'Adakah ia meliputi rancangan yang sedia ada'],
				message21: ['Mudah', 'Biasa', 'Sederhana', 'Tinggi'],
				message22: ['Semangat:', 'Rendah', 'Tinggi'],
				message23: ['Bilangan kali telah digunakan untuk membeli langganan untuk membuka lebih banyak kali.'],
			},
		},
		punch: {
			title: "Selesai",
			title2: "Tamat",
			h1: "Pilihan Senaman",
			h2: "Pencapaian Saya",
			button: "Daftar Masuk",
			day: "Berterusan Log masuk",
			dayunit: "Hari",
		},
eat: {
			title: "Diet",
			DietaryIntake: "Makan",
			kcal: "Kcal",
			Stillingestible: "Baki",
			overingest: "Melebihi",
			Recommendedintake: "Saranan",
			Motorconsumption: "Gerak",
			carbohydrate: "Karbohidrat",
			protein: "Protein",
			fat: "Lemak",
			gram: "g",
			drinkDk: "Daftar Masuk Air",
			drinkDkhealth: "Minum air lebih sihat",
			eatsuggest: "Cadangan Diet",
			analyzefoodglx: "Menganalisis rasionaliti diet",
			eatPlan: "Pelan Diet",
			makeeatPlan: "Sesuaikan pelan diet eksklusif anda",
			breakfast: "Sarapan",
			lunch: "Tengahari",
			dinner: "Malam",
			snack: "Snek",
			addFood: "Tambah Makanan",
			noAddFood: "Tiada makanan ditambah",
			cancle: "Batal",
			confirm: "Sahkan",
			tips: "Mengisi secara proaktif dianggap sebagai persetujuan untuk kami mengumpulkan maklumat projek ini dan memaparkannya dalam diet",
			addFooded: "Makanan ditambah",
			added: "Ditambah",
			numFood: "Item makanan",
			record: "Rekod",
			dkTitle: "Daftar Masuk",
			dailygoal: "Matlamat Harian",
			drinkDw: "Cawan/Hari",
			drinkWater: "Minum Air",
			dailygoalWater: "Matlamat minum air harian",
			myachievement: "Pencapaian Saya",
			lxdkDays: "Berterusan Log masuk",
			day: "Hari",
			analyzeHistory: "Sejarah",
			conclusion: "Ringkasan",
			eatDataAnalyze: "Analisis Data Diet",
			selectStartDate: "Pilih Tarikh Mula",
			chooseoneDate: "Sila pilih 1 hari sebagai tarikh mula",
			scheduling: "Pengaturan Pelan",
			schedulingDesc: "Mulakan dari tarikh terdekat yang anda pilih, jumlah 7 hari; kandungan spesifik adalah seperti berikut:",
			eatDetail:"Butiran Diet",
			analyzeFailed:"Analisis gagal",
			noData:"tiada data"

		}
	},
  error: {
    error1101:"Sambungan rangkaian tidak stabil, masa tamat sesi semasa",
    error1102:"Buat masa ini tiada rangkaian, sila sambung semula",
    error1103:"Ralat tidak diketahui berlaku",
    error61:"Perkhidmatan semasa tidak tersedia",
    error4:"Suara belum dikesan lagi, sila cuba lagi",
    error12:"Sila masukkan perkataan atau ayat standard untuk AI fahami dan lukis",
		error50:"Maaf, pelayan semasa salah, sila cuba lagi"
  },
	setting: {
		index: {
			title: "Tetapan",
			problem: "Maklum Balas Masalah",
			order: "Rekod Pesanan",
			notice: "Pemberitahuan",
			language: "Bahasa",
			clear: "Kosongkan Cache",
			clearTip: "Adakah anda pasti mahu\nmengosongkan cache?",
			confirm: "Sahkan",
			cancel: "Batal",
		},
		language: {
			title: "Bahasa",
			langList: ["Bahasa Cina Ringkas", "Bahasa Cina Tradisional", "Bahasa Inggeris", "Bahasa Arab", "Bahasa Bulgaria", "Bahasa Bengali", "Bahasa Czech", "Bahasa Jerman", "Bahasa Yunani", "Bahasa Sepanyol", "Bahasa Parsi", "Bahasa Filipina", "Bahasa Perancis", "Bahasa Hindi", "Bahasa Hungary", "Bahasa Indonesia", "Bahasa Itali", "Bahasa Ibrani", "Bahasa Jepun", "Bahasa Korea", "Bahasa Melayu", "Bahasa Belanda", "Bahasa Punjabi", "Bahasa Poland", "Bahasa Portugis", "Bahasa Romania", "Bahasa Rusia", "Bahasa Slovak", "Bahasa Thai", "Bahasa Turki", "Bahasa Ukraine", "Bahasa Urdu", "Bahasa Vietnam"],
		},
		order: {
			title: "Rekod Pesanan",
			success: "Berjaya Diperoleh",
			unpaid: "Belum Dibayar",
			paid: "Telah Dibayar",
			expired: "Tamat Tempoh",
			refunded: "Dikembalikan",
			pTime: "Masa Pembayaran",
			eTime: "Masa Tamat Tempoh",
			pMethod: "Kaedah Pembayaran",
			pObject: "Objek Pembayaran",
			oNumber: "Nombor Pesanan",
			pType: "Jenis Pakej",
			oTime: "Masa Pengambilan",
			watch: "Lihat IMEI",
			delete: "Padam Pesanan",
			refund: "Permintaan Bayaran Balik",
		},
problem: {
			title: "Maklum Balas Masalah",
			feedback: "Jenis Maklum Balas",
			please: "Sila Pilih",
			content: "Kandungan",
			improve: "Sila tuliskan cadangan anda, seperti permintaan ciri, aduan produk, dll. Kami akan berusaha untuk memperbaiki",
			upload: "Muat Naik Imej",
			submit: "Hantar",
			general: "Maklum Balas Umum",
			purchase: "Berkaitan Pembelian",
			bug: "Laporan Bug",
			suggestion: "Saranan",
			other: "Lain-lain",
		},
		notice: {
			title: "Pemberitahuan",
			type: "Jenis Pemberitahuan: ",
			time: "Masa Pemberitahuan: "
		},
		unsubscribe: {
			watch: "Jam Tangan:",
			title: "Langganan Berterusan",
			subTitle: "Dilanggani",
			unsubscribe: "Berhenti Langganan",
			packList: ["Langganan Premium", "Langganan Asas", "Langganan Standard"],
		},
		refund: {
			title: "Permintaan Bayaran Balik",
			number: "Nombor Pesanan",
			content: "Sila terangkan sebab untuk bayaran balik",
			packList: ["Pakej Diskaun Pengguna Baru", "Pakej 50 Kali", "Pakej 100 Kali", "Pakej 300 Kali"],
		}
	},
	tutorial: {
		index: {
			title: "Dapatkan Tutorial Lanjutan",
			watchList: ["Hidupkan jam tangan, cari aplikasi WearAI dan klik untuk membuka.",
				"Klik pada butang manfaat lanjutan di halaman utama.",
				"Keluarkan telefon bimbit anda dan imbas kod QR di jam tangan untuk masuk ke halaman manfaat."
			]
		}
	},
}