module.exports = {
	language: "ภาษาไทย",
	navigator: {
		tools: "เครื่องมือ AI",
		draw: "วาด",
		healthy: "ข้อมูล",
	},
aitools: {
		chat: {
			gpt: "จาร์วิส",
			categoryList: ["แนะนำ", "เรียน", "ชีวิต", "สร้างสรรค์", "งาน"],
			presetGreet: "สวัสดีครับ/ค่ะ~ ฉันคือผู้ดูแลสุขภาพของคุณ พร้อมที่จะให้ความรู้เกี่ยวกับสุขภาพที่น่าสนใจและมีประโยชน์เพื่อช่วยให้คุณรักษาสุขภาพ!",
			presetQuiz: ["สาเหตุของความเหนื่อยล้าเรื้อรังคืออะไร?", "เยาวชนสามารถป้องกันโรคเรื้อรังได้อย่างไร?", "จะรักษานิสัยการกินที่ดีได้อย่างไร?", "จะรักษาการนอนได้ดีได้อย่างไร?"],
			presets: "คุณสามารถถามฉันได้ว่า:",
			change: "เปลี่ยนชุด",
			uploadImage: "ส่งภาพ",
			footer: "เนื่องจากเนื้อหาสร้างขึ้นโดยแบบจำลอง AI จึงไม่สามารถรับประกันความถูกต้องได้ทั้งหมด",
			remind: "เตือนให้ทำ",
			limit: "การสนทนานี้ถึงขีดจำกัดแล้ว กรุณาเริ่มการสนทนา AI ใหม่",
			newDialog: "เริ่มการสนทนาใหม่",
			stopRecording: "คลิกที่ใดก็ได้หรือเมื่อการนับถอยหลังสิ้นสุดลงเพื่อรับรู้",
			send: "ส่งข้อความ",
			say: "คลิกเพื่อพูด",
			notImage: "เอเยนต์นี้ไม่รองรับการส่งไฟล์และรูปภาพ",
			talking: "การสนทนาปัจจุบันกำลังดำเนินการอยู่",
			drawViolations: "คำสั่งการวาดของ AI ละเมิดกฎ โปรดป้อนคำสั่งที่ถูกต้อง",
			exhausted: "จำนวนคำถามและคำตอบหมดแล้ว กรุณาไปที่หน้าแอพและสแกน QR โค้ดเพื่อดำเนินการต่อ",
			serverBusy: "ลิงก์เครือข่ายปัจจุบันไม่เสถียร กรุณาลองอีกครั้ง",
			chatHistory: "ประวัติการแชท",
			chatHistoryDesc: "บันทึกการถามตอบจะถูกเก็บไว้เพียง 3 วัน",
			todoList: "รายการที่ต้องทำ",
			todoDesc: "รายละเอียดสิ่งที่ต้องทำ",
			todoListDesc: "สามารถมีรายการที่ต้องทำได้สูงสุด 10 รายการ",
			confirm: "ยืนยัน",
			cancel: "ยกเลิก",
			text: "ข้อความไม่สามารถว่างเปล่า",
			cancelRemind: "ยกเลิกการเตือน",
			remindTime: "เวลาที่เตือน:",
			remindTitle: "ชื่อเตือน:",
			remindContent: "เนื้อหาการเตือน:",
			edit: "แก้ไข",
			reachLimit: "เพิ่มถึงขีดจำกัดแล้ว",
			errorChat:"ยังตรวจไม่พบเสียง โปรดลองอีกครั้ง",
			      noSTT:"ไม่ได้รับการอนุญาต โปรดเปิดใช้งานการอนุญาตก่อนดำเนินการต่อ",
      error50:"เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ โปรดลองอีกครั้ง"
		},
		draw: {
			describe: "อธิบายภาพของคุณ",
			voice: "ป้อนด้วยเสียง",
			press: "กดเพื่อพูด",
			placeholder: "อธิบายภาพที่คุณต้องการสร้าง",
			style: "สไตล์การวาดภาพ",
			styleList: ["ลายหมึก", "ไซเบอร์พังก์", "อนิเมะ", "โอริกามิ", "ถักนิตติ้ง", "กราฟิก", "การ์ตูน","เลโก้","ดินสอ","วาด"],
			record: "บันทึกการวาดภาพ",
			recordDesc: "สามารถบันทึกบันทึกการวาดภาพได้สูงสุด 10 รายการ",
			recordEmpty: "คุณยังไม่มีบันทึกการวาดภาพ",
			generate: "สร้างภาพ",
			result: "ผลการสร้างภาพ",
			download: "ดาวน์โหลด",
			regenerate: "สร้างใหม่",
			setDial: "ตั้งเป็นหน้าปัดนาฬิกา",
			error: "การสร้างภาพล้มเหลว",
			errorDesc: "การเชื่อมต่อเครือข่ายไม่เสถียร กรุณาส่งใหม่อีกครั้ง",
			confirm: "ยืนยัน",
			analysiserror: "การวิเคราะห์ล้มเหลว",
      SetWatchErr:["ตั้งค่าการโทรสำเร็จ","ยกเลิกการส่ง","การยืนยันข้อมูลล้มเหลว","ไฟล์ข้อมูลหน้าปัดมีขนาดใหญ่เกินไป","การซิงโครไนซ์หน้าปัดนาฬิกาถึงขีดจำกัดสูงสุดแล้ว","บลูทูธถูกตัดการเชื่อมต่อ"],
			SetWatchTips:["การตั้งค่าล้มเหลว","อยู่ระหว่างการตั้งค่าการโทร","กำลังตั้งค่าหน้าปัดนาฬิกา โปรดอย่าออกจากหน้าปัจจุบัน"],
      progressText:"ปรับเทียบข้อมูล"

		},
		sports: {
			analyze: "วิเคราะห์สุขภาพ",
			analyzeDesc: "วิเคราะห์ข้อมูลพื้นฐาน",
			advice: "คำแนะนำสุขภาพ",
			adviceDesc: "ให้คำแนะนำเกี่ยวกับสุขภาพ",
			experience: "ประสบการณ์",
			program: {
				plan: "แผนการลดน้ำหนัก",
				startDate: "เลือกวันที่เริ่มต้น",
				chooseDate: "กรุณาเลือก 1 วันเป็นวันที่เริ่มต้น",
				week: [{
					num: "อา.",
					day: "วันอาทิตย์"
				}, {
					num: "จ.",
					day: "วันจันทร์"
				}, {
					num: "อ.",
					day: "วันอังคาร"
				}, {
					num: "พ.",
					day: "วันพุธ"
				}, {
					num: "พฤ.",
					day: "วันพฤหัสบดี"
				}, {
					num: "ศ.",
					day: "วันศุกร์"
				}, {
					num: "ส.",
					day: "วันเสาร์"
				}, ],
				arrange: "จัดแผน",
				arrangeDesc: "เริ่มต้นจากวันที่ใกล้ที่สุดที่คุณเลือก รวมทั้งหมด 7 วันฝึกซ้อม; เนื้อหาที่เฉพาะเจาะจงมีดังนี้:",
				confirm: "ยืนยันการจัดแผน",
				success: "ขอแสดงความยินดี คุณได้ปรับแต่งแผนสำเร็จแล้ว! เริ่มฝึกในหน้าการเช็คอิน",
				successButton: "ไปที่การเช็คอินการออกกำลังกาย",
			},
newmessage: {
				message1: ['ศูนย์ส่วนบุคคล', 'ข้อมูลสุขภาพ'],
				message2: ['สุขภาพ', 'กีฬา', 'อาหาร'],
				message3: ['เพศ', 'อายุ', 'ส่วนสูง', 'น้ำหนัก'],
				message4: ['การนอนหลับ', 'อัตราชีพจร', 'อ๊อกซิเจน', 'จำนวนก้าว', 'เลือด', 'ความดัน', 'สรุป'],
				message5: ['บริโภค', 'แนะนำ', 'จำนวนก้าววันนี้',
					'เวลากีฬา'
				],
				message6: ['เช็คอินออกกำลังกาย', 'เวอร์ชันโฮมฟิตเนส',
					'ฟิตเนสโปร', 'แผนการลดน้ำหนัก'
				],
				message7: ['ออกกำลังกายและมีชีวิตที่สุขภาพดี',
					'ปรับแผนฟิตเนสของคุณเอง', 'ปรับแผนฟิตเนสของคุณเอง',
					'สร้างแผนการลดน้ำหนักสำหรับคุณ'
				],
				message8: ['แคลอรี่', 'ก้าว', 'นาที', 'ชุด', 'วัน', 'ปี', 'ซม.', 'กก.','ชม.','สัปดาห์'],
				message9: ['เช็คอิน', 'การฝึกซ้อม', 'ตัวเลือกการออกกำลังกาย', 'ความสำเร็จของฉัน',
					'จำนวนวันเช็คอินติดต่อกัน', 'ไม่ได้เช็คอิน'
				],
				message10: ['คลิกเพื่อวิเคราะห์', 'ประวัติการวิเคราะห์ (บันทึกสูงสุด 10 รายการ)', 'วิเคราะห์',
					'คุณยังไม่มีบันทึกประวัติ', 'จำนวนวันเช็คอินติดต่อกัน', 'การวิเคราะห์ข้อมูล'],
				message11: ['หญิง', 'ชาย'],
				message12: ['ยกเลิก', 'ยืนยัน'],
				message13: ['ปี', 'เซนติเมตร', 'กิโลกรัม'],
				message14: ['ป้อนข้อมูลด้วยตนเอง', 'เวลาที่คาดหวัง (สัปดาห์) ', 'เพศ', 'อายุ', 'ส่วนสูง (เซนติเมตร)',
					'น้ำหนัก (กิโลกรัม)', 'น้ำหนักที่คาดหวัง (กิโลกรัม)'
				],
				message15: ['ประวัติ', 'การวิเคราะห์ข้อมูลสุขภาพ', 'คำแนะนำสุขภาพ'],
				message16: ['เช็คอินสำเร็จ', 'กำลังเช็คอิน', 'สามารถเช็คอินได้เฉพาะวันเดียวกัน'],
				message17: ['การวิเคราะห์ข้อมูลสุขภาพสามารถรับข้อมูลได้วันละครั้งเท่านั้น และไม่พบข้อมูลท้องถิ่น', 'คำแนะนำสุขภาพสามารถรับข้อมูลได้วันละครั้งเท่านั้น และไม่พบข้อมูลท้องถิ่น'],
				message18:['ปัจจุบันน้ำหนักต่ำเกินไป','ค่าดัชนีมวลกายของคุณคือ','ไม่แนะนำให้ลดน้ำหนักต่อไปแล้ว (ช่วงสุขภาพดี 18.5<BMI<24)'],
				message19:['ลดน้ำหนักแบบปานกลาง','แนะนำไม่ให้ลดน้ำหนักมากกว่า 500 กรัมต่อวัน'],
				message20:['มีแผนอยู่แล้ว','ครอบคลุมแผนที่มีอยู่หรือไม่'],
				message21:['ง่าย','ปกติ','ปานกลาง','สูง'],
				message22:['จิตวิญญาณ:','ต่ำ','สูง'],
				message23:['จำนวนครั้งที่ใช้หมดไป ซื้อการสมัครสมาชิกเพื่อปลดล็อคครั้งมากขึ้น'],
			},
		},
		punch: {
			title: "เสร็จสิ้น",
			title2: "เสร็จสิ้น",
			h1: "ตัวเลือกการออกกำลังกาย",
			h2: "ความสำเร็จของฉัน",
			button: "เช็คอิน",
			day: "จำนวนวันเช็คอินติดต่อกัน",
			dayunit: "วัน",
		},
eat: {
			title: "อาหาร",
			DietaryIntake: "การกิน",
			kcal: "แคลอรี่",
			Stillingestible: "เหลือ",
			overingest: "เกิน",
			Recommendedintake: "แนะนำ",
			Motorconsumption: "การออกกำลังกาย",
			carbohydrate: "คาร์โบไฮเดรต",
			protein: "โปรตีน",
			fat: "ไขมัน",
			gram: "กรัม",
			drinkDk: "เช็คอินดื่มน้ำ",
			drinkDkhealth: "เช็คอินรายวันเพื่อสุขภาพที่ดีขึ้น",
			eatsuggest: "ข้อเสนอแนะด้านอาหาร",
			analyzefoodglx: "วิเคราะห์ความสมเหตุสมผลของอาหาร",
			eatPlan: "แผนอาหาร",
			makeeatPlan: "ปรับแผนอาหารของคุณเอง",
			breakfast: "อาหารเช้า",
			lunch: "มื้อเที่ยง",
			dinner: "อาหารเย็น",
			snack: "ของว่าง",
			addFood: "เพิ่มอาหาร",
			noAddFood: "ไม่มีอาหารที่เพิ่ม",
			cancle: "ยกเลิก",
			confirm: "ยืนยัน",
			tips: "การกรอกข้อมูลเชิงรุกถือว่าเป็นการยินยอมให้เรารวบรวมข้อมูลโครงการนี้และแสดงในอาหาร",
			addFooded: "อาหารที่เพิ่มแล้ว",
			added: "เพิ่มแล้ว",
			numFood: "จำนวนอาหาร",
			record: "บันทึก",
			dkTitle: "เช็คอิน",
			dailygoal: "เป้าหมายรายวัน",
			drinkDw: "ถ้วย/วัน",
			drinkWater: "ดื่มน้ำ",
			dailygoalWater: "เป้าหมายการดื่มน้ำรายวัน",
			myachievement: "ความสำเร็จของฉัน",
			lxdkDays: "จำนวนวันที่เช็คอินติดต่อกัน",
			day: "วัน",
			analyzeHistory: "ประวัติ",
			conclusion: "สรุป",
			eatDataAnalyze: "วิเคราะห์ข้อมูลอาหาร",
			selectStartDate: "เลือกวันที่เริ่มต้น",
			chooseoneDate: "กรุณาเลือก 1 วันเป็นวันที่เริ่มต้น",
			scheduling: "การจัดแผน",
			schedulingDesc: "เริ่มต้นจากวันที่ใกล้ที่สุดที่คุณเลือก รวมทั้งหมด 7 วัน; เนื้อหาที่เฉพาะเจาะจงมีดังนี้:",
			eatDetail:"รายละเอียดอาหาร",
			analyzeFailed:"การวิเคราะห์ล้มเหลว",
			noData:"ไม่มีข้อมูล"


		}
	},
  error: {
    error1101:"การเชื่อมต่อเครือข่ายไม่เสถียรการหมดเวลาเซสชันปัจจุบัน",
    error1102:"ขณะนี้ไม่มีเครือข่าย โปรดเชื่อมต่อใหม่",
    error1103:"เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ",
    error61:"บริการปัจจุบันไม่พร้อมใช้งาน",
    error4:"ยังตรวจไม่พบเสียง โปรดลองอีกครั้ง",
    error12:"กรุณากรอกคำหรือประโยคมาตรฐานเพื่อให้ AI เข้าใจและวาด",
		error50:"ขออภัยเซิร์ฟเวอร์ปัจจุบันผิดโปรดลองอีกครั้ง"
  },
	setting: {
		index: {
			title: "การตั้งค่า",
			problem: "ปัญหาการตอบกลับ",
			order: "ประวัติการสั่งซื้อ",
			notice: "การแจ้งเตือน",
			language: "ภาษา",
			clear: "ล้างแคช",
			clearTip: "คุณแน่ใจหรือว่าต้องการ\nล้างแคช?",
			confirm: "ยืนยัน",
			cancel: "ยกเลิก",
		},
		language: {
			title: "ภาษา",
			langList: ["ภาษาจีนตัวย่อ", "ภาษาจีนตัวเต็ม", "ภาษาอังกฤษ", "ภาษาอาหรับ", "ภาษาบัลแกเรีย", "ภาษาบังคลาเทศ", "ภาษาเช็ก", "ภาษาเยอรมัน", "ภาษากรีก", "ภาษาสเปน", "ภาษาเปอร์เซีย", "ภาษาฟิลิปปินส์", "ภาษาฝรั่งเศส", "ภาษาฮินดี", "ภาษาฮังการี", "ภาษาอินโดนีเซีย", "ภาษาอิตาลี", "ภาษาฮีบรู", "ภาษาญี่ปุ่น", "ภาษาเกาหลี", "ภาษามลายู", "ภาษาดัตช์", "ภาษาปัญจาบ", "ภาษาโปแลนด์", "ภาษาโปรตุเกส", "ภาษารูมาเนีย", "ภาษารัสเซีย", "ภาษาสโลวาเกีย", "ภาษาไทย", "ภาษาตุรกี", "ภาษายูเครน", "ภาษาอูรดู", "ภาษาเวียดนาม"],
		},
		order: {
			title: "ประวัติการสั่งซื้อ",
			success: "ได้รับข้อมูลสำเร็จ",
			unpaid: "ยังไม่ได้ชำระเงิน",
			paid: "ชำระเงินแล้ว",
			expired: "หมดอายุ",
			refunded: "คืนเงินแล้ว",
			pTime: "เวลาการชำระเงิน",
			eTime: "เวลาหมดอายุ",
			pMethod: "วิธีการชำระเงิน",
			pObject: "วัตถุการชำระเงิน",
			oNumber: "หมายเลขคำสั่งซื้อ",
			pType: "ประเภทแพ็กเกจ",
			oTime: "เวลาการรับข้อมูล",
			watch: "ดู IMEI",
			delete: "ลบคำสั่งซื้อ",
			refund: "ขอคืนเงิน",
		},
problem: {
			title: "ปัญหาการตอบกลับ",
			feedback: "ประเภทการตอบกลับ",
			please: "กรุณาเลือก",
			content: "เนื้อหา",
			improve: "กรุณาเขียนคำแนะนำของคุณ เช่น การขอคุณสมบัติ การร้องเรียนผลิตภัณฑ์ เป็นต้น เราจะพยายามปรับปรุง",
			upload: "อัปโหลดภาพ",
			submit: "ส่ง",
			general: "การตอบกลับทั่วไป",
			purchase: "เกี่ยวกับการซื้อ",
			bug: "รายงานข้อบกพร่อง",
			suggestion: "ข้อเสนอแนะ",
			other: "อื่นๆ",
		},
		notice: {
			title: "การแจ้งเตือน",
			type: "ประเภทการแจ้งเตือน: ",
			time: "เวลาการแจ้งเตือน: "
		},
		unsubscribe: {
			watch: "นาฬิกา:",
			title: "การสมัครต่อเนื่อง",
			subTitle: "สมัครแล้ว",
			unsubscribe: "ยกเลิกสมัคร",
			packList: ["การสมัครพรีเมียม", "การสมัครเบสิก", "การสมัครมาตรฐาน"],
		},
		refund: {
			title: "ขอคืนเงิน",
			number: "หมายเลขคำสั่งซื้อ",
			content: "กรุณาอธิบายเหตุผลในการขอคืนเงิน",
			packList: ["แพ็คเกจส่วนลดสำหรับผู้ใช้ใหม่", "แพ็คเกจ 50 ครั้ง", "แพ็คเกจ 100 ครั้ง",
				"แพ็คเกจ 300 ครั้ง"
			],
		}
	},
	tutorial: {
		index: {
			title: "รับข้อมูลงานขั้นสูง",
			watchList: ["เปิดนาฬิกา ค้นหาแอพลิเคชั่น WearAI และคลิกเพื่อเปิด.",
				"คลิกที่ปุ่มสิทธิบัตขั้นสูงที่หน้าแรก.",
				"นำโทรศัพท์มือถือออกมาและสแกน QR โค้ดบนหน้าจอเพื่อเข้าสู่หน้าสิทธิบัต."
			]
		}
	},
}