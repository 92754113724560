module.exports = {
	language: "한국어",
	navigator: {
		tools: "AI 도구",
		draw: "그리다",
		healthy: "건강한",
	},
aitools: {
		chat: {
			gpt: "자비스",
			categoryList: ["추천", "공부", "생활", "창작", "일"],
			presetGreet: "안녕하세요~ 나는 당신의 건강 관리사입니다. 항상 재미있고 유용한 건강 지식을 제공하여 당신의 건강을 지키는 데 도움을 줄 준비가 되어 있습니다!",
			presetQuiz: ["만성 피로의 원인은 무엇인가요?", "젊은 사람들이 만성 질병을 예방하기 위해 무엇을 할 수 있나요?", "건강한 식습관을 유지하는 방법은?", "좋은 수면을 유지하는 방법은?"],
			presets: "나에게 물어볼 수 있습니다:",
			change: "배치 변경",
			uploadImage: "이미지 보내기",
			footer: "내용이 AI 모델에 의해 생성되었기 때문에, 그 진위성을 완전히 보장할 수 없습니다.",
			remind: "할일 알림",
			limit: "이 대화는 제한에 도달했습니다. 새로운 AI 채팅 세션을 시작하세요",
			newDialog: "새로운 대화 시작하기",
			stopRecording: "아무 곳이나 클릭하거나 카운트다운이 끝날 때 인식",
			send: "메시지 보내기",
			say: "클릭하여 말하기",
			notImage: "이 에이전트는 파일 및 이미지 전송을 지원하지 않습니다",
			talking: "현재 대화 중",
			drawViolations: "AI 그림 프롬프트가 규칙을 위반했습니다. 적절한 프롬프트를 입력하십시오.",
			exhausted: "Q&A 횟수가 소진되었습니다. 시계 끝에서 QR 코드를 스캔하여 계속하십시오.",
			serverBusy: "현재 네트워크 링크가 불안정합니다. 다시 시도해 주세요",
			chatHistory: "대화 기록",
			chatHistoryDesc: "Q&A 기록은 3일 동안만 유지됩니다",
			todoList: "할일 목록",
			todoDesc: "할일 세부사항",
			todoListDesc: "최대 10개의 할일이 존재할 수 있습니다",
			confirm: "확인",
			cancel: "취소",
			text: "텍스트는 비워둘 수 없습니다",
			cancelRemind: "알림 취소",
			remindTime: "알림 시간:",
			remindTitle: "알림 제목:",
			remindContent: "알림 내용:",
			edit: "편집",
			reachLimit: "최대 한도에 도달했습니다",
			errorChat:"아직 음성이 감지되지 않았습니다. 다시 시도해 주세요.",
			      noSTT:"권한을 얻지 못했습니다. 계속하기 전에 권한을 활성화하세요.",
      error50:"알 수 없는 오류가 발생했습니다. 다시 시도해 주세요."
		},
		draw: {
			describe: "당신의 이미지를 설명하세요",
			voice: "음성 입력",
			press: "눌러서 말하기",
			placeholder: "생성하고자 하는 이미지를 설명하십시오",
			style: "그림 스타일",
			styleList: ["잉크 스타일", "사이버펑크", "애니메이션", "종이접기", "뜨개질",  "그래픽", "만화", "레고","연필", "그리기"],
			record: "그리기 기록",
			recordDesc: "최대 10개의 그림 기록이 저장될 수 있습니다",
			recordEmpty: "현재 그림 기록이 없습니다",
			generate: "이미지 생성",
			result: "이미지 생성 결과",
			download: "이미지 다운로드",
			regenerate: "다시 생성",
			setDial: "시계 화면으로 설정",
			error: "이미지 생성 실패",
			errorDesc: "불안정한 네트워크 연결, 다시 제출해 주세요.",
			confirm: "확인",
			analysiserror: "분석 실패",
      SetWatchErr:["다이얼이 성공적으로 설정되었습니다.","보내기 취소","데이터 확인 실패","시계 모드 데이터 파일이 너무 큽니다.","시계 모드 동기화가 상한에 도달했습니다.","블루투스 연결이 끊어졌습니다"],
      SetWatchTips:["설정 실패","다이얼 설정 진행 중","시계 모드를 설정하는 중입니다. 현재 페이지를 종료하지 마세요."],
      progressText:"동기화 중"

		},
sports: {
			analyze: "건강 분석",
			analyzeDesc: "기본 데이터 분석",
			advice: "건강 조언",
			adviceDesc: "건강 조언 제공",
			experience: "경험",
			program: {
				plan: "체중 감량 계획",
				startDate: "시작 날짜 선택",
				chooseDate: "시작 날짜로 1일을 선택하십시오",
				week: [{
					num: "일",
					day: "일요일"
				}, {
					num: "월",
					day: "월요일"
				}, {
					num: "화",
					day: "화요일"
				}, {
					num: "수",
					day: "수요일"
				}, {
					num: "목",
					day: "목요일"
				}, {
					num: "금",
					day: "금요일"
				}, {
					num: "토",
					day: "토요일"
				}, ],
				arrange: "계획 배열",
				arrangeDesc: "가장 가까운 날짜부터 시작하여 총 7일의 훈련 일입니다; 구체적인 내용은 다음과 같습니다:",
				confirm: "배열 확인",
				success: "축하합니다, 계획을 성공적으로 맞춤 설정했습니다! '운동 체크인' 페이지에서 훈련을 시작하세요!",
				successButton: "운동 체크인으로 이동",
			},
newmessage: {
				message1: ['개인 센터', '건강 데이터'],
				message2: ['건강', '운동', '식단'],
				message3: ['성별', '나이', '키', '몸무게'],
				message4: ['수면', '심박수', '산소', '걸음', '혈압', '스트레스', '요약'],
				message5: ['소비', '제안', '오늘의 걸음 수', '운동 시간'],
				message6: ['운동 체크인', '피트니스 홈 에디션', '피트니스 프로', '체중 감량 계획'],
				message7: ['운동하고 건강한 삶을 살기', '피트니스 계획 맞춤 설정', '피트니스 계획 맞춤 설정', '체중 감량 계획을 만드세요'],
				message8: ['Kcal', '걸음', '분', '세트', '일', '년', 'cm', 'kg','h','주'],
				message9: ['체크인', '훈련', '운동 옵션', '내 업적', '연속 체크인 일수', '체크인 미완료'],
				message10: ['분석 클릭', '분석 기록 (최대 10개 저장)', '분석', '현재 기록이 없습니다', '연속 체크인 일수', '데이터 분석'],
				message11: ['여성', '남성'],
				message12: ['취소', '확인'],
				message13: ['년', '센티미터', '킬로그램'],
				message14: ['수동 입력', ' 예상 시간(주)', '성별', '나이', '키 (센티미터)', '몸무게 (킬로그램)', '예상 몸무게 (킬로그램)'],
				message15: ['기록', '건강 데이터 분석', '건강 조언'],
				message16: ['체크인 성공', '체크인 중', '같은 날에만 체크인 가능'],
				message17: ['건강 데이터 분석은 하루에 한 번만 가능합니다, 로컬 데이터를 찾을 수 없습니다', '건강 조언은 하루에 한 번만 가능합니다, 로컬 데이터를 찾을 수 없습니다'],
				message18:['현재 저체중','BMI 지수는','계속해서 체중을 줄이는 것은 권장되지 않습니다 (건강 범위 18.5<BMI<24)'],
				message19:['적당한 체중 감량','하루에 500g 이상을 줄이는 것은 권장되지 않습니다'],
				message20:['계획이 있습니다','기존 계획을 포함하나요'],
				message21:['쉬움','보통','중간','높음'],
				message22:['정신:', '낮음', '높음'],
				message23:['횟수를 모두 사용했습니다. 더 많은 횟수를 잠금 해제하려면 구독을 구매하세요.'],
			},
		},
punch: {
			title: "완료",
			title2: "끝",
			h1: "운동 옵션",
			h2: "내 업적",
			button: "체크인",
			day: "연속 체크인 일수",
			dayunit: "일",
		},
		eat: {
			title: "식단",
			DietaryIntake: "섭취",
			kcal: "Kcal",
			Stillingestible: "잔여",
			overingest: "초과",
			Recommendedintake: "제안",
			Motorconsumption: "운동",
			carbohydrate: "탄수화물",
			protein: "단백질",
			fat: "지방",
			gram: "그램",
			drinkDk: "물 체크인",
			drinkDkhealth: "건강을 위한 일일 체크인",
			eatsuggest: "식단 제안",
			analyzefoodglx: "식단의 합리성 분석",
			eatPlan: "식단 계획",
			makeeatPlan: "맞춤형 식단 계획 설정",
			breakfast: "아침",
			lunch: "점심",
			dinner: "저녁",
			snack: "간식",
			addFood: "음식 추가",
			noAddFood: "추가된 음식 없음",
			cancle: "취소",
			confirm: "확인",
			tips: "자발적으로 기입하는 것은 우리가 이 프로젝트 정보를 수집하고 식단에 표시하는 것에 동의하는 것으로 간주됩니다",
			addFooded: "음식 추가됨",
			added: "추가됨",
			numFood: "음식 항목",
			record: "기록",
			dkTitle: "체크인",
			dailygoal: "일일 목표",
			drinkDw: "컵/일",
			drinkWater: "물 마시기",
			dailygoalWater: "일일 물 섭취 목표",
			myachievement: "내 업적",
			lxdkDays: "연속 체크인 일수",
			day: "일",
			analyzeHistory: "기록",
			conclusion: "요약",
			eatDataAnalyze: "식단 데이터 분석",
			selectStartDate: "시작 날짜 선택",
			chooseoneDate: "시작 날짜로 1일을 선택하십시오",
			scheduling: "계획 배열",
			schedulingDesc: "선택한 가장 가까운 날짜부터 시작하여 총 7일 동안 진행됩니다. 구체적인 내용은 다음과 같습니다:",
			eatDetail:"식단 상세",
			analyzeFailed:"분석 실패",
			noData:"데이터 없음"
		}
	},
  error: {
    error1101:"네트워크 연결이 불안정하고 현재 세션 타임 아웃입니다",
    error1102:"현재 네트워크가 없습니다. 다시 연결해 주세요.",
    error1103:"알 수 없는 오류가 발생했습니다.",
    error61:"현재 서비스를 이용할 수 없습니다",
    error4:"아직 음성이 감지되지 않았습니다. 다시 시도해 주세요.",
    error12:"AI가 이해하고 그릴 수 있는 표준 단어나 문장을 입력해주세요.",
		error50:"죄송합니다. 현재 서버가 잘못되었습니다. 다시 시도하십시오"
  },
	setting: {
		index: {
			title: "설정",
			problem: "문제 피드백",
			order: "주문 기록",
			notice: "알림",
			language: "언어",
			clear: "캐시 지우기",
			clearTip: "캐시를 지우시겠습니까?",
			confirm: "확인",
			cancel: "취소",
		},
		language: {
			title: "언어",
			langList: ["중국어 간체", "중국어 번체", "영어", "아랍어", "불가리아어", "벵골어", "체코어", "독일어", "그리스어", "스페인어", "페르시아어", "필리핀어", "프랑스어", "힌디어", "헝가리어", "인도네시아어", "이탈리아어", "히브리어", "일본어", "한국어", "말레이어", "네덜란드어", "펀자브어", "폴란드어", "포르투갈어", "루마니아어", "러시아어", "슬로바키아어", "태국어", "터키어", "우크라이나어", "우르두어", "베트남어"],
		},
		order: {
			title: "주문 기록",
			success: "성공적으로 불러왔습니다",
			unpaid: "미지불",
			paid: "지불 완료",
			expired: "만료됨",
			refunded: "환불됨",
			pTime: "결제 시간",
			eTime: "만료 시간",
			pMethod: "결제 방법",
			pObject: "결제 대상",
			oNumber: "주문 번호",
			pType: "패키지 유형",
			oTime: "불러온 시간",
			watch: "IMEI 보기",
			delete: "주문 삭제",
			refund: "환불 요청",
		},
problem: {
			title: "문제 피드백",
			feedback: "피드백 유형",
			please: "선택해 주세요",
			content: "내용",
			improve: "기능 요청, 제품 불만 사항 등 제안 사항을 작성해 주세요. 개선하기 위해 노력하겠습니다",
			upload: "이미지 업로드",
			submit: "제출",
			general: "일반 피드백",
			purchase: "구매 관련",
			bug: "버그 신고",
			suggestion: "제안",
			other: "기타",
		},
		notice: {
			title: "알림",
			type: "알림 유형: ",
			time: "알림 시간: "
		},
		unsubscribe: {
			watch: "시계:",
			title: "연속 구독",
			subTitle: "구독됨",
			unsubscribe: "구독 취소",
			packList: ["프리미엄 구독", "베이직 구독", "스탠다드 구독"],
		},
		refund: {
			title: "환불 요청",
			number: "주문 번호",
			content: "환불 사유를 설명해 주세요",
			packList: ["신규 사용자 할인 패키지", "50회 패키지", "100회 패키지", "300회 패키지"],
		}
	},
	tutorial: {
		index: {
			title: "고급 튜토리얼 받기",
			watchList: ["시계를 켜고 WearAI 애플리케이션을 찾아서 클릭하여 엽니다.",
				"홈 페이지에서 고급 혜택 버튼을 클릭합니다.",
				"휴대폰을 꺼내 시계에 표시된 QR 코드를 스캔하여 혜택 페이지로 이동합니다."
			]
		}
	},
}