module.exports = {
	language: "русский",
	navigator: {
		tools: "AI Tools",
		draw: "Рисовать",
		healthy: "данные",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Рекомендуемое", "Учеба", "Жизнь", "Создание", "Работа"],
			presetGreet: "Привет, я ваш стюард здоровья, всегда готов предоставить интересные и полезные знания о здоровье, чтобы помочь вам защитить свое здоровье!",
			presetQuiz: ["Каковы причины хронической усталости?", "Как молодым людям предотвратить хронические заболевания?", "Как поддерживать здоровые пищевые привычки?", "Как поддерживать хороший сон?"],
			presets: "Вы можете спросить меня:",
			change: "Сменить партию",
			uploadImage: "Отправить изображение",
			footer: "Поскольку содержимое генерируется моделью ИИ, его достоверность не может быть полностью гарантирована.",
			remind: "Напоминание о делах",
			limit: "Этот разговор достиг предела, пожалуйста, начните новый сеанс чата с ИИ",
			newDialog: "Начать новый разговор",
			stopRecording: "Нажмите в любом месте или дождитесь окончания обратного отсчета для распознавания",
			send: "Отправить сообщение",
			say: "Нажмите, чтобы говорить",
			notImage: "Этот агент не поддерживает отправку файлов и изображений",
			talking: "Текущий разговор в процессе",
			drawViolations: "Запрос на рисование ИИ нарушает правила, пожалуйста, введите соответствующий запрос.",
			exhausted: "Количество вопросов и ответов исчерпано, пожалуйста, перейдите на конец просмотра и отсканируйте QR-код, чтобы продолжить.",
			serverBusy: "Текущая сеть нестабильна, пожалуйста, попробуйте снова",
			chatHistory: "История чатов",
			chatHistoryDesc: "Записи вопросов и ответов хранятся только 3 дня",
			todoList: "Список дел",
			todoDesc: "Детали дел",
			todoListDesc: "Максимум 10 дел могут существовать",
			confirm: "Подтвердить",
			cancel: "Отменить",
			text: "Текст не может быть пустым",
			cancelRemind: "Отменить напоминание",
			remindTime: "Время напоминания:",
			remindTitle: "Название напоминания:",
			remindContent: "Содержание напоминания:",
			edit: "Редактировать",
			reachLimit: "Добавлено до предела",
			errorChat:"Голос еще не обнаружен, попробуйте еще раз",
			      noSTT:"Разрешение не получено, пожалуйста, включите разрешение, прежде чем продолжить.",
      error50:"Произошла неизвестная ошибка. Повторите попытку."
		},
		draw: {
			describe: "Опишите образ",
			voice: "Голосовой ввод",
			press: "Нажмите, чтобы говорить",
			placeholder: "Опишите изображение, которое хотите создать",
			style: "Стиль рисования",
			styleList: ["Стиль тушью", "Киберпанк", "Аниме", "Оригами", "Вязание",  "Графика", "Мультик","LEGO","Карандаш","Рисовать"],
			record: "Запись рисования",
			recordDesc: "Можно сохранить до 10 записей рисования",
			recordEmpty: "У вас нет записей рисования",
			generate: "Создать изображение",
			result: "Pезультат",
			download: "Cкачать",
			regenerate: "Eще раз",
			setDial: "Установить как циферблат часов",
			error: "Ошибка генерации изображения",
			errorDesc: "Нестабильное сетевое соединение, пожалуйста, отправьте снова.",
			confirm: "Подтвердить",
			analysiserror: "Ошибка анализа",
      SetWatchErr:["Набор номера успешно установлен","Отменить отправку","Проверка данных не удалась","Файл данных циферблата слишком велик.","Синхронизация циферблата достигла верхнего предела","Bluetooth отключен"],
      SetWatchTips:["Установка не удалась","Выполняется настройка набора номера","Циферблат устанавливается, не закрывайте текущую страницу."],
      progressText:"Синхронизировано."

		},
		sports: {
			analyze: "Анализ здоровья",
			analyzeDesc: "Ситуация с данными",
			advice: "Советы по здоровью",
			adviceDesc: "Разумный совет",
			experience: "Опыт",
			program: {
				plan: "План похудения",
				startDate: "Выберите дату начала",
				chooseDate: "Пожалуйста, выберите 1 день в качестве даты начала",
				week: [{
					num: "Вс.",
					day: "Воскресенье"
				}, {
					num: "Пн.",
					day: "Понедельник"
				}, {
					num: "Вт.",
					day: "Вторник"
				}, {
					num: "Ср.",
					day: "Среда"
				}, {
					num: "Чт.",
					day: "Четверг"
				}, {
					num: "Пт.",
					day: "Пятница"
				}, {
					num: "Сб.",
					day: "Суббота"
				}, ],
				arrange: "Планирование",
				arrangeDesc: "Начните с ближайшей выбранной даты, всего 7 тренировочных дней; конкретное содержание следующее:",
				confirm: "Подтвердить планирование",
				success: "Поздравляем, вы успешно настроили план! Начните свои тренировки на странице 'Зарегистрироваться на тренировку'!",
				successButton: "Перейти к регистрации на тренировку",
			},
			newmessage: {
				message1: ['Личный кабинет', 'Данные о здоровье'],
				message2: ['Здоровье', 'спорт', 'диета'],
				message3: ['Пол', 'Возраст', 'Рост', 'Вес'],
				message4: ['Сон', 'ЧСС', 'Кислород', 'Шаги', 'АД', 'Давление', 'Итог'],
				message5: ['Состояние', 'Подходит', 'ходить',
					'спорт время'
				],
				message6: ['Регистрация на упражнения', 'Фитнес для дома',
					'Фитнес про', 'План похудения'
				],
				message7: ['тренируйся каждый день',
					'Настройте свой план фитнеса', 'Настройте свой план фитнеса',
					'Создайте план похудения для себя'
				],
				message8: ['Ккал', 'Шаги', 'Минуты', 'Комплекты', 'Дни', 'Годы', 'см', 'кг', 'ч','Недели'],
				message9: ['Регистрация', 'Тренировка', 'Варианты упражнений', 'Мои достижения',
					'Постоянные проверки', 'Не зарегистрирован'
				],
				message10: ['Нажмите для анализа', 'История анализа (максимум 10 сохраненных)', 'Анализ',
					'У вас нет исторических записей', 'Постоянные проверки', 'Анализ данных'
				],
				message11: ['Женский', 'Мужской'],
				message12: ['Отмена', 'Подтвердить'],
				message13: ['Годы', 'Сантиметры', 'Килограммы'],
				message14: ['Ручной ввод', 'Ожидаемое время (недели) ', 'Пол', 'Возраст', 'Рост (сантиметры)',
					'Вес (килограммы)', 'Ожидаемый вес (килограммы)'
				],
				message15: ['История', 'Анализ данных о здоровье', 'Советы по здоровью'],
				message16: ['Регистрация успешна', 'Регистрация в процессе', 'Можно регистрироваться только в тот же день'],
				message17: ['Анализ данных о здоровье можно получить только раз в день, и локальные данные не найдены', 'Советы по здоровью можно получить только раз в день, и локальные данные не найдены'],
				message18: ['В настоящее время недостаточный вес', 'Ваш индекс массы тела (ИМТ)', 'не рекомендуется продолжать терять вес (Здоровый диапазон 18,5<BMI<24)'],
				message19: ['Умеренная потеря веса', 'Рекомендуется худеть не более 500г в день'],
				message20: ['есть планы', 'Покрывает ли это существующие планы'],
				message21: ['Легкий', 'Нормальный', 'Средний', 'Высокий'],
				message22: ['Дух:', 'Низкий', 'Высокий'],
				message23: ['Количество использований. Приобретите подписку, чтобы разблокировать больше раз.'],
			},
		},
		punch: {
			title: "Готово",
			title2: "Завершить",
			h1: "Варианты упражнений",
			h2: "Мои достижения",
			button: "Регистрация",
			day: "Постоянные проверки",
			dayunit: "Дни",
		},
		eat: {
			title: "диета",
			DietaryIntake: "Питание",
			kcal: "Ккал",
			Stillingestible: "Остаток",
			overingest: "Превышение",
			Recommendedintake: "Может",
			Motorconsumption: "Движение",
			carbohydrate: "Углеводы",
			protein: "Белок",
			fat: "Жиры",
			gram: "Граммы",
			drinkDk: "Регистрация воды",
			drinkDkhealth: "Питьевая вода полезнее",
			eatsuggest: "Рекомендации по диете",
			analyzefoodglx: "Анализ рациональности питания",
			eatPlan: "План питания",
			makeeatPlan: "Индивидуальное питание",
			breakfast: "Завтрак",
			lunch: "Обед",
			dinner: "Ужин",
			snack: "Перекус",
			addFood: "Добавить еду",
			noAddFood: "Еда не добавлена",
			cancle: "Отменить",
			confirm: "Подтвердить",
			tips: "Добровольное заполнение считается согласием на сбор этой информации и отображение её в диете",
			addFooded: "Еда добавлена",
			added: "Добавлено",
			numFood: "Количество еды",
			record: "Запись",
			dkTitle: "Регистрация",
			dailygoal: "Ежедневная цель",
			drinkDw: "Чашек/день",
			drinkWater: "Пить воду",
			dailygoalWater: "цели на день",
			myachievement: "Мои достижения",
			lxdkDays: "Постоянные проверки",
			day: "Дни",
			analyzeHistory: "История",
			conclusion: "Итог",
			eatDataAnalyze: "Анализ данных питания",
			selectStartDate: "Выберите дату начала",
			chooseoneDate: "Пожалуйста, выберите 1 день в качестве даты начала",
			scheduling: "Планирование",
			schedulingDesc: "Начните с ближайшей выбранной даты, всего 7 дней; конкретное содержание следующее:",
			eatDetail: "Детали питания",
			analyzeFailed: "Анализ не удался",
			noData: "нет данных"


		}
	},
  error: {
    error1101:"Сетевое соединение нестабильно, текущий тайм -аут сеанса",
    error1102:"В настоящее время сети нет, пожалуйста, переподключитесь",
    error1103:"Произошла неизвестная ошибка",
    error61:"Текущая услуга недоступна",
    error4:"Голос еще не обнаружен, попробуйте еще раз",
    error12:"Введите стандартные слова или предложения, чтобы ИИ мог их понять и нарисовать.",
		error50:"Извините, текущий сервер неверен, попробуйте еще раз"
	},
	setting: {
		index: {
			title: "Настройки",
			problem: "Обратная связь",
			order: "История заказов",
			notice: "Уведомления",
			language: "Язык",
			clear: "Очистить кэш",
			clearTip: "Вы уверены, что хотите\nочистить кэш?",
			confirm: "Подтвердить",
			cancel: "Отменить",
		},
		language: {
			title: "Язык",
			langList: ["Упрощенный китайский", "Традиционный китайский", "Английский", "Арабский", "Болгарский", "Бенгальский", "Чешский", "Немецкий", "Греческий", "Испанский", "Фарси", "Филиппинский", "Французский", "Хинди", "Венгерский", "Индонезийский", "Итальянский", "Иврит", "Японский", "Корейский", "Малайский", "Голландский", "Панджаби", "Польский", "Португальский", "Румынский", "Русский", "Словацкий", "Тайский", "Турецкий", "Украинский", "Урду", "Вьетнамский"],
		},
		order: {
			title: "История заказов",
			success: "Успешно получено",
			unpaid: "Неоплачено",
			paid: "Оплачено",
			expired: "Истекло",
			refunded: "Возвращено",
			pTime: "Время оплаты",
			eTime: "Время истечения срока",
			pMethod: "Метод оплаты",
			pObject: "Объект оплаты",
			oNumber: "Номер заказа",
			pType: "Тип пакета",
			oTime: "Время получения",
			watch: "Просмотр IMEI",
			delete: "Удалить заказ",
			refund: "Запросить возврат",
		},
		problem: {
			title: "Обратная связь",
			feedback: "Тип Обратной Связи",
			please: "Пожалуйста, выберите",
			content: "Содержание",
			improve: "Пожалуйста, напишите свои предложения, такие как запросы функций, жалобы на продукт и т.д. Мы будем стремиться к улучшению",
			upload: "Загрузить изображение",
			submit: "Отправить",
			general: "Общая обратная связь",
			purchase: "Связано с покупкой",
			bug: "Сообщить об ошибке",
			suggestion: "Предложение",
			other: "Другое",
		},
		notice: {
			title: "Уведомление",
			type: "Тип уведомления: ",
			time: "Время уведомления: "
		},
		unsubscribe: {
			watch: "Часы:",
			title: "Непрерывная подписка",
			subTitle: "Подписан",
			unsubscribe: "Отписаться",
			packList: ["Премиум подписка", "Базовая подписка", "Стандартная подписка"],
		},
		refund: {
			title: "Запросить возврат",
			number: "Номер заказа",
			content: "Пожалуйста, объясните причину возврата",
			packList: ["Скидочный пакет для новых пользователей", "Пакет на 50 попыток", "Пакет на 100 попыток",
				"Пакет на 300 попыток"
			],
		}
	},
	tutorial: {
		index: {
			title: "Получить расширенное руководство",
			watchList: ["Включите часы, найдите приложение WearAI и нажмите, чтобы открыть.",
				"Нажмите кнопку расширенных преимуществ на главной странице.",
				"Возьмите свой мобильный телефон и отсканируйте QR-код на часах, чтобы перейти на страницу преимуществ."
			]
		}
	},
}