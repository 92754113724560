module.exports = {
	language: "język polski",
	navigator: {
		tools: "AI Tools",
		draw: "Rysować",
		healthy: "Zdrów",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Polecam", "Nauka", "Życie", "Tworzenie", "Praca"],
			presetGreet: "Hej, cześć~ Jestem twoim zdrowotnym opiekunem, zawsze gotowym dostarczać interesującą i użyteczną wiedzę na temat zdrowia, aby pomóc ci chronić swoje zdrowie!",
			presetQuiz: ["Jakie są przyczyny chronicznego zmęczenia?", "Jak młodzi ludzie mogą zapobiegać chorobom przewlekłym?", "Jak utrzymać zdrowe nawyki żywieniowe?", "Jak utrzymać zdrowy sen?"],
			presets: "Możesz mnie zapytać:",
			change: "Zmień partię",
			uploadImage: "Wyślij obraz",
			footer: "Ponieważ treść jest generowana przez model AI, jej autentyczność nie może być w pełni zagwarantowana.",
			remind: "Przypomnienie do zrobienia",
			limit: "Rozmowa osiągnęła limit, proszę rozpocząć nową sesję czatu AI",
			newDialog: "Rozpocznij nową rozmowę",
			stopRecording: "Kliknij gdziekolwiek lub kiedy skończy się odliczanie, aby rozpoznać",
			send: "Wyślij wiadomość",
			say: "Kliknij, aby mówić",
			notImage: "Ten agent nie obsługuje wysyłania plików i obrazów",
			talking: "Trwa rozmowa",
			drawViolations: "Komenda rysunku AI narusza zasady, proszę wprowadzić zgodną komendę.",
			exhausted: "Wyczerpano liczbę pytań i odpowiedzi, proszę przejść na stronę oglądania i zeskanować kod QR, aby kontynuować.",
			serverBusy: "Bieżące połączenie sieciowe jest niestabilne, proszę spróbować ponownie",
			chatHistory: "Historia czatu",
			chatHistoryDesc: "Rekordy Q&A są przechowywane tylko przez 3 dni",
			todoList: "Lista rzeczy do zrobienia",
			todoDesc: "Szczegóły rzeczy do zrobienia",
			todoListDesc: "Może istnieć maksymalnie 10 rzeczy do zrobienia",
			confirm: "Potwierdź",
			cancel: "Anuluj",
			text: "Tekst nie może być pusty",
			cancelRemind: "Anuluj przypomnienie",
			remindTime: "Czas przypomnienia:",
			remindTitle: "Tytuł przypomnienia:",
			remindContent: "Treść przypomnienia:",
			edit: "Edytuj",
			reachLimit: "Dodano do limitu",
			errorChat:"Głos nie został jeszcze wykryty. Spróbuj ponownie",
			      noSTT:"Nie uzyskano pozwolenia. Włącz pozwolenie, zanim będziesz kontynuować.",
      error50:"Wystąpił nieznany błąd. Spróbuj ponownie"
		},
		draw: {
			describe: "Opisz swój obraz",
			voice: "Wejście głosowe",
			press: "Naciśnij, aby mówić",
			placeholder: "Opisz obraz, który chcesz wygenerować",
			style: "Styl malowania",
			styleList: ["Styl tuszu", "Cyberpunk", "Anime", "Origami", "Dzianie", "Grafika", "Kreskówka","LEGO","Ołówek","Rysunek"],
			record: "Rekord rysowania",
			recordDesc: "Można zapisać maksymalnie 10 rekordów rysowania",
			recordEmpty: "Brak danych",
			generate: "Generuj obraz",
			result: "Rezultat",
			download: "ściągać",
			regenerate: "Ponownie",
			setDial: "Ustaw jako tarczę zegarka",
			error: "Nie udało się wygenerować obrazu",
			errorDesc: "Niestabilne połączenie sieciowe, proszę przesłać ponownie.",
			confirm: "Potwierdź",
			analysiserror: "Analiza nie powiodła się",
      SetWatchErr:["Wybieranie ustawione pomyślnie","Anuluj wysyłanie","Anuluj wysyłanie","Plik danych tarczy zegarka jest za duży","Synchronizacja tarczy zegarka osiągnęła górny limit","Bluetooth został rozłączony"],
      SetWatchTips:["Konfiguracja nie powiodła się","Trwa ustawianie wybierania","Trwa ustawianie tarczy zegarka. Nie opuszczaj bieżącej strony."],
			progressText:"Synchronizacji"
		},
		sports: {
			analyze: "Analiza Zdrowia",
			analyzeDesc: "Analizuj dane podstawowe",
			advice: "Porady Zdrowotne",
			adviceDesc: "rozsądna rada",
			experience: "Doświadczenie",
			program: {
				plan: "Plan Odchudzania",
				startDate: "Wybierz Datę Rozpoczęcia",
				chooseDate: "Proszę wybrać 1 dzień jako datę rozpoczęcia",
				week: [{
					num: "Niedz.",
					day: "Niedziela"
				}, {
					num: "Pon.",
					day: "Poniedziałek"
				}, {
					num: "Wt.",
					day: "Wtorek"
				}, {
					num: "Śr.",
					day: "Środa"
				}, {
					num: "Czw.",
					day: "Czwartek"
				}, {
					num: "Pt.",
					day: "Piątek"
				}, {
					num: "Sob.",
					day: "Sobota"
				}, ],
				arrange: "Plan Treningowy",
				arrangeDesc: "Zacznij od najbliższego wybranego dnia, łącznie 7 dni treningowych; szczegółowa treść jest następująca:",
				confirm: "Potwierdź Układ",
				success: "Gratulacje, pomyślnie dostosowałeś plan! Zacznij trening na stronie 'Zameldowanie na trening'!",
				successButton: "Przejdź do Zameldowania na trening",
			},
			newmessage: {
				message1: ['Centrum Osobiste', 'Dane Zdrowotne'],
				message2: ['Zdrowie', 'Sport', 'Dietę'],
				message3: ['Płeć', 'Wiek', 'Wzrost', 'Waga'],
				message4: ['Sen', 'HR', 'Tlen', 'Kroki', 'Krew ucisk', 'Stres', 'Podsumowanie'],
				message5: ['Spożycie', 'Sugestia', 'Dzisiejsze Kroki',
					'Czas Ćwiczeń '
				],
				message6: ['Zameldowanie na trening', 'Fitness Edycja Domowa',
					'Fitness Pro', 'Plan Odchudzania'
				],
				message7: ['Ćwicz i żyj zdrowo',
					'Dostosuj swój plan fitness', 'Dostosuj swój plan fitness',
					'Utwórz plan odchudzania dla siebie'
				],
				message8: ['Kcal', 'Kroki', 'Minuty', 'Serie', 'Dni', 'Lata', 'cm', 'kg', 'h','Tygodnie'],
				message9: ['Zameldowanie', 'Trening', 'Opcje Ćwiczeń', 'Moje Osiągnięcia',
					'Dni Stałego Zameldowania', 'Nie Zameldowano'
				],
				message10: ['Kliknij, aby Analizować', 'Historia Analiz (maksymalnie 10 zapisanych)', 'Analizuj',
					'Brak danych', 'Dni Stałego Zameldowania', 'Analiza Danych'
				],
				message11: ['Kobieta', 'Mężczyzna'],
				message12: ['Anuluj', 'Potwierdź'],
				message13: ['Lata', 'Centymetry', 'Kilogramy'],
				message14: ['Ręczny Wpis', 'Oczekiwany czas (Tygodnie)', 'Płeć', 'Wiek', 'Wzrost (Centymetry)',
					'Waga (Kilogramy)', 'Oczekiwana Waga (Kilogramy)'
				],
				message15: ['Historia', 'Analiza Danych Zdrowotnych', 'Porady Zdrowotne'],
				message16: ['Zameldowanie Udane', 'Zameldowywanie', 'Można się zameldować tylko tego samego dnia'],
				message17: ['Analiza danych zdrowotnych może być uzyskana tylko raz dziennie, lokalnych danych nie znaleziono', 'Porady zdrowotne można uzyskać tylko raz dziennie, lokalnych danych nie znaleziono'],
				message18: ['Obecnie niedowaga', 'Twój wskaźnik BMI wynosi', 'nie zaleca się dalszego odchudzania (Zakres zdrowy 18.5<BMI<24)'],
				message19: ['Umiarkowane odchudzanie', 'Zaleca się tracić nie więcej niż 500g dziennie'],
				message20: ['masz plany', 'Czy obejmuje istniejące plany'],
				message21: ['Łatwy', 'Normalny', 'Średni', 'Wysoki'],
				message22: ['Duch:', 'Niski', 'Wysoki'],
				message23: ['Liczba razy została wykorzystana. Kup subskrypcję, aby odblokować więcej razy.'],
			},
		},
		punch: {
			title: "Zrobione",
			title2: "Zakończ",
			h1: "Opcje Ćwiczeń",
			h2: "Moje Osiągnięcia",
			button: "Zameldowanie",
			day: "Dni Stałego Zameldowania",
			dayunit: "Dni",
		},
		eat: {
			title: "Dietę",
			DietaryIntake: "Jedzenie",
			kcal: "Kcal",
			Stillingestible: "Pozostało",
			overingest: "Ponad",
			Recommendedintake: "Sugestia",
			Motorconsumption: "Ruch",
			carbohydrate: "Węglowodany",
			protein: "Białko",
			fat: "Tłuszcz",
			gram: "g",
			drinkDk: "Zameldowanie Wody",
			drinkDkhealth: "Picie wody jest zdrowsze",
			eatsuggest: "Sugestie Dietetyczne",
			analyzefoodglx: "Analizuj racjonalność diety",
			eatPlan: "Plan Dietetyczny",
			makeeatPlan: "Dostosuj swój ekskluzywny plan diety",
			breakfast: "Poranek",
			lunch: "Lunch",
			dinner: "Kolacja",
			snack: "Więcej",
			addFood: "Dodaj Jedzenie",
			noAddFood: "Brak dodanego jedzenia",
			cancle: "Anuluj",
			confirm: "Potwierdź",
			tips: "Dobrowolne wypełnienie jest uznawane za zgodę na zbieranie przez nas tych informacji na temat projektu i ich wyświetlanie w diecie",
			addFooded: "Dodano jedzenie",
			added: "Dodano",
			numFood: "Sztuk jedzenia",
			record: "Rekord",
			dkTitle: "Zameldowanie",
			dailygoal: "Cel Dzienny",
			drinkDw: "Kubków/Dzień",
			drinkWater: "Pij Wodę",
			dailygoalWater: "Cele Codzienne",
			myachievement: "Moje Osiągnięcia",
			lxdkDays: "Dni Stałego Zameldowania",
			day: "Dni",
			analyzeHistory: "Historia",
			conclusion: "Podsumowanie",
			eatDataAnalyze: "Analiza Danych Żywieniowych",
			selectStartDate: "Wybierz Datę Rozpoczęcia",
			chooseoneDate: "Proszę wybrać 1 dzień jako datę rozpoczęcia",
			scheduling: "Planowanie",
			schedulingDesc: "Zacznij od najbliższego wybranego dnia, łącznie 7 dni; szczegółowa treść jest następująca:",
			eatDetail: "Szczegóły Diety",
			analyzeFailed: "Analiza nie powiodła się",
			noData: "brak danych"


		}
	},
  error: {
    error1101:"Połączenie sieciowe jest niestabilne, aktualny limit czasu sesji",
    error1102:"Obecnie nie ma sieci. Połącz się ponownie",
    error1103:"Wystąpił nieznany błąd",
    error61:"Bieżąca usługa jest niedostępna",
    error4:"Głos nie został jeszcze wykryty. Spróbuj ponownie",
    error12:"Wprowadź standardowe słowa lub zdania, aby sztuczna inteligencja mogła je zrozumieć i narysować",
		error50:"Przepraszamy, obecny serwer jest zły, spróbuj ponownie"
  },
	setting: {
		index: {
			title: "Ustawienia",
			problem: "Zgłaszanie Problemów",
			order: "Rekordy Zamówień",
			notice: "Powiadomienia",
			language: "Język",
			clear: "Wyczyść Pamięć Podręczną",
			clearTip: "Czy na pewno chcesz\nwyczyścić pamięć podręczną?",
			confirm: "Potwierdź",
			cancel: "Anuluj",
		},
		language: {
			title: "Język",
			langList: ["Chiński uproszczony", "Chiński tradycyjny", "Angielski", "Arabski", "Bułgarski", "Bengalski", "Czeski", "Niemiecki", "Grecki", "Hiszpański", "Perski", "Filipino", "Francuski", "Hinduski", "Węgierski", "Indonezyjski", "Włoski", "Hebrajski", "Japoński", "Koreański", "Malajski", "Holenderski", "Pendżabski", "Polski", "Portugalski", "Rumuński", "Rosyjski", "Słowacki", "Tajski", "Turecki", "Ukraiński", "Urdu", "Wietnamski"],
		},
		order: {
			title: "Rekordy Zamówień",
			success: "Pomyślnie Odebrano",
			unpaid: "Nieopłacone",
			paid: "Opłacone",
			expired: "Wygasło",
			refunded: "Zwrócono",
			pTime: "Czas Płatności",
			eTime: "Czas Wygaśnięcia",
			pMethod: "Metoda Płatności",
			pObject: "Obiekt Płatności",
			oNumber: "Numer Zamówienia",
			pType: "Typ Pakietu",
			oTime: "Czas Odebrania",
			watch: "Zobacz IMEI",
			delete: "Usuń Zamówienie",
			refund: "Złóż Wniosek o Zwrot",
		},
		problem: {
			title: "Zgłaszanie Problemów",
			feedback: "Rodzaj Informacji Zwrotnej",
			please: "Proszę Wybrać",
			content: "Treść",
			improve: "Proszę napisać swoje sugestie, takie jak prośby o funkcje, skargi na produkt itp. Będziemy się starać poprawić",
			upload: "Prześlij Obraz",
			submit: "Prześlij",
			general: "Ogólna Informacja Zwrotna",
			purchase: "Zakupy",
			bug: "Zgłoszenie Bledu",
			suggestion: "Sugestia",
			other: "Inne",
		},
		notice: {
			title: "Powiadomienie",
			type: "Typ Powiadomienia: ",
			time: "Czas Powiadomienia: "
		},
		unsubscribe: {
			watch: "Zegarek:",
			title: "Ciągła Subskrypcja",
			subTitle: "Subskrybowano",
			unsubscribe: "Anuluj Subskrypcję",
			packList: ["Subskrypcja Premium", "Subskrypcja Podstawowa", "Subskrypcja Standardowa"],
		},
		refund: {
			title: "Złóż Wniosek o Zwrot",
			number: "Numer Zamówienia",
			content: "Proszę wyjaśnić powód zwrotu",
			packList: ["Pakiet Zniżkowy dla Nowych Użytkowników", "Pakiet 50 Razów", "Pakiet 100 Razów",
				"Pakiet 300 Razów"
			],
		}
	},
	tutorial: {
		index: {
			title: "Uzyskaj Zaawansowany Samouczek",
			watchList: ["Włącz zegarek, znajdź aplikację WearAI i kliknij, aby otworzyć.",
				"Kliknij przycisk zaawansowanych korzyści na stronie głównej.",
				"Wyjmij telefon i zeskanuj kod QR na zegarku, aby przejść na stronę korzyści."
			]
		}
	},
}