module.exports = {
  language: "Tagalog",
  navigator: {
  tools: "AI Mga Tool",
  draw: "Gumuhit",
  healthy: "Datos",
  },
  aitools: {
      chat: {
        gpt: "Jarvis",
        categoryList: ["Inirerekomenda", "Pag-aaral", "Buhay", "Paglikha", "Trabaho"],
        presetGreet: "Hey, hello~ Ako ang iyong tagapangalaga ng kalusugan, laging handang magbigay ng kawili-wili at kapaki-pakinabang na kaalaman sa kalusugan upang matulungan kang mapanatili ang iyong kalusugan!",
        presetQuiz: ["Ano ang mga sanhi ng malalang pagkapagod?", "Paano maiiwasan ng mga kabataan ang mga malalang sakit?", "Paano mapapanatili ang malusog na kaugalian sa pagkain?", "Paano mapapanatili ang magandang tulog?"],
        presets: "Maaari mo akong tanungin:",
        change: "Baguhin ang batch",
        uploadImage: "Magpadala ng larawan",
        footer: "Dahil ang nilalaman ay nabuo ng isang AI modelo, ang katotohanan nito ay hindi ganap na matitiyak.",
        remind: "Paalala ng mga gawain",
        limit: "Naabot na ang limitasyon sa pakikipag-usap, mangyaring magsimula ng bagong sesyon ng chat",
        newDialog: "Magsimula ng bagong pag-uusap",
        stopRecording: "I-click kahit saan o kapag natapos ang countdown upang makilala",
        send: "Magpadala ng mensahe",
        say: "I-click upang magsalita",
        notImage: "Ang agent na ito ay hindi sumusuporta sa pagpapadala ng mga file at larawan",
        talking: "Kasalukuyang pag-uusap na nagaganap",
        drawViolations: "Ang AI prompt sa pagguhit ay lumalabag sa mga tuntunin, mangyaring mag-input ng isang sumusunod na prompt.",
        exhausted: "Naubos na ang bilang ng mga Q&A, mangyaring pumunta sa dulo ng panonood at i-scan ang QR code upang magpatuloy.",
        serverBusy: "Ang kasalukuyang network link ay hindi matatag, mangyaring subukan muli",
        chatHistory: "Kasaysayan ng chat",
        chatHistoryDesc: "Itala Itinabi lamang ng 3 araw",
        todoList: "Listahan ng mga gawain",
        todoDesc: "Mga detalye ng mga gawain",
        todoListDesc: "Hanggang 10 gawain lamang ang maaari",
        confirm: "Kumpirmahin",
        cancel: "Kanselahin",
        text: "Hindi maaaring walang laman ang text",
        cancelRemind: "Kanselahin ang paalala",
        remindTime: "Oras ng paalala:",
        remindTitle: "Pamagat ng paalala:",
        remindContent: "Nilalaman ng paalala:",
        edit: "I-edit",
        reachLimit: "Nagdagdag na hanggang sa limitasyon",
        errorChat:"Hindi pa natukoy ang boses, pakisubukang muli",
             noSTT:"Hindi nakuha ang pahintulot, mangyaring paganahin ang pahintulot bago magpatuloy.",
      error50:"May naganap na hindi kilalang error, pakisubukang muli"
      },
      draw: {
        describe: "Ilarawan larawan",
        voice: "Pag-input ng boses",
        press: "Pindutin upang magsalita",
        placeholder: "Ilarawan ang larawan na nais mong likhain",
        style: "Estilo ng pagpipinta",
        styleList: ["Estilo ng tinta", "Cyberpunk", "Anime", "Origami", "Pagkakagawa",  "Graphics", "Cartoon", "LEGO","Lapis","Gumuhit"],
        record: "Rekord ng pagguhit",
        recordDesc: "Panatilihin ang 10 larawan",
        recordEmpty: "Wala ka pang mga rekord ng pagguhit",
        generate: "Lumikha ng larawan",
        result: "Resulta ng nilikhang larawan",
        download: "Download",
        regenerate: "Bumuo muli",
        setDial: "Itakda bilang mukha ng relo",
        error: "Nabigo ang paglikha ng larawan",
        errorDesc: "Hindi matatag na koneksyon sa network, mangyaring muling isumite.",
        confirm: "Kumpirmahin",
        analysiserror: "Nabigo ang pagsusuri",
        SetWatchErr:["Matagumpay na naitakda ang pag-dial","Kanselahin ang pagpapadala","Nabigo ang pag-verify ng data","Masyadong malaki ang file ng data ng mukha ng relo","Naabot na ng pag-synchronize ng watch face ang pinakamataas na limitasyon","Nadiskonekta ang Bluetooth"],
        SetWatchTips:["Nabigo ang pag-setup","Nabigo ang pag-setup","Ang mukha ng relo ay itinatakda, mangyaring huwag lumabas sa kasalukuyang pahina."],
        progressText:"Pag-sync"

      },
  sports: {
      analyze: "Suriin malusog",
      analyzeDesc: "Pangunahing datos",
      advice: "Payo ng katawan",
      adviceDesc: "Mabuting payo",
      experience: "Karanasan",
      program: {
        plan: "Plano sa Pagpapayat",
        startDate: "Pumili ng Petsa ng Pagsisimula",
        chooseDate: "Mangyaring pumili ng 1 araw bilang petsa ng pagsisimula",
        week: [{
          num: "Ling.",
          day: "Linggo"
        }, {
          num: "Lun.",
          day: "Lunes"
        }, {
          num: "Mar.",
          day: "Martes"
        }, {
          num: "Miy.",
          day: "Miyerkules"
        }, {
          num: "Huw.",
          day: "Huwebes"
        }, {
          num: "Biy.",
          day: "Biyernes"
        }, {
          num: "Sab.",
          day: "Sabado"
        }, ],
        arrange: "Kaayusan ng Plano",
        arrangeDesc: "Simulan mula sa pinakamalapit na petsa na pinili mo, kabuuang 7 araw ng pagsasanay; ang tiyak na nilalaman ay ang mga sumusunod:",
        confirm: "Kumpirmahin ang Kaayusan",
        success: "Binabati kita, matagumpay mong na-customize ang plano! Simulan ang iyong pagsasanay sa pahina ng 'Pag-check-in ng Ehersisyo'!",
        successButton: "Pumunta sa Pag-check-in ng Ehersisyo",
      },
  newmessage: {
    message1: ['Personal na Sentro', 'Datos ng Kalusugan'],
    message2: ['Kalusugan', 'Isports', 'Pagkain'],
    message3: ['Kasarian', 'Edad', 'Taas', 'Timbang'],
    message4: ['Tulog', 'HR', 'Oxygen', 'Hakbang', 'BP', 'Presyon', 'Buod'],
    message5: ['Konsumo', 'Ubusin', 'Hakbang Ngayon', 'Tagal ng Ehersisyo'],
    message6: ['Pag-check-in ng Ehersisyo', 'Fitness Home Edition', 'Fitness Pro', 'Plano sa Pagpapayat'],
    message7: ['Mag-ehersisyo at mamuhay ng malusog', 'I-customize ang iyong plano sa fitness', 'I-customize ang iyong plano sa fitness', 'Paano mawalan ng timbang'],
    message8: ['Kcal', 'Hakbang', 'Minuto', 'Sets', 'Araw', 'Taon', 'cm', 'kg', 'h','Linggo'],
    message9: ['Check-in', 'Pagsasanay', 'Mga Pagpipilian sa Ehersisyo', 'Aking mga Aklama', 'Tuloy-tuloy Gumana', 'Hindi nakapag-check-in'],
    message10: ['I-click para Analahin', 'Kasaysayan ng Pagsusuri (hanggang 10 naitala)', 'Suriin', 'Wala kang anumang talaan ng kasaysayan', 'Tuloy-tuloy Gumana', 'Pagsusuri ng Datos'],
    message11: ['Babae', 'Lalaki'],
    message12: ['Kanselahin', 'Kumpirmahin'],
    message13: ['Taon', 'Sentimetro', 'Kilogramo'],
    message14: ['Manwal na Input', 'Inaasahang oras (Linggo)', 'Kasarian', 'Edad', 'Taas (Sentimetro)', 'Timbang (Kilogramo)', 'Inaasahang Timbang (Kilogramo)'],
    message15: ['Kasaysayan', 'Pagsusuri ng Datos na Pangkalusugan', 'Payo ng katawan'],
    message16: ['Matagumpay na Check-in', 'Nag-check-in', 'Maaaring mag-check-in lamang sa parehong araw'],
    message17: ['Ang pagsusuri ng datos na pangkalusugan ay maaaring makuha isang beses lamang sa isang araw, at walang natagpuang datos na lokal', 'Ang payong pangkalusugan ay maaaring makuha isang beses lamang sa isang araw, at walang natagpuang datos na lokal'],
    message18:['Kasalukuyang kulang sa timbang','Ang iyong BMI index ay','hindi ipinapayo na ipagpatuloy ang pagpapapayat (Malusog na sakop 18.5<BMI<24)'],
    message19:['Katamtamang pagbawas ng timbang','Inirerekomenda na huwag lumagpas sa 500g bawat araw'],
    message20:['may mga plano','Saklaw ba nito ang umiiral na mga plano'],
    message21:['Madali','Karaniwan','Katamtaman','Mataas'],
    message22:['Espirit:','Mababa','Mataas'],
	message23:['Ang dami ng beses naubos na Bumili ng subscription para mag-unlock ng mas maraming beses.'],
  },

  },
  punch: {
    title: "Tapos",
    title2: "Tapusin",
    h1: "Mga Pagpipilian sa Ehersisyo",
    h2: "Aking mga Aklama",
    button: "Check-in",
    day: "Tuloy-tuloy Gumana",
    dayunit: "Araw",
  },
  eat: {
    title: "Diyeta",
    DietaryIntake: "Pagkain",
    kcal: "Kcal",
    Stillingestible: "Natitira",
    overingest: "Lagpas",
    Recommendedintake: "Ubusin",
    Motorconsumption: "Motion",
    carbohydrate: "Carbs",
    protein: "Protina",
    fat: "Taba",
    gram: "g",
    drinkDk: "Pag-inom ng Tubig Check-in",
    drinkDkhealth: "Malusog na inuming tubig",
    eatsuggest: "Mga Mungkahi sa Pagkain",
    analyzefoodglx: "Suriin ang pagiging makatwiran ng diyeta",
    eatPlan: "Plano ng Pagkain",
    makeeatPlan: "Eksklusibo pumayat",
    breakfast: "Almusal",
    lunch: "Tanghalian",
    dinner: "Hapunan",
    snack: "Meryenda",
    addFood: "Magdagdag ng Pagkain",
    noAddFood: "Walang food",
    cancle: "Kanselahin",
    confirm: "Kumpirmahin",
    tips: "Ang proaktibong pagsagot ay itinuturing na pahintulot para sa amin na mangolekta ng impormasyong ito at ipakita ito sa diyeta",
    addFooded: "Nadagdag na Pagkain",
    added: "May",
    numFood: "ulam",
    record: "Tala",
    dkTitle: "Check-in",
    dailygoal: "Pang-araw-araw na Layunin",
    drinkDw: "Tasa/Araw",
    drinkWater: "Uminom ng Tubig",
    dailygoalWater: "Plano sa araw-araw",
    myachievement: "Aking mga Nakamit",
    lxdkDays: "Tuloy-tuloy Gumana",
    day: "Araw",
    analyzeHistory: "Kasaysayan",
    conclusion: "Buod",
    eatDataAnalyze: "Pagsusuri ng Datos ng Dieta",
    selectStartDate: "Pumili ng Petsa ng Pagsisimula",
    chooseoneDate: "Mangyaring pumili ng 1 araw bilang petsa ng pagsisimula",
    scheduling: "Kaayusan ng Plano",
    schedulingDesc: "Simulan mula sa pinakamalapit na petsa na pinili mo, kabuuang 7 araw; ang tiyak na nilalaman ay ang mga sumusunod:",
    eatDetail:"Mga Detalye ng Diyeta",
    analyzeFailed:"Nabigo ang pagsusuri",
    noData:"walang datos"
  
  },
},
error: {
  error1101:"Ang koneksyon sa network ay hindi matatag, ang kasalukuyang oras ng session",
  error1102:"Kasalukuyang walang network, mangyaring kumonekta muli",
  error1103:"May naganap na hindi kilalang error",
  error61:"Ang kasalukuyang serbisyo ay hindi magagamit",
  error4:"Hindi pa natukoy ang boses, pakisubukang muli",
  error12:"Mangyaring maglagay ng mga karaniwang salita o pangungusap para maunawaan at maiguhit ng AI",
  error50:"Paumanhin, mali ang kasalukuyang server, mangyaring subukang muli"
},
  setting: {
    index: {
      title: "Mga Setting",
      problem: "Feedback sa Problema",
      order: "Mga Talaan ng Order",
      notice: "Abiso",
      language: "Wika",
      clear: "I-clear ang Cache",
      clearTip: "Sigurado ka bang gusto mong\ni-clear ang cache?",
      confirm: "Kumpirmahin",
      cancel: "Kanselahin",
    },
    language: {
      title: "Wika",
      langList: ["Simplified Chinese", "Traditional Chinese", "English", "Arabic", "Bulgarian", "Bengali", "Czech", "German", "Greek", "Spanish", "Persian", "Filipino", "French", "Hindi", "Hungarian", "Indonesian", "Italian", "Hebrew", "Japanese", "Korean", "Malay", "Dutch", "Punjabi", "Polish", "Portuguese", "Romanian", "Russian", "Slovak", "Thai", "Turkish", "Ukrainian", "Urdu", "Vietnamese"],
    },
  order: {
    title: "Mga Talaan ng Order",
    success: "Matagumpay na Nakuhanan",
    unpaid: "Hindi Bayad",
    paid: "Bayad",
    expired: "Nag-expire",
    refunded: "Na-refund",
    pTime: "Oras ng Pagbabayad",
    eTime: "Oras ng Pag-expire",
    pMethod: "Paraan ng Pagbabayad",
    pObject: "Bagay na Binayaran",
    oNumber: "Numero ng Order",
    pType: "Uri ng Package",
    oTime: "Oras ng Pagkuha",
    watch: "Tingnan ang IMEI",
    delete: "Tanggalin ang Order",
    refund: "Humiling ng Refund",
  },
  problem: {
    title: "Feedback sa Problema",
    feedback: "Uri ng Feedback",
    please: "Pakiusap Piliin",
    content: "Nilalaman",
    improve: "Pakisulat ang iyong mga mungkahi, tulad ng mga kahilingan ng tampok, mga reklamo sa produkto, atbp. Susumikapin naming mag-ayos",
    upload: "Mag-upload ng Larawan",
    submit: "Isumite",
    general: "Pangkalahatang Feedback",
    purchase: "May Kaugnayan sa Pagbili",
    bug: "Ulat ng Bug",
    suggestion: "Ubusin",
    other: "Iba pa",
  },
  notice: {
    title: "Abiso",
    type: "Uri ng Abiso: ",
    time: "Oras ng Abiso: "
  },
  unsubscribe: {
    watch: "Relo:",
    title: "Patuloy na Subscription",
    subTitle: "Nakasubscribe",
    unsubscribe: "Mag-unsubscribe",
    packList: ["Premium Subscription", "Basic Subscription", "Standard Subscription"],
  },
  refund: {
    title: "Humiling ng Refund",
    number: "Numero ng Order",
    content: "Pakipaliwanag ang dahilan para sa refund",
    packList: ["Discount Package ng Bagong User", "50 Times Package", "100 Times Package", "300 Times Package"],
  },

  },
  tutorial: {
    index: {
      title: "Kumuha ng Advanced na Tutorial",
      watchList: ["Buksan ang relo, hanapin ang WearAI application at i-click upang buksan.",
        "I-click ang advanced benefits button sa home page.",
        "Kunin ang iyong mobile phone at i-scan ang QR code sa relo upang makapasok sa benefits page."
      ]
    }
  }
  }