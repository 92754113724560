export default {
	SET_CHANNEL_ID: (state, channelId) => {
		state.channelId = channelId;
	},
	SET_UID: (state, uid) => {
		state.uid = uid;
	},
	SET_OS: (state, os) => {
		state.os = os;
	},
	SET_LANGUAGE: (state, language) => {
		state.language = language;
	},
	SET_OS_VERSION: (state, osVersion) => {
		state.osVersion = osVersion;
	},
	SET_DEVICE_ID: (state, deviceId) => {
		state.deviceId = deviceId;
	},
	SET_APP_PKG_NAME: (state, appPkgName) => {
		state.appPkgName = appPkgName;
	},
	SET_APP_VERSION: (state, appVersion) => {
		state.appVersion = appVersion;
	},
	SET_SDK_VERSION: (state, sdkVersion) => {
		state.sdkVersion = sdkVersion;
	},
	SET_RESOLUTION: (state, resolution) => {
		state.resolution = resolution;
	},
	SET_MODEL: (state, model) => {
		state.model = model;
	},
	SET_WATCH_LIST: (state, watchList) => {
		state.watchList = watchList;
	},


	SET_TOKEN: (state, token) => {
		state.token = token;
	},
	SET_NOTIFICATION_ID: (state, notificationId) => {
		state.notificationId = notificationId;
	},
	SET_NOTIFICATION_TYPE: (state, notificationType) => {
		state.notificationType = notificationType;
	},

	// 状态

	SET_WATCH_LIST: (state, watchList) => {
		state.watchList = watchList;
	},
	SET_TOTAL_NUMBER: (state, totalNumber) => {
		state.totalNumber = totalNumber;
	},
	SUB_TOTAL_NUMBER: (state) => {
		if (state.totalNumber > 0) {
			state.totalNumber -= 1;
		} else {
			state.totalNumber = 0;
		}

	},
	//更新图标
	setchartupload: (state, chartupload) => {
		state.chartupload = chartupload;
	},
	//输入错误值
	setError(state, message) {
		state.errorMessage = message;
	},
	//清理错误值
	clearError(state) {
		state.errorMessage = null;
	}

}