module.exports = {
	language: "български",
	navigator: {
		tools: "AI функции",
		draw: "Рисуване",
		healthy: "Здрав",
	},
	aitools: {
		chat: {
			gpt: "Джарвис",
			categoryList: ["Препоръчай", "Изучаване", "Живот", "Създаване", "Работа"],
			presetGreet: "Здравейте, аз съм вашият здравен стюард и винаги съм готов да предоставя интересни и полезни знания за здравето, за да ви помогна да защитите здравето си!",
			presetQuiz: ["Какви са причините за хронична умора?", "Как младите хора могат да предотвратят хронични заболявания?", "Как да поддържате здравословни хранителни навици?", "Как да осигурите добър сън?"],
			presets: "Можете да ме попитате:",
			change: "Смяна на партида",
			uploadImage: "Изпрати изображение",
			footer: "Тъй като съдържанието се генерира от AI модел, автентичността му не може да бъде напълно гарантирана.",
			remind: "Напомняне за задачите",
			limit: "Този разговор достигна своя лимит, моля, започнете нова AI чат сесия",
			newDialog: "Започнете нов разговор",
			stopRecording: "Щракнете навсякъде или когато таймерът изтече, за да разпознаете",
			send: "Изпратете съобщение",
			say: "Щракнете за разговор",
			notImage: "Този агент не поддържа изпращане на файлове и изображения",
			talking: "Текущ разговор в прогрес",
			drawViolations: "Подсказката за AI рисуване нарушава правилата, моля въведете съответстваща подсказка.",
			exhausted: "Броят на въпросите и отговорите е изчерпан, моля, отидете до края на гледането и сканирайте QR кода, за да продължите.",
			serverBusy: "Текущата мрежова връзка е нестабилна, моля, опитайте отново",
			chatHistory: "История на чата",
			chatHistoryDesc: "Записите се съхраняват само 3 дни",
			todoList: "Списък със задачи",
			todoDesc: "Подробности за задачите",
			todoListDesc: "Могат да съществуват максимум 10 задачи",
			confirm: "Потвърдете",
			cancel: "Отменете",
			text: "Текстът не може да бъде празен",
			cancelRemind: "Отменете напомнянето",
			remindTime: "Време за напомняне:",
			remindTitle: "Заглавие на напомнянето:",
			remindContent: "Съдържание на напомнянето:",
			edit: "Редактирайте",
			reachLimit: "Достигнат е лимитът",
			errorChat:"Гласът все още не е открит, моля, опитайте отново",
			      noSTT:"Разрешението не е получено, моля, активирайте разрешението, преди да продължите.",
      error50:"Възникна неизвестна грешка, моля, опитайте отново"
		},
		draw: {
			describe: "Oписвам снимка",
			voice: "Гласов вход",
			press: "Натиснете за говор",
			placeholder: "Опишете изображението, което искате да генерирате",
			style: "Стил на рисуване",
			styleList: ["мастило", "Киберпънк", "Аниме", "Оригами", "Плетене",  "Графика", "Карикатура","ЛЕГО","Молив","Рисуване"],
			record: "Запис на рисуване",
			recordDesc: "Могат да се запазят до 10 записа на рисуване",
			recordEmpty: "В момента нямате записи на рисуване",
			generate: "Генериране на изображение",
			result: "Pезултат",
			download: "изтегли",
			regenerate: "отново",
			setDial: "Задайте като циферблат",
			error: "Неуспешно генериране на изображение",
			errorDesc: "Нестабилна мрежова връзка, моля, подайте отново.",
			confirm: "Потвърдете",
			analysiserror: "Анализът не успя",
			SetWatchErr:["Циферблатът е настроен успешно","Отказ от изпращането","Проверката на данните е неуспешна","Файлът с данни за циферблата е твърде голям","Синхронизирането на циферблата е достигнало горната граница","Bluetooth връзката е прекъсната"],
      SetWatchTips:["Настройката е неуспешна","Извършва се настройка на циферблата","Циферблатът се настройва, моля, не излизайте от текущата страница."],
      progressText:"Синхронизиране"

		},
		sports: {
			analyze: "Анализ на здравето",
			analyzeDesc: "Основни данни",
			advice: "Здравни съвети",
			adviceDesc: "Разумен съвет",
			experience: "Опит",
			program: {
				plan: "План за отслабване",
				startDate: "Изберете начална дата",
				chooseDate: "Моля, изберете 1 ден като начална дата",
				week: [{
					num: "Нед.",
					day: "Неделя"
				}, {
					num: "Пон.",
					day: "Понеделник"
				}, {
					num: "Вто.",
					day: "Вторник"
				}, {
					num: "Сря.",
					day: "Сряда"
				}, {
					num: "Чет.",
					day: "Четвъртък"
				}, {
					num: "Пет.",
					day: "Петък"
				}, {
					num: "Съб.",
					day: "Събота"
				}, ],
				arrange: "Подреждане на плана",
				arrangeDesc: "Започнете от най-близката избрана от вас дата, общо 7 тренировъчни дни; специфичното съдържание е както следва:",
				confirm: "Потвърдете подреждането",
				success: "Поздравления, успешно настроихте плана! Започнете тренировката си на страницата „Спортно настаняване“!",
				successButton: "Отидете на ежедневната проверка на упражнението",
			},
			newmessage: {
				message1: ['Личен център', 'Здравни данни'],
				message2: ['Здраве', 'спорт', 'Диета'],
				message3: ['Пол', 'Възраст', 'Височина', 'Тегло'],
				message4: ['Сън', 'ЧС', 'Кислород', 'Стъпки', 'КН', 'Натиск', 'Резюме'],
				message5: ['Консумация', 'внушение', 'Днешни стъпки',
					'Cпорт Bреме'
				],
				message6: ['Спортно настаняване', 'Фитнес домашно издание',
					'Фитнес Про', 'План за отслабване'
				],
				message7: ['продължавайте да тренирате',
					'Персонализирайте своя фитнес план', 'Персонализирайте своя фитнес план',
					'Създайте план за отслабване за вас'
				],
				message8: ['Ккал', 'Стъпки', 'Минути', 'Комплекти', 'Дни', 'Години', 'см', 'кг', 'ч'],
				message9: ['Регистрация', 'Тренировка', 'Опции за упражнение', 'Моите постижения',
					'поредни дни', 'Без регистрация'
				],
				message10: ['Щракнете за анализ', 'История на анализите (до 10 запазени)', 'Анализирайте',
					'В момента нямате исторически записи', 'поредни дни', 'Анализ на данните'
				],
				message11: ['Жена', 'Мъж'],
				message12: ['Отмяна', 'Потвърдете'],
				message13: ['Години', 'Сантиметри', 'Килограми'],
				message14: ['Ръчен вход', 'Очаквано време (дни)', 'Пол', 'Възраст', 'Височина (сантиметри)',
					'Тегло (килограми)', 'Очаквано тегло (килограми)'
				],
				message15: ['История', 'Анализ на здравни данни', 'Здравни съвети'],
				message16: ['Успешна регистрация', 'Регистриране', 'Можете да се регистрирате само в същия ден'],
				message17: ['Анализ на здравни данни може да се получи само веднъж на ден и не може да се намери локални данни', 'Здравни съвети могат да се получат само веднъж на ден и не може да се намери локални данни'],
				message18: ['В момента поднормено тегло', 'Вашият ИТМ индекс е', 'не се препоръчва да продължавате да отслабвате (Здравословен диапазон 18.5<ИТМ<24)'],
				message19: ['Умерено отслабване', 'Препоръчва се да отслабвате не повече от 500г на ден'],
				message20: ['имате планове', 'Покрива ли съществуващите планове'],
				message21: ['Лесно', 'Нормално', 'Средно', 'Високо'],
				message22: ['Дух:', 'Нисък', 'Висок'],
				message23: ['Броят пъти е бил използван. Купете абонамент, за да отключите повече пъти.'],
			},
		},
		punch: {
			title: "Готово",
			title2: "Завършване",
			h1: "Опции за упражнение",
			h2: "Моите постижения",
			button: "Регистрация",
			day: "поредни дни",
			dayunit: "Дни",
		},
		eat: {
			title: "Диета",
			DietaryIntake: "Хранене",
			kcal: "Ккал",
			Stillingestible: "Оставащо",
			overingest: "Над",
			Recommendedintake: "внушение",
			Motorconsumption: "употреба",
			carbohydrate: "Въглехидрати",
			protein: "Протеин",
			fat: "Мазнини",
			gram: "гр",
			drinkDk: "Регистрация за вода",
			drinkDkhealth: "Пийте вода всеки ден",
			eatsuggest: "Предложения за диета",
			analyzefoodglx: "Разумна диета",
			eatPlan: "Диетичен план",
			makeeatPlan: "Генериране на диетичен план",
			breakfast: "Закуска",
			lunch: "Обяд",
			dinner: "Вечеря",
			snack: "Закуска",
			addFood: "Добави храна",
			noAddFood: "Без добавена храна",
			cancle: "Отмяна",
			confirm: "Потвърдете",
			tips: "Проактивното попълване се счита за съгласие за събиране на информация по този проект и показването му в диетата",
			addFooded: "Добавена храна",
			added: "Добавено",
			numFood: "Броя на хранителни продукти",
			record: "Запис",
			dkTitle: "Регистрация",
			dailygoal: "Ежедневна цел",
			drinkDw: "Чаши/ден",
			drinkWater: "Пийте вода",
			dailygoalWater: "дневни цели",
			myachievement: "Моите постижения",
			lxdkDays: "поредни дни",
			day: "Дни",
			analyzeHistory: "История",
			conclusion: "Обобщение",
			eatDataAnalyze: "Анализ на данните за диетата",
			selectStartDate: "Изберете начална дата",
			chooseoneDate: "Моля, изберете 1 ден като начална дата",
			scheduling: "Подреждане на плана",
			schedulingDesc: "Започнете от най-близката избрана от вас дата, общо 7 дни; специфичното съдържание е както следва:",
			eatDetail: "Подробности за диетата",
			analyzeFailed: "Анализът не успя",
			noData: "няма данни"
		}
	},
  error: {
    error1101:"Мрежовата връзка е нестабилна, текущата сесия изчакване",
    error1102:"В момента няма мрежа, моля, свържете се отново",
    error1103:"Възникна неизвестна грешка",
    error61:"Текущата услуга не е налична",
    error4:"Гласът все още не е открит, моля, опитайте отново",
    error12:"Моля, въведете стандартни думи или изречения, които изкуственият интелект да разбира и рисува",
		error50:"Извинете, текущият сървър е грешен, моля, опитайте отново"
  },
	setting: {
		index: {
			title: "Настройки",
			problem: "Обратна връзка за проблем",
			order: "Записи за поръчки",
			notice: "Известие",
			language: "Език",
			clear: "Изчистване на кеша",
			clearTip: "Сигурни ли сте, че искате да\nизчистите кеша?",
			confirm: "Потвърдете",
			cancel: "Отмяна",
		},
		language: {
			title: "Език",
			langList: ["Опростен китайски", "Традиционен китайски", "Английски", "Арабски", "Български", "Бенгалски", "Чешки", "Немски", "Гръцки", "Испански", "Персийски", "Филипински", "Френски", "Хинди", "Унгарски", "Индонезийски", "Италиански", "Иврит", "Японски", "Корейски", "Малайски", "Холандски", "Пенджабски", "Полски", "Португалски", "Румънски", "Руски", "Словашки", "Тайландски", "Турски", "Украински", "Урду", "Виетнамски"],
		},
		order: {
			title: "Записи за поръчки",
			success: "Успешно извлечено",
			unpaid: "Неплатено",
			paid: "Платено",
			expired: "Изтекло",
			refunded: "Възстановено",
			pTime: "Време на плащане",
			eTime: "Време на изтичане",
			pMethod: "Метод на плащане",
			pObject: "Обект на плащане",
			oNumber: "Номер на поръчка",
			pType: "Тип пакет",
			oTime: "Време на извличане",
			watch: "Преглеждане на IMEI",
			delete: "Изтриване на поръчка",
			refund: "Заявка за възстановяване",
		},
		problem: {
			title: "Обратна връзка за проблема",
			feedback: "Тип обратна връзка",
			please: "Моля изберете",
			content: "Съдържание",
			improve: "Моля, напишете вашите предложения, като искания за функции, оплаквания за продукти и т.н. Ще се стремим да подобрим",
			upload: "Качване на изображение",
			submit: "Изпращане",
			general: "Обща обратна връзка",
			purchase: "Свързано с покупка",
			bug: "Съобщаване за грешка",
			suggestion: "внушение",
			other: "Друго",
		},
		notice: {
			title: "Известие",
			type: "Тип известие: ",
			time: "Време на известието: "
		},
		unsubscribe: {
			watch: "Наблюдавай:",
			title: "Непрекъснат абонамент",
			subTitle: "Абониран",
			unsubscribe: "Отписване",
			packList: ["Премиум абонамент", "Базов абонамент", "Стандартен абонамент"],
		},
		refund: {
			title: "Заявка за възстановяване на сума",
			number: "Номер на поръчка",
			content: "Моля, обяснете причината за възстановяване",
			packList: ["Пакет с отстъпка за нов потребител", "Пакет от 50 пъти", "Пакет от 100 пъти",
				"Пакет от 300 пъти"
			],
		}
	},
	tutorial: {
		index: {
			title: "Получете напреднал урок",
			watchList: ["Включете часовника, намерете приложението WearAI и кликнете, за да го отворите.",
				"Кликнете върху бутона за напреднали предимства на началната страница.",
				"Извадете мобилния си телефон и сканирайте QR кода на часовника, за да влезете в страницата с предимства."
			]
		}
	},
}