module.exports = {
    language: "丹麦",
    navigator: {
        chat: "Chat GPT",
        healthy: "Sund",
        draw: "AI Tegning",
        astrol: "Astrologi",
        setting: "Indstillinger",
        scan: "Scan",
    },
    error: {
        nullWatch: "Du har endnu ikke tilsluttet uret, venligst tilslut uret først, før du bruger det.",
        network: {
            yes: "Internetforbindelsen er ustabil\nprøv venligst igen",
            no: "Ingen internetforbindelse\nprøv venligst igen",
        },
        expire: {
            sub: "Abonnementet er udløbet, venligst forny abonnementet via appen for at fortsætte med at bruge.",
            unsub: "Den gratis prøveperiode har nået sin grænse, venligst abonner via appen for at fortsætte med at bruge.",
        },
        times: {
            unsub: "Dagens gratis prøveversion har nået sin grænse, venligst forny abonnementet via appen for at fortsætte med at bruge.",
            chat: "Antallet af spørgsmål er opbrugt, venligst køb en spørgsmålspakke via appen.",
            draw: "Antallet af AI-genererede billeder er opbrugt, venligst køb en billedpakke via appen.",
            astrol: "Den daglige horoskopmængde er opbrugt, venligst køb en spørgsmålspakke via appen.",
        },
        empty: {
            draw: "Indtast venligst standardord og sætninger for at lette AI's læsning og tegning.",
            astrol: "AI-serveren er optaget, prøv venligst igen. Dette horoskop bruger ikke dine point.",
        }
    },
    chat: {
        index: {
            nav: {
                l: "Chat GPT",
                r: "Chat Historik",
            },
            chat: {
                title: "Kan hjælpe dig:",
                box: "Tal med Chat GPT",
            },
            record: {
                title1: "Se GPT Historik:",
                title2: "Chat Historik:",
            },
            dialog: {
                title: "Optagelse",
                cancel: "Annuller",
                submit: "Indsend",
            }
        },
        page: {
            cut: "Den maksimale ordgrænse er nået (ca. 700)",
            done: "(Denne samtale har nået grænsen, start venligst en ny AI-samtale)",
            tip1: "(Du kan også stille (",
            tip2: ") spørgsmål i denne samtale)",
        },
        prompt: {
            title: {
                greetings: "Hilsener",
                education: "Uddannelse",
                fun: "Sjov",
                daily: "Daglig Livsstil",
                health: "Sundhed & Ernæring",
                astrology: "Astrologi",
                art: "Kunst",
                travel: "Rejse",
                business: "Forretning & Markedsføring",
                social: "Social",
                career: "Karriere",
                email: "E-Mail"
            },
            Greetings: [{ label: `Glædelig jul`, value: `Du er en julebudskabsforfatter, der genererer unikke og hjertelige beskeder til at fejre jul. Skriv en besked for mig til at sende til mine kære.` },
            { label: `Glædelig Mors Dag`, value: `Du er en Mors Dag-kort forfatter, der skaber hjertelige og unikke beskeder til at fejre Mors Dag. Skriv en besked for mig til at sende til min mor, hvor jeg udtrykker kærlighed, taknemmelighed og påskønnelse for alt, hvad hun gør.` },
            { label: `Tillykke med fødselsdagen`, value: `Du er en fødselsdagsbeskedsforfatter, der genererer unikke og personlige beskeder til at fejre jul. Skriv en fødselsdagsbesked for mig til at sende til min ven, der fylder 24. Her er nogle fakta om dem: Elsker sci-fi bøger, en fantastisk kok` },
            { label: `Glædelig Valentinsdag`, value: `Du er en Valentinsdag-beskedsforfatter, der genererer romantiske og flirtende beskeder fra elskere til elskere. Skriv en besked for mig til at sende til min partner.` },
            { label: `Godt jubilæum`, value: `Du er en jubilæumsbeskedsforfatter, der genererer romantiske og hjertelige beskeder fra partner til partner. Spørg mig først, hvilket jubilæum jeg fejrer, og skriv mig en besked til at sende til min kæreste baseret på denne information.` },
            { label: `Glædelig Thanksgiving`, value: `Du er en Thanksgiving-beskedsforfatter, der genererer unikke og hjertelige beskeder til at fejre Thanksgiving. Skriv en besked for mig til at sende til mine kære.` },
            { label: `Glædelig Halloween`, value: `Du er en julebudskabsforfatter, der genererer uhyggelige og sjove beskeder til at fejre Halloween. Skriv en besked for mig til at sende til mine kære.` },
            { label: `Hilsener på 101 sprog`, value: `- Opfør dig som en polyglot forfatter, der kan tale ethvert sprog, der nogensinde har eksisteret. Skriv følgende besked på følgende sprog:\n\nBesked: Jeg elsker dig\n\nSprog: Engelsk, Fransk, Spansk, Latin` },
            ],
            Education: [{ label: `Videnskabs Chat`, value: `Opfør dig som en professor. Du vil give mig ny information. Du vil præsentere interessant information fra grundlæggende videnskaber. Du kan starte med at sige en interessant sætning om videnskab.` },
            { label: `Engelsklærer`, value: `Opfør dig som en engelsklærer. Lektionerne skal fokusere på udtale af engelske ord, sætninger og sætninger. Giv en kort opsummering af emnet i maksimalt to afsnit. Første emne: Adjektiv.` },
            { label: `Oversætter`, value: `Jeg vil tale til dig på ethvert sprog, og du vil registrere det og oversætte det og besvare i den korrigerede og forbedrede version af min tekst på engelsk. Min første sætning er “Hola”` },
            { label: `Matematiklærer`, value: `Jeg vil have dig til at fungere som matematiklærer. Jeg vil give nogle matematiske ligninger eller begreber, og det vil være din opgave at beregne dem. Min første anmodning er “346 x 569”` },
            { label: `Lav et kort essay om ethvert emne`, value: `Jeg vil have dig til at skrive et essay. Du skal undgå plagiat ved at gøre indholdet originalt.\n\nEmne: AI og menneskelig adfærd \n Forskningsspørgsmål: Hvordan kan kunstig intelligens fodre menneskelig intelligens?\nOrdgrænse: 1000\nStil: APA` },
            { label: `Citations Generator til enhver stil`, value: `Du vil skrive APA-stil citation.\n\n\nForfatter(e): Joe Schank\nTitel på opslag: Hvor er AI?\nTitel på encyklopædi: AI i verden\nUdgiver: AI Society\nUdgivelsesdato: 01/22/2007` },
            { label: `Kursusgenerator om ethvert emne`, value: `Opfør dig som en kursusgenerator. Du kan oprette indholdsfortegnelse, lektioner, slides osv. Start med at spørge, hvad jeg vil lære.` },
            ],
            Fun: [{ label: `Tal med Neo i The Matrix`, value: `Opfør dig som Neo fra The Matrix. Jeg vil have dig til at svare og besvare som Neo ved at bruge tonen. Svar kun som Neo. Du skal kende al viden om Neo. Din første besked er “Jeg er Neo.”` },
            { label: `Drømmetyder`, value: `Opfør dig som en drømmetyder. Jeg vil beskrive mine drømme; du fortolker symboler/temaer i drømmen. Min første drøm handler om at blive jagtet af en kæmpe edderkop.` },
            { label: `Omdan enhver tekst til emojis`, value: `Jeg vil have dig til at oversætte de sætninger, jeg skrev, til emojis. Jeg vil skrive sætningen, og du vil udtrykke den med emojis. Min første sætning er “Det var det, hun sagde.”` },
            { label: `Kat-Ven`, value: `Opfør dig som en kat. Når jeg siger noget til dig, skal du mjaue først og derefter forklare, hvad det betyder. Mjau ofte, mens du taler. Din første besked er "Meowww...”` },
            { label: `Fortæl mig en vittighed`, value: `Fortæl mig en vittighed` },
            { label: `Giv råd som Elon Musk om biler`, value: `Giv råd som Elon Musk om biler.` },
            { label: `Kyle i South Park`, value: `Opfør dig som {Kyle Broflovski} fra {South Park}. Jeg vil have dig til at svare og besvare som {Kyle Broflovski} ved at bruge tonen. Svar kun som {Kyle}. Du skal kende al viden om {Kyle}. Din første besked er “Jeg er Kyle.”` },
            { label: `Spil skak`, value: `Du vil spille skak med mig. Lav bare dine træk.\n[Farve på mit skakbræt] : Hvid\n[Forklaring af dit træk] : Ja\n[Mit første træk] : E4 bonde` },
            { label: `Spil Trivia Quest`, value: `Lav et trivia-spil kaldet Trivia Quest. Stil et spørgsmål i henhold til den kategori og sværhedsgrad, jeg oplyser, og bed mig vælge mellem mulighederne. Hvis jeg tager fejl, forklar det korrekte svar og stil det andet spørgsmål.\n\nKategori: Videnskab\nSværhedsgrad: Svær` },
            { label: `Minecraft Steve`, value: `Opfør dig som {Steve} fra {Minecraft}. Jeg vil have dig til at svare og besvare som {Steve} ved at bruge tonen. Svar kun som {Steve}. Du skal kende al viden om {Steve}. Din første besked er “Jeg er Steve.”` },
            { label: `Eminem-stil vittigheder om Max Payne`, value: `Lav Eminem-stil vittigheder om Max Payne.` },
            { label: `Son-Goku i Dragon Ball`, value: `Opfør dig som {Son Goku} fra {Dragon Ball}. Jeg vil have dig til at svare og besvare som {Son Goku} ved at bruge tonen. Svar kun som {Son Goku}. Du skal kende al viden om {Son Goku}. Din første besked er “Jeg er Son.”` },
            ],
            Daily: [{ label: `Daglig horoskop / Kærlighed, Penge, Humør, Sundhed`, value: `Opfør dig som en astrolog. Du vil lære om stjernetegnene og deres betydninger og dele indsigt. Spørg om mit horoskop og lav en daglig horoskop-kommentar om penge, kærlighed, humør og sundhed.` },
            { label: `Outfit idé / harmonisk med begivendhedens koncept`, value: `Opfør dig som min personlige stylist. Du vil foreslå tøj, jeg kan have på. Dine første spørgsmål: “Jeg har en formel begivenhed i vente, og jeg har brug for hjælp til at vælge et outfit.”` },
            { label: `Hvor mange liter vand skal jeg drikke om dagen?`, value: `Hvor mange liter vand skal jeg drikke om dagen?` },
            { label: `Makeup idé kompatibel med koncept og outfit`, value: `Jeg er inviteret til en middag. Jeg tænker på at have en blå lang sweatshirt på, kan du give mig en makeup idé?` },
            { label: `Måltidsidé med ingredienser og instruktioner`, value: `Har du en måltidsidé? Lav ingredienser og instruktioner` },
            { label: `Måltidsbestillingsidé til morgenmad, frokost, middag og natmad.`, value: `Jeg vil bestille mad, men jeg er forvirret. Hvad kan jeg bestille? Men spørg mig først, hvilket måltid` },
            { label: `Munter mentor visdom til livets spørgsmål`, value: `Opfør dig som en klog og vidende karakter, der tilbyder råd og samtale. Jeg søger en let snak i DM-format. "Frokostpausen er ovre, har du et råd til at komme igennem resten af dagen?”` },
            ],
            Health: [{ label: `Livscoach`, value: `Opfør dig som en livscoach. Du skal foreslå strategier til at hjælpe mig med at træffe bedre beslutninger og nå mål. Din første besked skal blot være "Hvordan har du det i dag? Hvordan kan jeg hjælpe dig?”` },
            { label: `Diætist`, value: `Som diætist vil jeg gerne designe en opskrift på 2 personer, der har cirka 500 kalorier per portion og har et lavt glykæmisk indeks. Kan du venligst give et forslag?` },
            { label: `6 Yoga Poser`, value: `Beskriv seks effektive yoga-poser eller strækøvelser, der er sikre og fremragende for folk i alle aldre.` },
            { label: `3 Tips til mere effektiv søvn`, value: `Kan du give mig 3 tips til at få min søvn mere effektiv? Start med at spørge om min alder, derefter det gennemsnitlige antal timer, jeg sover om dagen, og giv derefter tre anbefalinger.` },
            { label: `Opskrift på mine ingredienser, måltid og diæt type`, value: `Lav et måltid baseret på de ingredienser, jeg har ved hånden.\n\nEn liste over ingredienser: Gulerødder, broccoli, majs, soyamælk\nMåltidstype: Aftensmad\nDiæt type: Vegansk` },
            { label: `Hvor mange kalorier skal jeg spise om dagen?`, value: `Kan du anbefale mig 10 YouTube-kanaler om sundhed, ernæring og sport? Venligst indlejr linket.` },
            { label: `YouTube-kanaler om sundhed, ernæring og sport`, value: `Opfør dig som en personlig træner og diætplanlægger. Hvor mange kalorier bør jeg indtage i dag baseret på svarene. Dit første spørgsmål skal være alder, køn, højde, vægt, aktivitetsniveau og vægtmål?` },
            { label: `Træningsplangenerator`, value: `Lav en træningsplan for mig i lyset af nedenstående information:\n\n[antal træninger om ugen]: 4\n[træningstype]: Hypertrofi\n[træningsvarighed]: 1 time` },
            ],
            Astrology: [{ label: `Hvad er 10 karakteristiske træk ved mit stjernetegn?`, value: `Jeg vil gerne vide karakteristikaene for mit stjernetegn. Spørg først om mit stjernetegn og fortæl derefter om dets træk. Venligst list trækene i 10 punkter.` },
            { label: `Fortolkning af dit horoskopkort`, value: `Uddrag og fortolk mit horoskopkort i henhold til de oplysninger, jeg har givet, ved at spørge mig om mit fødested, dato og tid.` },
            { label: `Tarot Analyse`, value: `Opfør dig som en astrolog. Jeg vil have dig til at kigge på mine Tarot-kort. Først skal du få mig til at vælge kort, derefter vil du lave tarot-analyse ved at beskrive symbolerne og alle andre træk ved kortene.` },
            { label: `Ugentligt Horoskop`, value: `Du vil lave min ugentlige horoskopanalyse, spørg først om mit tegn og giv mig derefter mit ugentlige horoskop.` },
            { label: `Musik & Film matcher med dit stjernetegn`, value: `Kan du spørge om mit stjernetegn og foreslå 10 musiknumre og 10 film, der passer til mit stjernetegn, venligst hold forklaringerne korte.` },
            { label: `Er en Vædder kvinde og en Tvilling mand et godt match?`, value: `Ved at se på de oplysninger, jeg gav dig, vil du give forholdskommentarer i henhold til horoskopet.\n\nTegn på 1. person: Vædder kvinde\nTegn på 2. person: Tvilling mand\nForholdstype: Kærlighed` },
            ],
            Art: [{ label: `Skriv en J. K. Rowling-stil kort historie`, value: `Skriv en J. K. Rowling-stil kort historie. Lad det være et enkelt afsnit, og historiens længde må ikke overstige 400 tegn.` },
            { label: `Skriv Travis Scott-stil sangtekster`, value: `Skriv Travis Scott-stil sangtekster` },
            { label: `Opret en playliste svarende til din yndlingssang`, value: `Opfør dig som en sanganbefaler. Jeg vil give dig en sang, og du vil oprette en playliste med 10 sange, der ligner den givne sang. Min første sang er “Eminem - Business”.` },
            { label: `Historiefortæller`, value: `Hvad ville der ske, hvis en ikke-teknologisk person befandt sig i en højteknologisk verden?` },
            { label: `Bog Anbefalinger`, value: `Kan du anbefale mig 3 bøger om anden verdenskrig?` },
            { label: `Digter Generator`, value: `Opfør dig som en digter. Du vil skabe digte, der vækker følelser og har magten til at røre folks sjæl. Sørg for, at dine ord formidler den følelse, du prøver at udtrykke, på smukke, men meningsfulde måder. Du kan også finde på korte vers, der stadig er stærke nok til at efterlade et aftryk i læsers sind. Min første anmodning er:\n\n“Jeg har brug for et digt om kærlighed.”` },
            { label: `Filmanmelder og serieanmeldelser`, value: `Opfør dig som filmanmelder og serieanmelder. Du vil udvikle en engagerende og kreativ anmeldelse. Du kan dække emner som plot, temaer og tone, skuespil og karakterer, instruktion, score, kinematografi, produktionsdesign, specialeffekter, redigering, tempo, dialog. Det vigtigste aspekt er dog at understrege, hvordan filmen har fået dig til at føle. Du kan også være kritisk over for filmen. Undgå venligst spoilere.\n\nFilm: Everything Everywhere All at Once` },
            { label: `Sanganbefaling, der matcher dit humør og din genre`, value: `Du vil foreslå mig 5 sange i overensstemmelse med de oplysninger, jeg har givet,\n\nHumør: Millionær\nGenre: Hip-Hop` },
            { label: `Skriv en South Park Episode`, value: `Opfør dig som en manuskriptforfatter. Jeg forventer, at du skriver en South Park-episode ved hjælp af de oplysninger, jeg gav dig.\n\nGæst: Elon Musk\nEpisodes Emne: Verdens undergang` },
            ],
            Travel: [{ label: `Ferieplanlægger`, value: `I henhold til disse oplysninger, lav en ferieplan for mig.\nPlacering: Sydney\nBudget: Lavt\nVarighed: 3 Dage\nInteresser: Lokale attraktioner, Teater\nÅrstid: Sommer` },
            { label: `Lokale fødevarer`, value: `Jeg vil gerne besøge Paris, spise de bedste croissanter og lokale fødevarer under en 3-dages tur.` },
            { label: `Bedste tidspunkt at besøge`, value: `Lav en rejseguide til Frankrig med den bedste tid at besøge. Organiser informationen for nem brug, og undgå unødvendige afsnit.` },
            { label: `Aktiviteter`, value: `Lav en rejseguide til Frankrig med must-see aktiviteter. Organiser informationen for nem brug, og undgå unødvendige afsnit.` },
            { label: `Budgetteringstips`, value: `Lav en rejseguide til Frankrig med budgetteringstips. Organiser informationen for nem brug, og undgå unødvendige afsnit.` },
            { label: `Lav rejseplan`, value: `Lav en rejseplan for en tur til et sted, inklusive topattraktioner, estimerede omkostninger og tid, transportmuligheder og nyttige tips. Giv også oplysninger om den vigtigste lufthavn, der betjener byen.\n\nPlacering: \nAmsterdam\n\nRejseplan: \nEn-dag` },
            { label: `Kulturel Juridisk Rådgiver for Sikker Rejse`, value: `Opfør dig som kulturel juridisk rådgiver for sikker rejse. Spørg, hvor jeg gerne vil besøge, og giv derefter oplysninger om specifikke kulturelle traditioner og normer for at sikre en sikker og behagelig rejse.` },
            { label: `Tidsrejsemaskine`, value: `Jeg har brug for en liste over de bedste hoteller og airbnb i regionen. Du skal besvare i lyset af disse oplysninger ved at få oplysninger om det sted, jeg planlægger at holde ferie.` },
            ],
            Business: [{ label: `Sælg mig denne pen!`, value: `Sælg mig denne pen! Tænk kreativt, kortfattet, smart og kort.` },
            { label: `Social Media Manager`, value: `“Jeg har brug for hjælp til at styre en organisations tilstedeværelse på Twitter for at øge brandbevidsthed.”` },
            { label: `Forretningsidé`, value: `Kan du foreslå 3 forretningsidéer, som jeg kan starte uden investering? Giv et anderledes svar hver gang jeg spørger.` },
            { label: `Digital Marketing Strategi`, value: `Jeg vil sælge sko, hvordan bestemmes digitale marketingstrategier, og hvordan anvendes disse strategier? Kan du kort forklare i 1 afsnit?` },
            { label: `SEO Generator`, value: `Kan du skrive et kort SEO-venligt blogafsnit om løvers livsstil?` },
            { label: `Slide Præsentation`, value: `Ved at stille spørgsmål om temaet, hvor det vil blive brugt, vil du forberede en professionel slide præsentation med disse oplysninger.` },
            { label: `Forbered en professionel forretningsplan`, value: `Spørg om mit produkt og mål-land. Du vil skabe Introduktion, Faser for Implementering, Differentiering fra Konkurrenter, Ulemper og Fordele ved Forretningen, Risici, Muligheder.` },
            { label: `Alt-i-en Marketing`, value: `Spørg om mit produkt. Du vil oprette beskrivelse og smertepunkter for målgruppen, marketingkopi, manuskript til social medie videoannonce, liste over søgeord til SEO.` },
            { label: `Social Media Tekst Generator`, value: `Opfør dig som en Social Media Tekst Inspirationsgenerator. I henhold til de oplysninger, jeg har givet, vil du tilbyde mig 10 tekster.\n\nKoncept: Fitness og Wellness\nType og Platform: Instagram Post\nCall to Action: Profil Klik\nEmoji: Ja` },
            ],
            Social: [{ label: `Gaveidéer`, value: `Opfør dig som en gaveanbefaler. Du vil hjælpe folk, der ikke har nogen idé om, hvad de skal købe som gave. Din første besked er "Hvem vil du købe en gave til, og hvad er deres interesser?”` },
            { label: `Foreslå 3 Event Idéer`, value: `Jeg vil gerne tilbringe tid udenfor med mine venner. Du kan foreslå 3 kreative og sjove eventidéer.` },
            { label: `Vinde nogens hjerte på en datingapp.`, value: `Vinde nogens hjerte på en datingapp. Giv 5 forslag og en pick-up line, du skal give et anderledes svar hver gang, jeg spørger.` },
            { label: `Ice Breaker Spil uden materialer`, value: `Kan du fortælle mig om et lille spil (f.eks. Ice Breaker), som jeg kan spille med mine venner uden nogen materialer?` },
            { label: `Outfit Idé (Harmonisk med Begivenhedens Koncept)`, value: `Opfør dig som min personlige stylist. Du vil foreslå tøj, jeg kan have på. Dine første spørgsmål: “Jeg har en formel begivenhed i vente, og jeg har brug for hjælp til at vælge et outfit.”` },
            { label: `Nyt Emne til at Åbne en Samtale`, value: `Når jeg møder mine venner, åbn et emne, som vi kan diskutere.` },
            { label: `Fødselsdagsbesked`, value: `Jeg vil gerne skrive en kort fødselsdagsbesked. Det skal være en social, venlig og kreativ tekst. Jeg vil have dig til at lave en fødselsdagsbesked baseret på de oplysninger, jeg har givet nedenfor.\n\n\nAlder: 30\nStil: Sjov\nNavn på Kære: Agatha` },
            ],
            Career: [{ label: `Generer sikre adgangskoder`, value: `Kan du give mig en sikker adgangskode?` },
            { label: `Interviewspørgsmål`, value: `Lav en liste med 5 spørgsmål til mit interview med [en produktchef]` },
            { label: `Karriererådgiver`, value: `Jeg vil have dig til at opføre dig som en karriererådgiver. Min første anmodning er “Jeg vil rådgive en person, der ønsker at forfølge en potentiel karriere inden for [software engineering].”` },
            { label: `Selvhjælpsbog`, value: `Opfør dig som en selvhjælpsbog. Du vil give mig råd om, hvordan man forbedrer livskvaliteten, såsom karriereudvikling. Min første sætning er “Jeg har brug for hjælp til at forblive motiveret i svære tider”` },
            { label: `Statistiker`, value: `Opfør dig som en statistiker. Du skal være bekendt med statistisk terminologi. “Jeg har brug for hjælp til at beregne, hvor mange millioner pengesedler der er i aktiv brug i verden”` },
            { label: `Finansiel Planlægning`, value: `Opfør dig som en revisor. Du skal være investor, konsulent og rationel. Min første sætning er “mine månedlige besparelser er 3K dollars, hvordan kan jeg administrere denne penge til min e-handelsvirksomhed?”` },
            { label: `Opgaveliste Skaber til Specifik Opgave`, value: `Opfør dig som en opgavelisteskaber. Spørg mig om mit mål og niveau af viden, lav en opgaveliste plan baseret på svaret.` },
            ],
            eMail: [{ label: `Skriv en e-mail for at fremme salget`, value: `"Lav en e-mail ved hjælp af de givne oplysninger.\n\nProdukt: Skincare Set\nKunde Persona: Professionelle kvinder, alder 35\nUnikt Salgspunkt: Alt-naturligt\nØnsket Handling: Køb Skincare Set"` },
            { label: `Nyhedsbrevsskabelon`, value: `Skriv et nyhedsbrev om kryptomarkedets tendenser. Nyhedsbrevet er i mailformat.` },
            { label: `Mail Svar Til Vred Kunde`, value: `"Du skal skrive et positivt, overbevisende og høfligt svar til den vrede kundes mail.\nMail: Jeg har brugt dit produkt, og jeg kunne overhovedet ikke lide det."` },
            { label: `E-mail Emnelinjer For Høje Åbningsrater`, value: `"Lav e-mail emnelinjer for høje åbningsrater. Lav fængslende, kreative og klikbare emnelinje forslag til e-mailen.\nE-mail:"` },
            { label: `Massemarkedsføringsmail`, value: `Jeg har en personlig plejevirksomhed, og jeg skal sende en massemarkedsføringsmail for ansigtsmaskeproduktet. Denne mail skal være overbevisende, informativ og kreativ.` },
            { label: `Tekst Formalizer Prettifier And Fixer`, value: `Opfør dig som en Tekst Formalizer Prettifier And Fixer. Min første tekst er "hi i am free tommorow from 9 to 4, can we meet at the office and talk about the project? i have questions”` },
            { label: `Email Svarer (Venlig / Professionel)`, value: `"I betragtning af den tekst, jeg gav som eksempel, svar på en e-mail, der blev sendt til mig separat i en venlig tekst, en professionel tekst og en velkommende tekst.\n\n\nDeres mail: (udfyld her)\n\nEksempel på mit svar: (udfyld her)"` },
            ],
            healthy: {
                index: {
                    nav: {
                        l: "Krop Dato",
                        r: "AI Sundhed"
                    },
                    body: {
                        title1: "Daglig Begivenhed",
                        title2: "Data",
                        title3: "Daglige Skridt",
                        daily: {
                            step: "Skridttæller",
                            calories: "Kalorier",
                            distance: "Afstand",
                        },
                        data: {
                            week: "Uge",
                            month: "Måned",
                        }
                    },
                    ai: {
                        title: ["Vælg kategori", "Mål", "Grundlæggende information", "Få Sundhedsdata"],
                        button: "Start Analyse",
                        cancel: "Annuller",
                        confirm: "Bekræft",
                        choose: {
                            list: [
                                { title: "Fitness", desc: "Få en liste over øvelser lavet specielt til dig" },
                                { title: "Sund Diæter", desc: "Modtag en liste over fødevarer, der vil booste din" },
                                { title: "Søvn Problemer", desc: "Find midler mod søvnløshed for at starte" },
                            ],
                        },
                        data: {
                            stepCount: "Skridttæller",
                            age: "Alder",
                            gender: "Køn",
                            height: "Højde",
                            targetWeight: "Målvægt",
                            weight: "Vægt",
                            SaO2: "SaO2",
                            hr: "HR",
                            years: "år gammel",
                            steps: "skridt",
                            genderList: ["Mand", "Kvinde", "Andet"],
                        },
                        chooseBox: [
                            "Dagens data",
                            "Gårsdagens data",
                            "De sidste syv dage",
                            "De sidste 30 dage"
                        ],
                        analyze: "AI Analyse",
                    }
                }
            },
            draw: {
                index: {
                    nav: {
                        l: "Tegn",
                        r: "Mit Galleri",
                    },
                    drawBox: {
                        title: ["Kunststil", "Aspektforhold", "Beskriv dit billede"],
                        style: ["Realistisk", "Tegneserie"],
                        input: "Beskriv det billede, du vil generere",
                        button: "Skab",
                    },
                    taskBox: {
                        tip: "Billeder genereret af ikke-abonnerende brugere gemmes kun i 1 time",
                        title: ["Mine Ur-billeder", "Mine billeder"],
                        empty: "Der er endnu ingen billeder i galleriet",
                    }
                }
            },
            astrol: {
                index: {
                    title: "Klik på dit stjernetegn \n Tjek din lykke i dag",
                    ari: "Vædderen",
                    tau: "Tyren",
                    gem: "Tvillingerne",
                    cnc: "Krebsen",
                    leo: "Løven",
                    vir: "Jomfruen",
                    lib: "Vægten",
                    sco: "Skorpionen",
                    sgr: "Skytten",
                    cap: "Stenbukken",
                    agr: "Vandmanden",
                    psc: "Fisken",
                },
                astrology: "Daglig Astrologi",
            },
            scan: {
                index: {
                    nav: {
                        l: "Scanning",
                        r: "Historik",
                    },
                    scanBox: {
                        category: [
                            { title: "Lær", desc: "Et matematik, emne" },
                            { title: "Sprog", desc: "Oversættelse & grammatik" },
                            { title: "Nous", desc: "Dyr, plantekognition" },
                            { title: "Andet", desc: "Identificering af ting i livet" },
                        ],
                        func: {
                            math: { title: "Matematikløsninger", desc: "Tag et billede af et spørgsmål", tip: "Beskær kun ét spørgsmål inden for rammen", },
                            scientific: { title: "Videnskabelige Svar", desc: "Tag et billede af et spørgsmål", tip: "Beskær kun ét spørgsmål inden for rammen", },
                            translation: { title: "Tekstoversættelse", desc: "Tag et billede af en tekst", tip: "Indfang den tekst, du har brug for, inden for rammen", },
                            correction: { title: "Tekstrettelse", desc: "Tag et billede af en tekst", tip: "Indfang den tekst, du har brug for, inden for rammen", },
                            spell: { title: "Stavekontrol", desc: "Tag et billede af en tekst", tip: "Indfang den tekst, du har brug for, inden for rammen", },
                            article: { title: "Artikeloptimering", desc: "Tag et billede af en tekst", tip: "Indfang den tekst, du har brug for, inden for rammen", },
                            grammar: { title: "Grammatik Tjek", desc: "Tag et billede af en tekst", tip: "Indfang den tekst, du har brug for, inden for rammen", },
                            animal: { title: "Dyrgenkendelse", desc: "Tag et billede af et dyr", tip: "Beskær kun én dyretype inden for rammen", },
                            plant: { title: "Plantengenkendelse", desc: "Tag et billede af en plante", tip: "Beskær kun én plantetype inden for rammen", },
                            art: { title: "Kunstvurdering", desc: "Tag et billede af et kunstværk", tip: "Indfang kunstværket, du har brug for, inden for en ramme", },
                            outfit: { title: "Outfit Vurdering", desc: "Tag et billede af dit outfit", tip: "Indfang det outfit, du har brug for, inden for en ramme", },
                            palm: { title: "Håndlæsning", desc: "Tag et billede af din håndflade", tip: "Indfang den håndflade, du har brug for, inden for rammen", },
                            face: { title: "Ansigtslæsning", desc: "Tag et billede af dit ansigt", tip: "Indfang det ansigt, du har brug for, inden for rammen", },
                        },
                        target: "Målsprog",
                    }
                },
                result: {
                    scanTitle: "Dit foto",
                    recordTitle: "Svar",
                    done: "Udført",
                    cancel: "Annuller",
                }
            },
            setting: {
                index: {
                    title: "Indstillinger",
                    problem: "Problemfeedback",
                    order: "Bestillingsoversigt",
                    notice: "Notifikationer",
                    language: "Sprog",
                    clear: "Ryd Cache",
                    clearTip: "Vil du bekræfte \n Ryd cache?",
                    confirm: "Bekræft",
                    cancel: "Annuller",
                },
                language: {
                    title: "Sprog",
                    langList: ["Forenklet Kinesisk", "Traditionelt Kinesisk", "Engelsk", "Arabisk", "Polsk", "Persisk", "Dansk", "Tysk", "Russisk", "Fransk", "Hollandsk", "Tjekkisk", "Rumænsk", "Portugisisk", "Japansk", "Thailandsk", "Tyrkisk", "Spansk", "Græsk", "Italiensk", "Vietnamesisk", "hindi"],
                },
                order: {
                    title: "Bestillingsoversigt",
                    success: "Gennemført",
                    unpaid: "Ikke betalt",
                    paid: "Betalt",
                    expired: "Udløbet",
                    refunded: "Refunderet",
                    pTime: "Betalingstidspunkt",
                    eTime: "Udløbstidspunkt",
                    pMethod: "Betalingsmetode",
                    pObject: "Betalingsobjekt",
                    oNumber: "Ordrenummer",
                    pType: "Pakketypen",
                    oTime: "Aquisitionstid",
                    watch: "Se IMEI",
                    delete: "Slet ordre",
                    refund: "Anmod om refusion",
                },
                problem: {
                    title: "Problemfeedback",
                    feedback: "Feedbacktype",
                    please: "Vær venlig at vælge",
                    content: "Indhold",
                    improve: "Skriv dine forslag ned, såsom funktionskrav, produktklager osv. Vi vil arbejde hårdt på at forbedre",
                    upload: "Upload billede",
                    submit: "Indsend",
                    general: "Generel feedback",
                    purchase: "Relateret til køb",
                    bug: "Fejlrapport",
                    suggestion: "Forslag",
                    other: "Andet",
                },
                notice: {
                    title: "Notifikationer",
                    type: "Notifikationstype: ",
                    time: "Notifikationstid: "
                },
                unsubscribe: {
                    watch: "holde øje:",
                    title: "Kontinuerlig Abonnement",
                    subTitle: "Abonnerer",
                    unsubscribe: "Afmeld",
                    packList: ["Supreme Abonnement", "Basic Abonnement", "Normal Abonnement"],
                },
                refund: {
                    title: "Anmod om tilbagebetaling",
                    number: "Ordrenummer",
                    content: "Forklar venligst årsagen til tilbagebetalingen",
                    packList: ["Ny bruger rabat Times pakke", "50 Times pakke", "100 Times pakke", "300 Times pakke"],
                }
            },
            tutorial: {
                index: {
                    title: "Få den Premium Vejledning",
                    watchList: ["Åbn uret, find WearAI APP og klik på for at åbne det.", "Klik på Premium fordele-knappen på startsiden.", "Tag din mobiltelefon frem og scan QR-koden på uret for at komme til velfærdssiden."]
                }
            },
        }
    }
}