import request from '@/utils/request'

// 用户信息 (次数/订阅)
export function getInfo(data) {
    return request({
        url: "/user/info",
        method: "POST",
        data: data,
    })
}

// 星座
export function getConstellation(data) {
    return request({
        url: "/chat/constellation",
        method: "POST",
        data: data,
    })
}

// iemi列表
export function listImei(data) {
    return request({
        url: "/chat/imei",
        method: "GET",
        params: data,
    })
}

// 语言转文字
export function postWhisper(data) {
    return request({
        url: "/chat/whisper",
        method: "POST",
        headers: {"Content-Type": "multipart/form-data",},
        data: data,
    })
}

//  对话历史
export function listRecords(query) {
    return request({
        url: "/chat/records",
        method: "GET",
        params: query,
    })
}
export function listRecordsById(query) {
    return request({
        url: "/chat/contents",
        method: "GET",
        params: query,
    })
}

//  画图
export function postDraw(data) {
    return request({
        url: "/dream/img",
        method: "POST",
        headers: {"Content-Type": "application/json",},
        data: data,
		
    })
}

export function listTasks(query) {
    return request({
        url: "/dream/list",
        method: "GET",
        params: query,
    })
}

export function postScan(data) {
    return request({
        url: "/chat/imgrecognition",
        method: "POST",
        headers: {
            'content-type': 'multipart/form-data',
        },
        data: data,
    })
}