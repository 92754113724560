module.exports = {
  language: "Finnish",
  navigator: {
    tools: "AI-työkalut",
    draw: "Piirtää",
    healthy: "Terveellinen",
  },
aitools: {
    chat: {
      gpt: "Jarvis",
      categoryList: ["Suositella", "Opiskella", "Elämä", "Luominen", "Työ"],
      presetGreet:
        "Hei, terve~ Olen sinun terveysvalvojasi, aina valmis tarjoamaan mielenkiintoista ja hyödyllistä terveys tietoa auttaakseni sinua suojaamaan terveyttäsi!",
      presetQuiz: [
        "Mitkä ovat kroonisen väsymyksen syyt?",
        "Miten nuoret voivat ehkäistä kroonisia sairauksia?",
        "Miten ylläpitää terveellisiä ruokailutottumuksia?",
        "Miten ylläpitää hyvää unta?",
      ],
      presets: "Voit kysyä minulta:",
      change: "Vaihda erä",
      uploadImage: "Lähetä kuva",
      footer:
        "Koska sisältö luodaan AI-mallin avulla, sen aitoutta ei voida täysin taata.",
      remind: "Tehtävämuistutus",
      limit:
        "Tämä keskustelu on saavuttanut rajan, aloita uusi AI-keskustelusessio",
      newDialog: "Aloita uusi keskustelu",
      stopRecording: "Napsauta mihin tahansa tai kun laskenta päättyy tunnistaaksesi",
      send: "Lähetä viesti",
      say: "Napsauta puhuaksesi",
      notImage: "Tämä agentti ei tue tiedostojen ja kuvien lähettämistä",
      talking: "Nykyinen keskustelu käynnissä",
      drawViolations:
        "AI-piirtoprompti rikkoo sääntöjä, syötä vaatimustenmukainen kehotus.",
      exhausted:
        "Kysymysten ja vastausten määrä on loppunut, siirry katsomaan loppua ja skannaa QR-koodi jatkaaksesi.",
      serverBusy: "Nykyinen verkkoyhteys on epävakaa, yritä uudelleen",
      chatHistory: "Keskusteluhistoria",
      chatHistoryDesc: "Kysymys-vastaus-tallenteet säilytetään vain 3 päivää",
      todoList: "Tehtävälista",
      todoDesc: "Tehtäväyksityiskohdat",
      todoListDesc: "Enintään 10 tehtävää voi olla olemassa",
      confirm: "Vahvista",
      cancel: "Peruuta",
      text: "Tekstin ei voi olla tyhjää",
      cancelRemind: "Peruuta muistutus",
      remindTime: "Muistutusaika:",
      remindTitle: "Muistutuksen otsikko:",
      remindContent: "Muistutuksen sisältö:",
      edit: "Muokkaa",
      reachLimit: "Lisätty rajan mukaisesti",
      errorChat:"Ääntä ei ole vielä havaittu, yritä uudelleen",
      noSTT:"Lupaa ei saatu, ota lupa käyttöön ennen kuin jatkat.",
      error50:"Tapahtui tuntematon virhe, yritä uudelleen"
    },
draw: {
      describe: "Kuvaile kuvaasi",
      voice: "Äänisyöttö",
      press: "Paina puhuaksesi",
      placeholder: "Kuvaile kuvaa, jonka haluat generoida",
      style: "Maalaustyyli",
      styleList: [
        "Inkki tyyli",
        "Kyberpunk",
        "Anime",
        "Origami",
        "Neulominen",
        "Grafiikka",
        "Sarjakuva",
        "LEGO",
        "Lyijykynä",
        "Piirtää",
      ],
      record: "Piirtohistoria",
      recordDesc: "Enintään 10 piirtohistoriaa voidaan tallentaa",
      recordEmpty: "Sinulla ei tällä hetkellä ole piirtohistoriaa",
      generate: "Generoi kuva",
      result: "Kuvan generoimisen tulos",
      download: "Lataa kuva",
      regenerate: "Generoi uudelleen",
      setDial: "Aseta kellotauluksi",
      error: "Kuvan generointi epäonnistui",
      errorDesc: "Epävakaa verkkoyhteys, lähetä uudelleen.",
      confirm: "Vahvista",
      analysiserror: "Analyysi epäonnistui",
      SetWatchErr:["Numeron asettaminen onnistui","Peruuta lähetys","Peruuta lähetys","Kellotaulun datatiedosto on liian suuri","Kellotaulun synkronointi on saavuttanut ylärajan","Bluetooth on katkaistu"],
      SetWatchTips:["Asennus epäonnistui","Numeron asetus käynnissä","Kellotaulua asetetaan, älä poistu nykyiseltä sivulta."],
      progressText:"Synkronointi"

    },
    sports: {
      analyze: "Terveysanalyysi",
      analyzeDesc: "Analysoi perusdata",
      advice: "Terveysneuvonta",
      adviceDesc: "Tarjoa terveysneuvoja",
      experience: "Kokemus",
      program: {
        plan: "Painonnostosuunnitelma",
        startDate: "Valitse aloituspäivämäärä",
        chooseDate: "Valitse 1 päivä aloituspäivämääräksi",
        week: [
          {
            num: "Su.",
            day: "Sunnuntai",
          },
          {
            num: "Ma.",
            day: "Maanantai",
          },
          {
            num: "Ti.",
            day: "Tiistai",
          },
          {
            num: "Ke.",
            day: "Keskiviikko",
          },
          {
            num: "To.",
            day: "Torstai",
          },
          {
            num: "Pe.",
            day: "Perjantai",
          },
          {
            num: "La.",
            day: "Lauantai",
          },
        ],
arrange: "Suunnitelman järjestäminen",
        arrangeDesc:
          "Aloita valitsemastasi lähimmästä päivästä, yhteensä 7 harjoituspäivää; tarkka sisältö on seuraava:",
        confirm: "Vahvista järjestely",
        success:
          "Onnittelut, olet onnistuneesti mukauttanut suunnitelman! Aloita harjoittelu 'Harjoitustarkastuksen' sivulta!",
        successButton: "Siirry harjoitustarkastukseen",
      },
      newmessage: {
        message1: ["Henkilökohtainen keskus", "Terveystiedot"],
        message2: ["Terveys", "Harjoittelu", "Ravitsemus"],
        message3: ["Sukupuoli", "Ikä", "Pituus", "Paino"],
        message4: [
          "Uni",
          "HR",
          "Happi",
          "Askeleet",
          "BP",
          "Paine",
          "Yhteenveto",
        ],
        message5: ["Kuluta", "Ehdota", "Tänään askeleet", "Harjoituksen kesto"],
        message6: [
          "Harjoitustarkastus",
          "Kuntokeskus kotiversio",
          "Kuntokeskus Pro",
          "Painonpudotussuunnitelma",
        ],
        message7: [
          "Harjoittele ja elä terveellistä elämää",
          "Mukauta kuntosuunnitelmaasi",
          "Mukauta kuntosuunnitelmaasi",
          "Luo painonpudotussuunnitelma sinulle",
        ],
        message8: [
          "Kcal",
          "Askeleet",
          "Minuutit",
          "Sarjat",
          "Päivät",
          "Vuosia",
          "cm",
          "kg",
          "h", "Viikot"
        ],
        message9: [
          "Tarkistus",
          "Harjoittelu",
          "Harjoitusvaihtoehdot",
          "Omat saavutukset",
          "Peräkkäiset tarkistuspäivät",
          "Ei tarkistettu",
        ],
        message10: [
          "Klikkaa analysoidaksesi",
          "Analyysihistoria (enintään 10 tallennettua)",
          "Analysoi",
          "Sinulla ei tällä hetkellä ole historiallisia tietoja",
          "Peräkkäiset tarkistuspäivät",
          "Tietoanalyysi",
        ],
message11: ["Nainen", "Mies"],
        message12: ["Peruuta", "Vahvista"],
        message13: ["Vuosia", "Senttejä", "Kilogrammoja"],
        message14: [
          "Manuaalinen syöttö",
          "Odotettu aika (Viikot)",
          "Sukupuoli",
          "Ikä",
          "Pituus (Senttejä)",
          "Paino (Kilogrammoja)",
          "Odotettu paino (Kilogrammoja)",
        ],
        message15: ["Historia", "Terveystietojen analyysi", "Terveysneuvonta"],
        message16: [
          "Tarkistus onnistui",
          "Tarkistetaan",
          "Voit tarkistaa vain samana päivänä",
        ],
        message17: [
          "Terveystietojen analyysi voidaan saada vain kerran päivässä, eikä paikallisia tietoja löytynyt",
          "Terveysneuvontaa voidaan saada vain kerran päivässä, eikä paikallisia tietoja löytynyt",
        ],
        message18: [
          "Olet tällä hetkellä alipainoinen",
          "BMI-indeksisi on",
          "painon jatkamista ei suositella (Terveellinen alue 18.5<BMI<24)",
        ],
        message19: [
          "Kohtuullinen painonpudotus",
          "Suositellaan, että painoa ei hävitetä yli 500g päivässä",
        ],
        message20: ["on suunnitelmia", "Katcoverko olemassa olevat suunnitelmat"],
        message21: ["Helppo", "Normaali", "Keskitaso", "Korkea"],
        message22: ["Henkinen:", "Matala", "Korkea"],
		message23: ["Käyttökerrat on käytetty. Osta tilaus, jotta voit avata lisää kertoja"],
      },
    },
    punch: {
      title: "Valmis",
      title2: "Lopeta",
      h1: "Harjoitusvaihtoehdot",
      h2: "Omat saavutukset",
      button: "Tarkistus",
      day: "Peräkkäiset tarkistuspäivät",
      dayunit: "Päivät",
    },
eat: {
      title: "Ravitsemus",
      DietaryIntake: "Syöminen",
      kcal: "Kcal",
      Stillingestible: "Jäljelle jäänyt",
      overingest: "Yli",
      Recommendedintake: "Suositus",
      Motorconsumption: "Liikunta",
      carbohydrate: "Hiilihydraatit",
      protein: "Proteiini",
      fat: "Rasva",
      gram: "Grammaa",
      drinkDk: "Veden tarkistus",
      drinkDkhealth: "Päivittäinen tarkistus terveemmän kehon hyväksi",
      eatsuggest: "Ravintosuositukset",
      analyzefoodglx: "Analysoi ruokavalion järkevyys",
      eatPlan: "Ravitsemussuunnitelma",
      makeeatPlan: "Mukauta omaa ravitsemussuunnitelmaasi",
      breakfast: "Aamiainen",
      lunch: "Lounas",
      dinner: "Illallinen",
      snack: "Välipala",
      addFood: "Lisää ruoka",
      noAddFood: "Ei lisättyä ruokaa",
      cancle: "Peruuta",
      confirm: "Vahvista",
      tips: "Aktiivinen täyttäminen katsotaan suostumukseksi kerätä tätä projektitietoa ja esittää se ravitsemuksessa",
      addFooded: "Ruoka lisätty",
      added: "Lisätty",
      numFood: "Ruoka-aineita",
      record: "Tallennus",
      dkTitle: "Tarkistus",
      dailygoal: "Päivittäinen tavoite",
      drinkDw: "Kupit/Päivä",
      drinkWater: "Juo vettä",
      dailygoalWater: "Päivittäinen veden juontitavoite",
      myachievement: "Omat saavutukset",
      lxdkDays: "Peräkkäiset tarkistuspäivät",
      day: "Päivät",
      analyzeHistory: "Historia",
      conclusion: "Yhteenveto",
      eatDataAnalyze: "Ravitsemustietojen analyysi",
      selectStartDate: "Valitse aloituspäivämäärä",
      chooseoneDate: "Valitse 1 päivä aloituspäivämääräksi",
      scheduling: "Suunnitelman järjestäminen",
      schedulingDesc:
        "Aloita valitsemastasi lähimmästä päivästä, yhteensä 7 päivää; tarkka sisältö on seuraava:",
      eatDetail: "Ravintotiedot",
      analyzeFailed: "Analyysi epäonnistui",
      noData: "ei tietoja",
    },
  },
  error: {
    error1101:"Verkkoyhteys on epävakaa, nykyinen istunnon aikakatkaisu",
    error1102:"Verkkoa ei tällä hetkellä ole, yhdistä uudelleen",
    error1103:"Tapahtui tuntematon virhe",
    error61:"Nykyinen palvelu ei ole käytettävissä",
    error4:"Ääntä ei ole vielä tunnistettu, yritä uudelleen",
    error12:"Kirjoita tavallisia sanoja tai lauseita, jotta tekoäly ymmärtää ja piirtää"
  },
  setting: {
    index: {
      title: "Asetukset",
      problem: "Ongelmapalautteet",
      order: "Tilauksen tiedot",
      notice: "Ilmoitukset",
      language: "Kieli",
      clear: "Tyhjennä välimuisti",
      clearTip: "Haluatko varmasti\ntyhjentää välimuistin?",
      confirm: "Vahvista",
      cancel: "Peruuta",
    },
    language: {
      title: "Kieli",
      langList: [
        "Yksinkertaistettu kiina",
        "Perinteinen kiina",
        "Englanti",
        "Arabia",
        "Puola",
        "Persia",
        "Tanska",
        "Saksa",
        "Venäjä",
        "Ranska",
        "Hollanti",
        "Tsekki",
        "Romania",
        "Portugali",
        "Japani",
        "Thaimaa",
        "Turkki",
        "Espanja",
        "Kreikka",
        "Italia",
        "Vietnam",
        "Hindi",
      ],
    },
order: {
      title: "Tilauksen tiedot",
      success: "Haku onnistui",
      unpaid: "Maksamatta",
      paid: "Maksettu",
      expired: "Vanhentunut",
      refunded: "Hyvitetty",
      pTime: "Maksuaika",
      eTime: "Vanhentumisaika",
      pMethod: "Maksutapa",
      pObject: "Maksuobjekti",
      oNumber: "Tilauksen numero",
      pType: "Pakettityyppi",
      oTime: "Haku aikaraja",
      watch: "Näytä IMEI",
      delete: "Poista tilaus",
      refund: "Pyydä hyvitystä",
    },
    problem: {
      title: "Ongelmapalautteet",
      feedback: "Palaute tyyppi",
      please: "Ole hyvä ja valitse",
      content: "Sisältö",
      improve:
        "Ole hyvä ja kirjoita ehdotuksesi, kuten ominaisuustoiveet, tuotevalitukset jne. Pyrimme parantamaan",
      upload: "Lataa kuva",
      submit: "Lähetä",
      general: "Yleinen palaute",
      purchase: "Ostoon liittyvä",
      bug: "Virheraportti",
      suggestion: "Ehdotus",
      other: "Muu",
    },
    notice: {
      title: "Ilmoitukset",
      type: "Ilmoitustyyppi: ",
      time: "Ilmoitusaika: ",
    },
    unsubscribe: {
      watch: "Kello:",
      title: "Jatkuva tilaus",
      subTitle: "Tilattu",
      unsubscribe: "Peruuta tilaus",
      packList: [
        "Premium-tilaus",
        "Perus-tilaus",
        "Standard-tilaus",
      ],
    },
refund: {
      title: "Pyyntö hyvityksestä",
      number: "Tilauksen numero",
      content: "Selitä hyvityksen syy",
      packList: [
        "Uuden käyttäjän alennuspaketti",
        "50 kertaa paketti",
        "100 kertaa paketti",
        "300 kertaa paketti",
      ],
    },
  },
  tutorial: {
    index: {
      title: "Hanki edistynyt opas",
      watchList: [
        "Käynnistä kello, etsi WearAI-sovellus ja napsauta sitä avatakseen.",
        "Napsauta etusivun edistyneiden etujen painiketta.",
        "Ota puhelin esiin ja skannaa kellon QR-koodi siirtyäksesi etujen sivulle.",
      ],
    },
  },
}