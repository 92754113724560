module.exports = {
	language: "magyar",
	navigator: {
		tools: "AI eszközök",
		draw: "Rajzolás",
		healthy: "Adat",
	},
	aitools: {
		chat: {
			gpt: "Jarvis",
			categoryList: ["Ajánlás", "Tanulás", "Élet", "Teremtés", "Munka"],
			presetGreet: "Hé, helló~ Én vagyok az egészségügyi gondnok, mindig készen állok arra, hogy érdekes és hasznos egészségügyi ismeretekkel lássalak el, hogy segítsek megőrizni az egészségedet!",
			presetQuiz: ["Mik az állandó fáradtság okai?", "Hogyan előzhetik meg a fiatalok a krónikus betegségeket?", "Hogyan tartsuk fenn az egészséges étkezési szokásokat?", "Hogyan biztosítsuk a jó alvást?"],
			presets: "Kérdezhetsz tőlem:",
			change: "Cserélj tételt",
			uploadImage: "Kép küldése",
			footer: "Mivel a tartalmat AI modell generálja, annak valódisága nem garantálható teljes mértékben.",
			remind: "Teendő emlékeztető",
			limit: "Ez a beszélgetés elérte a korlátot, kérlek kezdj új AI beszélgetést",
			newDialog: "Kezdj új beszélgetést",
			stopRecording: "Kattints bárhol, vagy amikor a visszaszámlálás véget ér, hogy felismerd",
			send: "Üzenet küldése",
			say: "Kattints a beszédhez",
			notImage: "Ez az ügynök nem támogatja fájlok és képek küldését",
			talking: "Jelenlegi beszélgetés folyamatban",
			drawViolations: "Az AI rajzolási utasítás sérti a szabályokat, kérlek adj meg megfelelő utasítást.",
			exhausted: "A kérdések és válaszok száma kimerült, kérlek menj az óra végéhez és szkenneld be a QR kódot a folytatáshoz.",
			serverBusy: "A jelenlegi hálózati kapcsolat instabil, kérlek próbáld újra",
			chatHistory: "Csevegéstörténet",
			chatHistoryDesc: "A feljegyzéseket csak 3 napig őrzik meg",
			todoList: "Teendő lista",
			todoDesc: "Teendők részletei",
			todoListDesc: "Legfeljebb 10 teendő létezhet",
			confirm: "Megerősít",
			cancel: "Mégsem",
			text: "A szöveg nem lehet üres",
			cancelRemind: "Emlékeztető törlése",
			remindTime: "Emlékeztető időpontja:",
			remindTitle: "Emlékeztető címe:",
			remindContent: "Emlékeztető tartalma:",
			edit: "Szerkeszt",
			reachLimit: "Elérte a határt",
			errorChat:"A hang még nem észlelhető, próbálkozzon újra",
			      noSTT:"Nincs engedély, kérjük, engedélyezze az engedélyt a folytatás előtt.",
      error50:"Ismeretlen hiba történt. Kérjük, próbálkozzon újra"
		},
		draw: {
			describe: "Írd le a képed",
			voice: "Hangbevitel",
			press: "Nyomd meg a beszédhez",
			placeholder: "Írd le a képet, amit létre szeretnél hozni",
			style: "Festési stílus",
			styleList: ["Tinta stílus", "Cyberpunk", "Anime", "Origami", "Kötés", "Grafika", "Rajzfilm","LEGO","Ceruza","Rajzolás"],
			record: "Rajzfelvétel",
			recordDesc: "Legfeljebb 10 rajzfelvétel menthető",
			recordEmpty: "Jelenleg nincs rajzfelvételed",
			generate: "Kép létrehozása",
			result: "Képgenerálás eredménye",
			download: "Kép letöltése",
			regenerate: "Újrateremtés",
			setDial: "Beállítás óraszámlapként",
			error: "Képgenerálás sikertelen",
			errorDesc: "Instabil hálózati kapcsolat, kérlek küldd be újra.",
			confirm: "Megerősít",
			analysiserror: "Elemzés sikertelen",
      SetWatchErr:["A tárcsázás sikeresen beállítva","A tárcsázás sikeresen beállítva","Az adatok ellenőrzése sikertelen","Az óralap adatfájlja túl nagy","Az óralap szinkronizálása elérte a felső határt","A Bluetooth kapcsolat megszakadt"],
      SetWatchTips:["A beállítás nem sikerült","Tárcsázási beállítás folyamatban","Az óralap beállítása folyamatban van, kérjük, ne lépjen ki az aktuális oldalról."],
      progressText:"Szinkronizál"

		},
		sports: {
			analyze: "Egészségügyi elemzés",
			analyzeDesc: "Alapadatok elemzése",
			advice: "Test javaslat",
			adviceDesc: "jó tanácsot",
			experience: "Tapasztalat",
			program: {
				plan: "Fogyás terv",
				startDate: "Válaszd ki a kezdési dátumot",
				chooseDate: "Kérjük, válasszon 1 napot kezdési dátumnak",
				week: [{
					num: "Vas.",
					day: "Vasárnap"
				}, {
					num: "Hét.",
					day: "Hétfő"
				}, {
					num: "Ked.",
					day: "Kedd"
				}, {
					num: "Sze.",
					day: "Szerda"
				}, {
					num: "Csü.",
					day: "Csütörtök"
				}, {
					num: "Pén.",
					day: "Péntek"
				}, {
					num: "Szo.",
					day: "Szombat"
				}, ],
				arrange: "Terv elrendezése",
				arrangeDesc: "Indíts a legközelebbi kiválasztott dátumtól, összesen 7 edzésnap; a konkrét tartalom a következők szerint:",
				confirm: "Az elrendezés megerősítése",
				success: "Gratulálok, sikeresen személyre szabhatta a tervet! Kezdje el edzését az „Edző bejelentkezés” oldalon!",
				successButton: "Edző bejelentkezéshez",
			},
			newmessage: {
				message1: ['Személyes központ', 'Egészségügyi adatok'],
				message2: ['Egészség', 'Sport', 'Diéta'],
				message3: ['Nem', 'Kor', 'Magasság', 'Súly'],
				message4: ['Alvás', 'HR', 'Oxigén', 'Lépések', 'BP', 'Nyomás', 'Összegzés'],
				message5: ['Fogyasztás', 'Javaslat', 'Mai lépések', 'Edzési időtartam'],
				message6: ['Edző bejelentkezés', 'Fitnesz otthoni kiadás', 'Fitnesz Pro', 'Fogyás terv'],
				message7: ['Edzés és egészséges élet', 'Személyre szabott fitnesz terv készítése', 'Személyre szabott fitnesz terv készítése', 'Fogyás terv készítése neked'],
				message8: ['Kcal', 'Lépések', 'Perc', 'Sorozat', 'Napok', 'Évek', 'cm', 'kg', 'óra','Hetek'],
				message9: ['Bejelentkezés', 'Edzés', 'Edzés opciók', 'Eredményeim', 'Folyamatos bejelentkezés', 'Nincs bejelentkezve'],
				message10: ['Kattintson az elemzéshez', 'Elemzés története (legfeljebb 10 menthető)', 'Elemzés', 'Jelenleg nincsenek történeti rekordjai', 'Folyamatos bejelentkezés', 'Adatok elemzése'],
				message11: ['Nő', 'Férfi'],
				message12: ['Mégsem', 'Megerősít'],
				message13: ['Évek', 'Centiméterek', 'Kilogrammok'],
				message14: ['Kézi bevitel', 'Perkiraan waktu (Pekan)', 'Nem', 'Kor', 'Magasság (centiméter)',
					'Súly (kilogramm)', 'Várható súly (kilogramm)'
				],
				message15: ['Történelem', 'Egészségügyi adatok elemzése', 'Test javaslat'],
				message16: ['Sikeres bejelentkezés', 'Bejelentkezés folyamatban', 'Csak ugyanazon a napon lehet bejelentkezni'],
				message17: ['Egészségügyi adatok elemzése csak egyszer beszerezhető naponta, és helyi adatok nem találhatók', 'Egészségügyi tanács csak egyszer beszerezhető naponta, és helyi adatok nem találhatók'],
				message18: ['Jelenleg alultáplált', 'Az Ön BMI indexe', 'nem tanácsos tovább fogyni (Egészséges tartomány 18.5<BMI<24)'],
				message19: ['Mérsékelt fogyás', 'Javasolt, hogy naponta legfeljebb 500g-ot fogyjon'],
				message20: ['tervei vannak', 'Fedi a meglévő terveket'],
				message21: ['Könnyű', 'Normál', 'Közepes', 'Magas'],
				message22: ['Lélek:', 'Alacsony', 'Magas'],
				message23: ['A felhasznált alkalmak száma Vásároljon előfizetést, hogy több alkalommal is feloldjon.'],
			},
		},
		punch: {
			title: "Kész",
			title2: "Befejezés",
			h1: "Edzés opciók",
			h2: "Eredményeim",
			button: "Bejelentkezés",
			day: "Folyamatos bejelentkezés",
			dayunit: "Napok",
		},
		eat: {
			title: "Diéta",
			DietaryIntake: "Ételbevitel",
			kcal: "Kcal",
			Stillingestible: "Maradék",
			overingest: "Túllép",
			Recommendedintake: "Ajánlani",
			Motorconsumption: "Mozgás",
			carbohydrate: "Szénhidrát",
			protein: "Fehérje",
			fat: "Zsír",
			gram: "g",
			drinkDk: "Víz Bejelentkezés",
			drinkDkhealth: "A víz ivása egészségesebb",
			eatsuggest: "Étrend javaslatok",
			analyzefoodglx: "Elemezze az étrend ésszerűségét",
			eatPlan: "Étrend Terv",
			makeeatPlan: "Személyre szabott étrend terv készítése",
			breakfast: "Reggeli",
			lunch: "Ebéd",
			dinner: "Vacsora",
			snack: "Snack",
			addFood: "Étel hozzáadása",
			noAddFood: "Nincs hozzáadott étel",
			cancle: "Mégsem",
			confirm: "Megerősít",
			tips: "Az önkéntes kitöltést úgy tekintjük, hogy beleegyezik abba, hogy összegyűjtsük ezt a projekt információt és megjelenítsük az étrendben",
			addFooded: "Hozzáadott étel",
			added: "Hozzáadva",
			numFood: "Ételek száma",
			record: "Rekord",
			dkTitle: "Bejelentkezés",
			dailygoal: "Napi cél",
			drinkDw: "Csészék/Nap",
			drinkWater: "Vizet inni",
			dailygoalWater: "Napi vízivási cél",
			myachievement: "Eredményeim",
			lxdkDays: "Folyamatos bejelentkezés",
			day: "Napok",
			analyzeHistory: "Történelem",
			conclusion: "Összegzés",
			eatDataAnalyze: "Étrend adatainak elemzése",
			selectStartDate: "Válaszd ki a kezdési dátumot",
			chooseoneDate: "Kérjük, válasszon 1 napot kezdési dátumként",
			scheduling: "Terv elrendezése",
			schedulingDesc: "Indíts a legközelebbi választott dátumtól, összesen 7 nap; a konkrét tartalom a következők szerint:",
			eatDetail: "Étrend részletei",
			analyzeFailed: "Elemzés sikertelen",
			noData: "nincs adat"

		}
	},
  error: {
    error1101:"A hálózati kapcsolat instabil, az aktuális munkamenet időkorlátja",
    error1102:"Jelenleg nincs hálózat, kérjük, csatlakozzon újra",
    error1103:"Ismeretlen hiba történt",
    error61:"A jelenlegi szolgáltatás nem elérhető",
    error4:"A hang még nem észlelhető, próbálkozzon újra",
    error12:"Kérjük, írjon be szabványos szavakat vagy mondatokat a mesterséges intelligencia megértéséhez és rajzolásához",
		error50:"Sajnálom, az aktuális szerver rossz, kérjük, próbálja újra"
  },
	setting: {
		index: {
			title: "Beállítások",
			problem: "Probléma visszajelzés",
			order: "Rendelési rekordok",
			notice: "Értesítés",
			language: "Nyelv",
			clear: "Gyorsítótár törlése",
			clearTip: "Biztosan törölni akarja a\n gyorsítótárat?",
			confirm: "Megerősít",
			cancel: "Mégsem",
		},
		language: {
			title: "Nyelv",
			langList: ["Egyszerűsített kínai", "Hagyományos kínai", "Angol", "Arab", "Bolgár", "Bengáli", "Cseh", "Német", "Görög", "Spanyol", "Perzsa", "Filippínó", "Francia", "Hindi", "Magyar", "Indonéz", "Olasz", "Héber", "Japán", "Koreai", "Maláj", "Holland", "Pandzsábi", "Lengyel", "Portugál", "Román", "Orosz", "Szlovák", "Thai", "Török", "Ukrán", "Urdu", "Vietnami"],
		},
		order: {
			title: "Rendelési rekordok",
			success: "Sikeresen lekérve",
			unpaid: "Kifizetetlen",
			paid: "Kifizetett",
			expired: "Lejárt",
			refunded: "Visszatérítve",
			pTime: "Fizetési idő",
			eTime: "Lejárati idő",
			pMethod: "Fizetési mód",
			pObject: "Fizetési objektum",
			oNumber: "Rendelési szám",
			pType: "Csomag típusa",
			oTime: "Lekérési idő",
			watch: "IMEI megtekintése",
			delete: "Rendelés törlése",
			refund: "Visszatérítés kérése",
		},
		problem: {
			title: "Probléma visszajelzés",
			feedback: "Visszajelzés típusa",
			please: "Kérjük válasszon",
			content: "Tartalom",
			improve: "Kérjük, írja le javaslatait, például funkciókéréseket, termékpanaszokat stb. Igyekszünk javítani",
			upload: "Kép feltöltése",
			submit: "Beküldés",
			general: "Általános visszajelzés",
			purchase: "Vásárlással kapcsolatos",
			bug: "Hibajelentés",
			suggestion: "Javaslat",
			other: "Egyéb",
		},
		notice: {
			title: "Értesítés",
			type: "Értesítés típusa: ",
			time: "Értesítés ideje: "
		},
		unsubscribe: {
			watch: "Óra:",
			title: "Folyamatos előfizetés",
			subTitle: "Előfizetve",
			unsubscribe: "Leiratkozás",
			packList: ["Prémium előfizetés", "Alap előfizetés", "Standard előfizetés"],
		},
		refund: {
			title: "Visszatérítés igénylése",
			number: "Rendelési szám",
			content: "Kérjük, magyarázza el a visszatérítés okát",
			packList: ["Új felhasználói kedvezmény csomag", "50 alkalom csomag", "100 alkalom csomag", "300 alkalom csomag"],
		}
	},
	tutorial: {
		index: {
			title: "Fejlett oktatóanyag beszerzése",
			watchList: ["Kapcsolja be az órát, keresse meg a WearAI alkalmazást és kattintson a megnyitásra.",
				"Klikkeljen az előnyök gombra a kezdőlapodon.",
				"Vegye elő mobiltelefonját és olvassa be az órán található QR-kódot, hogy belépjen az előnyök oldalára."
			]
		}
	},
}